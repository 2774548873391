export const activities = {
    actionButtonsInMultiMatrix: {
        default: ['previousActivityInMulti', 'nextActivityInMulti'],
        CRT: ['previousActivityInMulti', 'nextActivityInMulti', 'hideSideNavigation'],
        summary: ['nextActivityInMulti', 'hideSideNavigation'],
    },
    allowErrorReporting: false,
    cardLayout: 'card-split-media-info',
    displayLearnerInfo: false,
    displayCreateLessonHelper: true,
    filters: {
        default: ['title', 'keywords', 'type']
    },
    grade: false,
    hideUserActionButtons: true,
    isOnlyModelLesson: true, // lesson menu will not contain tab menu. menu are on the left we reuse an already existing setting.
    latexKeyboard: false,
    lessonDialogFields: {
        default: ['title', 'thumbnail', 'educationnalLevel', 'description'],
    },
    lessonDisplayCardHeaderMenu: true,
    lessonStep: {
        steps: 4,
        markerFields: ['title', 'location', 'question', 'clue', 'tracks', 'url'],
        typeSteps: [
            {
                type: 'VIDEO',
                label: 'activities.we_watch'
            },
            {
                type: 'LESSON',
                label: 'activities.we_vote',
                icon: 'video_library',
                subLessonType: ['POLL'],
                limit: 5,
                config: {'poll': true},
                summary: false
            },
            {
                type: 'VIDEO',
                icon: 'video_library',
                label: 'activities.we_investigate',
                dataFromStep: {
                    stepIndex: 0,
                    readOnly: true,
                },
                markers: true,
                markersLimit: 6,
                config: {'markers': true}
            },
            {
                type: 'LESSON',
                icon: 'video_library',
                label: 'activities.we_revote',
                dataFromStep: {
                    stepIndex: 1,
                    readOnly: true,
                },
                config: {'poll': true},
                summary: true
            },
            {
                type: 'SUMMARY',
                icon: 'video_library',
                label: 'activities.we_summarize',
                dataFromStep: {
                    stepIndex: 2,
                    readOnly: true,
                },
                config: {'markers': true}
            }],
        insidePollColor: '#00C0FF',
        outsidePollColor: '#EC184F'
    },
    loadLessonWithSublesson: {
        multi_step: 0
    },
    openDialogInfoAfterDuplicateLesson: true,
    preActionMatrix: {
        launchNextActivity: ['saveAnswer'],
        launchPreviousActivity: ['saveAnswer']
    },
    progress: false,
    saveOnDestroy: ['shortAnswer'],
    searchFields: ['title', 'educationnalLevel', 'launchSearch', 'countEntities', 'bookmarks'], // this fields are auto order like the list order
    showMetadataButton: false,
    stepper: {
        align: 'center',
        position: 'bottom',
        showRecap: false,
        stepValidationNeedSave: true,
    }
};