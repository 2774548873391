import {Component, OnDestroy, OnInit} from '@angular/core';
import {GraphAssignationService} from './graph-assignation.service';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {Subscription} from 'rxjs/Subscription';
import {Subject} from 'rxjs/index';
import {Group, Learner} from '@modules/groups-management/core/definitions';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {localizedDate} from '../../../shared/utils';
import {FormControl} from '@angular/forms';
import {DateAdapter} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-graph-assignation',
  templateUrl: './graph-assignation.component.html',
  styleUrls: ['./graph-assignation.component.scss']
})
export class GraphAssignationComponent implements OnInit, OnDestroy {

    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
    };
    assignmentGroupListSubscription: Subscription;
    private unsubscribeInTakeUntil = new Subject();

    public displayedFilters: string[] = [];

    assignmentGroupList: any = [];
    learnersList: any[] = [];
    groupsList: any[] = [];
    public allTypes: any[] = [];

    graphConfigObs: Subject<any> = new Subject();
    graphOptions: any;
    averageLearnerGrade = '';
    averageGroupGrade = '';

    /* Filters Form */
    startDateControl = new FormControl('');
    dueDateControl = new FormControl('');
    groupControl = new FormControl('');
    typeControl = new FormControl('');
    learnerControl = new FormControl('all');

    urlImgProfile: string;
    isLearnerInformationShowed = false;

    noData = '';
    minDueDate: any;
    maxStartDate: any;

    constructor(private graphService: GraphAssignationService,
                private octopusConnect: OctopusConnectService,
                private adapterLocalDate: DateAdapter<any>,
                private translate: TranslateService) {

        this.startDateControl.valueChanges.subscribe((data) => {
            if (data) {
                this.applyFilters( data.format('X'), 'date_from' );
                this.minDueLimit =  new Date(+data.format('x'));
            } else {
                this.applyFilters( null, 'date_from' );
                this.minDueLimit =  null;
            }
        });

        this.dueDateControl.valueChanges.subscribe((data) => {
            if (data) {
                const newDate = new Date(+data.format('x'));
                newDate.setHours(23);
                newDate.setMinutes(59);
                this.applyFilters(newDate.getTime() / 1000, 'date_to' );
                this.maxStartLimit =  new Date(+data.format('x'));
            } else {
                this.applyFilters( null, 'date_to' );
                this.maxStartLimit =  null;
            }
        });

        this.typeControl.valueChanges.subscribe((data) => {
            if (data) {
                this.applyFilters( data.id, 'type_term' );
            } else {
                this.applyFilters( null, 'type_term' );
            }
        });

        this.adapterLocalDate.setLocale(this.translate.currentLang);

        this.translate.onLangChange.subscribe(() => {
            this.adapterLocalDate.setLocale(this.translate.currentLang);

        });

        this.groupControl.valueChanges.subscribe((data) => {
            if (data) {
                this.learnersList = [...this.learners.filter((learner) => learner.groups.indexOf(data.groupname) !== -1)];
                if (this.isUserTrainer) {
                    this.learnerControl.setValue('all');
                }
                this.applyFilters(data.id, 'groups');
            } else {
                this.applyFilters(null, 'groups');
            }

        });
    }

  ngOnInit(): any {
      this.translate
          .get('graph-assignation.no-data')
          .subscribe((translation: string) => this.noData = translation);

      if (this.graphService.graphSettings) {
            if (this.isUserTrainer) {
                this.displayedFilters = this.graphService.graphSettings['default'];
            } else {
                this.learnerControl.setValue(this.graphService.userData);
                this.displayedFilters = this.graphService.graphSettings['learner'];
            }
      }
      this.assignmentGroupListSubscription = this.refreshList();

      this.graphService.loadAssignationsGroupTypes().subscribe(types => {
          this.allTypes = types;
      });

  }

    refreshList(): Subscription {
        return this.assignmentGroupListSubscription = this.graphService.loadPaginatedAssignmentGroupList(this.optionsInterface)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((assignmentGroupList: DataEntity[]) => {
                this.assignmentGroupList = assignmentGroupList;

                this.graphService.setAssignments = this.assignmentGroupList;
                if (!this.assignmentGroupList) {
                    return;
                }

                if (!this.groupControl.value) {
                    this.groupsList = this.groups;
                    this.learnersList = this.learners;
                }

                // TODO ameliorer ces conditions...
                if (this.groupsList.length) {
                    if (!this.groupControl.value) {
                        if (!this.isUserTrainer) {
                            this.groupControl.setValue(this.groupsList.filter((group) => this.graphService.userData.get('groups').indexOf(+group.id))[0]);
                        } else {
                            this.groupControl.setValue(this.groupsList[0]);
                        }
                        this.prepareForRefresh();
                    } else {
                        this.setConfig();
                    }
                } else {
                    this.graphOptions = {};
                    this.graphOptions['chartData'] = [{
                        data: [0, 0, 0, 0],
                        label: this.noData,
                    }];
                    this.graphOptions['chartLabels'] = [this.noData];
                    this.setConfig();
                }

                this.isLearnerInformationShowed = this.learnerControl.value !== 'all';
                this.resetAvatar();
            });
    }

    prepareForRefresh(): void {
        this.resetTakeUntil();
        this.generate();
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    applyFilters(val, type): void {
        if (val === 'all' || !val) {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = val;
        }
    }

    setConfig(): any {
        this.graphOptions = {};
        this.graphOptions['chartData'] = [];
        this.graphOptions['chartLabels'] = [];
        this.graphOptions['chartColors'] = [];
        const gradeArrayGroup = [];
        const gradeArrayLearner = [];
        const gradeArrayLearnerAndCoeff = [];
        const gradeArrayGroupAndCoeff = [];
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const pointTitles = [];
        const allGrades = [];
        const assignmentIds = [];
        const ratingsBase = [];
        let assignmentGroupListFiltered = this.assignmentGroupList.filter((assign) => assign.get('average') != null );
        const allCoeff = [];

        if (this.learnerControl.value && this.learnerControl.value !== 'all') {
             assignmentGroupListFiltered = this.assignmentGroupList.filter((assign) => assign.get('ratings')[this.learnerControl.value.id] >= 0 );
        }

        const pointRadius = {
            1: 8,
            2: 12,
            4: 16,
        };

        if (assignmentGroupListFiltered.length) {
            for (const assignmentGroup of assignmentGroupListFiltered) {
                const gradeGroup =  [];
                const coeff = +assignmentGroup.get('rating_base');
                for (const grade in assignmentGroup.get('ratings')) {
                    if (assignmentGroup.get('ratings')[grade] >= 0) {
                        gradeArrayGroup.push(assignmentGroup.get('ratings')[grade] * coeff);
                        gradeGroup.push(assignmentGroup.get('ratings')[grade] * coeff);
                    }
                }
                allCoeff.push(coeff);
                assignmentIds.push(assignmentGroup.get('assignations'));
                allGrades.push(gradeGroup);
                pointTitles.push(assignmentGroup.get('lesson'));

                gradeArrayGroupAndCoeff.push(assignmentGroup.get('average') * coeff);
                const data = {
                    data: assignmentGroupListFiltered.map((entity) => entity.get('average')),
                    label: [assignmentGroup.get('lesson'), 'class'],
                };
                this.graphOptions['chartData'].push(data);

                ratingsBase.push(assignmentGroup.get('rating_base'));

                this.graphOptions['chartColors'].push({
                    pointRadius:  assignmentGroupListFiltered.map((assign) => pointRadius[+assign.get('rating_base')]),
                    pointHoverRadius: assignmentGroupListFiltered.map((assign) => pointRadius[+assign.get('rating_base')]),
                    pointBackgroundColor: '#A9A9A9',
                    borderWidth: 2, // taille bordure
                    borderColor: '#A9A9A9', // couleur bordure ligne
                    pointBorderColor: 'transparent', // couleur bordure point,
                    backgroundColor: 'transparent',
                    showLine:  false
                });

                this.graphOptions['chartLabels'].push(localizedDate(assignmentGroup.get('dates')));

                if (this.learnerControl.value !== 'all' || !this.isUserTrainer) {
                    gradeArrayLearner.push(+assignmentGroup.get('ratings')[this.learnerControl.value.id]);
                    gradeArrayLearnerAndCoeff.push(+assignmentGroup.get('ratings')[this.learnerControl.value.id] * coeff);
                }

            }

            if (gradeArrayGroupAndCoeff.length) {
                this.setAverageGroup =  Math.round((gradeArrayGroupAndCoeff.reduce(reducer) / allCoeff.reduce(reducer)) * 100) / 100;
            } else {
                this.setAverageGroup = null;
            }

            if (this.learnerControl.value !== 'all' || !this.isUserTrainer) {
                this.graphOptions['chartData'].push({
                    data: gradeArrayLearner,
                    label: ['learner'],
                });

                this.graphOptions['chartData'].reverse();


                this.graphOptions['chartColors'].push({
                    showLine:  true,
                    pointRadius:  assignmentGroupListFiltered.map((assign) => pointRadius[+assign.get('rating_base')]),
                    pointHoverRadius:  assignmentGroupListFiltered.map((assign) => pointRadius[+assign.get('rating_base')]),
                    pointBackgroundColor: assignmentGroupListFiltered.map((assign) => this.getStyle(assign.get('type_term').label)),
                    borderWidth: 2, // taille bordure
                    borderColor: '#4B4B4B', // couleur bordure ligne
                    backgroundColor: 'transparent',
                    pointBorderColor: 'transparent', // couleur bordure point
                });

                this.graphOptions['chartColors'].reverse();
                this.graphOptions['chartColors'][1].showLine = true;


                if (gradeArrayLearner.length) {
                    this.setAverageLearner =  Math.round((gradeArrayLearnerAndCoeff.reduce(reducer) / allCoeff.reduce(reducer)) * 100) / 100;
                } else {
                    this.setAverageLearner = null;
                }
            }

            this.graphOptions['titles'] = pointTitles;
            this.graphOptions['types'] = assignmentGroupListFiltered.map((assign) => assign.get('type_term').label);
            this.graphOptions['learnersData'] = {
                learnerSelected: this.learnerControl.value !== 'all',
                learnersGrades: allGrades,
            };
            this.graphOptions['assignmentIdList'] = this.learnerControl.value !== 'all' &&
                assignmentGroupListFiltered.map((assignmentGroup) => assignmentGroup.get('assignations')[this.learnerControl.value.id].id);
            this.graphOptions['lessonIdList'] = assignmentGroupListFiltered.map((assignmentGroup) => assignmentGroup.get('lessonId'));
        } else {
            this.averageLearnerGrade = null;
            this.averageGroupGrade = null;
        }


        this.graphConfigObs.next(this.graphOptions);
    }

    public getStyle(data): any {
        const style = {
            homework : '#24B7C7',
            assessment: '#006D86',
            default: '#A9A9A9'
        };
        return style[data] ? style[data] : style.default;
    }

    public get groups(): Array<Group> {
        if (this.graphService.groupsList) {
            return this.graphService.groupsList;
        }

        return [];
    }

    public get learners(): Array<Learner> {
        if (this.graphService.learnersList) {
            return this.graphService.learnersList;
        }

        return [];
    }

    public get groupsFiltered(): Array<Group> {
        if (this.groupsList) {
            return this.groupsList;
        }

        return this.groups;
    }

    public get learnersFiltered(): Array<Learner> {
        if (this.learnersList) {
            return this.learnersList;
        }

        return this.learners;
    }

    public set setAverageLearner(data) {
        this.averageLearnerGrade = this.calculateResult(+data) + ' / 20';

    }
    public set setAverageGroup(data) {
        this.averageGroupGrade = this.calculateResult(+data) + ' / 20';
    }

    calculateResult(data): number{
        if (data > 0) {
            return Math.round(+data * 100) / 100;
        } else {
            return 0;
        }
    }

    public get averageLearner(): string {
        return this.averageLearnerGrade ? this.averageLearnerGrade :  this.noData;
    }
    public get averageGroup(): string {
        return this.averageGroupGrade ? this.averageGroupGrade :  this.noData;
    }

    public get validGraph(): boolean {
        return this.groupControl.value || this.learnerControl.value;
    }

    public generate(): void {
        if (this.assignmentGroupListSubscription) {
            this.assignmentGroupListSubscription.unsubscribe();
        }
        this.assignmentGroupListSubscription = this.refreshList();
    }

    public get typeList(): any[] {
        return this.allTypes;
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    resetTakeUntil(): void {
        if (this.unsubscribeInTakeUntil) {
            this.unsubscribeInTakeUntil.next();
            this.unsubscribeInTakeUntil.complete();
        }
        this.unsubscribeInTakeUntil = new Subject();
    }

    public get showAverageLabel(): boolean {
        return !this.graphOptions ||
            !this.graphOptions['chartData'] ||
            this.graphOptions['chartData'].length === 0;
    }

    public get isUserTrainer(): boolean {
        return this.graphService.isUserTrainer;
    }

    public set minDueLimit(data) {
        this.minDueDate = data;
    }

    public set maxStartLimit(data) {
        this.maxStartDate = data;
    }

    public get dateLimit(): any {
        return this.minDueDate;
    }

    public get maxDateLimit(): any {
        return this.maxStartDate;
    }

    ngOnDestroy(): any {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private resetAvatar(): void {
        this.urlImgProfile = null;

        // On part du principe que si le User n'est pas Trainer, c'est qu'il est le learner donc le selectedLearner
        if (this.isUserTrainer === false) {
            this.urlImgProfile = this.graphService.userData.get('picture');
        } else if (this.learnerControl.value) {
            this.urlImgProfile = this.learnerControl.value.avatar;
        }
    }
}
