import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';


@Component({
  selector: 'app-groups-management',
  templateUrl: './groups-management.component.html',
  styleUrls: ['./groups-management.component.scss']
})
export class GroupsManagementComponent implements OnInit {

    public displayHeader = true;
    public displayFilters = true;

  constructor(public authService: AuthenticationService,
              public groupsService: GroupsManagementService) { }

  ngOnInit(): void {
        this.displayHeader = this.groupsService.settings.displayHeader;
        this.displayFilters = this.groupsService.settings.displayFilters;
  }

}
