import {Injectable} from '@angular/core';
import {DataEntity, DataCollection, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {MatDialog} from '@angular/material';
import {Observable, combineLatest, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management';
import {TranslateService} from '@ngx-translate/core';
import {AgendaEventUI, AgendaEventAPI, EventFilters} from './definitions';
import {AuthenticationService} from '@modules/authentication';
import {defaultApiURL} from 'app/settings';
import {DayGridView} from '@fullcalendar/daygrid';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AgendaService {

    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';
    private _userProfile: DataEntity;

    learnersList: any[];
    rawGroupsList: any[];
    rawWorkgroupsList: any[];


    public events: DataCollection;
    public assignations: DataCollection;

    public groupsReady = new ReplaySubject();

    constructor(
        private communicationCenter: CommunicationCenterService,
        private connector: OctopusConnectService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this._userProfile = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private postLogout(): void {
    }

    private postAuthentication(): void {

        const learners = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList');
        const groups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');
        const workgroups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');
        const project = this.communicationCenter
            .getRoom('project-management')
            .getSubject('selectedProject');

        combineLatest(learners, groups, workgroups).subscribe((data: [any, any, any]) => {
            this.learnersList = data[0];
            this.rawGroupsList = data[1];
            this.rawWorkgroupsList = data[2];
            this.groupsReady.next(true);
        });
    }

    public get groupsList(): any[] {
        const filteredGroups = this.rawGroupsList.filter((group) => !group.archived);
        return filteredGroups;
    }

    public get workgroupsList(): any[] {
        const filteredGroups = this.rawWorkgroupsList.filter((group) => !group.archived);
        return filteredGroups;
    }

    /**
     * Get date filter from start and end given in the view
     * @param view DayGridView the appropriate view
     */
    public getFiltersFromView(view: DayGridView): EventFilters {
        const start = moment(view.currentStart).subtract(1, 'days');
        const end = moment(view.currentEnd).add(1, 'days');

        return {
            date_from: start.unix(),
            date_to: end.unix()
        };
    }

    /**
     * Load events according to the given filters
     * @param filters EventFilters
     */
    public searchAllEvents(filters: EventFilters): Observable<AgendaEventUI[]> {
        let observables = new Array<Observable<AgendaEventUI[]>>();

        if (!filters || (filters && (filters.type_term === -1 || filters.type_term == null))) {
            observables.push(this.getEvents(filters).map(events => events.entities.map(entity => AgendaEventUI.fromEventEntity(entity))));
        }

        if (!filters || (filters && (filters.type_term >= 0 || filters.type_term == null))) {
            observables.push(this.getAssignations(filters).map(assignations => assignations.entities.map(entity => AgendaEventUI.fromAssignationEntity(entity))));
        }

        return combineLatest(observables).map((events: AgendaEventUI[][]) => {
            return events.flat(1);
        });
    }

    getEvents(filters?: EventFilters): Observable<DataCollection> {
        const sanitizedFilters = {...filters};
        delete sanitizedFilters.type_term;
        delete sanitizedFilters.date_from;
        delete sanitizedFilters.date_to;
        if (sanitizedFilters && sanitizedFilters !== {}) {
            return this.connector.loadCollection('events', sanitizedFilters);
        } else {
            return this.connector.loadCollection('events');
        }
    }

    getAssignations(filters?: EventFilters): Observable<DataCollection> {

        if (filters && filters !== {}) {
            return this.connector.loadCollection('event-assignation', filters);
        } else {
            return this.connector.loadCollection('event-assignation');
        }

    }

    getAssignationTypes(): Observable<Array<{id: string | number, label: string}>> {
        return this.connector.loadCollection('variables/instance')
            .map((collection: DataCollection) => collection.entities[0].get('assignationsGroupTypes'))
            .take(1);
    }

    addEvent(event: AgendaEventUI): Observable<DataEntity> {
        return this.connector.createEntity('events', AgendaEventAPI.fromUI(event));
    }

    updateEvent(event: AgendaEventUI): Observable<DataEntity> {
        event.extendedProps.entity.attributes = {
            ...event.extendedProps.entity.attributes,
            ...AgendaEventAPI.fromUI(event)
        };
        return event.extendedProps.entity.save();
    }

    deleteEvent(event: AgendaEventUI): Observable<boolean> {
        return this.connector.deleteEntity(event.extendedProps.entity).take(1);
    }

    launchAssignment(event: any): void {
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .take(1)
            .subscribe((data) => {
                if (data !== null) {
                    this.router.navigate(['followed', 'assignment', 'lessons', event.extendedProps.entity.attributes.lessonId, 'player']);
                }
            });

        const userId = this.authenticationService.userData.id;
        const assignmentId = event.extendedProps.entity.attributes.assignations[userId].id;
        this.communicationCenter
            .getRoom('assignment')
            .next('launch', assignmentId);
    }
}
