import { Component, OnInit } from '@angular/core';
import {fuseAnimations} from 'fuse-core/animations';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {Observable} from 'rxjs/Observable';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'fuse-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss'],
  animations : fuseAnimations
})
export class FuseValidateEmailComponent implements OnInit {

    token: string;

    constructor( private connector: OctopusConnectService, private route: ActivatedRoute, public router: Router,
                 private usersProvider: AccountManagementProviderService,
                 private profileProvider: ProfileService) {
    }

    ngOnInit() {
        this.route.params.subscribe(( value: Object ) => {
            this.token = value['token'];
            this.setToken(this.token);

        });
    }

    setToken(token){

        let myDate = new Date();
        let timestampDate;
        myDate.setHours(myDate.getHours() + 24);
        timestampDate = Math.floor(new Date(myDate).getTime());

        localStorage.setItem('http_accessToken', JSON.stringify(token));
        localStorage.setItem('http_expires_in',  JSON.stringify(timestampDate));
        localStorage.setItem('http_currentUser', JSON.stringify({}));

        console.log(localStorage.getItem('http_currentUser'));

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.subscribe((userData: DataEntity) => {

            this.profileProvider.editValidateMail( userData );
            this.usersProvider.loggedUser = userData;
            this.profileProvider.userInformation = userData;
        }, () => {
            console.log('error');
        });

    }

}
