import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { DataEntity } from 'octopus-connect';
import { Creature, BadgeType } from '../definitions';

@Component({
  selector: 'app-animal-canvas',
  templateUrl: './animal-canvas.component.html',
  styleUrls: ['./animal-canvas.component.scss']
})
export class AnimalCanvasComponent implements OnInit, AfterViewInit {
  _elements: Array<DataEntity> | undefined;
  isReady = false;
  /** Template reference to the canvas element */
  @ViewChild('composition')
  canvasEl: ElementRef;
  @ViewChild('avatar')
  avatarCanvasEl: ElementRef;
  @ViewChild('compositionContainer')
  container: ElementRef;


  /** Canvas 2d context */
  private context: CanvasRenderingContext2D;

  private _creature: Creature;
  /**
   * Getter creature
   * @return {Creature}
   */
  public get creature(): Creature {
    return this._creature;
  }

  /**
   * Setter creature
   * @param {Creature} value
   */
  @Input()
  public set creature(value: Creature) {
    this._creature = value;
    this.elements = undefined;
    this.refresh();

  }

  get elements(): Array<DataEntity> {
    if (!this._creature) {
      return new Array<DataEntity>();
    }
    const selectedAssociatedBadges = this._elements ? this._elements : [
      ...this._creature.accessories.filter(e => e.attributes.selected === true),
      this._creature.universes.find(u => u.attributes.selected === true)
    ];
    return [this._creature.creature, ...selectedAssociatedBadges]
      .filter(e => e)
      .sort((a, b) => a.attributes['z-index'] - b.attributes['z-index']);
  }

  @Input()
  set elements(elements: Array<DataEntity> | undefined) {
    this._elements = elements;
  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.refresh();
  }

  refresh(overrideElements?: Array<DataEntity>): void {

    if (overrideElements) {
      this.elements = overrideElements;
    }

    (this.canvasEl.nativeElement as HTMLCanvasElement).width = 1920;
    (this.canvasEl.nativeElement as HTMLCanvasElement).height = 1080;

    this.context = (this.canvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
    this.draw();
  }

  /**
  * Draws something using the context we obtained earlier on
  */
  private async draw(): Promise<void> {
    if (!this.elements) {
      return;
    }
    this.isReady = false;
    this.context.clearRect(0, 0, (this.canvasEl.nativeElement as HTMLCanvasElement).width, (this.canvasEl.nativeElement as HTMLCanvasElement).height);

    const imgsToDraw = new Array<{ img: HTMLImageElement; leftOffset: number; topOffset: number; newWidth: number; newHeight: number }>();
    const creature = this.elements.find(e => e.attributes.type.name === BadgeType.Creature).attributes;

    for (let i = 0; i < this.elements.length; i++) {
      const cd = this.elements[i];
      let topOffset = 0;
      let leftOffset = 0;
      if (cd.attributes.type.name !== BadgeType.Univers) {
        topOffset = creature.compositiony;
        leftOffset = creature.compositionX;
      }
      imgsToDraw.push(await this.drawImage(cd.attributes, leftOffset, topOffset));
    }
    const canvasWidth = (this.canvasEl.nativeElement as HTMLCanvasElement).clientWidth;
    const containerWidth = (this.container.nativeElement as HTMLDivElement).clientWidth;
    this.isReady = true;
  }

  get width(): number {
    const canvasWidth = (this.canvasEl.nativeElement as HTMLCanvasElement).clientWidth;
    const containerWidth = (this.container.nativeElement as HTMLDivElement).clientWidth;
    return canvasWidth < containerWidth ? canvasWidth : containerWidth;
  }

  private drawImage(element: any, leftOffset: number, topOffset: number)
    : Promise<{ img: HTMLImageElement; leftOffset: number; topOffset: number; newWidth: number; newHeight: number }> {
    return new Promise<{ img: HTMLImageElement; leftOffset: number; topOffset: number; newWidth: number; newHeight: number }>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const newWidth = img.width;
        const newHeight = img.height;
        this.context.drawImage(img, leftOffset, topOffset, newWidth, newHeight);
        resolve({ img, leftOffset, topOffset, newWidth, newHeight });
      };
      img.crossOrigin = 'Anonymous';
      img.src = element.image;
    });
  }

  getAvatarData(): string {
    const creature = this.elements.find(e => e.attributes.type.name === BadgeType.Creature).attributes;
    if (creature) {
      const avatarContext = (this.avatarCanvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
      avatarContext.drawImage((this.canvasEl.nativeElement as HTMLCanvasElement), creature.avatarX, creature.avatarY, 300, 300, 0, 0, 300, 300);
      return (this.avatarCanvasEl.nativeElement as HTMLCanvasElement).toDataURL('image/png');
    }
    return null;
  }

}
