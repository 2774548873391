import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {DataSource} from '@angular/cdk/collections';
import {LearnerService} from '@modules/groups-management/core/learner/learner.service';
import {FormGroup} from '@angular/forms';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'app-learner',
    templateUrl: './learner.component.html',
    styleUrls: ['./learner.component.scss']
})
export class LearnerComponent implements OnInit, OnDestroy {
    public displayedColumns = ['checkbox', 'avatar', 'username', 'groups', 'workgroups', 'buttons'];
    public dataSource: LearnerDataSource | null;
    public customActions: object;
    public newLearner: any = {
        data: {
            action: 'new',
            title: 'add_student',
            fields: ['avatar', 'username', 'password', 'groups', 'workgroups'],
            selects: {
                groups: () => this.learnerService.getGroups(),
                workgroups: () => this.learnerService.getWorkgroups()
            },
            typeEntity: 'learner'
        },
        callback: (response) => this.newLearnerCallback(response)
    };
    public editLearner: any = {
        data: {
            action: 'edit',
            title: 'edit_student',
            fields: ['id', 'avatar', 'username', 'password', 'groups', 'workgroups', 'sso'],
            selects: {
                groups: () => this.learnerService.getGroups(),
                workgroups: () => this.learnerService.getWorkgroups()
            },
            typeEntity: 'learner'
        },
        callback: (response) => this.editLearnerCallback(response)
    };

    public deleteLearner: any = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_learner',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (learner) => this.deleteLearnerCallback(learner),
        deleteList: (list) => this.deleteListCallback(list)
    };

    public showMethodsLicensing: any = {
        title: 'groups-management.licensing.title',
        views: [{'room': 'licensing', 'subject': 'licenses_content', 'params': {'uid': '{userid}'}}],
        actionsButton: [{'room': 'licensing', 'subject': 'licenses_add', 'params': {'uid': '{userid}'}}],
        styleClasses: 'w-80-p',
        rules: {'needSso': false}
    };

    constructor(
        private learnerService: LearnerService,
        private groupsManagementService: GroupsManagementService,
    ) {
    }

    ngOnInit(): any {
        this.dataSource = new LearnerDataSource(this.learnerService);
        this.groupsManagementService.setHeaderTitle('groups-management.students');
        this.groupsManagementService.gettingStarted = this.groupsManagementService.settings.gettingStarted.learners;
        this.customActions = {};
        const actionToAdd = [];
        if (this.learnerService.settingsLicensing.visible) {
            actionToAdd.push({'show_licenses_method': {icon: 'bookmark', label: 'groups-management.list.title', data: this.showMethodsLicensing}});
        }
        actionToAdd.forEach(action => {
            Object.assign(this.customActions, action);
        });
    }

    ngOnDestroy(): void {
        this.groupsManagementService.gettingStarted = '';
    }

    public newLearnerCallback(response: FormGroup): Observable<DataEntity> {
        if (response) {
            return this.learnerService.addLearner(response.getRawValue());
        }
    }

    public editLearnerCallback(response: FormGroup): Observable<DataEntity> {
        if (response) {
            return this.learnerService.saveLearner(response.getRawValue());
        }
    }

    public deleteLearnerCallback(learner: any): void {
        this.learnerService.deleteLearner(learner);
    }


    public deleteListCallback(learners: any): void {
        for (const learner of learners) {
            this.learnerService.deleteLearner(learner);
        }
    }
}

export class LearnerDataSource extends DataSource<any> {
    constructor(private learnerService: LearnerService) {
        super();
    }

    connect(): Observable<any[]> {
        return this.learnerService.onLearnersChanged;
    }

    disconnect() {
    }
}
