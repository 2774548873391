import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-external',
    templateUrl: './external.component.html',
    styleUrls: ['./external.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExternalComponent implements OnInit, OnDestroy {

    @Input() public activityId: any;
    @Input() public contextId: string;
    @Input() questionTypeName: string;

    public questionObject: any;
    public instruction: SafeResourceUrl;
    public wording: string;
    public userSave: DataEntity;

    private unsubscribeInTakeUntil = new Subject();
    private isInitializing: boolean;



    constructor (
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private changeDetector: ChangeDetectorRef,
        private octopusConnect: OctopusConnectService,
        private sanitizer: DomSanitizer
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    /**
     * Initializes the current component
     */
    private initialize(): void {
        this.reset();

        this.activityService.launchActivity((this.activityId))
            .take(1)
            .subscribe(data => {
                this.questionObject = data.reference;
                this.instruction = this.sanitizer.bypassSecurityTrustResourceUrl(this.questionObject.instruction);
                this.wording = "this.questionObject.wording";
                this.isInitializing = false;
            });
    }

      /**
     * Resets the current component
     */
    private reset(): void {
        this.isInitializing = true;
        delete this.questionObject;
        delete this.instruction;
        delete this.wording;

        this.changeDetector.detectChanges();
    }


    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
