import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {fuseAnimations} from 'fuse-core/animations';
import {FuseConfigService} from 'fuse-core/services/config.service';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {Router} from '@angular/router';
import {Subject} from "rxjs";
import {brand, brandLogoSvg} from 'app/settings';

@Component({
    selector   : 'fuse-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls  : ['./forgot-password.component.scss'],
    animations : fuseAnimations
})
export class FuseForgotPasswordComponent implements OnInit, OnDestroy {
    forgotPasswordForm: FormGroup;
    forgotPasswordFormErrors: any;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private fuseConfig: FuseConfigService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {
        this.forgotPasswordFormErrors = {
            email: {}
        };
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.forgotPasswordForm.valueChanges.subscribe(() => {
            this.onForgotPasswordFormValuesChanged();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    onForgotPasswordFormValuesChanged() {
        for ( const field in this.forgotPasswordFormErrors )
        {
            if ( !this.forgotPasswordFormErrors.hasOwnProperty(field) )
            {
                continue;
            }

            // Clear previous errors
            this.forgotPasswordFormErrors[field] = {};

            // Get the control
            const control = this.forgotPasswordForm.get(field);

            if ( control && control.dirty && !control.valid )
            {
                this.forgotPasswordFormErrors[field] = control.errors;
            }
        }
    }

    sendResetLink() {
        if (!this.forgotPasswordForm.invalid){
            this.authenticationService.forgotPassword(this.forgotPasswordForm.value['email'])
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe(() => {
                    this.router.navigate(['password-reset-email']);
                });
        }
    }
}
