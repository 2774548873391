import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DateAdapter, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AssignationService} from '../../assignation.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs/Observable';
import {map, startWith} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {localizedDate} from '../../../../../shared/utils';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'fuse-app-assignation',
    templateUrl: './assignation.component.html',
    styleUrls: ['./assignation.component.scss']
})
export class AssignationComponent implements OnInit {
    public entityForm: FormGroup;
    public entityFormErrors: any;
    public groupsChips: any[] = [];
    public groupsCtrl = new FormControl();
    public groupsAddOnBlur = false;
    public groupsSeparatorKeysCodes = [ENTER, COMMA];
    public groupsSelectable = true;
    public groupsRemovable = true;
    public groupsFilteredChips: Observable<any[]>;
    public learnersChips = []; // array of workgroup Chips selected
    public learnersCtrl = new FormControl();
    public learnersAddOnBlur = false;
    public learnersSeparatorKeysCodes = [ENTER, COMMA];
    public learnersSelectable = true;
    public learnersRemovable = true;
    public learnersFilteredChips: Observable<any[]>;
    public projectsList;
    public workgroupsChips = []; // array of workgroup Chips selected
    public workgroupsCtrl = new FormControl();
    public workgroupsAddOnBlur = false;
    public workgroupsSeparatorKeysCodes = [ENTER, COMMA];
    public workgroupsSelectable = true;
    public workgroupsRemovable = true;
    public workgroupsFilteredChips: Observable<any[]>;

    private allTypes: any[] = [];
    private entity: any;
    private groupsAllChips = [];
    private initialDate: any;
    private learnersAllChips = [];
    private maxDueTime: string;
    private maxStartDate: any;
    private maxStartTime: string;
    private minStartDate: any;
    private minDueDate: any;
    private minDueTime: string;
    private translatedTermAll: string;
    private workgroupsAllChips = [];

    @ViewChild('groupsChipInput') groupsChipInput: ElementRef;
    @ViewChild('workgroupsChipInput') workgroupsChipInput: ElementRef;
    @ViewChild('learnersChipInput') learnersChipInput: ElementRef;
    @ViewChild('startDateInput') startDateInput: ElementRef;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private translate: TranslateService,
        private adapter: DateAdapter<any>,
        private formBuilder: FormBuilder,
        public assignationService: AssignationService,
        public dialogRef: MatDialogRef<AssignationComponent>,
        public authService: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
        this.communicationCenter
            .getRoom('projects-management')
            .getSubject('projectsList')
            .subscribe((projectsList) => {
                this.projectsList = projectsList.filter(project => !project.archived);
            });

        this.entity = {
            nodeId: +data.nodeId
        };

        const formFields = ['learners'];

        if (this.hasProject) {
            formFields.push('project');
        }

        if (this.hasType) {
            formFields.push('type');
        }

        formFields.push('rating_base');
        formFields.push('startDate');
        formFields.push('startTime');
        formFields.push('dueDate');
        formFields.push('dueTime');

        formFields.push('nodeId');
        formFields.push('group');

        this.initialDate = this.adapter.today();
        this.entityFormErrors = {
            dueTime: {}
        };
        this.entityForm = this.createEntityForm(formFields);

        this.entityForm.get('group').setValue([]);
        this.entityForm.get('learners').setValue([]);

        this.entityForm.get('startDate').valueChanges.subscribe((startDate) => {
            if (startDate) {
                this.minDueLimit =  new Date(+startDate.format('x'));
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000) && this.dueTime) {
                    this.maxStartSetTime = this.dueTime;
                } else {
                    this.maxStartSetTime = null;
                }
            }
        });
        this.entityForm.get('dueDate').valueChanges.subscribe((dueDate) => {
            if (dueDate) {
                this.maxStartLimit =  new Date(+dueDate.format('x'));
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000) && this.startTime) {
                    this.minDueSetTime = this.startTime;
                    this.timeError();
                }else {
                    this.minDueSetTime = null;
                }
            }
        });
        this.entityForm.get('startTime').valueChanges.subscribe((startTime) => {
            if (startTime) {
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000)) {
                    this.minDueSetTime = startTime;
                }
            }
        });
        this.entityForm.get('dueTime').valueChanges.subscribe((dueTime) => {
            if (dueTime) {
                if (this.dueDateMinLimit && this.maxDateLimit && localizedDate(this.dueDateMinLimit.getTime() / 1000) === localizedDate(this.maxDateLimit.getTime() / 1000)) {
                    this.maxStartSetTime = dueTime;
                }
            }
        });
        this.entityForm.valueChanges.subscribe(() => {
            const controlType = this.entityForm.get('type');
            const controlRatingBase = this.entityForm.get('rating_base');
            const controlStartDate = this.entityForm.get('startDate');
            const controlStartTime = this.entityForm.get('startTime');
            const controlDueDate = this.entityForm.get('dueDate');
            const controlDueTime = this.entityForm.get('dueTime');
            if (controlType && controlType.dirty && controlType.valid){
                if (controlType.value.label !== 'training'){
                    if (controlType.value.label === 'assessment'){
                        controlStartTime.setValidators(Validators.required);
                        controlDueTime.setValidators(Validators.required);
                    } else {
                        controlStartTime.setErrors(null);
                        controlDueTime.setErrors(null);
                        controlStartTime.setValidators(null);
                        controlDueTime.setValidators(null);
                    }
                    controlRatingBase.setValidators(Validators.required);
                    controlStartDate.setValidators(Validators.required);
                    controlDueDate.setValidators(Validators.required);

                } else {

                    if (controlStartDate.value === null){ // if no StartDate
                        this.entityForm.patchValue({startDate: this.initialDate}); // set today as start date for training
                    }
                    controlRatingBase.setErrors(null);
                    controlStartDate.setErrors(null);
                    controlStartTime.setErrors(null);
                    controlDueDate.setErrors(null);
                    controlDueTime.setErrors(null);

                    controlStartDate.setValidators(Validators.required);
                    controlStartTime.setValidators(null);
                    controlDueDate.setValidators(null);
                    controlDueTime.setValidators(null);

                }
            }

        });
    }

    ngOnInit(): void {
        this.minDueLimit = new Date(Date.now());
        this.minStartLimit = new Date(Date.now());
        this.translate.get('generic.all').subscribe((translation: string) => this.translatedTermAll = translation);
        this.translate.onLangChange.subscribe((data) => {
            this.translatedTermAll = data.translations.generic.all;
        });

        this.adapter.setLocale(this.translate.currentLang);

        this.learnersAllChips   = this.assignationService.learnersList   ? this.assignationService.learnersList : [];
        this.groupsAllChips     = this.assignationService.groupsList     ? this.assignationService.groupsList : [];
        this.workgroupsAllChips = this.assignationService.workgroupsList ? this.assignationService.workgroupsList : [];

        this.learnersFilteredChips = this.learnersCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip && chip !== 'all' ?
                this.distinctFilter(this.filter(chip && chip.username, 'learner'), 'learners') :
                this.distinctFilter(this.filterLearnersByGroupAndWGroups(this.learnersAllChips.slice()), 'learners')
            )
        );

        this.groupsFilteredChips = this.groupsCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip ?
                this.distinctFilter(this.filter(chip && chip.groupname, 'group'), 'group') :
                this.distinctFilter(this.groupsAllChips.slice(), 'group')
            )
        );


        this.workgroupsFilteredChips = this.workgroupsCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: any | null) => chip ?
                this.distinctFilter(this.filter(chip && chip.workgroupname, 'workgroup'), 'workgroup') :
                this.distinctFilter(this.workgroupsAllChips.slice(), 'workgroup')
            )
        );

        this.assignationService.loadAssignationsTypes().subscribe(types => {
            this.allTypes = types;
        });

        this.dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                const data = response.getRawValue();
                const lessonType = !!data.type ? data.type.label : null;
                if (!this.assignationService.hasCompletionStartDateOnly(lessonType)) {
                    if (!this.assignationService.hasCompletionDate(lessonType)) {
                        if (!this.assignationService.hasCompletionTime(lessonType)) {
                            data.startTime = 0;
                            data.dueTime = 0;
                        }
                        data.startDate = 0;
                        data.dueDate = 0;
                    }
                }

                this.assignationService.createAssignment(data);
            }
        });
        this.entityForm.get('learners').setValidators(Validators.required);
    }

    private filter(name: string, type: string): Array<string> {
        const allChips = type + 'sAllChips';
        let chipName = type + 'name';
        if (type === 'learner'){
            chipName = 'username';
        }

        return this[allChips].filter(chip =>
            chip[chipName].toLowerCase().indexOf(name.toLowerCase()) !== -1);
    }

    /**
     * Remove already selected items in chip list
     * @param items could be a learner, group or workgroup but type is defined by {field}
     * @param field type of item to filter (learner, group or workgroup)
     */
    private distinctFilter(items: any[], field: string): any[] {
        switch (field) {
            case 'workgroup':
                return items.filter(item => this.workgroupsChips.includes(item) === false);
            case 'group':
                return items.filter(item => this.groupsChips.includes(item) === false);
            case 'learners':
                return items.filter(item => this.learnersChips.includes(item) === false);
            default:
                break;
        }
    }

    /**
     * Remove a chip or all chips in the local chip list
     * @param chip if null, all chips will be remove
     * @param type field of chip (learner, group or workgroup)
     */
    public removeChip(chip: any | null, type: string): void {

        // remove chip from array of chips (group or workgroup)
        const chips = type + 'sChips';
        const index = this[chips].indexOf(chip);

        if (!chip) {
            this[chips] = [];
        } else {
            if (index >= 0) {
                this[chips].splice(index, 1);
            }

            this.fillInternalForm();
        }

        this[type + 'sCtrl'].setValue(null);
        // trigger the list refresh
        this.learnersCtrl.setValue(this.learnersCtrl.value, {emitEvent: true});
        this.blurAllChipsList();
    }

    public blurAllChipsList(): void{
        this.groupsChipInput.nativeElement.blur();
        this.workgroupsChipInput.nativeElement.blur();
        this.learnersChipInput.nativeElement.blur();
    }

    public chipSelected(event: MatAutocompleteSelectedEvent, type: string): void {
        const chips = type + 'sChips';
        const input = type + 'sChipInput';
        const chipsCtrl = type + 'sCtrl';

        if (type === 'learner' ){
            // Remove 'all' chip from selected if exist
            if (this[chips].indexOf('all') !== -1 || this[chips].indexOf(this.translatedTermAll) !== -1 ){
                this.removeChip(this.translatedTermAll, 'learner');
            }

            // remove others chip if 'all' is selected
            if (this[chips].length && event.option.value === 'all'){
                this.removeChip(null, type);
            }

            // add new chip in chip list (could be 'all')
            this[chips].push(event.option.value === 'all' ? this.translatedTermAll : event.option.value);

        } else{
            this.removeChip(null, type);
            this[chips].push(event.option.value);
            this.learnersCtrl.setValue(null);
            this.learnersChips = [this.translatedTermAll];
        }
        this[chipsCtrl].setValue(null);
        this[input].nativeElement.value = '';
        this.fillInternalForm();
        this.blurAllChipsList();
    }

    private filterLearnersByGroupAndWGroups(learners: any[]): any[] {
        if (!Array.isArray(learners)) {
            return [];
        }

        let filteredList = learners;

        // There can be only one group.
        if (this.groupsChips.length === 1) {
            filteredList = filteredList.filter(item => item.groups.includes(this.groupsChips[0].groupname));
        }

        // There can be only one workgroup.
        if (this.workgroupsChips.length === 1) {
            filteredList = filteredList.filter(item => item.workgroups.includes(this.workgroupsChips[0].workgroupname));
        }

        return filteredList;
    }

    private fillInternalForm(): any{
        let learners = [];

        if (this.learnersChips.length && (
                this.learnersChips.indexOf('all') === -1 &&
                this.learnersChips.indexOf(this.translatedTermAll) === -1
            )
        ) {
            learners.push(... this.learnersChips);
        } else {
            learners = this.filterLearnersByGroupAndWGroups(this.learnersAllChips);
        }

        const controlLearners = this.entityForm.get('learners');
        if (learners.length) {
            controlLearners.reset([]);
            controlLearners.setValue(learners);
        } else {
            controlLearners.setValue([]);
            controlLearners.setErrors({learnersArrayEmpty: true});
        }

        this.entityForm.get('group').reset([]);
        this.entityForm.get('group').value.push(...this.groupsChips, ... this.workgroupsChips);
    }

    public displayField(name: string): boolean {
        const role = this.authService.accessLevel;
        let formFields = this.assignationService.settings.formFields[role];
        if (formFields === undefined) {
            formFields = this.assignationService.settings.formFields['default'];
        }
        return formFields.indexOf(name) > -1;
    }

    public get typeList(): any[] {
        return this.allTypes;
    }

    public get hasProject(): boolean {
        return this.projectsList && !!this.projectsList.length;
    }

    public get hasType(): boolean {
        return this.assignationService.settings.hasType;
    }

    public get hasCompletionDate(): boolean {
        return this.assignationService.hasCompletionDate(this.entityForm && this.entityForm.value && this.entityForm.value.type ? this.entityForm.value.type.label : '');
    }

    public get hasCompletionStartDateOnly(): boolean {
        return this.assignationService.hasCompletionStartDateOnly(this.entityForm && this.entityForm.value && this.entityForm.value.type ? this.entityForm.value.type.label : '');
    }

    public get hasCompletionTime(): boolean {
        return this.assignationService.hasCompletionTime(this.entityForm && this.entityForm.value && this.entityForm.value.type ? this.entityForm.value.type.label : '');
    }

    public get hasGrade(): boolean {
        return this.assignationService.hasGrade(this.entityForm && this.entityForm.value && this.entityForm.value.type ? this.entityForm.value.type.label : '');
    }

    public get ratingBaseList(): string[] {
        return this.assignationService.settings.ratingBase;
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    private createEntityForm(fields: string[]): FormGroup {
        const config = {};
        fields.forEach((field: string) => {
            config[field] = [this.entity[field]];
        });

        config['rating_base'] = [this.ratingBaseList[this.ratingBaseList.length - 1]];

        return this.formBuilder.group(config);
    }

    public set minDueLimit(data) {
        this.minDueDate = data;
    }
    public set minStartLimit(data) {
        this.minStartDate = data;
    }
    public get dueDateMinLimit(): any {
        return this.minDueDate;
    }

    public get startDateMinLimit(): any {
        return this.minStartDate;
    }

    public get inputStartDateValue(): any {
        return this.startDateInput && this.startDateInput.nativeElement.value && this.startDateInput.nativeElement.value !== '';
    }
    public set maxStartLimit(data) {
        this.maxStartDate = data;
    }
    public get maxDateLimit(): any {
        return this.maxStartDate;
    }

    public set maxStartSetTime(data) {
        this.maxStartTime = data;
    }
    public set minDueSetTime(data) {
        this.minDueTime = data;
    }

    public get minStartTimeLimit(): any {
        if (this.entityForm.get('startDate').value) {
            const limitTime = new Date(Date.now());
            const currentDate = this.entityForm.get('startDate').value;
            if (localizedDate(limitTime.getTime() / 1000) === localizedDate(currentDate.format('X'))) {
                return limitTime.getTime();
            }
        }
        return null;
    }

    public get maxStartTimeLimit(): any {
        return this.maxStartTime;
    }
    public get minDueTimeLimit(): any {
        return this.minDueTime;
    }
    public get maxDueTimeLimit(): any {
        return this.maxDueTime;
    }

    public get startTime(): any {
        if (this.entityForm.get('startTime')) {
            return this.entityForm.get('startTime').value;
        }
        return null;
    }

    public get dueTime(): any {
        if (this.entityForm.get('dueTime')) {
            return this.entityForm.get('dueTime').value;
        }
        return null;
    }

    private timeError(): void {
        if (this.startTime && this.dueTime) {
            if (this.entityForm && this.entityForm.get('dueTime')) {
                const startTimeArray = this.startTime.split(':');
                const dueTimeArray = this.dueTime.split(':');
                if (+startTimeArray[0] > +dueTimeArray[0] || +startTimeArray[0] === +dueTimeArray[0] && +startTimeArray[1] > +dueTimeArray[1]) {
                    this.entityForm.get('dueTime').setErrors({timeError: true});
                    this.entityFormErrors['dueTime'] = this.entityForm.get('dueTime').errors;
                } else {
                    this.entityForm.get('dueTime').setErrors(null);
                }
            }
        }
    }
}
