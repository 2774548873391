import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Group, Learner} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subscription} from 'rxjs/Subscription';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';

@Injectable()
export class GroupService {
  private userData: DataEntity;
  private groupsCollection: {[key: number]: DataEntity} = {};
  public groupsList: Group[] = [];
  private groupsSubscription: Subscription = null;
  public onGroupsChanged: BehaviorSubject<any> = new BehaviorSubject([]);
  private listArchived = false;
  private learnersList: Learner[] = [];
  private filteredInstitutionList: Array<object> = [];
  public archiveMode = false;
  public selectedClass: DataEntity;

  constructor(
    private groupsManagement: GroupsManagementService,
    private octopusConnect: OctopusConnectService,
    private communicationCenter: CommunicationCenterService,
  ) {
    this.communicationCenter
      .getRoom('authentication')
      .getSubject('userData')
      .subscribe((data: DataEntity) => {
          if (data) {
              this.userData = data;
              this.postAuthentication();
          } else {
              this.postLogout();
          }
      });

    this.communicationCenter
      .getRoom('projects-management')
      .getSubject('setProjectGroups')
      .subscribe((data) => this.updateProjectInGroups(data));

  }

  loadGroups(): Observable<DataCollection> {
    return this.octopusConnect.loadCollection('groups');
  }

  loadLevels(): Observable<DataCollection> {
    return this.octopusConnect.loadCollection('levels').take(1);
  }

  private postLogout(): void {
    if (this.groupsSubscription) {
        this.groupsSubscription.unsubscribe();
        this.groupsSubscription = null;
    }
  }

  private postAuthentication(): void {
    if (!this.groupsSubscription) {

      const subjectLearnerList = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('learnerList');

      const subjectInstitutionListChanged = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('institutionListFiltered');

      const obsLoad = this.loadGroups();
      this.groupsSubscription = obsLoad.combineLatest(subjectInstitutionListChanged).subscribe((data: [DataCollection, Array<any>]) => {
        this.groupsList = [];

          for (const entity of data[0].entities) {
              if ( (parseInt(entity.get('type') ? entity.get('type').toString() : '')) === this.groupsManagement.settings.groupType) {
                  const group: Group =  {
                      id: parseInt(entity.id.toString()),
                      groupname: entity.get('label'),
                      color: entity.get('color'),
                      learnersIds: [],
                      learners: 0,
                      level: (entity.get('level') ? entity.get('level') : ''),
                      code: (entity.get('code') ? entity.get('code') : entity.id),
                      projects: (entity.get('projects') ? entity.get('projects').slice() : []),
                      archived: entity.get('archived'),
                      metacognitionActive: entity.get('metacognitionActive'),
                      parent: (entity.get('parents') ? entity.get('parents')[0] : ''),
                  };

                  this.filteredInstitutionList = data[1];

                  this.groupsList.push(group);

                  this.groupsCollection[entity.id] = entity;

              }
          }

        this.communicationCenter
          .getRoom('groups-management')
          .next('groupsList', this.groupsList);

        this.filterArchived(this.archiveMode);
      });


      obsLoad.take(1).subscribe((data: DataCollection) => {

      subjectInstitutionListChanged.subscribe((institutionListFiltered: Array<any>) => {
          this.filteredInstitutionList = institutionListFiltered;
      });

        subjectLearnerList.subscribe((learnersList: Learner[]) => {
          this.learnersList = learnersList;
          this.groupsList.forEach((group: Group) => {
            const learners = this.learnersList
              .filter((learner: Learner) => learner.groups.indexOf(group.groupname) > -1)
              .map((learner: Learner) => learner.id);

            group.learners = learners.length;

              group.learnersIds = this.learnersList
                  .filter((learner: Learner) => learner.groups.indexOf(group.groupname) > -1)
                  .map((learner: Learner) => +learner.id);
          });
        });
      });

      subjectInstitutionListChanged.subscribe((institutionListFiltered: Array<any>) => {
        this.filteredInstitutionList = institutionListFiltered;
      });
    }
  }

  addGroup(group): Observable<DataEntity> {
      const obs = this.octopusConnect
          .createEntity('groups', {
              label: group.groupname.trim(),
              type: this.groupsManagement.settings.groupType.toString(),
              archived: false,
              projects: [],
              level: group.level,
              color: group.color,
              parents: group.parent ? [group.parent.id] : []
          });

    return obs;
  }

  /**
   * Given a list of groups, add a project ID to those groups and remove project ID from groups not listed.
   * @param data Object containing groups ID (groups) and project ID (project)
   */
  updateProjectInGroups(data) {
    const projectId = data.project.toString();

    // Remove projectId from groups not listed in data.groups
    this.groupsList
      .filter((group: Group) => group.projects.indexOf(projectId) > -1)
      .forEach((group: Group) => {
        if (data.groups.indexOf(group.id) === -1) {
          group.projects.splice(group.projects.indexOf(projectId), 1);
          this.saveGroup(group);
        }
      });

    // Add projectId to groups listed in data.groups
    this.groupsList
      .filter((group: Group) => data.groups.indexOf(group.id) > -1)
      .forEach((group: Group) => {
        if (group.projects.indexOf(projectId) === -1) {
          group.projects.push(projectId);
          this.saveGroup(group);
        }
      });
  }

    saveGroup(group): Observable<DataEntity>{
        const groupEntity = this.groupsCollection[group.id];

        if (groupEntity) {
            groupEntity.set('label', group.groupname.trim());
            groupEntity.set('archived', group.archived);
            groupEntity.set('metacognitionActive', group.metacognitionActive);
            groupEntity.set('projects', group.projects);
            groupEntity.set('color', group.color);
            if (group.parent){
                groupEntity.set('parents', group.parent.id);
            }
            groupEntity.set('level', group.level);
            return groupEntity.save();
        }
    }

  deleteGroup(group): void {
    const groupEntity = this.groupsCollection[group.id];

    if (groupEntity) {
      groupEntity.remove();
    }
  }

  getParents(): object[] {
    return this.filteredInstitutionList;
  }

  getGroups(): string[] {
    return this.groupsList
      .map((group: Group) => group.groupname);
  }

  public get groups(): object[] {
    return this.groupsList.slice();
  }

  public filterArchived(archived: boolean): any {
    this.archiveMode = archived;
    this.onGroupsChanged.next(this.groupsList.filter((group) => group.archived === archived));
  }

  public resetFilter(): any {
    this.onGroupsChanged.next(this.groupsList);
  }

  public joinGroup(group): any {

      if (!this.classExist(group.id) && +group.get('type') === 2) {
          const currentAllIds = [];
          currentAllIds.push(...this.userData.get('groups'));

          for (const entity in this.groupsCollection) {
                  if (currentAllIds.indexOf(entity) !== -1) {
                      currentAllIds.splice(currentAllIds.indexOf(entity), 1);
                  }
          }

          this.groupsList = [];
          this.groupsList.push({
              id: +group.id,
              groupname: group.get('label'),
              color: group.get('color'),
              learnersIds: [],
              learners: 0,
              level: (group.get('level') ? group.get('level') : ''),
              code: (group.get('code') ? group.get('code') : group.id),
              projects: (group.get('projects') ? group.get('projects').slice() : []),
              archived: group.get('archived'),
              metacognitionActive: group.get('metacognitionActive'),
              parent: (group.get('parents') ? group.get('parents')[0] : ''),
          });
          this.onGroupsChanged.next(this.groupsList);
          currentAllIds.push(group.id);
          this.userData.set('groups', currentAllIds);
          return this.userData.save();
      }

  }

    public getClass(id): Observable<DataEntity> {
        const obs = this.octopusConnect.loadEntity('groups', +id);
        obs.subscribe((entity: DataEntity) => {
            this.selectedClass = entity;
        });

        return obs;
    }

    public classExist(id): boolean {
        return id && this.userData.get('groups').indexOf(id.toString()) !== -1 && !!this.groupsList.find((item) => item.id.toString() === id.toString());
    }

    public removeClassFromList(entity): void {
        if (entity || this.selectedClass && !this.classExist(this.selectedClass.id)) {
            const id = entity && entity.id ? entity.id : this.selectedClass.id;
            const index = this.groupsList.findIndex((item) => item.id.toString() === id.toString());
            if (index !== -1) {
                this.groupsList.splice(index, 1);
                this.onGroupsChanged.next(this.groupsList);
            }
        }
    }

}
