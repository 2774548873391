import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QcmComponent} from '@modules/activities/core/player-components/qcm/qcm.component';
import {OrdOnComponent} from '@modules/activities/core/player-components/ordon/ordon.component';
import {ShortAnswerComponent} from '@modules/activities/core/player-components/short-answer/short-answer.component';
import {FillInBlanksComponent} from '@modules/activities/core/player-components/fill-in-blanks/fill-in-blanks.component';
import {TrueFalseComponent} from '@modules/activities/core/player-components/true-false/true-false.component';
import {QcuComponent} from '@modules/activities/core/player-components/qcu/qcu.component';
import {FillInBlanksChildComponent} from '@modules/activities/core/player-components/fill-in-blanks/fill-in-blanks-child/fill-in-blanks-child.component';
import {MatButtonModule, MatGridListModule, MatIconModule, MatCardModule, MatProgressSpinnerModule} from '@angular/material';
import {SharedTranslateModule} from '../../../../shared/shared-translate.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {DragulaModule} from 'ng2-dragula';
import {RouterModule, Routes} from '@angular/router';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {AppaireComponent} from '@modules/activities/core/player-components/appaire/appaire.component';
import { MediaComponent } from './media/media.component';
import { Ng2DragDropModule } from 'ng2-drag-drop';
import { DividerComponent } from './divider/divider.component';
import { AudioComponent } from '@modules/activities/core/player-components/audio/audio.component';
import { VideoComponent } from './video/video.component';
import { ImageComponent } from './image/image.component';
import { InteractiveImageComponent } from './interactive-image/interactive-image.component';
import { PositionAndColorDotDirective } from './interactive-image/directives/position-and-color-dot.directive';
import { MultiComponent } from './multi/multi.component';
import { PollComponent } from './poll/poll.component';
import {SummaryActivityComponent} from '@modules/activities/core/player-components/summary-activity/summary-activity.component';
import {MultimediaComponent} from '@modules/activities/core/player-components/multimedia/multimedia.component';
import {ExternalComponent} from '@modules/activities/core/player-components/external/external.component';
import {SharedMaterialModule} from '../../../../shared/shared-material.module';
import { MultipleChoiceGridComponent } from './multiple-choice-grid/multiple-choice-grid.component';
import { MultipleChoiceComponent } from './multiple-choice-grid/multiple-choice/multiple-choice.component';
const ActivitiesRoutes: Routes = [
    {
        path: 'qcm/:activityId',
        component: QcmComponent
    },
    {
        path: 'qcu/:activityId',
        component: QcuComponent
    },
    {
        path: 'truefalse/:activityId',
        component: TrueFalseComponent
    },
    {
        path: 'ordon/:activityId',
        component: OrdOnComponent
    },
    {
        path: 'fillinblanks/:activityId',
        component: FillInBlanksComponent
    },
    {
        path: 'shortanswer/:activityId',
        component: ShortAnswerComponent
    },
    {
        path: 'appaire/:activityId',
        component: AppaireComponent
    },
    {
        path: 'media/:activityId',
        component: MediaComponent
    },
    {
        path: 'divider/:activityId',
        component: DividerComponent
    },
    {
        path: 'audio/:activityId',
        component: AudioComponent
    },
    {
        path: 'video/:activityId',
        component: VideoComponent
    },
    {
        path: 'image/:activityId',
        component: ImageComponent
    },
    {
        path: 'interactiveimage/:activityId',
        component: InteractiveImageComponent
    },
    {
        path: 'summary/:activityId',
        component: SummaryActivityComponent
    },
    {
        path: 'medias/:activityId',
        component: MultimediaComponent
    },
    {
        path: 'external/:activityId',
        component: ExternalComponent
    },
    {
        path: 'gcm/:activityId',
        component: MultipleChoiceGridComponent
    }
];

const routes: Routes = [
    {
        path: 'multi/:subLessonId',
        component: MultiComponent,
        children: ActivitiesRoutes
    },
    ...ActivitiesRoutes
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedTranslateModule,
        CommonSharedModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        MatCardModule,
        ActivitiesPipesModule,
        DragulaModule,
        Ng2DragDropModule.forRoot(),
        MatProgressSpinnerModule,
        SharedMaterialModule,
    ],
    declarations: [
        AppaireComponent,
        AudioComponent,
        DividerComponent,
        FillInBlanksComponent,
        FillInBlanksChildComponent,
        ImageComponent,
        InteractiveImageComponent,
        MediaComponent,
        MultiComponent,
        OrdOnComponent,
        PositionAndColorDotDirective,
        QcmComponent,
        QcuComponent,
        ShortAnswerComponent,
        TrueFalseComponent,
        VideoComponent,
        PollComponent,
        SummaryActivityComponent,
        MultimediaComponent,
        ExternalComponent,
        MultimediaComponent,
        MultipleChoiceGridComponent,
        MultipleChoiceComponent
    ],
    exports: [
        AppaireComponent,
        AudioComponent,
        DividerComponent,
        FillInBlanksComponent,
        FillInBlanksChildComponent,
        ImageComponent,
        MediaComponent,
        OrdOnComponent,
        QcmComponent,
        QcuComponent,
        RouterModule,
        ShortAnswerComponent,
        TrueFalseComponent,
        VideoComponent,
        SummaryActivityComponent,
        MultimediaComponent,
        ExternalComponent
    ]
})
export class PlayersModule {
}
