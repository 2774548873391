import {NgModule} from '@angular/core';
import {SharedMaterialModule} from './shared-material.module';
import {SharedTranslateModule} from './shared-translate.module';
import {FuseConfirmDialogModule} from 'fuse-core/components/confirm-dialog/confirm-dialog.module';
import {DynamicModule} from '@fuse/components/dynamic/dynamic.module';
import {BasicPageModule} from 'fuse-core/components/basic-page/basic-page.module';
import {CardModule} from '@fuse/components/card/card.module';
import {GettingStartedModule} from 'fuse-core/components/getting-started/getting-started.module';

@NgModule({
    imports: [
        CardModule,
        DynamicModule,
        FuseConfirmDialogModule,
        BasicPageModule,
        GettingStartedModule,
        SharedMaterialModule,
        SharedTranslateModule
    ],
    exports: [
        CardModule,
        DynamicModule,
        FuseConfirmDialogModule,
        BasicPageModule,
        GettingStartedModule,
        SharedMaterialModule,
        SharedTranslateModule
    ]
})
export class SharedModule {}
