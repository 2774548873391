import {Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-lesson-metadata-dialog',
    templateUrl: './lesson-metadata-dialog.component.html',
    styleUrls: ['./lesson-metadata-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LessonMetadataDialogComponent implements OnInit {
    public metadata;
    public methodsNames: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialogRef: MatDialogRef<LessonMetadataDialogComponent>) {
        this.metadata = data.metadatas
    }

    ngOnInit() {
        this.methodsNames = this.metadata.licenseContent.map(x => x.label).join(',');
    }

}
