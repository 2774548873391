import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {modulesSettings} from '../../../settings';
import {
    IGlobalPeriod,
    IImage,
    IImagePeriod,
    IMove,
    IParamsZoneAround,
    IPeriod,
    ITimelineData,
    ITitle,
    ITitlePeriod,
    IZoneImage
} from '@modules/timeline/core/models/timeline-data.models';
import {IPositionAndStyle} from '@modules/timeline/core/models/position-and-style.models';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {ModelSchema, Structures} from 'octopus-model';

const settingsStructure: ModelSchema = new ModelSchema({
    activeGesture: Structures.boolean(false),
});

@Injectable({
    providedIn: 'root'
})
export class TimelineService {
    public settings: { [key: string]: any };
    private arrayZoneImageValue: IZoneImage[] = [];
    private ajustementPositionPx: number = 0;
    /**
     * back data
     */
        // region getter for private data without setter
    private _timelineData: ITimelineData = {
        title: '', dynamic_date: {start_date: 0, end_date: 0}, dynamic_size: 8000,
        globalPeriod: [], titlePeriod: [], imagePeriod: []
    };
    get timelineData(): ITimelineData {
        return this._timelineData;
    }

    private _dataReady: boolean = false;
    get dataReady(): boolean {
        return this._dataReady;
    }

    private _maPosition: IMove = {position: 0, type: 'smooth', numeroImage: 0, ajustementPositionPx: 0};
    get maPosition(): IMove {
        return this._maPosition;
    }

    private _imageList: IImage [] = [];
    get imageList(): IImage [] {
        return this._imageList;
    }

    private _paramsZone: IParamsZoneAround = {width: 0, startColor: '', endColor: ''};
    get paramsZone(): IParamsZoneAround {
        return this._paramsZone;
    }

    private _arrayPeriodeColor: IPositionAndStyle[] = [];
    get arrayPeriodeColor(): IPositionAndStyle[] {
        return this._arrayPeriodeColor;
    }

    public _positionSeparateurZone: number[] = [];
    get positionSeparateurZone(): number[] {
        return this._positionSeparateurZone;
    }

    private _arrayDot: number[] = [];
    get arrayDot(): number[] {
        return this._arrayDot;
    }

    private _title: ITitle = {mainTitle: '', titledotPeriod: '', titleImage: '', titleHistoryPeriod: ''};
    get title(): ITitle {
        return this._title;
    }

    private _currentSliderValue: number = 0;
    get currentSliderValue(): number {
        return this._currentSliderValue;
    }

    // endregion

    constructor(private octopusConnect: OctopusConnectService,
                private dialog: MatDialog
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.timeline);
    }

    /**
     * reset all value to default value
     */
    private resetToDefaultValue(): void {
        this._timelineData = {
            title: '', dynamic_date: {start_date: 0, end_date: 0}, dynamic_size: 8000,
            globalPeriod: [], titlePeriod: [], imagePeriod: []
        };
        this._dataReady = false;
        this._maPosition = {position: 0, type: 'smooth', numeroImage: 0, ajustementPositionPx: 0};
        this._imageList = [];
        this._paramsZone = {width: 0, startColor: '', endColor: ''};
        this._arrayPeriodeColor = [];
        this._positionSeparateurZone = [];
        this._arrayDot = [];
        this._title = {mainTitle: '', titledotPeriod: '', titleImage: '', titleHistoryPeriod: ''};
    }

    /**
     * get data from timeline by id
     * @param id : id of the timeline
     */
    public getTimelineData(id: number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('timeline', id);
    }

    /**
     * set all the data need from back in the front object :
     * @param data : all data from back for managing timeline
     */
    private initTimeLineData(data: DataEntity): void {
        const allPeriodData = data.get('period');
        this.setGlobalPeriodData(allPeriodData);
        this.setDotPipePeriod(allPeriodData);
        this.setImagePeriodData(allPeriodData);
        // to always launch after this.setImagePeriodData()
        this.setCommonTimelineData(data);
        this._currentSliderValue = this.timelineData.dynamic_date.start_date;
    }

    /**
     * set the common data of all period of the timeline in the object of type ITimelineData
     * @param data : all data from back for managing timeline
     */
    private setCommonTimelineData(data: DataEntity): void {
        this._timelineData.title = data.get('title');
        this._timelineData.dynamic_date = data.get('dynamic_date');

        // the dynamic size back data take all picture including doublon we will remove duplicate from back to front
        // and so don't use : this._timelineData.dynamic_size = data.get('dynamic_size');
        let totalWidth = 0;
        let previousData = null;
        this.timelineData.imagePeriod.forEach(imagePeriod => {
            if (previousData === null || imagePeriod.image.uri !== previousData.image.uri) {
                totalWidth = totalWidth + +imagePeriod.image.width;
            }
            previousData = imagePeriod;
        });
        // set the global size manualy because data become from back take doublon in calcul
        // add Tcolor zone in calcul because we put by hand at begining and at the end
        this._timelineData.dynamic_size = totalWidth + this._paramsZone.width * 2;
    }

    /**
     * set the pipe period (the first level) from back in the object of type ITimelineData
     * @param allPeriodData : all period data with array inside array each time in another fields period.
     * in reality back object is more complex but we take only wht we need in front.
     * than IGlobalPeriod[] but only fields of IGlobalPeriod[] are used.
     */
    private setGlobalPeriodData(allPeriodData: IGlobalPeriod[]): void {
        this._timelineData.globalPeriod = allPeriodData.map((data: IGlobalPeriod) => {
            return {
                title: data.title,
                dynamic_date: data.dynamic_date,
                dynamic_size: data.dynamic_size,
                color: data.color
            };
        });
    }

    /**
     * set the dot period from back in the object of type ITimelineData. use to put dark dot in timeline.
     * @param allPeriodData : all period data with array inside array in fields perdiod
     */
    private setDotPipePeriod(allPeriodData: any[]): void {
        this._timelineData.titlePeriod = allPeriodData.flatMap((data: IPeriod) => {
            return data.period.map((dotPeriod: ITitlePeriod) => {
                return {
                    title: dotPeriod.title,
                    dynamic_date: dotPeriod.dynamic_date,
                    dynamic_size: +dotPeriod.dynamic_size
                };
            });
        });
    }

    /**
     * set the image period from back in the object of type ITimelineData
     * @param allPeriodData : all period data with array inside array in fields period
     */
    private setImagePeriodData(allPeriodData: any[]): void {
        // get data from the period under period
        const dataImageZoneTemp = allPeriodData.map((data: IPeriod) => {
            return data.period.map((imagePeriod: any) => {
                return imagePeriod.period;
            });
        }).flat(2);
        // set the data for image period
        this._timelineData.imagePeriod = dataImageZoneTemp.flatMap((data: IImagePeriod) => {
            return {
                id: data.id,
                title: data.title,
                dynamic_date: data.dynamic_date,
                dynamic_size: +data.dynamic_size,
                image: data.image,
                points: data.points
            };
        });
    }

    /**
     * return an array with info to segment timeline and zone of colors for segment timeline in color zone
     */
    private getColorZoneParams(): IPositionAndStyle[] {
        const data: IPositionAndStyle[] = [];
        let cumulPourcentage = 0;
        let lastPourcentage = 0;
        const tailleFrise = Math.abs(+this.timelineData.dynamic_date.start_date) +
            Math.abs(+this.timelineData.dynamic_date.end_date);

        this.timelineData.globalPeriod.forEach(dataGlobalPeriod => {
            const finPortion = +dataGlobalPeriod.dynamic_date.end_date;
            const position = 100 * ((Math.abs(+this.timelineData.dynamic_date.start_date) + finPortion)) / tailleFrise;
            const pourcentageZoneTotal = position - lastPourcentage;

            if (data.length === 0) {
                data.push({
                    position: 0,
                    color: dataGlobalPeriod.color,
                    width: position
                });
            } else {
                data.push({
                    position: cumulPourcentage,
                    color: dataGlobalPeriod.color,
                    width: pourcentageZoneTotal
                });
            }
            cumulPourcentage = cumulPourcentage + pourcentageZoneTotal;
            lastPourcentage = lastPourcentage + pourcentageZoneTotal;
        });
        return data;
    }

    /**
     * return an array of pourcent who are the dark dot position in timeline in pourcent
     */
    private getdotPosition(): number[] {
        const data: number[] = [];
        let cumulPourcentage = 0;
        let lastPourcentage = 0;
        const tailleFrise = Math.abs(+this.timelineData.dynamic_date.start_date) +
            Math.abs(+this.timelineData.dynamic_date.end_date);

        this.timelineData.titlePeriod.forEach(dataTitlePeriod => {
            const finPortion = +dataTitlePeriod.dynamic_date.end_date;
            const position = 100 * ((Math.abs(+this.timelineData.dynamic_date.start_date) + finPortion)) / tailleFrise;
            const pourcentageZoneTotal = position - lastPourcentage;
            // adjust because timeline thumb not begin and finish at the gegin or end of the slide block
            data.push(cumulPourcentage - 0.5);
            cumulPourcentage = cumulPourcentage + pourcentageZoneTotal;
            lastPourcentage = lastPourcentage + pourcentageZoneTotal;
        });
        return data;
    }

    /**
     * set the position of white pipe separator of main history zone
     * split at place where color zone could change
     */
    private setPipePeriodPosition(): void {
        this._positionSeparateurZone = this.arrayPeriodeColor
            .filter(res => res.position !== 0 && res.position !== 100)
            .map(res => {
                // adjust - 1 because thumb not begin at the begin begin and not finish at the finish exactly
                return res.position - 1;
            });
    }


    /**
     * set an array of date zone with the image order from 0 to X to determine
     * by calcul after a sliderClick() event the good image position
     */
    private setZoneImageLimit(): void {
        let previousRes = null;
        let i = 0;
        this.timelineData.imagePeriod.forEach(res => {
            if (previousRes === null || res.image.uri !== previousRes.image.uri) {
                this._imageList.push({
                    idPeriod: +res.id,
                    uri: res.image.uri,
                    width: res.image.width,
                    height: res.image.height
                });
                this.arrayZoneImageValue.push({
                    numero: i,
                    start: +res.dynamic_date.start_date,
                    end: +res.dynamic_date.end_date
                });
                i++;
            }
            previousRes = res;
        });
    }

    /**
     * set the zone before and after the image in the viewer : size and color
     * @param data : All back data :
     * get('colors')[0] => color of begining ,
     * get('colors')[1] => color of end
     * get('width') => width of bloc before and after images
     *
     */
    private initParamsZoneAroundImage(data: DataEntity): void {
        this._paramsZone.width = data.get('width');
        this._paramsZone.startColor = data.get('colors')[0];
        this._paramsZone.endColor = data.get('colors')[1];
    }

    /**
     * set the title of the main history period to show in regard off period (dot zone)
     * @param sliderPosition : current year
     */
    private setTitleHistoryPeriodByDate(sliderPosition: number): void {
        this._title.mainTitle = '';
        this.timelineData.titlePeriod.forEach(dot => {
            if (+sliderPosition >= +dot.dynamic_date.start_date && +sliderPosition < +dot.dynamic_date.end_date) {
                this._title.titledotPeriod = dot.title;
            }
        });
    }

    /**
     * set the title of the current image period to show in regard off period
     * @param sliderPosition : current year
     */
    private setTitleImagePeriodByDate(sliderPosition: number): void {
        this._title.mainTitle = '';
        this.timelineData.imagePeriod.forEach(image => {
            if (+sliderPosition >= +image.dynamic_date.start_date && +sliderPosition < +image.dynamic_date.end_date) {
                this._title.titleImage = image.title;
            }
        });
    }

    /**
     * set the title to show in regard off period antiquity mid age etc.
     * @param sliderPosition : current year
     */
    private setTitleByDate(sliderPosition: number): void {
        this._title.titleHistoryPeriod = '';
        this.timelineData.globalPeriod.forEach(period => {
            if (+sliderPosition >= +period.dynamic_date.start_date && +sliderPosition <= +period.dynamic_date.end_date) {
                this._title.titleHistoryPeriod = period.title;
            }
        });
    }

    /**
     * init all the title in regard of the current year
     */
    private initTimelineTitle(year: number): void {
        this.setTitleHistoryPeriodByDate(year);
        this.setTitleImagePeriodByDate(year);
        this.setTitleByDate(year);
    }

    /**
     * get the timeline data by id
     * @param id: timeline id
     */
    public setTimelineDataById(id): void {
        this.resetToDefaultValue();
        this.getTimelineData(id).take(1)
            .subscribe((data: DataEntity) => {
                this.initAllData(data);
            });
    }

    /**
     * init all data we need in front with back data
     * @param data all back data
     */
    private initAllData(data: DataEntity): void {
        // to always launch before initParamsZoneAroundImage()
        this.initParamsZoneAroundImage(data);
        this.initTimeLineData(data);
        this._arrayPeriodeColor = this.getColorZoneParams();
        this.setPipePeriodPosition();
        this._arrayDot = this.getdotPosition();
        this.setZoneImageLimit();
        this.initTimelineTitle(+this.timelineData.dynamic_date.start_date);
        this.ajustementPositionPx = +data.get('decalagePositionImage');
        this.maPosition.ajustementPositionPx = this.ajustementPositionPx;
        this._dataReady = true;
    }

    /**
     * set the slider in position with the current image of the period in midle of screen
     * @param position : slider value in year
     */
    private calculImagePosition(sliderPosition: number): number {
        let positionImageToShow = 0;
        const decalage = +this.timelineData.imagePeriod[0].dynamic_size;
        const ImageWidth = +this.timelineData.imagePeriod[0].dynamic_size;

        if (sliderPosition === +this.timelineData.dynamic_date.start_date) {
            positionImageToShow = decalage;
        } else {
            this.arrayZoneImageValue.forEach(dot => {
                if (+sliderPosition > dot.start && +sliderPosition <= dot.end) {
                    if (+dot.numero === 0) {
                        positionImageToShow = decalage;
                    } else {
                        // numero begin to 0 and each image increase of + 1
                        positionImageToShow = decalage + (ImageWidth * +dot.numero);
                    }
                }
            });
        }
        return positionImageToShow;
    }

    /**
     * get number of image 0 to x concerned by period
     * @param position : slider value in year
     */
    private calculImageToShow(sliderPosition: number): number {
        let numero = 0;
        this.arrayZoneImageValue.forEach(dot => {
            if (+sliderPosition > dot.start && +sliderPosition <= dot.end) {
                numero = +dot.numero;
            }
        });
        return numero;
    }

    /**
     * for moment only one element to click so use the main id
     * open the media associated
     * @param idPeriod id common to image and dot
     */
    public openBasicModalWithInfo(idPeriod: Number): void {
        // get info of document to open
        const imagewithdocToOpen = this.timelineData.imagePeriod.filter(image => +image.id === idPeriod)[0];
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: imagewithdocToOpen.points[0].title,
            panelClass: 'entity-form-dialog',
        };

        // modal contain in HTML pass by innerHtml
        dialogConfig.data.bodyDialog = '<div class = "popInContentTimeline">' +
            '<img class="imgPopinTimeline" src="' + imagewithdocToOpen.points[0].media[0].uri + '"> ' +
            '<div class="txtPopinTimeline">' + imagewithdocToOpen.points[0].description + '</div> ' +
            '</div>';

        // open modal construct by passing html
        const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                // nothing
            }
        });
    }

    /**
     * change info slider and title after a move by gesture on image
     * @param numImage : image affiché de 0 à x
     */
    public currentImage(numImage: number): void {
        this._currentSliderValue = +this.arrayZoneImageValue[numImage].start;
        this.setTitleByDate(this._currentSliderValue);
        this.setTitleHistoryPeriodByDate(this._currentSliderValue);
        this.setTitleImagePeriodByDate(this._currentSliderValue);
    }

    /**
     * move the images in regard of slider value linar move not smoothy.
     * @param position : slider value
     */
    public sliderMove(position: number): void {
        this.setTitleByDate(position);
        this.setTitleHistoryPeriodByDate(position);
        this.setTitleImagePeriodByDate(position);
        this._maPosition = {position: this.calculPositionInPixelImage(position), type: 'auto', numeroImage: -1, ajustementPositionPx: 0};
    }

    /**
     * return the position in pixel of the image according of the slider current year
     * @param sliderValue : value of the cursor in the slider in year
     */
    private calculPositionInPixelImage(sliderValue: number): number {
        const sizePixel = +this.timelineData.dynamic_size;
        const minValue = this.timelineData.dynamic_date.start_date;
        const totalValue = Math.abs(minValue) + Math.abs(this.timelineData.dynamic_date.end_date);
        const positionInPixel = sizePixel * ((Math.abs(minValue) + sliderValue) / totalValue);

        return positionInPixel;
    }

    /**
     * move to the good image in middle of screen whith a smooth effect
     * position is used only if calculImageToShow is différent of -1
     * new Method to validate before deleting old one
     * @param position : slider value in year
     */
    public sliderClick(position: number): void {
        this._maPosition = {
            position: this.calculImagePosition(position), type: 'smooth',
            numeroImage: this.calculImageToShow(position),
            ajustementPositionPx: this.ajustementPositionPx
        };
    }
}
