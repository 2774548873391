import {ChangeDetectorRef, Injectable, QueryList} from '@angular/core';
import {DataCollection, DataEntity, OrderDirection, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs/Observable';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {MatCheckbox, MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {Subscription} from 'rxjs/Subscription';
import {localizedDate, localizedTime} from '../../../shared/utils';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash';
import {PaginatedCollection} from 'octopus-connect/src/paginated-collection.interface';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {isArray} from 'util';

const settingsStructure: ModelSchema = new ModelSchema({
    columns: Structures.object({
        default: Structures.array(['type', 'assigned_node_title', 'start_date', 'end_date', 'assigned_user', 'class', 'group', 'state', 'progress', 'score', 'buttons'])
    }),
    filters: Structures.object({
        default: Structures.array(['type', 'title', 'group', 'workgroup', 'learner'])
    }),
    actions: Structures.object({
        default: ['unassign']
    }),
    hasType: Structures.boolean(true),
    stateWithIcon: Structures.boolean(false),
    hasCompletionDate: Structures.boolean(true),
    hasCompletionTime: Structures.boolean(true),
    completionDate: Structures.array(['assessment', 'homework']),
    completionTime: Structures.array(['assessment']),
    completionStartDateOnly: Structures.array(),
    enableGradeType: Structures.array(),
    ratingBase: Structures.array(),
    gettingStarted: Structures.object({}),
    getStateFromDate: Structures.boolean(),
    followedList: Structures.object({
        formCreationLink: Structures.array([])
    }),
    formFields: Structures.object({
        default: ['group', 'workgroup', 'learner', 'project', 'assignment_type', 'assignment_grade', 'start_date', 'due_date']
    })
});

const projectSettingsStructure: ModelSchema = new ModelSchema(({
    accessProject: Structures.boolean()
}));

@Injectable()
export class AssignationService {
    assignations: any = [];
    public settings: { [key: string]: any };
    public projectSettings: { [key: string]: any };
    private states: DataEntity[] = [];
    followedSubscription: Subscription;
    onFilesChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onFileSelected: BehaviorSubject<any> = new BehaviorSubject({});
    onSelectedResourcesChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    i: number;
    dialogYes: string;
    dialogCancel: string;
    dialogTitle: string;
    dialogDeleteMessage: string;
    userData: DataEntity;
    public assignmentsPaginated: any;
    private assignmentsPaginatedObs: Observable<DataEntity[]>;
    private currentAssignment: DataEntity;
    private savePending: { [key: string]: Observable<DataEntity> } = {};
    private saveQueue: { [key: string]: DataEntity } = {};

    learnersList: any[];
    rawGroupsList: any[];
    rawWorkgroupsList: any[];
    selectedProject: any;

    assignmentCollection: DataCollection;
    public assignmentGroupListPaginated: PaginatedCollection;
    private assignmentsGroupListPaginatedObs: Observable<DataEntity[]>;
    private assignmentsGroup: DataEntity[] = [];

    public methodDialogInfos: { title: string, body: string, ok: string } = {title: '', body: '', ok: ''};
    public settingsLicensing: { [key: string]: any };
    public learnerMethods = [];

    private types: any[];
    private _dataSource: MatTableDataSource<any> = new MatTableDataSource();
    get dataSource(): MatTableDataSource<any> {
        return this._dataSource;
    }

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private translate: TranslateService,
        private authService: AuthenticationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((assignment: DataEntity) => {
                this.currentAssignment = assignment;
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('launchAssignment')
            .subscribe((data) => {
                this.launchAssignment(data);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('createAssignment')
            .subscribe((data) => {
                this.createAssignmentWithCallBack(data);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('comment')
            .subscribe(data => {
                if (this.currentAssignment && data.comment) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('comment', data.comment);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('changeState')
            .subscribe(data => {
                if (this.currentAssignment && data.state) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('state_term', this.getState(data.state));

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveGrade')
            .subscribe((data) => {
                if (this.currentAssignment && data.grade !== undefined) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('grade', data.grade);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveProgress')
            .subscribe((data) => {
                if (this.currentAssignment && data.progress !== undefined) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('progress', data.progress);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('event')
            .subscribe((data) => {
                if (data.id && data.event) {
                    this.octopusConnect.loadEntity('assignations', data.id + '/' + data.event);
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('launch')
            .subscribe((data) => {
                this.loadAndGetAssignmentById(data)
                    .take(1)
                    .subscribe(assignment => {
                        this.launchAssignment(assignment);
                    }, error => {
                        throw error;
                    });
            });

        this.onFilesChanged.subscribe(data => {
            this.assignations = data;
        });

        this.settings = settingsStructure.filterModel(modulesSettings.assignation);
        this.projectSettings = projectSettingsStructure.filterModel(modulesSettings.projectsManagement);

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadAvailableGroups')
            .subscribe((load: boolean) => {
                if (load) {
                    this.loadPaginatedAssignmentGroupList({}, true);
                } else {
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('assignmentsGroup', {assignmentsGroup: []});
                }
            });

        this.communicationCenter.getRoom('licenses')
            .getSubject('settings')
            .subscribe((data) => {
                this.settingsLicensing = data;
            });

        this.communicationCenter.getRoom('licenses')
            .getSubject('methods')
            .subscribe((entities: Array<DataEntity>) => {
                this.learnerMethods = entities.map((entity) => {
                    return entity.attributes.access.id;
                });
            });
    }

    loadPaginatedAssignmentGroupList(filterOptions = {}, getForDashboard = false): void {
        if (getForDashboard) {
            this.loadAssignationsTypes().take(1).subscribe((data) => {
                this.types = data;
                const trainingType = this.types.find((state) => state.label === 'training');
                const homeworkType = this.types.find((state) => state.label === 'homework');
                const assessmentType = this.types.find((state) => state.label === 'assessment');

                const optionsForAssessment: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: assessmentType ? assessmentType.id : null
                    },
                    orderOptions: [{
                        field: 'endDate',
                        direction: OrderDirection.ASC
                    }]
                };

                const optionsForHomeworkType: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: homeworkType ? homeworkType.id : null
                    },
                    orderOptions: [{
                        field: 'endDate',
                        direction: OrderDirection.ASC
                    }]
                };
                const optionsForTraining: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: trainingType ? trainingType.id : null
                    },
                    orderOptions: [{
                        field: 'startDate',
                        direction: OrderDirection.DESC
                    }]
                };

                if (filterOptions && filterOptions['filter'] && filterOptions['filter']['group']) {
                    optionsForAssessment.filter.group = filterOptions['filter']['group'];
                    optionsForHomeworkType.filter.group = filterOptions['filter']['group'];
                    optionsForTraining.filter.group = filterOptions['filter']['group'];
                }

                const obsTraining = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForTraining)
                    .collectionObservable;
                const obsAssessment = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForHomeworkType)
                    .collectionObservable;
                const obsHomework = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForAssessment)
                    .collectionObservable;

                obsAssessment.combineLatest(obsHomework, obsTraining)
                    .subscribe((data: [any, any, any]) => {
                        const assignmentsGroupSort = data[0].entities.concat(...data[1].entities).sort((a, b) => a.get('endDate') - b.get('endDate'));
                        assignmentsGroupSort.push(...data[2].entities);

                        this.assignmentsGroup = assignmentsGroupSort.slice();
                        this.communicationCenter
                            .getRoom('assignation')
                            .next('assignmentsGroup', {
                                assignmentsGroup: this.assignmentsGroup,
                                callback: (options, forDashboard) => this.loadPaginatedAssignmentGroupList(options, forDashboard)
                            });
                    });
            });

        } else {
            this.assignmentGroupListPaginated = this.octopusConnect.paginatedLoadCollection('assignations-group', filterOptions);
            this.assignmentsGroupListPaginatedObs = this.assignmentGroupListPaginated.collectionObservable.map(collection => collection.entities);

            this.assignmentsGroupListPaginatedObs.subscribe((assignmentGroupList: DataEntity[]) => {
                this.assignmentsGroup = assignmentGroupList.slice();
                this.communicationCenter
                    .getRoom('assignation')
                    .next('assignmentsGroup', {assignmentsGroup: this.assignmentsGroup, callback: (options) => this.loadPaginatedAssignmentGroupList(options)});
            });
        }
    }

    loadAssignments(): void {
        const assignedState = this.states.find((state) => state.get('label') === 'assigned');
        const trainingType = this.types.find((state) => state.label === 'training');
        const assessmentType = this.types.find((state) => state.label === 'assessment');
        const homeworkType = this.types.find((state) => state.label === 'homework');
        const optsTraining = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: trainingType.id
            }
        };
        const optsAssessment = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: assessmentType.id
            }
        };
        const optsHomework = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: homeworkType.id
            }
        };

        const obsTraining = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsTraining)
            .collectionObservable;
        const obsAssessment = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsAssessment)
            .collectionObservable;
        const obsHomework = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsHomework)
            .collectionObservable;

        obsAssessment.combineLatest(obsHomework, obsTraining)
            .subscribe((data: [any, any, any]) => {
                let assignments = data[0].entities.concat(...data[1].entities, ...data[2].entities);
                const dateNow = Math.floor(Date.now() / 1000);

                assignments = assignments.filter((assignment) => {
                    const startDate = +assignment.get('dates').value;
                    const endDate = +assignment.get('dates').value2;

                    return ((startDate && startDate <= dateNow) || !startDate) && ((endDate && endDate > dateNow) || !endDate);
                });
                this._dataSource.data = assignments.slice(0, 3);
            });
    }

    private postLogout(): void {
        if (this.followedSubscription) {
            this.followedSubscription.unsubscribe();
            this.followedSubscription = null;
        }
    }

    private postAuthentication(): void {
        const learners = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList');
        const groups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');
        const workgroups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');
        const project = this.communicationCenter
            .getRoom('project-management')
            .getSubject('selectedProject');

        project.subscribe((selectedProject) => {
            this.selectedProject = selectedProject;
        });

        combineLatest(learners, groups, workgroups).subscribe((data: [any, any, any]) => {
            this.learnersList = data[0];
            this.rawGroupsList = data[1];
            this.rawWorkgroupsList = data[2];
        });

        this.loadAssignationsStates()
            .subscribe((states: DataEntity[]) => {
                this.states = states;
                this.loadAssignationsTypes().subscribe((data) => {
                    this.types = data;
                    if (this.authService.isLearner()) {
                        this.loadAssignments();
                    }
                });
                this.loadAssignmentsByRole();

            });


    }

    public get groupsList(): any[] {
        let filteredGroups = this.rawGroupsList.filter((group) => !group.archived);
        if (this.selectedProject) {
            filteredGroups = filteredGroups.filter((group) => group.projects.find((project) => +project === +this.selectedProject.id));
        }
        return filteredGroups;
    }

    public get workgroupsList(): any[] {
        let filteredGroups = this.rawWorkgroupsList.filter((group) => !group.archived);
        if (this.selectedProject) {
            filteredGroups = filteredGroups.filter((group) => group.projects.find((project) => +project === +this.selectedProject.id));
        }
        return filteredGroups;
    }


    loadPaginatedAssignments(filterOptions = {}): Observable<Object[]> {
        this.assignmentsPaginated = this.octopusConnect.paginatedLoadCollection('assignation_search', filterOptions);
        this.assignmentsPaginatedObs = this.assignmentsPaginated.collectionObservable.map(collection => collection.entities);
        return this.assignmentsPaginatedObs;
    }

    loadAssignmentsByRole(): any {
        if (this.authService.isAtLeastTrainer()) {
            const pendingState = this.states.find((state) => state.get('label') === 'pending');
            const options = {filter: {'assignator': this.userData.id, assignments_state: pendingState ? pendingState.id : null}};

            const obs = this.octopusConnect
                .paginatedLoadCollection('assignation_search', options)
                .collectionObservable;

            obs.subscribe((data) => {
                this.assignmentCollection = data;
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('assignmentList')
                    .next(data.entities);
            });
        } else {
            const assignedState = this.states.find((state) => state.get('label') === 'assigned');
            const closedState = this.states.find((state) => state.get('label') === 'closed');

            const optsAssigned = {filter: {'assignated_user': this.userData.id, assignments_state: assignedState ? assignedState.id : null}};
            const optsClosed = {filter: {'assignated_user': this.userData.id, assignments_state: closedState ? closedState.id : null}};

            const obsAssigned = this.octopusConnect
                .paginatedLoadCollection('assignation_search', optsAssigned)
                .collectionObservable;
            const obsClosed = this.octopusConnect
                .paginatedLoadCollection('assignation_search', optsClosed)
                .collectionObservable;

            obsAssigned.combineLatest(obsClosed).subscribe((data: [any, any]) => {
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('assignmentList')
                    .next(data);
            });
        }

    }

    /**
     * launchAssignement if user is allowed to open lesson and return true or false
     * @param assignment : use to get info on lesson assigned to compare with licence
     */
    public launchAssignment(assignment: DataEntity): boolean {
        if (assignment && this.isAllowedToOpenLesson(assignment)) {
            this.currentAssignment = assignment;
            this.communicationCenter
                .getRoom('assignment')
                .next('current', assignment);

            this.communicationCenter
                .getRoom('assignation')
                .next('event', {id: assignment.id, event: 'start'});

            return true;
        } else {
            return false;
        }
    }

    loadAndGetAssignmentById(assignmentId: string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('assignation_search', +assignmentId);
    }

    private queueSave(assignment: DataEntity): void {
        if (!this.saveQueue[assignment.id]) {
            this.saveQueue[assignment.id] = assignment;
        } else {
            const queuedAssignment = this.saveQueue[assignment.id];
            const diff = assignment.getDiff();

            for (const key in diff) {
                queuedAssignment.set(key, diff[key]);
            }
        }
    }

    private saveAssignment(assignment: DataEntity): void {
        const entity = _.cloneDeep(assignment);
        entity.type = 'assignations';
        this.savePending[entity.id] = entity.save();
        this.communicationCenter
            .getRoom('assignment')
            .next('saving', true);
        this.savePending[entity.id].take(1).subscribe((save: DataEntity) => {
            delete this.savePending[entity.id];
            let queued;

            if (this.saveQueue[save.id]) {
                queued = this.saveQueue[save.id];
                delete this.saveQueue[save.id];

                const diff = queued.getDiff();
                for (const key in diff) {
                    save.set(key, diff[key]);
                }
            }

            this.communicationCenter
                .getRoom('assignment')
                .next('current', save);
            this.communicationCenter
                .getRoom('assignment')
                .next('savingDone', true);
            this.communicationCenter
                .getRoom('assignment')
                .next('saving', false);

            if (queued) {
                this.saveAssignment(queued);
            }
        });
    }

    openDialog(entity: any, checkBoxesList: QueryList<MatCheckbox> = null) {

        // get translation
        this.translate.get('generic.yes').subscribe((translation: string) => this.dialogYes = translation);
        this.translate.get('generic.cancel').subscribe((translation: string) => this.dialogCancel = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => this.dialogTitle = translation);

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: this.dialogTitle,
        };

        const checkboxes = document.getElementsByName('corpusCheckboxe');
        const checkboxesChecked = [];
        // loop over them all
        for (this.i = 0; this.i < checkboxes.length; this.i++) {
            // And stick the checked ones onto an array...
            if (checkboxes[this.i]['checked']) {
                checkboxesChecked.push(checkboxes[this.i].id.replace('-input', ''));
            }
        }

        // Return the array if it is non-empty, or null
        // return checkboxesChecked.length > 0 ? checkboxesChecked : null;

        if (entity !== 'multiple') { // for 1 entity
            this.translate.get('generic.confim_delete_single_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
            dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
            dialogConfig.data.labelTrueDialog = this.dialogYes;
            dialogConfig.data.labelFalseDialog = this.dialogCancel;

            const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    entity.remove();
                }
            });

        } else { // for 1 or multiple entities
            if (checkboxesChecked.length > 0) {
                this.translate.get('generic.confim_delete_multiple_files').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
                dialogConfig.data.labelTrueDialog = this.dialogYes;
                dialogConfig.data.labelFalseDialog = this.dialogCancel;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result === true) {
                        for (this.i = 0; this.i < checkboxesChecked.length; this.i++) {
                            this.octopusConnect.loadEntity('node', checkboxesChecked[this.i]).take(1).subscribe(entity => entity.remove());
                        }
                    }
                });

            } else { // no checked checkbox
                this.translate.get('generic.confim_action_no_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
            }
        }
    }

    // generic function to get the exact object from the nestedObject.
    getPropertyFromNestedObject(mainObject: Object, pathToAttribute: Array<string>): any {
        return pathToAttribute.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, mainObject);
    }

    public hasCompletionDate(type: string): boolean {
        return this.settings.hasCompletionDate
            && (!this.settings.completionDate.length
                || (this.settings.completionDate.length
                    && (
                        this.settings.completionDate.includes(type)
                        || this.settings.completionDate.includes('*')
                    )
                )
            );
    }

    public hasCompletionStartDateOnly(type: string): boolean {
        return this.settings.hasCompletionDate
            && this.settings.completionStartDateOnly.length
            && (
                this.settings.completionStartDateOnly.includes(type)
                || this.settings.completionStartDateOnly.includes('*')
            );
    }

    public hasCompletionTime(type: string): boolean {
        return this.settings.hasCompletionDate
            && this.settings.hasCompletionTime
            && (!this.settings.completionTime.length
                || (this.settings.completionTime.length
                    && (
                        this.settings.completionTime.includes(type)
                        || this.settings.completionTime.includes('*')
                    )));
    }

    public hasGrade(type: string): boolean {
        return this.settings.enableGradeType.length
            && this.settings.enableGradeType.includes(type);
    }

    /**
     * create assignement using communication center with a callback when finish
     * @param data : data and calBack
     */
    private createAssignmentWithCallBack(data: any): void {
        this.createAssignment(data.assigment).subscribe(assignments => {
            data.callback(assignments[0]);
        });
    }

    public createAssignment(assignment): Observable<DataEntity[]> {
        const project = assignment.project;
        let startTime = 0;
        let dueTime = 0;

        const assignementType = this.settings.hasType ? assignment.type.label : null;
        if (this.hasCompletionDate(assignementType) || this.hasCompletionStartDateOnly(assignementType)) {
            const startMoment = assignment.startDate;
            const dueMoment = assignment.dueDate;

            if (this.hasCompletionTime(assignementType)) {
                const start = assignment.startTime.split(':').map(element => +element);
                startMoment.add(start[0], 'hours');
                startMoment.add(start[1], 'minutes');

                const due = assignment.dueTime.split(':').map(element => +element);
                dueMoment.add(due[0], 'hours');
                dueMoment.add(due[1], 'minutes');
            } else {
                if (this.hasCompletionDate(assignementType)) {
                    dueMoment.add(23, 'hours');
                    dueMoment.add(59, 'minutes');
                }
            }

            startTime = Math.floor(new Date(startMoment).getTime() / 1000);
            if (this.hasCompletionDate(assignementType)) {
                dueTime = Math.floor(new Date(dueMoment).getTime() / 1000);
            }
        }

        const assignations = [];
        const obs: Observable<DataEntity>[] = [];
        assignment.learners.forEach((learner) => {
            const assignmentObs = this.octopusConnect
                .createEntity('assignations', {
                    assignator: +this.userData.id,
                    assignated_user: learner.id,
                    assignated_node: assignment.nodeId,
                    dates: {
                        value: startTime,
                        value2: dueTime
                    },
                    state_term: this.getState('assigned'),
                    type_term: this.settings.hasType ? +assignment.type.id : null,
                    rating_base: this.settings.hasType ? assignment.rating_base : null,
                    assignatedCount: assignment.assignatedCount ? assignment.assignatedCount : null,
                    comment: assignment.comment ? assignment.comment : null
                })
                .take(1);

            assignmentObs.subscribe((assignment: DataEntity) => {
                assignations.push(assignment.id);

                const assignated_node = assignment.get('assignated_node');

                this.communicationCenter
                    .getRoom('notifications')
                    .next('sendNotification', {
                        recipient: learner.id,
                        type: 'NEW_ASSIGNATION',
                        content: {
                            author: this.userData ? this.userData.get('label') : '',
                            id: assignment.id,
                            formName: assignated_node.title,
                            project: project ? project.id : null
                        }
                    });
            });

            obs.push(assignmentObs);
        });

        const dataEntityArrayObs = combineLatest(...obs);

        dataEntityArrayObs.subscribe(dataArray => {
            this.octopusConnect.createEntity('assignations-group', {
                assignations: assignations,
                dates: {
                    value: startTime,
                    value2: dueTime
                },
                date: dueTime,
                type_term: this.settings.hasType ? +assignment.type.id : null,
                rating_base: this.settings.hasType ? assignment.rating_base : null,
                group: assignment.group.map(g => g.id)
            }).take(1);
        });

        return dataEntityArrayObs;
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date: number): string {
        return localizedDate(date);
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeTime(date: number): string {
        return localizedTime(date);
    }

    loadAssignationsTypes(): Observable<any[]> {
        return this.octopusConnect.loadCollection('variables/instance')
            .map((collection: DataCollection) => collection.entities[0].get('assignationTypes'))
            .take(1);
    }

    loadAssignationsStates(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('assignation_state')
            .map((collection: DataCollection) => collection.entities)
            .take(1);
    }

    private getState(stateName: string): number {
        return +(this.states.find((state: DataEntity) => state.get('label') === stateName).id);
    }

    /***
     * check if user have the paper method licence
     * @param entity : use to get info on lesson assigned to compare with licence
     */
    public isAllowedToOpenLesson(entity: DataEntity): boolean {
        const assignated_node = entity.get('assignated_node');
        const assignated_nodes = entity.get('assignated_nodes');
        // multiassignement x lesson to check
        if (assignated_node === null && assignated_nodes.length > 0) {
            return this.isAllLessonsAllowed(assignated_nodes);
        }
        // mono assignment one lesson to check
        return this.isLessonAllowed(assignated_node);
    }

    /**
     * check if all lessons in multiassignement are allowed
     * @param assignated_nodes : array of assignated node with id title and type
     */
    private isAllLessonsAllowed(assignated_nodes: any[]): boolean {
        for (let i = 0; i < assignated_nodes.length; i++) {
            if (!this.isLessonAllowed(assignated_nodes[i])) {
                // one or more lesson are not allowed => exit for all
                return false;
            }
        }
        // all lesson are allowed return true
        return true;
    }

    /**
     * check if licencing is activate for this assignated_node
     * if it is check if user has the right to open it
     * @param assignated_node : assignated_node with id type title
     */
    private isLessonAllowed(assignated_node): boolean {
        if (this.settingsLicensing['restrict'].includes(assignated_node.type)
            && !this.learnerMethods.includes(assignated_node.chapters[0].id)) {
            this.openMethodModalRedirect(assignated_node.chapters[0].label);
            return false;
        } else {
            return true;
        }
    }

    private openMethodModalRedirect(methodName: string): void {
        this.setTranslateValueForMethodModalRedirect();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: this.methodDialogInfos.title,
            bodyDialog: this.methodDialogInfos.body + methodName,
            labelTrueDialog: this.methodDialogInfos.ok
        };
        const dialogRedirect = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        dialogRedirect.afterClosed().subscribe(() => {
            this.router.navigate(['profile/licensing']);
        });
    }

    private setTranslateValueForMethodModalRedirect(): void {
        this.translate.get('assignment.method_modal.title').subscribe((translation: string) => this.methodDialogInfos.title = translation);
        this.translate.get('assignment.method_modal.body').subscribe((translation: string) => this.methodDialogInfos.body = translation);
        this.translate.get('assignment.method_modal.button.ok').subscribe((translation: string) => this.methodDialogInfos.ok = translation);
    }

    /**
     * Obtain list of educational levels
     */
    public getEducationalLevels(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('educational_level').map(collection => collection.entities);
    }

    /**
     * Obtain allowed actions like 'edit' or 'unassign' for current user or default
     */
    public getAllowedActions(): string[] {
        const allowedActions = this.settings.actions[(this.settings.actions[this.authService.accessLevel] ? this.authService.accessLevel : 'default')];
        return isArray(allowedActions) ? allowedActions : [];
    }
}
