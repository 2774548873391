import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigurationService} from '@modules/configuration/core/configuration.service';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {RouterModule, Routes} from '@angular/router';
import {IsUserLogged} from '../../../is-user-logged.class';
import {ConfigurationDataComponent} from './components/configuration-data/configuration-data.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from 'app/shared/shared.module';
import {AuthenticationService} from '@modules/authentication';
import {LicencingImportComponent} from '@modules/configuration/core/components/licencing-import/licencing-import.component';
import {AnalyticsComponent} from '@modules/configuration/core/components/analytics/analytics.component';
import {modulesSettings} from '../../../settings';

const routes: Routes = [
    {
        path: 'configuration/data',
        canActivate: [IsUserLogged],
        component: ConfigurationDataComponent
    },
    {
        path: 'analytics',
        canActivate: [IsUserLogged],
        component: AnalyticsComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    declarations: [ConfigurationDataComponent, LicencingImportComponent, AnalyticsComponent],
    exports: [LicencingImportComponent],
    entryComponents: [LicencingImportComponent]
})
export class ConfigurationModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private communicationCenter: CommunicationCenterService,
        private authService: AuthenticationService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ConfigurationModule,
            providers: [
                ConfigurationService
            ]
        };
    }

    private postLogout(): void {
        ConfigurationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'configuration-data');
        this.dynamicNavigation.clearMenuItem('level0', 'analytics');
    }

    private postAuthentication(): void {
        if (!ConfigurationModule.isMenuSet) {
            if (this.authService.hasLevel(['administrator', 'manager'])) {
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id': 'configuration-data',
                    'title': 'Données',
                    'translate': 'navigation.data',
                    'type': 'item',
                    'icon': 'import_export',
                    'url': '/configuration/data'
                });
            }
            if (this.authService.hasLevel(modulesSettings.configuration.role ? modulesSettings.configuration.role : []) && modulesSettings.configuration.show) {
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id': 'analytics',
                    'title': 'Analytics',
                    'translate': 'Analytics',
                    'type': 'item',
                    'icon': 'analytics',
                    'url': 'analytics'
                });
            }

            ConfigurationModule.isMenuSet = true;
        }



    }
}
