import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {brand} from '../../../settings';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivitiesComponent implements OnInit {
    public brand: string = brand;

  constructor() { }

  ngOnInit() {
  }

}
