export const accountManagement = {
    allowedExtensions: ['jpg, jpeg, png, gif'],
    allowedMaxSize: 1,
    displayDialogMinorAdult: true,
    canEditAvatar: ['trainer', 'manager', 'administrator'],
    fields: {
        minor : ['label', 'contact_email', 'password', 'picture'],
        default : ['label', 'email', 'password', 'picture']
    },
    selfSignup: false,
    signup: false,
};