import { Injectable } from '@angular/core';
import { GroupService } from '@modules/groups-management/core/group/group.service';
import { ProjectsService } from '@modules/projects-management/core/projects.service';
import { CorpusService } from '@modules/corpus';
import {InstitutionGroupService, LearnerService, TrainerService, WorkgroupService} from '@modules/groups-management';
import { ResearchSheetService } from '@modules/research-sheet';
import {AssignationService, AssignmentComponentsService} from '@modules/assignation';
// import { AuthenticationService } from '@modules/authentication-module';
import {SvgIconList} from './themes/erasme/svgIconList';
import {LessonsService} from '@modules/activities';
import {LicensingService} from '@modules/licensing/core/licensing.service';
import {GamificationService} from '@modules/gamification/core/gamification.service';
import {MessagingService} from '@modules/messaging';
import {ConfigurationService} from '@modules/configuration';

@Injectable()
export class ServiceInstantiator {

  constructor(
    // private authenticationService: AuthenticationService,
    private groupService: GroupService,
    private workgroupService: WorkgroupService,
    private institutionGroupService: InstitutionGroupService,
    private learners: LearnerService,
    private projectsService: ProjectsService,
    private corpusService: CorpusService,
    private trainerService: TrainerService,
    private researchSheetService: ResearchSheetService,
    private assignationService: AssignationService,
    private assignmentComponentsService: AssignmentComponentsService,
    private SvgIconList: SvgIconList,
    private lessonsService: LessonsService,
    private licensingService: LicensingService,
    private gamificationService: GamificationService,
    private messaginService: MessagingService,
    private configurationService: ConfigurationService
  ) { }

}
