import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import { IsUserLogged } from '../../../is-user-logged.class';
import { FuseSharedModule } from '@fuse/shared.module';

import { DashboardService } from './dashboard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import { WidgetProjectComponent } from './widgets/widget-project/widget-project.component';
import { WidgetGroupComponent } from './widgets/widget-group/widget-group.component';
import {WidgetGroupService} from '@modules/dashboard/core/widgets/widget-group/widget-group.service';
import {WidgetProjectService} from '@modules/dashboard/core/widgets/widget-project/widget-project.service';
import {DashWidgetModule} from 'fuse-core/components/dash-widget/dash-widget.module';
import {SharedModule} from '../../../shared/shared.module';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';
import {WidgetMessagingComponent} from './widgets/widget-messaging-thread/widget-messaging-thread.component';
import {WidgetResearchSheetModelsComponent} from '@modules/dashboard/core/widgets/widget-research-sheet-models/widget-research-sheet-models.component';
import {WidgetFormModelsComponent} from '@modules/dashboard/core/widgets/widget-form-models/widget-form-models.component';
import {WidgetWorkgroupComponent} from '@modules/dashboard/core/widgets/widget-workgroup/widget-workgroup.component';
import {WidgetAssignmentsGroupComponent} from '@modules/dashboard/core/widgets/widget-assignments-group/widget-assignments-group.component';
import {FullPageComponent} from 'fuse-core/components/basic-page/full-page/full-page.component';

const routes: Routes = [
    {
        path       : 'dashboard',
        canActivate: [IsUserLogged],
        component  : DashboardComponent
    },
    {
        path: 'page/:alias',
        component: FullPageComponent
    },
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
        DashWidgetModule,
    ],
    providers: [

    ],
    declarations: [
        DashboardComponent,
        WidgetProjectComponent,
        WidgetGroupComponent,
        WidgetResearchSheetModelsComponent,
        WidgetFormModelsComponent,
        WidgetWorkgroupComponent,
        WidgetAssignmentsGroupComponent,
        WidgetMessagingComponent,
    ]
})
export class DashboardModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private dashboardService: DashboardService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DashboardModule,
            providers: [
                DashboardService,
                WidgetGroupService,
                WidgetProjectService
            ]
        };
    }

    private postLogout(): void {
        DashboardModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'dashboard');
    }

    private postAuthentication(): void {
        if (!DashboardModule.isMenuSet && this.authService.isAuthenticated) {
            // redirect dashboard'url to basic page url
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id'   : 'dashboard',
                'title': 'Tableau de bord',
                'translate': 'navigation.dashboard',
                'type' : 'item',
                'icon' : 'dashboard',
                'url'  : this.dashboardService.settings['redirectUrlBasicPage'] ? this.dashboardService.settings['redirectUrlBasicPage'] : '/dashboard',
            });

            DashboardModule.isMenuSet = true;
        }
    }
}
