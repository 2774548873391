export const activities = {
    allowErrorReporting: true,
    assignableLesson: true,
    cardLayout: 'card-simple-course',
    columns: {
        administrator: ['checkbox', 'type', 'title', 'level', 'difficulty', 'used', 'success-rate', 'changed', 'source', 'actions'],
        manager: ['checkbox', 'type', 'title', 'level', 'difficulty', 'used', 'success-rate', 'answers-count', 'changed', 'source', 'actions'],
        trainer: ['checkbox', 'type', 'title', 'level', 'difficulty', 'used', 'actions'],
        default: ['checkbox', 'type', 'title', 'level', 'difficulty', 'changed', 'actions']
    },
    filters: {
        administrator: ['chapters', 'title', 'keywords', 'type', 'source', 'skills', 'concepts', 'used'],
        manager: ['chapters', 'title', 'keywords', 'type', 'source', 'skills', 'concepts', 'used'],
        trainer: ['chapters', 'title', 'keywords', 'type', 'skills', 'concepts', 'used'],
        default: ['chapters', 'title', 'keywords', 'type', 'skills', 'concepts']
    },
    loadLessonWithSublesson: null,
    searchFields: ['title', 'method', 'launchSearch', 'countEntities', 'bookmarks'], // this fields are auto order like the list order
    lessonDialogFields: {
        default: ['title', 'method', 'tags', 'description'],
    },
    grade: true,
    gradeCalculation: {
        qcm: {
            right: 1,
            wrong: -0.75,
            missingRight: -0.25,
            missingWrong: 0.15,
        }
    },
    latexKeyboard: true,
    levels: {
        primary: ['P3', 'P4', 'P5', 'P6', '3LJ', '4LJ', '5LJ', '6LJ'],
        secondary: ['1C', '2C', '1J', '2J']
    },
    lessonDisplayCardHeaderMenu: true,
    openDialogInfoAfterDuplicateLesson: true,
    progress: true,
    showMetadataButton: true,
    stepperLoadStatus: true,
    lessonStep: null,
};