import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IActivity, IActivityContentPoint, IFullActivityContent} from '@modules/activities/core/player-components/interactive-image/models/interactive-image.models';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-interactive-image',
    templateUrl: './interactive-image.component.html',
    styleUrls: ['./interactive-image.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InteractiveImageComponent implements OnInit, OnDestroy {

    private _activity: IActivity;
    private _isDotDocOpen: boolean = false;
    /***
     * is media open by click on the interactiv picture
     */
    get isDotDocOpen(): boolean {
        return this._isDotDocOpen;
    }

    private _data: IFullActivityContent;
    /***
     * all the data we need to manage the interactive picture
     */
    get data(): IFullActivityContent {
        return this._data;
    }

    private _currentDot: IActivityContentPoint;
    /***
     * current dot click who was click on the interactive picture( content data to show)
     */
    get currentDot(): IActivityContentPoint {
        return this._currentDot;
    }

    private _isShowDot: boolean = false;
    /***
     * is dot show on interactive picture
     */
    get isShowDot(): boolean {
        return this._isShowDot;
    }

    private unsubscribeInTakeUntil = new Subject();

    constructor(private activatedRoute: ActivatedRoute,
                private activityService: ActivitiesService) {
        this.activatedRoute.queryParams.subscribe((params: IActivity) => {
            this._activity = params;
            this.initialize();
        });
    }

    ngOnInit(): void {
    }

    initialize(): void {
        this.reset();
        this.getActivityData();
    }

    private getActivityData(): void {
        this.activityService.launchActivity(this._activity)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((data) => {
                this.setContentData(data);
            });
    }

    private setContentData(data): void {
        this._data = this.activityService.getPropertyFromNestedObject(data, ['reference']) as IFullActivityContent;
        this.initLocalValueDotOpenToFalse();
    }

    private initLocalValueDotOpenToFalse(): void {
        // local value not exist in object from back
        // => init value dot not already selected when open interactive picture
        this._data.activity_content.points.forEach(res => {
            res.alreadyOpen = false;
        });
    }

    ngOnDestroy(): void {
        this._data = {} as IFullActivityContent;
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private reset(): void {
        this.activityService.displayActions.next(false);
    }

    /***
     * open the media after a click on a dot on interactive picture
     * @param p : nfo of current dot
     */
    public openDocument(point: IActivityContentPoint): void {
        this._currentDot = point;
        this._isDotDocOpen = true;
        // add info about media was already open to change color
        this._data.activity_content.points.filter(res => res.id === point.id)[0].alreadyOpen = true;
    }

    /***
     * close the media open
     */
    public closeDocument(): void {
        this._isDotDocOpen = false;
        this._currentDot = null;
    }

    /**
     * show dot on interactive picture
     */
    public showhideDot(): void {
        this._isShowDot = !this._isShowDot;
    }

    /***
     * open pdf
     */
    public openPdf(path: string): void{
        window.open(path, '_blank');
    }
}
