import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {Subject} from 'rxjs';
import {CardsService} from 'fuse-core/components/card/cards.service';

@Component({
    selector: 'app-corpus-favorites',
    templateUrl: './corpus-favorites.component.html',
    styleUrls: ['./corpus-favorites.component.scss']
})
export class CorpusFavoritesComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private type = 'currentUser';
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        public corpusService: CorpusService,
        private datacardService: DatacardService,
        private cardService: CardsService,
        private mediaUploadService: MediaUploadService,
    ) { }

    ngOnInit(): void {
        this.cardService.settingBookmark.subscribe(() => {
            this.loadResources();
        });

        this.loadResources();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * Loads resources that have been bookmarked
     */
    private loadResources(): void {
        const callbacks = {
            'isEditableAndErasable': (resource) => this.datacardService.isEditableAndErasable(resource, this.type),
            'openAssign': (originalResource) => this.openResourceEditionModal(originalResource),
            'openDialog': (resource) => this.corpusService.openDialog(resource),
            'openDuplicate': (originalResource) => this.openResourceEditionModal(originalResource),
            'openEditor': (originalResource) => this.openResourceEditionModal(originalResource),
            'openMetaDialog': (originalResource) => this.corpusService.openRessource(originalResource),
            'play': (originalResource) => this.corpusService.openRessource(originalResource),
        };

        this.corpusService
            .loadCorpusRessources(null, 6, {label: 'bookmarks', value: true}, null, 'widget')
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((resources: CorpusRessource[]) => {
                this.corpusService.onFilesChanged.next(resources);
                this.dataCards = this.datacardService.processResources(resources, callbacks, this.type);
            });
    }

    private openResourceEditionModal(resource: CorpusRessource): void {
        this.mediaUploadService.openResourceEditionModal(resource);
    }
}
