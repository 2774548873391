import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import {LangChangeEvent, TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { navigation } from 'app/navigation/navigation';
import { ServiceInstantiator } from './service-instantiator.class';
import {TralaTranslationLoaderService} from './trala-translation-loader.service';
import {BrowserTestService} from '@modules/browser-test';
import {DynamicNavigationService} from './navigation/dynamic-navigation.service';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {brand, defaultLanguage, langs, trackingIDFromSettings} from './settings';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {AccountManagementProviderService} from '@modules/account-management';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{

    lang: string;
    browserLang: string;
    themeColor = 'red';
    route: string;
    fuseConfig: any;
    navigation: any;
    brand: string = brand;
    trackingID = trackingIDFromSettings;

    // Private
    private _unsubscribeAll: Subject<any>;

    // keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private translationLoader: TralaTranslationLoaderService,
        private serviceInstantiator: ServiceInstantiator,
        private browserTestService: BrowserTestService,
        private dynamicNavigation: DynamicNavigationService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private location: Location,
        private connector: OctopusConnectService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private usersProvider: AccountManagementProviderService,
        private ccService: NgcCookieConsentService,
        angulartics: Angulartics2GoogleGlobalSiteTag,
    )
    {
        this.loadGoogleAnalytics(this.trackingID);

        // analtics tracking
        angulartics.startTracking();

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', []);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        router.events.subscribe((val) => {
            if (location.path() !== ''){
                this.route = location.path();
            } else {
                this.route = 'Home';
            }
        });

        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.browserLang = defaultLang.indexOf(navigator.language.substring(0, 2)) !== -1 ? navigator.language.substring(0, 2) : defaultLanguage;

        // Add languages
        this._translateService.addLangs(defaultLang);

        // Set the default language
        this._translateService.setDefaultLang(this.browserLang);

        // Use a language
        this.lang = localStorage.getItem('lang') || this.browserLang;
        this.connector.setLanguage(this.lang);
        this._translateService.use(this.lang);
        // Set the navigation translations
        this.translationLoader.loadTranslations(...defaultLang);


        this._translateService.onLangChange.subscribe((event: LangChangeEvent)  => {
            this.connector.setLanguage(event.lang);
        });

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        
        this._fuseConfigService.setConfig({
            layout: {
                navigation: 'true',
                toolbar   : 'below',
                footer    : 'none'
            }
        });
        
        browserTestService.checkCompatibility();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.document.body.classList.add(this.brand);

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }
            });

        // Check (prepare data) if user is Logged or Anonymous
        this.connector.authenticated('http').take(1).subscribe((userData: DataEntity) => {
            this.authService.loggedUser = userData;
            this.authService.isAuthenticated = true;
            this.communicationCenter.getRoom('authentication').next('userData', userData);
        }, () => {
            // Nothing to do here
        });


        // waiting for translation to be loaded to replace link placeholder
        this._translateService.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
            if (event.lang === this._translateService.currentLang) {
                // get translation
                this._translateService
                .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy', 'cookie.href'])
                .subscribe(data => {
                    this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
                    // Override default messages with the translated ones
                    this.ccService.getConfig().content.header = data['cookie.header'];
                    this.ccService.getConfig().content.message = data['cookie.message'];
                    this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
                    this.ccService.getConfig().content.allow = data['cookie.allow'];
                    this.ccService.getConfig().content.deny = data['cookie.deny'];
                    this.ccService.getConfig().content.link = data['cookie.link'];
                    this.ccService.getConfig().content.policy = data['cookie.policy'];
                    this.ccService.getConfig().content.href = data['cookie.href'];

                    this.ccService.destroy(); // remove previous cookie bar (with default messages)
                    this.ccService.init(this.ccService.getConfig()); // update config with translated messages
                });
            }
        });

        // subscribe to cookieconsent observables to react to main events
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.initializeSubscription = this.ccService.initialize$.subscribe(
            (event: NgcInitializeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
                this.ccService.getConfig();
                switch (event.status) {
                    case 'allow':
                        // reload to load google analytics scripts
                        location.reload();
                        break;
                    case 'deny':
                        const cookies = document.cookie.split(';');
                        cookies.forEach( cookie => {
                            const eqPos = cookie.indexOf('=');
                            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
                        });
                        // console.log('cookieAfterDeny', cookies);
                        break;
                }
            });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
            (event: NgcNoCookieLawEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    loadGoogleAnalytics(trackingID: string): void {

        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag(\'js\', new Date());
        gtag(\'config\', \'${ trackingID }\');`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }
}
