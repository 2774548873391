import { NgModule } from '@angular/core';
import {
    MatAutocompleteModule, MatButtonModule, MatCardModule,
    MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialog, MatDialogModule, MatExpansionModule,
    MatFormFieldModule, MatGridListModule,
    MatIconModule, MatInputModule, MatListModule, MatMenuModule,
    MatPaginatorModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSlideToggleModule,
    MatTableModule, MatTabsModule, MatToolbarModule,
    MatTooltipModule
} from '@angular/material';

@NgModule({
  imports: [
      MatAutocompleteModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatPaginatorModule,
      MatRadioModule,
      MatSelectModule,
      MatSlideToggleModule,
      MatSidenavModule,
      MatTabsModule,
      MatTableModule,
      MatToolbarModule,
      MatTooltipModule,
      MatButtonModule
  ],
  exports: [
      MatAutocompleteModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatPaginatorModule,
      MatRadioModule,
      MatSelectModule,
      MatSlideToggleModule,
      MatSidenavModule,
      MatTabsModule,
      MatTableModule,
      MatToolbarModule,
      MatTooltipModule,
      MatButtonModule
  ],
  declarations: []
})
export class SharedMaterialModule { }
