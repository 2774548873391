
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsUserLogged} from '../../../../is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {DynamicNavigationService} from '../../../../navigation/dynamic-navigation.service';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {ThemesComponent} from '@modules/activities/core/themes/themes.component';
import {ThemesService} from '@modules/activities/core/themes/themes.service';
import {CardModule} from 'fuse-core/components/card/card.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import { ThemeFavoritesComponent } from './widget/theme-favorites/theme-favorites.component';
import { ThemeCreatedComponent } from './widget/theme-created/theme-created.component';
import { ThemeConsultedComponent } from './widget/theme-consulted/theme-consulted.component';
import {LessonsConsultedComponent} from '@modules/activities/core/lessons/widget/lessons-consulted/lessons-consulted.component';
import {LessonsCreatedComponent} from '@modules/activities/core/lessons/widget/lessons-created/lessons-created.component';
import {LessonsFavoritesComponent} from '@modules/activities/core/lessons/widget/lessons-favorites/lessons-favorites.component';
import {SharedMaterialModule} from '../../../../shared/shared-material.module';
import {SharedTranslateModule} from '../../../../shared/shared-translate.module';
import {MatProgressSpinnerModule} from '@angular/material';
import {SearchFiltersModule} from 'fuse-core/components';


const routes: Routes = [
    {
        path: 'themes',
        canActivate: [IsUserLogged],
        children: [
            {
                path: '**',
                component: ThemesComponent
            },
        ]
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CardModule,
        FuseSharedModule,
        SharedMaterialModule,
        SharedTranslateModule,
        MatProgressSpinnerModule,
        SearchFiltersModule
    ],
    declarations: [
        ThemesComponent,
        ThemeFavoritesComponent,
        ThemeCreatedComponent,
        ThemeConsultedComponent,
    ],
    entryComponents: [
        ThemeFavoritesComponent,
        ThemeCreatedComponent,
        ThemeConsultedComponent,
    ],
    exports: [
        RouterModule,
    ],
    providers: []
})

export class ThemesModule {
    private static isMenuSetThemes = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('theme')
            .next('themeConsultedComponent', ThemeConsultedComponent);

        this.communicationCenter
            .getRoom('theme')
            .next('themeCreatedComponent', ThemeCreatedComponent);

        this.communicationCenter
            .getRoom('theme')
            .next('themeFavoritesComponent', ThemeFavoritesComponent);
    }

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: ThemesModule,
            providers: [
                ThemesService,
            ]
        };
    }

    private postLogout(): void {
        ThemesModule.isMenuSetThemes = false;
        this.dynamicNavigation.clearMenuItem('level0', 'themes');
    }

    private postAuthentication(): void {
        if (!ThemesModule.isMenuSetThemes) {
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id': 'themes',
                    'title': 'themes',
                    'translate': 'generic.author_global_corpus',
                    'type': 'item',
                    'icon': 'book_open',
                    'url': '/themes'
                });
            ThemesModule.isMenuSetThemes = true;
            }
        }
    }


