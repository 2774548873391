import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommunicationCenterService} from './communication-center.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        //CommunicationCenterService
    ]
})
export class CommunicationCenterModule {

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: CommunicationCenterModule,
            providers: [

            ]
        };
    }
}
