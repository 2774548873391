import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {Learner, Workgroup} from '@modules/groups-management/core/definitions';
import {Subscription} from "rxjs/Subscription";
import {GroupsManagementService} from "@modules/groups-management/core/groups-management.service";

@Injectable()
export class WorkgroupService {

  private userData: DataEntity;
  public onWorkgroupsChanged: BehaviorSubject<any> = new BehaviorSubject([]);
  private learnersList: Learner[] = [];
  private workgroupsCollection: {[key: number]: DataEntity} = {};
  public workgroupsList: Workgroup[] = [];
  private workgroupSubscription: Subscription = null;

  public archiveMode = false;

  constructor(
    private groupsManagement: GroupsManagementService,
    private octopusConnect: OctopusConnectService,
    private communicationCenter: CommunicationCenterService,
  ) {
    this.communicationCenter
      .getRoom('authentication')
      .getSubject('userData')
      .subscribe((data: DataEntity) => {
          if (data) {
              this.userData = data;
              this.postAuthentication();
          } else {
              this.postLogout();
          }
      });

    this.communicationCenter
      .getRoom('projects-management')
      .getSubject('setProjectWorkgroups')
      .subscribe((data) => this.updateProjectInWorkgroups(data));
  }

    private postLogout(): void {
      if (this.workgroupSubscription) {
          this.workgroupSubscription.unsubscribe();
          this.workgroupSubscription = null;
      }
    }

  private postAuthentication(): void {
    if (!this.workgroupSubscription) {
      const subjectLearnerList = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('learnerList');

      const obsLoad = this.loadWorkgroups();

      this.workgroupSubscription = obsLoad.subscribe((data: DataCollection) => {
        this.workgroupsList = [];

        for (const entity of data.entities) {
          if (parseInt(entity.get('type').toString()) === this.groupsManagement.settings.workgroupType) {
            this.workgroupsList.push({
              id: (entity.id ? parseInt(entity.id.toString()) : -1),
              workgroupname: entity.get('label'),
              archived: entity.get('archived'),
              learners: [],
              learnersIds: [],
              projects: (entity.get('projects') ? entity.get('projects').slice() : []),
            });
          }
          this.workgroupsCollection[entity.id] = entity;
        }
        this.communicationCenter
          .getRoom('groups-management')
          .next('workgroupsList', this.workgroupsList);

        this.filterArchived(this.archiveMode);
      });

      // TODO - Document split into two subscriptions
      obsLoad.take(1).subscribe((data: DataCollection) => {
        subjectLearnerList.subscribe((learnersList: Learner[]) => {
          this.learnersList = learnersList;

          this.workgroupsList.forEach((workgroup: Workgroup) => {
            const learners = this.learnersList
              .filter((learner: Learner) => learner.workgroups.indexOf(workgroup.workgroupname) > -1)
              .map((learner: Learner) => learner.username);

            workgroup.learners = learners;
            workgroup.learnersIds = this.learnersList
                .filter((learner: Learner) => learner.workgroups.indexOf(workgroup.workgroupname) > -1)
                .map((learner: Learner) => learner.id);
          });
        });
      });
    }
  }

  loadWorkgroups(): Observable<DataCollection> {
    return this.octopusConnect.loadCollection('groups');
  }

  public get groups(): Workgroup[] {
      return this.workgroupsList.slice();
  }

  public filterArchived(archived: boolean): void {
      this.archiveMode = archived;
      this.onWorkgroupsChanged.next(this.workgroupsList.filter((group) => group.archived === archived));
  }

    /**
   * Given a list of workgroups, add a project ID to those workgroups and remove project ID from workgroups not listed.
   * @param data Object containing workgroups ID (workgroups) and project ID (project)
   */
  updateProjectInWorkgroups(data): void {
    const projectId = data.project.toString();

    // Remove projectId from groups not listed in data.groups
    this.workgroupsList
      .filter((workgroup: Workgroup) => workgroup.projects.indexOf(projectId) > -1)
      .forEach((workgroup: Workgroup) => {
        if (data.workgroups.indexOf(workgroup.id) === -1) {
          workgroup.projects.splice(workgroup.projects.indexOf(projectId), 1);
          this.saveWorkgroup(workgroup);
        }
      });

    // Add projectId to workgroups listed in workgroups
    this.workgroupsList
      .filter((workgroup: Workgroup) => data.workgroups.indexOf(workgroup.id) > -1)
      .forEach((workgroup: Workgroup) => {
        if (workgroup.projects.indexOf(projectId) === -1) {
          workgroup.projects.push(projectId);
          this.saveWorkgroup(workgroup);
        }
      });
  }

  addWorkgroup(workgroup): Observable<DataEntity> {
    return this.octopusConnect
      .createEntity('groups', {
        label: workgroup.workgroupname.trim(),
        type: this.groupsManagement.settings.workgroupType.toString(),
        archived: false,
        projects: []
      });
  }

    saveWorkgroup(workgroup): Observable<DataEntity> {
        const workgroupEntity = this.workgroupsCollection[workgroup.id];
        const oldGroupName: string = workgroupEntity.get('label');
        workgroupEntity.set('label', workgroup.workgroupname.trim());
        workgroupEntity.set('archived', workgroup.archived);
        workgroupEntity.set('projects', workgroup.projects);
        const obs = workgroupEntity.save();

        obs.take(1).subscribe(() => {
            if (workgroup.workgroupname !== oldGroupName) {

                this.communicationCenter
                    .getRoom('notifications')
                    .next('sendNotification', {
                        recipient : this.workgroupsList.find((item => item.id === workgroup.id)).learnersIds,
                        type: 'GROUP_NAME_CHANGED',
                        content: {
                            author: (this.userData ? this.userData.get('label') : ''),
                            oldName: oldGroupName,
                            newName: workgroup.workgroupname
                        }
                    });
            }
        });

        return obs;
    }

  deleteWorkgroup(workgroup): void {
        if (workgroup.learnersIds.length) {
            this.communicationCenter.getRoom('groups-management')
                .getSubject('learnersChanged')
                .next([workgroup.learnersIds, workgroup, (entity) => this.removeGroup(entity)]);
        } else {
            this.removeGroup(workgroup);
        }
  }

  removeGroup(workgroup): void {
      const workgroupEntity = this.workgroupsCollection[workgroup.id];

      if (workgroupEntity) {
          workgroupEntity.remove();
      }
  }

  getWorkgroupService(): string[] {
    return this.workgroupsList.map((workgroup) => workgroup.workgroupname);
  }
}
