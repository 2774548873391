export const mainMenu = {
    'level0':
        [
            'dashboard',
            'lessonsDeployableWithoutCommunity',
            'followed',
            'corpus',
            'corpusPdf',
        ]
};
