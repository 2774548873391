export const activities = {
    allowedThumbnailExtensions: ['image/jpg', 'image/jpeg', 'image/png'],
    actionButtonsInMultiMatrix: {
        default: [],
        QCM: ['testAnswer'],
        QCMU: ['testAnswer', 'resetAnswer']
    },
    addFromActivities: false,
    availableAddButtons: {
        default: ['activity', 'divider', 'resource', 'multimedia']
    },
    cardLayout: 'card-split-media-info',
    columns: {
        default: ['checkbox', 'type', 'title', 'author', 'actions']
    },
    filters: {
        default: ['title', 'type']
    },
    filtersTheme: {
        default: ['title', 'skills', 'difficulty', 'launchSearch', 'countEntities', 'bookmarks'],
    },
    filterThemeUsePosition: true,
    filterTitleToTranslate: 'generic.title',
    grade: false,
    gradeCalculation: {},
    hideUserActionButtons: true,
    lessonDialogFields: {
        default: ['title', 'thumbnail', 'educationnalLevel', 'difficulty', 'skill', 'theme', 'files'],
    },
    lessonStep: null,
    stepTitlPrefixPosition: true, // show prefix in stepper in title lesson 1-title or not // TODO que dans lamorim ?
    showActivityTitle: true, // show title instead of lesson name // TODO que dans lamorim ?
    showLessonTitle: false,
    displayFullscreenButton: false,
    loadLessonWithSublesson: null,
    maxSizeThumbnail: '600000',
    openDialogInfoAfterDuplicateLesson: true,
    progress: false,
    searchFields: ['title', 'skills', 'theme', 'educationnalLevel', 'launchSearch', 'countEntities', 'bookmarks'], // this fields are auto order like the list order
    stepper: {
        align: 'start',
        showRecap: false,
        stepValidationNeedSave: false,
        position: 'left'
    },
    shareableModel: 1,
    hideAddButtonLessonForModel: true,
    hideAddButtonLessonForCommunity: true,
};

