import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import {AgendaService} from '../agenda.service';
import {EventEmitter} from '@angular/core';
import {Output} from '@angular/core';
import {EventFilters} from '../definitions';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-event-filters',
  templateUrl: './event-filters.component.html',
  styleUrls: ['./event-filters.component.scss'],
})
export class EventFiltersComponent implements OnInit {

  userRole: 'manager' | 'trainer' | 'learner';
  origFilters: EventFilters = {};
  filters: EventFilters = {};

  allEventTypes = [];

  @Output()
  search = new EventEmitter<any>();

  constructor(
    private authenticationService: AuthenticationService,
    public agendaService: AgendaService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.userRole = (this.authenticationService.accessLevel as 'manager' | 'trainer' | 'learner');
    this.agendaService.getAssignationTypes().subscribe(assignationTypes => {
      this.translate.get([
        'agenda.filters.type.all',
        'agenda.filters.type.events',
        ...assignationTypes.map(at => 'agenda.filters.type.' + at.label)
      ]).subscribe((translations: Object) => {
        this.allEventTypes = [{
          label: translations['agenda.filters.type.all'],
          id: null
        }, {
          label: translations['agenda.filters.type.events'],
          id: -1
        }, ...assignationTypes.map(at => {
          return {
            ...at,
            label: translations['agenda.filters.type.' + at.label]
          };
        })];
      });
    });
  }

  get learners(): Array<number | string> {
    return this.filters.members;
  }
  set learners(learners: Array<number | string>) {
    if (!learners) {
      delete this.filters.members;
    } else {
      this.filters.members = learners;
    }
  }

  get eventType(): number | string {
    return this.filters.type_term;
  }
  set eventType(eventType: number | string) {
    if (!eventType) {
      delete this.filters.type_term;
    } else {
      this.filters.type_term = eventType;
    }
  }

  get label(): string {
    return this.filters.label;
  }
  set label(label: string) {
    if (!label) {
      delete this.filters.label;
    } else {
      this.filters.label = label;
    }
  }

  get buttonActivated(): boolean {
    return this.origFilters.group !== this.filters.group
      || this.origFilters.label !== this.filters.label
      || JSON.stringify(this.origFilters.members) !== JSON.stringify(this.filters.members)
      || this.origFilters.type_term !== this.filters.type_term;
  }

  triggerSearch(): void {
    this.origFilters = JSON.parse(JSON.stringify(this.filters));
    this.search.emit(this.filters);
  }

}
