import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {IsUserLogged} from '../../../is-user-logged.class';
import {Router, RouterModule, Routes} from '@angular/router';
import {AssignationComponent} from './components/assignation/assignation.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {FollowedListComponent} from './components/followed-list/followed-list.component';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {FlagService} from '../../../shared/flag.service';
import {MatPaginatorIntl} from '@angular/material';
import {AssignationService} from '@modules/assignation/core/assignation.service';
import {AssignmentComponentsService} from '@modules/assignation/core/assignment-components.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {LessonsModule} from '@modules/activities';
import {WidgetAssignmentsLessonComponent} from '@modules/assignation/core/components/widget/widget-assignments-lesson/widget-assignments-lesson.component';

const routes: Routes = [
    {
        path: 'followed',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: FollowedListComponent
            },
            {
                path: 'assignment',
                children: [
                    {
                        path: '',
                        loadChildren: () => LessonsModule
                    }
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        CommonSharedModule,
        NgxMaterialTimepickerModule.forRoot()
    ],
    declarations: [
        AssignationComponent,
        FollowedListComponent,
        WidgetAssignmentsLessonComponent,
    ],
    entryComponents: [
        AssignationComponent,
        WidgetAssignmentsLessonComponent,
    ],
    exports: [
        RouterModule,
        WidgetAssignmentsLessonComponent,
    ],
    providers: []
})
export class AssignationModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private assignationService: AssignationService,
        private router: Router
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'NEW_ASSIGNATION',
                icon: 'activity',
                text: 'assignment.notification_new_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        userName: data['userName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    this.assignationService.loadAndGetAssignmentById(data['id'])
                        .take(1)
                        .subscribe((assignment: DataEntity) => {
                            this.assignationService.launchAssignment(assignment);

                            const node = assignment.get('assignated_node');
                            const route = [];

                            if (data['project']) {
                                route.push(...['projects', data['project'], 'tools']);
                            }

                            route.push(...['followed', 'assignment', `${node.type}s`, node.id, 'player']);

                            this.router.navigate(route);
                        })
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .next('widgetAssignmentsLessonComponent', WidgetAssignmentsLessonComponent);
    }

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: AssignationModule,
            providers: [
                AssignationService,
                AssignmentComponentsService,
                FlagService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
        AssignationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'followed');
    }

    private postAuthentication(): void {
        if (!AssignationModule.isMenuSet && this.authService.hasLevel(['learner', 'trainer', 'administrator'])) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'followed',
                'title': 'Assignations',
                'translate': 'assignment.title',
                'type': 'item',
                'icon': 'assignment',
                'url': '/followed'
            });

            AssignationModule.isMenuSet = true;
        }
    }
}
