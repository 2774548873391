import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatRadioChange} from '@angular/material';
import {Router} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs/Subject';
import {DataEntity} from 'octopus-connect';
import {Observable} from 'rxjs';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Component({
    selector: 'app-options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OptionsComponent implements OnInit, OnDestroy {
    @Input('answer') options: any;
    @Input('optionsText') optionText: string;
    @Input('usersSaved') usersSaved: any;
    @Input('qcmOptions') qcmOptions = false;
    @Input('qcuOptions') qcuOptions = false;
    @Input('activityId') activityId: any;
    @Input('contextId') contextId: any;
    @Input('answerStatus') answerStatus: any;
    @Input('isAnswered') isAnswered: any;
    @Input('hideCorrection') hideCorrection: boolean;
    @Input('hideMissing') hideMissing: boolean;
    @Input('disabled') public disabled: boolean;

    public checked: boolean;
    public validated = false;
    public answersChecked = false;
    public showFinalAnswers = false;

    private unsubscribeInTakeUntil = new Subject();
    private userAnswerTakeUntil = new Subject();

    constructor(
        private router: Router,
        private activityService: ActivitiesService,
        public lessonsService: LessonsService
    ) {
        this.activityService.userActionWaiting
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((action) => {
                if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining() ||
                    this.getUserAccessCorrection
                ) {
                    switch (action.actionLabel) {
                        case 'test':
                            this.answersChecked = true;
                            break;
                        case 'reset':
                            this.answersChecked = false;
                            this.showFinalAnswers = false;
                            this.validated = false;
                            this.resetAllOptions();
                            break;
                        case 'see_solution':
                            this.showFinalAnswers = true;
                            break;
                        case 'see_answer':
                            this.showFinalAnswers = false;
                            break;
                    }
                }
                action.endSubject.next();
            });

        this.activityService.userAnswer
            .takeUntil(this.userAnswerTakeUntil)
            .subscribe(answers => {
                // Do whatever you want with your
                const finalObj = {
                    showAnswers: true,
                    withoutAnyUserResponse: false,
                    reinitializeOptions: false
                };
                this.activityService.checkAnswers.next(finalObj);

                if (answers) {
                    this.usersSaved = answers;
                    this.checkAnswers();
                }
            });
    }

    ngOnInit(): void {
        this.activityService.doesUserResponsed.next(false);
        this.activityService.userSelectedOption = [];
    }

    ngOnDestroy(): void {
        this.activityService.userSelectedOption = [];
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private get getUserAccessCorrection(): boolean {
        return this.lessonsService.isLessonEvaluation() &&
            (this.lessonsService.isAtLeastTrainer() || this.lessonsService.isLessonCorrected());
    }

    public get valueChecked(): string {
        const option = this.options ? this.options.find(option => option.select) : null;
        return option ? option.answer : null;
    }

    public set valueChecked(value: string) {
        if (this.qcuOptions && this.options) {
            this.options.forEach(item => {
                item.select = (value === item.answer);
            });
        }
    }

    public radioChange($event: MatRadioChange): void {
        this.valueChecked = $event.value;

        // i did this set save answer value
        this.activityService.setSaveAnswer(this.options);
        this.activityService.doesUserResponsed.next(true);
    }

    public checkAnswers(access = false): any {
        if (this.lessonsService.isLessonTest() ||
            this.lessonsService.isLessonTraining() || access) {
            this.answersChecked = true;
        } else if (this.lessonsService.isLessonEvaluation()) {
            this.validated = true;
        }

        this.activityService.doesUserResponsed.next(true);
    }

    public saveAnswer(options: any[], status: number, userSave?: DataEntity, step?: number): Observable<DataEntity> {
        const answers = options.filter(item => item.select).map(item => item.id);
        this.validated = true;
        return this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, answers, status, 'qcm-save', userSave, step);
    }

    /* *
    * When user check a single option this will enable TESTER MA REPONSE button,
    * if user Deselect all options it will deactivate the same buttion.
    * */
    public informThatUserInteracted(event): void {
        this.activityService.doesUserResponsed.next(this.isAnyOtherOptionsChecked(this.options));
        this.activityService.setSaveAnswer(this.options);
    }

    /* *
    * Function to check if any option is checked or not.
    * RETURNS: boolean if any number of options are checked.
    * */
    private isAnyOtherOptionsChecked(options: Array<any>): boolean {
        const arrayLength = options.map((item) => (item.select));
        return arrayLength.includes(true);
    }

    public optionState(option: any): string {
        if (this.showFinalAnswers) {
            if (option.correct_answer) {
                return 'correctAnswer';
            }
        } else {
            if (!this.hideCorrection && this.answersChecked) {
                if (option.select) {
                    if (option.correct_answer) {
                        return 'correctAnswer';
                    } else {
                        return 'wrongAnswer';
                    }
                } else {
                    if (option.correct_answer && !this.hideMissing) {
                        return 'answerMissed';
                    }
                }
            }

            if (option.select) {
                if (this.validated) {
                    return 'validated';
                } else {
                    return 'selected';
                }
            }
        }

        return '';
    }

    public resetAllOptions(): void {
        this.answersChecked = false;
        this.showFinalAnswers = false;
        this.validated = false;
        this.hideMissing = !this.getUserAccessCorrection && !this.lessonsService.isLessonTest();
        this.validated = false;
        if (this.qcuOptions) {
            this.valueChecked = undefined;
        }
        if (this.qcmOptions) {
            this.options.forEach(item => {
                item.select = false;
            });
        }

        this.activityService.doesUserResponsed.next(false);
    }
}
