import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Router} from '@angular/router';
import {brand} from '../../../../../settings';
import {Location} from '@angular/common';
import {UserActionsService} from '@modules/activities/core/services/user-actions.service';
import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Component({
    selector: 'app-lessons-recap',
    templateUrl: './lessons-recap.component.html',
    styleUrls: ['./lessons-recap.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LessonsRecapComponent implements OnInit, OnDestroy {
    public brand: string = brand;
    public lesson: any;
    public title: string;
    public description: string;
    public label: string;
    public answerStatus: any = [];

    private unansweredFirst: number;
    private unsubscribeInTakeUntil = new Subject();
    private assignmentId: string;

    constructor(
        public activitiesService: ActivitiesService,
        private router: Router,
        private location: Location,
        private userActionsService: UserActionsService,
        private lessonsService: LessonsService,
        private communicationCenter: CommunicationCenterService
    ) {
    }

    ngOnInit(): void {
        if (!this.lessonsService.isLessonLaunched()) {
            console.error('No mode to run lesson in');
        }

        if (this.lessonsService.currentAssignment) {
            this.assignmentId = this.lessonsService.currentAssignment.id.toString();
        }

        if (this.activitiesService.currentLesson) {
            this.lesson = this.activitiesService.currentLesson;
        } else {
            this.activitiesService.pushLessonFromAssignment
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe(data => {
                    this.lesson = data;
                    this.activitiesService.currentLesson = data;
                    this.initValuesInView();
                });
        }

        this.communicationCenter
            .getRoom('assignation')
            .next('event', {id: this.assignmentId, event: 'end'});

        this.initValuesInView();
        this.processLabels();

        this.setAnswerStatus();
    }

    initValuesInView(): void {
        if (this.lesson) {
            this.title = this.lesson.attributes.metadatas.title;
            this.description = this.lesson.attributes.metadatas.description;

            if (this.lesson.attributes.metadatas.lessonType.length) {
                this.label = this.lesson.attributes.metadatas.lessonType[0].label || null;
            }
        }
    }

    private processLabels(): void {
        let activityCount = 0;

        this.activitiesService.activitiesArray.forEach((activity) => {
            switch (activity.type) {
                case 'audio':
                case 'document':
                case 'image':
                case 'media':
                case 'text':
                case 'url':
                case 'video':
                case 'videoUrl':
                    activity.label = 'M';
                    activity.badge = false;
                    break;
                case 'divider':
                    activity.label = 'i';
                    activity.badge = false;
                    break;
                default:
                    activityCount += 1;
                    activity.label = activityCount;
                    activity.badge = true;
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }


    get isLessonValidated():boolean{
        return this.lessonsService.isLessonValidated();
    }
    get isLessonTest():boolean{
        return this.lessonsService.isLessonTest();
    }
    get isLessonTraining():boolean{
        return this.lessonsService.isLessonTraining();
    }
    get isLessonEvaluation():boolean{
        return this.lessonsService.isLessonEvaluation();
    }
    get isLessonCorrected():boolean{
        return this.lessonsService.isLessonCorrected();
    }

    public get isLessonEvaluationPending(): boolean {
        return this.isLessonEvaluation && !this.isLessonValidated && !this.isLessonCorrected;
    }

    public get isLessonEvaluationValidated(): boolean {
        return this.isLessonEvaluation && this.isLessonValidated && !this.isLessonCorrected;
    }

    public get isLessonEvaluationCorrected(): boolean {
        return this.isLessonEvaluation && this.isLessonCorrected;
    }

    public get displayGrade(): boolean {
        if (this.lessonsService.isAtLeastTrainer()) {
            return this.isLessonEvaluation;
        }

        return this.isLessonEvaluationCorrected;
    }

    public get isUserTrainer(): boolean {
       return this.lessonsService.isAtLeastTrainer();
    }

    public get loading(): boolean {
        return this.lessonsService.savingAssignment;
    }

    public isStatusCorrect(index: number): boolean {
        return this.isActivity(index) && this.answerStatus[index] === 1;
    }

    public isStatusMissing(index: number): boolean {
        return this.isActivity(index) && this.answerStatus[index] === 2 || this.answerStatus[index] === 4;
    }

    public isStatusWrong(index: number): boolean {
        return this.isActivity(index) && this.answerStatus[index] === 3;
    }

    public isStatusAnswered(index: number): boolean {
        return !this.isActivity(index) || this.isStatusCorrect(index) || this.isStatusWrong(index);
    }

    public isActivity(index: number): boolean {
        return this.activitiesService.activitiesArray[index].type === 'activity';
    }

    private setAnswerStatus(): void {
        if (this.activitiesService.activitiesArray) {
            this.activitiesService.activitiesArray.forEach((item, index) => {
                const value = this.activitiesService.activityAnswerResult[index];
                this.answerStatus[index] = (value !== undefined && value) ? this.activitiesService.activityAnswerResult[index] : 2;
            });
            this.unansweredFirst = this.answerStatus.indexOf(2);
        }
    }

    public restartPlay(event, clearData): void {
        let obs: ReplaySubject<boolean> = new ReplaySubject();
        if (clearData) {
            obs = this.activitiesService.resetActivityAnswerData();
        } else {
            obs.next(true);
        }

        obs.takeUntil(this.unsubscribeInTakeUntil).subscribe((value: boolean) => {
            if (value) {
                this.communicationCenter
                    .getRoom('assignation')
                    .next('event', {id: this.assignmentId, event: 'restart'});

                this.activitiesService.playScreenStatus = 0;
                this.activitiesService.loadFirstActivity(true);
                this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
                this.lessonsService.lessonButtonClicked.next(true);
            }
        });
    }

    public goBack(event): void {
        event.stopPropagation();
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadActivityByStep(this.activitiesService.activitiesArray.length - 1, true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public gotoListPage(event): void {
        this.activitiesService.resetArrayindex();
        this.router.navigate([this.router.routerState.snapshot.url.split('/')[1], 'list']);
    }

    public gotoCurrentPage(event, action): void {
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadActivityByStep(action, true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public completeMyHomeWork(): void {
        this.activitiesService.playScreenStatus = 0;
        const activityNotCompleteIndex = this.answerStatus.findIndex(answer => answer === 2);
        const activitytoGo = activityNotCompleteIndex > -1 ? activityNotCompleteIndex : 0;
        this.activitiesService.loadActivityByStep(activitytoGo, true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public validateAnswer(event): void {
        this.communicationCenter
            .getRoom('assignment')
            .next('changeState', {id: this.assignmentId, state: 'closed'});
    }

    public trainingMode(event): void {
        this.lessonsService.lessonRunTraining = true;
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.presentArrayElementIndex = 0;

        this.lessonsService.userAnswerTempSave = this.activitiesService.userAnswerTempSave;
        this.lessonsService.activityAnswerResult = this.activitiesService.activityAnswerResult;
        this.activitiesService.userAnswerTempSave = [];
        this.activitiesService.activityAnswerResult = [];
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.activitiesService.loadActivityByStep(this.activitiesService.presentArrayElementIndex);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public onMyHomeWork(event): void {
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadFirstActivity(true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public stepIcon(index): string {
        let iconName = 'step_warning';
        if ((this.isStatusAnswered(index) && this.isLessonEvaluation && !this.isLessonEvaluationCorrected) || !this.isActivity(index)) {
            iconName = 'step_validated';
        } else if (this.isLessonEvaluationCorrected || !this.isLessonEvaluation) {
            switch (this.answerStatus[index]) {
                case 1:
                    iconName = 'step_success';
                    break;
                case 2:
                    iconName = 'step_warning';
                    break;
                case 3:
                    iconName = 'step_error';
                    break;
                default:
                    iconName = 'step_warning';
                    break;
            }
        }
        return iconName;
    }

    getGrade(): string {
        if (this.lessonsService.currentAssignment) {
            const assignation = this.lessonsService.currentAssignment;
            if (assignation) {
                if (this.lessonsService.isLessonEvaluation() &&
                    (this.lessonsService.isAtLeastTrainer() || this.lessonsService.isLessonCorrected())
                ) {
                    const grade = +assignation.get('grade');

                    const newGrade = Math.min(20, Math.round(grade * 10) / 10);

                    return  newGrade.toString() + '/20';
                }
            }
            return '';
        }
    }

}
