import {Component, OnDestroy, Input, OnInit, ViewEncapsulation, AfterViewChecked} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import {Subject} from 'rxjs/Subject';
import {ActivityInterface} from '@modules/activities/core/player-components/activity.interface';
import {DataEntity} from 'octopus-connect';
import {Observable} from 'rxjs';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {DragulaService, Group} from 'ng2-dragula';

@Component({
    selector: 'app-appaire',
    templateUrl: './appaire.component.html',
    styleUrls: ['./appaire.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DragulaService]
})
export class AppaireComponent implements OnDestroy, OnInit, AfterViewChecked, ActivityInterface {
    @Input('activityId') public activityId: any;
    @Input('contextId') public contextId: string;
    @Input('questionTypeName') questionTypeName: string;

    public activityPercentil = 0;
    public answers = [];
    public answerStatus = 2; // 1 => correct, 2 => missing, 3 => wrong;
    public dragContainers: any[] = [];
    public dragEnabled = true;
    public displayFeedback = false;
    public instruction: any;
    public isTwoColumns = false;
    public isVertical = false;
    /**
     * Seems to be the droppable data (the draggable data will be associated to the droppable by the user). The order has to be mixed.
     */
    public quesAnswer = [];
    /**
     * Seems to be the draggable data (the draggable data will be associated to the droppable by the user). The order is mixed.
     */
    public quesImages = [];
    public questionObject: any;
    public wording: string;

    private activityStepIndex: number;
    private answersDragGroup: Group; // dragula Group
    private disableDragWhenReady = false;
    private isSaving = false;
    private unsubscribeInTakeUntil = new Subject();
    private userAccessCorrection = false;
    private userSave: DataEntity;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private dragulaService: DragulaService,
        private lessonsService: LessonsService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngAfterViewChecked(): void {
        if (this.disableDragWhenReady) {
            this.disableDragWhenReady = false;
            this.disableAllDragBlock();
            this.enableNotCloneDragBlock();
        }
    }

    /**
     * enable all block except if they have status cloned = dropped to appaire
     */
    private enableNotCloneDragBlock(): void {
        const leftBag = this.answersDragGroup.drake.containers[0].children;
        this.quesImages.forEach((item, index) => {
            if (!item.cloned) {
                leftBag[index].classList.remove('cloned');
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.initialize();
        });
    }

    initialize(): void {
        this.lessonsService.initDebugGrade();
        this.reset(true);
        this.activityService.launchActivity(this.activityId)
            .take(1)
            .subscribe(data => {
                if (data.reference.config) {
                    this.isTwoColumns = data.reference.config.doubleColumn !== 0;
                    this.isVertical = (data.reference.config.direction === 'vertical');
                }
                this.questionObject = this.activityService.getPropertyFromNestedObject(data, ['reference']);
                this.instruction = this.questionObject.instruction;
                this.wording = this.questionObject.wording;
                this.quesImages = this.questionObject.activity_content.answers_app;
                this.shuffle();

                this.initDragula();
                this.loadUserSave();
                this.activityStepIndex = this.activityService.presentArrayElementIndex;

            });

        this.activityService.userActionWaiting
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((action) => {
                switch (action.actionLabel) {
                    case 'test':
                        this.userAccessCorrection = true;
                    case 'save':
                        this.checkAnswer();
                        this.saveAnswer()
                            .take(1)
                            .subscribe((userSave: DataEntity) => {
                                if (this.isSaving && userSave) {
                                    this.isSaving = false;
                                    this.userSave = userSave;
                                }
                                action.endSubject.next();
                            });
                        break;
                    case 'reset':
                        this.shuffle();
                        this.reset(false, 'reset');
                        action.endSubject.next();
                        break;
                    case 'modify':
                        this.reset(false, 'modify');
                        action.endSubject.next();
                        break;
                    case 'see_solution':
                        this.seeAnswerSolution();
                        action.endSubject.next();
                        break;
                    case 'see_answer':
                        this.reviewAnswer();
                        action.endSubject.next();
                        break;
                }
            });
    }

    private getDragBlockClassId(className: string): string {
        return className.substr(className.indexOf('drag-block-'), className.indexOf('drag-block-') + 1);
    }

    private initDragula() {

        this.resetDragContainers();
        if (this.answersDragGroup) {
            this.dragulaService.find(this.answersDragGroup.name).options.direction = this.isVertical ? 'horizontal' : 'vertical';
            this.dragulaSubscribe();
            return;
        }
        this.answersDragGroup = this.dragulaService.createGroup('ANSWERS', {
            direction: this.isVertical ? 'horizontal' : 'vertical',
            copy: (el, source) => {
                return el.className.includes('block-left') && !source.className.includes('answers-bag');
            },
            copyItem: (item) => {
                let copy = _.cloneDeep(item);
                copy.draggable = true;
                return copy;
            },
            removeOnSpill: true,
            moves: (el, source, handle, sibling) => {
                return el.className.includes('block-left') && this.dragEnabled;
            },
            accepts: (el, source, handle, sibling) => {
                let classId = this.getDragBlockClassId(el.className);
                let firstSibling = source.children[0];

                let possiblyAlreadyAwnsered = (firstSibling.classList.contains('block-left') && !firstSibling.classList.contains(classId));
                if (!possiblyAlreadyAwnsered && source.childElementCount > 2) {
                    possiblyAlreadyAwnsered = true;
                }

                return !source.className.includes('left') && (!possiblyAlreadyAwnsered);
            },
            invalid: (el, handle) => {
                return el.className && typeof el.className === 'string' && el.className.includes('cloned');
            }
        });
        this.dragulaSubscribe();
    }

    public dragulaSubscribe(): void {
        this.dragulaService.dropModel('ANSWERS').takeUntil(this.unsubscribeInTakeUntil).subscribe(({name, el, target, source, sibling, sourceModel, targetModel, item}) => {
            if (item.draggable) {
                item.classId = this.getDragBlockClassId(el.className);
                this.onAnswerDrop(item, this.getDragBlockClassId(target.className));
            }
        });
        this.dragulaService.cloned('ANSWERS').takeUntil(this.unsubscribeInTakeUntil).subscribe(({original, cloneType}) => {
            if (cloneType === 'copy') {
                original.className += ' cloned';
            }
        });
        this.dragulaService.removeModel('ANSWERS').takeUntil(this.unsubscribeInTakeUntil).subscribe(({el, source, item, sourceModel}) => {
            let container = this.answersDragGroup.options.containers[0].children;
            for (let i = 0; i < container.length; i++) {
                if (container[i].className.includes(item.classId)) {
                    container[i].classList.remove('cloned');
                    break;
                }
            }
            this.onAnswerRemove(item);
        });
        this.dragulaService.cancel('ANSWERS').takeUntil(this.unsubscribeInTakeUntil).subscribe(({el, container, source}) => {
            if (container === null) {
                for (let i = 0; i < source.children.length; i++) {
                    let classId = this.getDragBlockClassId(el.className);
                    if (source.children[i].className.includes(classId) && source.children[i].className.includes('left')) {
                        source.children[i].classList.remove('cloned');
                        break;
                    }
                }
            }
        });
    }

    private shuffle(): void {
        this.quesAnswer = _.cloneDeep(this.quesImages);
        this.quesAnswer = this.derange(this.quesAnswer);
        this.quesImages = this.derange(this.quesAnswer);
    }

    private loadUserSave(): void {
        this.activityService.getUserSave(this.activityId.id, this.contextId)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    this.setAnswer();

                    this.activityPercentil = Math.round(this.getGrade().oldGrade * 100);
                } else if (this.lessonsService.isMyAssignment()) {
                    this.saveDefault();
                } else if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.userAccessCorrection = true;
                    this.checkAnswer();
                }
            });
    }

    derange(quesAnswer): Array<any> {
        const quesAnswerCopy = quesAnswer.slice();
        if (quesAnswerCopy.length < 2) {
            return quesAnswerCopy;
        }

        let result = [];
        let index, i, quesAnswerLength;
        let lastMoved = false;

        for (let i = 0, quesAnswerLength = quesAnswerCopy.length - 1; i < quesAnswerLength; i++) {

            if (quesAnswerCopy.length === 2 && !lastMoved) {
                result = result.concat(quesAnswerCopy.reverse().splice(0, 2));
                break;
            }

            do {
                index = Math.random() * quesAnswerCopy.length | 0;

            } while (quesAnswer.indexOf(quesAnswerCopy[index]) === result.length);

            result.push(quesAnswerCopy.splice(index, 1)[0]);
            lastMoved = lastMoved || index === quesAnswerCopy.length;
        }

        if (quesAnswerCopy.length) {
            result.push(quesAnswerCopy[0]);
        }
        return result;
    }

    private disableAllDragBlock(): void {
        const leftBag = this.answersDragGroup.drake.containers[0].children;
        for (let i = 0; i < leftBag.length; i++) {
            leftBag[i].classList.add('cloned');
        }
    }

    setAnswer(): any {
        if (this.userSave && this.userSave.get('state') !== 'incomplete') {
            const answers = this.userSave.get('userActivity').entitySave.answers;

            if (answers) {
                this.restoreAnswers(answers);
                if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.userAccessCorrection = true;
                }
                this.checkAnswer();
                this.activityService.userAnswer.next(answers);
            }
        } else if (this.lessonsService.isTrainerSeeCorrection()) {
            this.userAccessCorrection = true;
            this.checkAnswer();
        }
    }

    private restoreAnswers(answers: any): void {
        // restore original answer order
        this.quesImages = this.questionObject.activity_content.answers_app;
        this.quesAnswer = _.cloneDeep(this.quesImages);

        this.resetDragContainers();
        for (let i = 0; i < answers.length; i++) {
            const itemDropped = _.cloneDeep(this.quesAnswer.find(qAnswer => qAnswer.id === answers[i]));
            if (itemDropped) {
                this.quesImages.find(qImage => qImage.id === itemDropped.id).cloned = true;
                itemDropped.draggable = true;
                itemDropped.dropped = null;
                this.quesAnswer[i].dropped = itemDropped;
                this.dragContainers[i].items = [itemDropped, this.quesAnswer[i]];
            }
        }
        this.dragContainers = this.derange(this.dragContainers);
        this.quesImages = this.derange(this.quesImages);
        this.quesAnswer = this.derange(this.quesAnswer);
        this.disableDragWhenReady = true;
    }

    private resetDragContainers(): void {
        this.dragContainers = [];
        this.quesAnswer.forEach((item, index) => {
            this.quesImages[index].cloned = false;
            this.quesAnswer[index].dropped = null;
            this.dragContainers.push({items: [this.quesAnswer[index]], index: index});
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    onAnswerDrop(item, targetClassId: string): void {
        let droppedOnId = parseInt(targetClassId.substr(item.classId.length - 1));
        if (this.quesAnswer[droppedOnId]) {
            this.quesImages[droppedOnId].cloned = true;
            item.ansno = +droppedOnId;
            item.dropped = null;
            this.quesAnswer[droppedOnId].dropped = _.cloneDeep(item);
            this.quesAnswer[droppedOnId].dropped.errorid = 0;
            if (this.quesAnswer.every(qAnswer => qAnswer.cloned)) {
                this.activityService.doesUserResponsed.next(true);
            }
        }
    }

    onAnswerRemove(item): void {
        if (this.quesAnswer[item.ansno]) {
            this.quesImages[item.ansno].cloned = false;
            this.quesAnswer[item.ansno].dropped = null;
            this.activityService.doesUserResponsed.next(false);
        }
    }

    getGrade(): any {
        let correctCnt;
        let oldSelectedCorrect = 0;
        let selectedCorrect = 0;

        let oldGrade = 0;
        let grade;

        correctCnt = this.quesAnswer.length;

        for (const item of this.quesAnswer) {
            if (item.dropped) {
                if (item.id === item.dropped.id && item.dropped.id) {
                    selectedCorrect += 1;
                }
            }
        }

        if (this.userSave) {
            const oldAnswers = this.userSave.get('userActivity').entitySave.answers;
            /**
             * {this.questionObject.activity_content.answers_app} are the original value.
             * The order of the original value is the correct order
             */
            this.questionObject.activity_content.answers_app
                .map((item) => item.id)
                .forEach((val, index) => {
                    if (oldAnswers[index] !== null && oldAnswers[index] === val) {
                        oldSelectedCorrect += 1;
                    }
                });

            if (oldSelectedCorrect > 0) {
                oldGrade = oldSelectedCorrect / correctCnt;
            }
        }

        grade = selectedCorrect / correctCnt;

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    checkAnswer(): void {
        let correctAnsCnt = 0;
        this.answerStatus = 3;
        this.quesAnswer.forEach((item, index) => {
            if (item.dropped) {
                item.dropped.errorid = 3;
                if (item.id === item.dropped.id && item.dropped.id) {
                    item.dropped.errorid = 1;
                    correctAnsCnt++;
                } else if (item.id !== item.dropped.id && item.dropped.id) {
                    item.dropped.errorid = 2;
                }
            } else {
                this.answerStatus = 2;
            }
        });

        if (this.quesAnswer.length === correctAnsCnt) {
            this.answerStatus = 1;
        }
        this.dragEnabled = false;
        this.activityService.doesUserResponsed.next(true);
        /* state message showing */
        if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining()) {
            this.userAccessCorrection = true;
            this.displayFeedback = this.answerStatus === 3 && this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer();

        }

        this.activityService.isUserAnswerStatus
           .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.userAccessCorrection) {
            this.activityService.checkAnswers.next({lessonCorrected: true});
        }
    }

    private saveDefault(): void {
        if (!this.isSaving && !this.userSave && !this.lessonsService.isLessonTest()) {
            this.isSaving = true;
            this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, [], this.answerStatus, 'app-save')
                .subscribe((userSave: DataEntity) => {
                    this.userSave = userSave;
                    this.isSaving = false;
                });
        }
    }

    // API Save Answer functionality
    private saveAnswer(): Observable<DataEntity> {
        this.isSaving = true;
        const pairing = _.cloneDeep(this.quesAnswer)
            .sort((a, b) => (+a.id) - (+b.id))
            .map((item) => item.dropped && item.dropped.id);

        const grade = this.getGrade();
        this.lessonsService.setAssignGrade(grade['newGrade'], grade['oldGrade']);
        this.lessonsService.setProgress(this.userSave, this.answerStatus);

        if (this.userSave) {
            this.userSave.set('grade', +this.calculUserSavePercentil);
        }

        return this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, pairing, this.answerStatus, 'app-save', this.userSave, this.activityStepIndex);
    }

    seeAnswerSolution(): void {
        this.userAccessCorrection = true;
        this.dragContainers = [];
        this.quesAnswer.forEach((item, index) => {
            item.cloned = true;
            let clonedItem = _.cloneDeep(item);
            clonedItem.dropped = null;
            clonedItem.errorid = 1;
            clonedItem.draggable = true;
            clonedItem.cloned = true;
            this.quesAnswer[index].dropped = clonedItem;
            this.dragContainers.push({items: [clonedItem, this.quesAnswer[index]], index: index});
        });
        this.disableAllDragBlock();

        this.dragEnabled = false;
        this.displayFeedback = false;
    }

    reset(resetAllSubscribe = false, type = null): void {
        this.activityService.displayActions.next(true);
        this.resetDragContainers();

        if (this.answersDragGroup) {
            let leftBag = this.answersDragGroup.drake.containers[0].children;
            for (let i = 0; i < leftBag.length; i++) {
                leftBag[i].classList.remove('cloned');
            }

        }

        if (!type) {
            //  type is only set when we need usersave for calculating grade.
            this.userSave = type;
        }

        if (resetAllSubscribe) {
            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject();
        }

        this.isSaving = false;
        this.answerStatus = 2;
        this.displayFeedback = false;
        this.userAccessCorrection = false;

        this.dragEnabled = this.lessonsService.isLessonTest() || !this.lessonsService.isAtLeastTrainerAndAssignmentExist;

        this.activityService.doesUserResponsed.next(false);
    }

    reviewAnswer(): void {
        this.displayFeedback = this.answerStatus === 3 && this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer();

        this.reset(false, 'reviewAnswer');
        this.userAccessCorrection = true;
        // restore answer only if add one and if almost one response is not null
        if (this.userSave && this.userSave.get('userActivity') && this.userSave.get('userActivity').entitySave.answers.filter(res => res !== null).length > 0) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.restoreAnswers(answers);
            this.checkAnswer();
        } else {
            // we don't have choose solution so we go back like if user click on reset button
            this.shuffle();
            this.reset(false, 'reset');
        }
    }

    public get showPercentil(): string {
        if (this.isPercentilDisplayAllowed) {
            return this.activityPercentil ? ' ' + this.activityPercentil + '%' : ' 0%';
        }

        return '';
    }

    public get isPercentilDisplayAllowed(): boolean {
        const asTrainer = this.lessonsService.isAtLeastTrainer() && (this.lessonsService.isLessonEvaluation() || this.lessonsService.isLessonTraining());
        const asLearner = this.lessonsService.isAtLeastTrainer() === false && (this.lessonsService.isLessonEvaluation() && this.userAccessCorrection);
        return asTrainer || asLearner;
    }

    private get calculUserSavePercentil(): number {
        return Math.round(this.getGrade().newGrade * 100);
    }
}
