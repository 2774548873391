import {Injectable} from '@angular/core';
import {FuseNavigationService} from 'fuse-core/components/navigation/navigation.service';
import {modulesSettings} from '../settings';
import {AuthenticationService} from '@modules/authentication';

@Injectable()
export class DynamicNavigationService {

    private currentMenu: string;

    private menus: {[key: string]: {[key: number]: Object[]}} = {};
    private pathClassItems: Array<string>;

    constructor(
        private fuseNavigationService: FuseNavigationService,
        private authenticationService: AuthenticationService
    ) {}


    switchClassPath(
        menuId: string
    ): void {
        const refClassPath = ['homepage', 'dashboard', 'groups', 'corpus', 'projects', 'research-sheet', 'tools', 'research-template', 'forms', 'followed', 'section-draft', 'ideas-wall'];
        let classPaths: Array<string>;
        const classListTemp: Array<string> = [];
        const sitemapLevel = 'level0';
        const wrapper = document.getElementsByTagName('body')[0];
        classPaths = window.location.pathname.split('/');
        this.pathClassItems = Array.from(wrapper.classList);



        wrapper.className = ''; // remove all class
        wrapper.classList.add(this.pathClassItems[0]);

        if (menuId === sitemapLevel) {
            wrapper.classList.remove('level1');
            wrapper.classList.add('level0');
        } else {
            wrapper.classList.remove('level0');
            wrapper.classList.add('level1');
        }

        for (const key in classPaths) {

            if (refClassPath.indexOf(classPaths[key]) !== -1) {
                classListTemp.push(classPaths[key]);
            }
        }
        // use just the last module path for css class
        wrapper.classList.add(classListTemp[classListTemp.length - 1]);

    }


    registerModuleMenu(
        menuId: string,
        menuItem: Object | Object[]
    ): void {
        const menuIsArray = Array.isArray(menuItem);
        let weight: number = 0;
        // un peu trop permissif, peut causer des bugs (ou pas) pour plus tard
        const _menuId = Object.keys(modulesSettings.mainMenu).find(id => id.includes(menuId) || menuId.includes(id));
        const menuLevel = modulesSettings.mainMenu[_menuId];
        const alreadyExist: boolean = this.flattenWeightedMenu(menuId).some((element) => {return element['id'] === menuItem['id'];})
        if (_menuId && !alreadyExist) {

            if (menuIsArray) {
                menuItem = (<Object[]>menuItem).filter(item => menuLevel.includes(item['id']));
            } else if (!menuLevel.includes(menuItem['id']) && !menuLevel.find(item => typeof item === 'object' && item.name === menuItem['id'])) {
                return;
            }
            let itemObject = menuLevel.find(item => typeof item === 'object' && item.name === menuItem['id']);
            if (itemObject) {
                if (itemObject.children) { // Si on a un filtre de sous-menu imposé
                    let childToKeep = menuItem['children'];
                    menuItem['children'].forEach(child => {
                        if (!itemObject.children.includes(child.id)) {
                            childToKeep.splice(1, childToKeep.indexOf(child))
                        }

                    });
                    menuItem['children'] = childToKeep;
                }
                if (itemObject.translate) {
                    menuItem['translate'] = this.getTranslateByRole(itemObject, 'parent');
                    for (const childKey in itemObject.translate.childs) {
                        if (itemObject.translate.childs.hasOwnProperty(childKey)) {
                            const child = menuItem['children'].find(child => child.id === childKey);
                            if (child) {
                                itemObject.translate.childs[childKey]
                                child.translate = this.getTranslateByRole(itemObject, 'childs', childKey);
                            }
                        }
                    }
                }
            }

            weight = menuLevel.indexOf(menuItem['id']) >= 0 ? menuLevel.indexOf(menuItem['id']) : menuLevel.indexOf(itemObject)

            this.menus[menuId] = this.menus[menuId] || {};
            this.menus[menuId][weight] = this.menus[menuId][weight] || [];

            // temporaire
            if (menuIsArray) {

                for (let i = 0; i < (<Object[]>menuItem).length; i++) {
                    const element = menuItem[i];
                    this.menus[menuId][menuLevel.indexOf(element['id'])] = element;
                }
            } else {
                this.menus[menuId][weight].push(menuItem);
            }

            if (this.currentMenu === menuId) {
                this.generateMenu(menuId, true);
            }
        }
    }

    private getTranslateByRole(item, itemType:string, childId:string=null) {
        let translate = item.translate[itemType];
        if(itemType === 'childs'){
            translate = translate[childId];
        }
        const role = translate[this.authenticationService.accessLevel] ? this.authenticationService.accessLevel : 'default';
        return translate[role]
    }

    setChildren(
        menuId: string,
        itemId: string,
        children: Object[]
    ) {
        this.clearChildren(menuId, itemId);

        children.forEach(child => {
            this.addChildTo(menuId, itemId, child);
        });
    }

    clearChildren(
        menuId: string,
        itemId: string
    ) {
        const flatten: Object = this.flattenWeightedMenu(menuId);

        for (const key in flatten) {
            if (flatten[key]['id'] === itemId) {
                flatten[key]['children'] = [];
            }
        }
    }

    addChildTo(
        menuId: string,
        itemId: string,
        menuItem: Object
    ) {
        const flatten: Object = this.flattenWeightedMenu(menuId);

        for (const key in flatten) {
            if (flatten[key]['id'] === itemId) {

                if (!flatten[key]['children']) {
                    flatten[key]['children'] = [];
                }

                flatten[key]['children'].push(menuItem);
                flatten[key]['children'].sort((a, b) => a.weight < b.weight ? -1 : 0);
            }
        }
    }

    clearMenu(menuId: string) {
        this.menus[menuId] = {};
    }

    clearMenuItem(menuId: string, itemId: string): void {
        if (this.menus[menuId]) {
            for (const key in this.menus[menuId]) {
                for (const subKey in this.menus[menuId][key]) {
                    if (this.menus[menuId][key][subKey]['id'] === itemId) {
                        this.menus[menuId][key].splice(+subKey, 1);
                    }
                }
            }

            this.generateMenu(menuId, true);
        }
    }

    private flattenWeightedMenu(id: string): Object[] {

        let flatten: Object[] = [];

        for (const weight in this.menus[id]) {
            if (this.menus[id][weight]) {
                flatten = flatten.concat(this.menus[id][weight]);
            }
        }

        return flatten;
    }

    generateMenu(id: string, refresh: boolean = false) {

        if (this.currentMenu === id && !refresh) {
            return;
        }

        this.currentMenu = id;

        const menu: any[] = this.flattenWeightedMenu(id);

        this.fuseNavigationService.unregister(id);
        this.fuseNavigationService.register(id, menu);
        this.fuseNavigationService.setCurrentNavigation(id);
    }
}
