import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.scss']
})
export class MultimediaComponent implements OnInit {
    private activity = {};
    public isInitializing: boolean;
    public resources: any[];
    public instruction: string;
    public wording: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private changeDetector: ChangeDetectorRef,
        private lessonsService: LessonsService,
        private octopusConnect: OctopusConnectService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activity[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): any {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    /**
     * Initializes the current component
     */
    private initialize(): void {
        this.reset();

        this.activityService.launchActivity((this.activity))
            .take(1)
            .subscribe(data => {
                this.instruction = data.reference.instruction;
                this.wording = data.reference.wording;

                this.resources = this.getGranule(data.reference.activity_content[0]);
                this.isInitializing = false;
            });
    }

    /**
     * Extract the granule's DataEntity from the given activityContent data
     * @param activityContent - field from activity
     * @returns granule from the content as a DataEntity
     */
    private getGranule(activityContent: any): DataEntity[] {
        if (activityContent && activityContent.granule && activityContent.granule.length) {
            const resources = activityContent.granule.map((granule) => {
                return new DataEntity('granule', granule, this.octopusConnect, granule.id);
            });

            return resources;
        }

        return [];
    }

    /**
     * Resets the current component
     */
    private reset(): void {
        this.isInitializing = true;
        delete this.instruction;
        delete this.wording;
        this.changeDetector.detectChanges();
    }

    public videoEmbedUrl(video): any {
        return {
            config: {
                src: video.get('reference').url,
            },
        };
    }

}
