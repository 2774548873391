import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResearchSheetService} from '../../research-sheet.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ResearchSection, ResearchSectionTemplate, ResearchSheet} from '@modules/research-sheet/core/definitions';
import {Subject, Subscription} from 'rxjs/index';
import {ResearchSheetDisplayAddSectionComponent} from '@modules/research-sheet/core/research-sheet/research-sheet-display/research-sheet-display-add-section/research-sheet-display-add-section.component';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-research-sheet-display',
    templateUrl: './research-sheet-display.component.html',
    styleUrls: ['./research-sheet-display.component.scss']
})
export class ResearchSheetDisplayComponent implements OnInit, OnDestroy {

    sections: ResearchSection[];
    private unsubscribeInTakeUntil = new Subject();

    public dialogRef: MatDialogRef<ResearchSheetDisplayAddSectionComponent>;


    constructor(
        private route: ActivatedRoute,
        private sheetService: ResearchSheetService,
        public dialog: MatDialog,
        private router: Router
    ) {
        this.route.params.subscribe((params: Params) => {
            if (params['sheetId']) {
                this.sheetService.loadResearchSheet(params['sheetId']);

                this.sheetService.loadResearchSheetsSectionsById(+params['sheetId'])
                    .takeUntil(this.unsubscribeInTakeUntil)
                    .subscribe((entities: ResearchSection[]) => {
                        this.sections = entities;

                        const self = this;

                        function getParent(sectionId: string): ResearchSection {
                            let parent = null;

                            if (sectionId) {
                                self.sections.some((section: ResearchSection) => {
                                    if (section.id.toString() === sectionId) {
                                        parent = section;
                                        return true;
                                    }
                                });
                            }

                            return parent;
                        }

                        let columns = 0;

                        this.sections.forEach((section: ResearchSection, index: number) => {
                            const parent = getParent(section.parent);
                            if (parent) {
                                parent.childCount += 1;

                                if (parent.childCount > 1) {
                                    columns += 1;
                                    section.x = columns;
                                } else {
                                    section.x = parent.x;
                                }
                            } else {
                                section.x = columns;
                            }

                            section.y = index;
                        });
                    });
            }
        });
    }

    ngOnInit(): any {
        this.sheetService.loadResearchDrafts();
    }

    public  selectTypeSection(): void {
        this.dialogRef = this.dialog.open(ResearchSheetDisplayAddSectionComponent, {data : {
                sections : this.sectionsTemplate,
                parent: null

            }});

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.router.navigate(['../section/edit'], {relativeTo: this.route});
            }
        });

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    get researchSheet(): ResearchSheet {
        return this.sheetService.currentSheet ? this.sheetService.currentSheet : new ResearchSheet();
    }

    public get sectionsTemplate(): ResearchSectionTemplate[] {
        return this.researchSheet.template.sections.slice();
    }

}
