import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent implements OnInit
{
    public titleDialog:string;
    public bodyDialog:any;
    public labelConfirmDialog:string;
    public labelConfirmDialogPlaceHolder:string;
    public labelTrueDialog:string;
    public labelFalseDialog:string;
    public confirmMessage: string;
    public showReportbutton: boolean;
    public resourceId: string;
    public inputValue: string;
    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        private domSanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

        this.titleDialog = data.titleDialog;
        this.bodyDialog = this.domSanitizer.bypassSecurityTrustHtml(data.bodyDialog);
        this.labelTrueDialog = data.labelTrueDialog;
        this.labelFalseDialog = data.labelFalseDialog;
        this.labelConfirmDialog = data.labelConfirmDialog;
        this.labelConfirmDialogPlaceHolder = data.labelConfirmDialogPlaceHolder;
        this.showReportbutton = data.showReportbutton;
        this.resourceId = data.resourceId;
    }

    ngOnInit()
    {
    }

}
