import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-tralalere-bar',
  templateUrl: './tralalere-bar.component.html',
  styleUrls: ['./tralalere-bar.component.scss']
})
export class TralalereBarComponent implements OnInit {

  public settings: { [key: string]: any };
  private linksObs: Observable<DataEntity[]>;
  public links: Array<object>;

  constructor(
      private translate: TranslateService,
      private octopusConnect: OctopusConnectService,
  ) {}

  ngOnInit(): void {
      if (!this.linksObs) {
          this.linksObs = this.octopusConnect.loadCollection('tralabar_links')
              .map((links: DataCollection) => links.entities);
      }

      this.linksObs.subscribe((data: DataEntity[]) => {
          this.links = data;
          console.log('this.links', this.links);
      });
  }

  public goToSite(pathKey: string): void{
      this.translate.get(pathKey).subscribe((translation: string) => window.open(translation, '_blank'));
  }

}
