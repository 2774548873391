import {environment, getTrackingID} from '../../../environments/environment';
import {activities} from './modules/activities';
import {accountManagement} from './modules/account-management';
import {authentication} from './modules/authentication';
import {corpus} from './modules/corpus';
import {assignation} from './modules/assignation';
import {dashboard} from './modules/dashboard';
import {cards} from './core/cards';
import {groupsManagement} from './modules/groups-management';
import {mainMenu} from './core/menu';
import {graphAssignation} from './modules/graph-assignation';
import {gamification} from './modules/gamification';
import {licensing} from './modules/licensing';
import {featureAccess} from './core/feature-access';
import {researchSheet} from './modules/research-sheet';
import {notification} from './modules/notification';
import {projectsManagement} from './modules/projects-management';
import {ideasWall} from './modules/ideas-wall';
import {browserTest} from './modules/browser-test';
import {configuration} from './modules/configuration';
import {timeline} from './modules/timeline';
import {NgcCookieConsentConfig} from 'ngx-cookieconsent';


export const brand = 'infohunter';
export const trackingID = 'G-WTQWL768HY';
export const trackingIDFromSettings = getTrackingID(trackingID);
export const brandLogoSvg = true;
export const defaultApiURL = environment.baseApiUrl(brand);
export const defaultNodeURL = environment.baseNodeUrl(brand);
export const defaultURL = environment.baseUrl(brand);
export const defaultLoginRoute = '/page/home-infohunter';
export const defaultRoute = '/page/home-infohunter';
export const defaultLanguage = 'en';
export const langs = [
    {
        id: 'en',
        title: 'English',
        flag: 'us'
    },
    {
        id: 'fr',
        title: 'Français',
        flag: 'fr'
    }
];
export const dateTimeOptions = {weekday: undefined, year: 'numeric', month: 'numeric', day: 'numeric'};
export const tralalereBar = {
    displayed: true,
};
export const displayHelp = true;

export const displayHeader = true;
export const displayHeaderTitle = true;

export const customHomePageBanner = true;

export const modulesSettings = {
    accountManagement,
    activities,
    assignation,
    authentication,
    browserTest,
    cards,
    corpus,
    dashboard,
    featureAccess,
    gamification,
    graphAssignation,
    groupsManagement,
    ideasWall,
    licensing,
    mainMenu,
    notification,
    projectsManagement,
    researchSheet,
    configuration,
    timeline,
};

// https://tinesoft.github.io/ngx-cookieconsent/home
export const cookiesConsent: NgcCookieConsentConfig = {
    cookie: {
        // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
        domain: window.location.hostname
    },
    palette: {
        popup: {
            background: '#000'
        },
        button: {
            background: '#FFF009'
        }
    },
    theme: 'edgeless',
    type: 'opt-in',
    // autoOpen: false,
    // revokable: true
};

export * from './core/fuse-config';
