import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {CorpusRessourcesTypes} from '@modules/corpus/core/corpus-ressources-types.class';
import {defaultApiURL, displayHeaderTitle} from 'app/settings';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {fuseAnimations} from 'fuse-core/animations';
import {HttpClient} from '@angular/common/http';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'app-corpus-display',
    templateUrl: './corpus-display.component.html',
    styleUrls: ['./corpus-display.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CorpusDisplayComponent implements OnInit {

    corpusId: string;
    @Input('itemId') itemId: string = null;
    @Input('data') data: {
        displayHeader: boolean,
        selectionMode: boolean,
        isMyCorpus: boolean,
        corpusFormatsAllowed: string[],
        showCheckBox: boolean,
        callBack: any
    };
    public displayHeader: boolean = true;

    selected: any;
    pathArr: string[];
    user;
    filedata: any;

    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';
    public displayHeaderTitle: boolean;

    @Input('mode') mode = 'normal';

    constructor(
        private route: ActivatedRoute,
        public corpusService: CorpusService,
        private accountManagementProvider: AccountManagementProviderService,
        private http: HttpClient,
        private uploadService: MediaUploadService,
        private router: Router,
        private mediaUploadService: MediaUploadService,
        public authService: AuthenticationService,
    ) {
        this.displayHeaderTitle = displayHeaderTitle;

        // TODO find a better way to distinct corpus
        if (this.corpusService.settings.userCorpus) {
            if (this.router.url.indexOf('/projects/') > -1) { // check if we are inside a project
                this.corpusService.mode = 'normal';
            } else {
                if (authService.isTrainer()) {
                    if (this.router.url.indexOf('/user/' + this.corpusService.currentUser.id) > -1) { // check if we are in user corpus
                        this.corpusService.mode = 'normal';
                    } else {
                        this.corpusService.mode = 'readonly';
                    }
                }
                if (authService.isAdministrator() || authService.isManager()) {
                    this.corpusService.mode = 'normal';
                }
            }
            this.mode = this.corpusService.mode;
        }
    }

    ngOnInit(): any {
        this.corpusService.dataForModalOpening.selectionMode = false;
        // if opening like a modal use data params to customize corpus
        if (this.data) {
            this.displayHeader = this.data.displayHeader && this.corpusService.settings.displayHeader;
            this.corpusService.dataForModalOpening.selectionMode = this.data.selectionMode;
            this.corpusService.dataForModalOpening = this.data;
            this.corpusService.dataForModalOpening.isMyCorpus = this.data.isMyCorpus;
        } else {
            this.displayHeader = this.corpusService.settings.displayHeader;
        }
        // if opening like a modal use isMycorpus params to set mycorpus or global corpus
        if (this.data && this.data.isMyCorpus === true) {
            this.mode = 'normal';
            this.corpusService.mode = 'normal';
        }

        this.route.params.distinctUntilChanged().subscribe(params => {
            if (params['id']) {
                console.log('id du corpus courant', params['id']);
                this.corpusId = params['id'];
            } else {
                console.log('id du corpus courant', this.corpusService.settings.globalCorpus);
                this.corpusId = this.corpusService.settings.globalCorpus;
            }
            this.corpusService.corpusId = this.corpusId;
        });
        // get parma
        const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
        this.itemId = this.route.snapshot.queryParamMap.get('itemId');
    }

    addTempUrlRessource(): void {
        this.corpusService.createRessource(CorpusRessourcesTypes.URL, this.corpusId, {
            url: 'http://truc.com'
        }, {
            description: 'Desc1',
            language: 'fr',
            title: 'Titre 1',
            'source-author': 'Auteur !!',
            source: 'Inconnu'
        }).subscribe(entity => {
            this.corpusService.onFileSelected.next(entity);
        });
    }

    addTempTextRessource(): void {
        this.corpusService.createRessource(CorpusRessourcesTypes.TEXT, this.corpusId, {
            text: 'Texte temporaire'
        }, {
            description: 'Desc2',
            language: 'fr',
            title: 'Titre 2',
            'source-author': 'Auteur 2 !!',
            source: 'Inconnu'
        }).subscribe(entity => {
            this.corpusService.onFileSelected.next(entity);
        });
    }

    openResourceCreationModal(): void {
        this.uploadService.openResourceCreationModal();
    }

    onFileChanged(e): void {
        this.filedata = e.target.files[0];
        console.log(e);

        if (this.validateFileType()) {
            this.user = this.accountManagementProvider.loggedUser;
            this.uploadFile(this.user, this.filedata);
        }
    }

    uploadFile(user, fileToUpload: File): void {


        const formData = new FormData();
        formData.append('file', fileToUpload);

        this.http
            .post<any>(this._urlFileUpload, formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
            .subscribe((res) => {
                this.corpusService.createRessource(CorpusRessourcesTypes.MEDIA, this.corpusId, +res['data'][0][0]['id'], {
                    title: 'oo',
                    language: 'fr',
                    description: 'descccc',
                    'source-author': 'Moi',
                    source: 'Inconnu'
                }).subscribe(entity => this.corpusService.onFileSelected.next(entity));
            });

    }

    validateFileType(): boolean {
        const fileName = this.filedata ? this.filedata.type : null;
        return fileName === 'image/jpg' ||
            fileName === 'image/jpeg' ||
            fileName === 'image/png' ||
            fileName === 'image/gif' ||
            fileName === 'video/mp4' ||
            fileName === 'video/mpeg' ||
            fileName === 'audio/mp3' ||
            fileName === 'audio/mpeg' ||
            fileName === 'application/pdf';
    }

    checkAccessLevel(action: string): boolean {
        return this.corpusService.checkAccessLevel(this.selected, action);
    }

    openMediaCreationModal(): void {
        this.mediaUploadService.openResourceCreationModal();
    }

    refreshCorpusResources(): void {
        this.corpusService.loadCorpusRessources().subscribe(() => {
        }).unsubscribe();
    }

    public get showButtonOptions(): boolean {
        return this.corpusService.settings && this.corpusService.settings.showButtonOptions;
    }

    titleCorpus(): string {
        return this.corpusService.corpusTerms ? this.corpusService.corpusTerms.title : null;
    }
}
