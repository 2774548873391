import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {MatSelectChange, MatSelect, MatCheckboxChange} from '@angular/material';
import {MessagingService} from '../../messaging.service';
import {Validators, FormControl} from '@angular/forms';
import {isNumber, isArray} from 'util';
import {DOMAIN_MESSAGING, EVENT_CORE_LABELS, EVENT_THREAD_COUNT} from '../../messaging.events';
import {Router, NavigationStart} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-messaging-index',
  templateUrl: './messaging-index.component.html',
  styleUrls: ['./messaging-index.component.scss']
})
export class MessagingIndexComponent implements OnInit, OnDestroy {
  
    @ViewChild('learnersSelect') learnersSelect: MatSelect;

    threadsListSize = 0;
    groups: any[];
    wgroups: any[];
    learners: any[];
    allLabel: string;
    
    /** search criterias object */
    query = {
        text: undefined,
        group : undefined,
        wgroup : undefined,
        archived: false,
        learners : []
    };

    /** text criteria validator */
    qtextFormControl = new FormControl('', [Validators.minLength(3)]);

    /**
     * 
     * @param messagingService 
     * @param translate 
     */
    constructor(
        public messagingService: MessagingService,
        private router: Router,
    ) {
        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_CORE_LABELS, (data) => {
            this.allLabel = data['generic.all'];
        });
        this.router.events.pipe(filter(event => event instanceof NavigationStart))
            .subscribe(
                (evt: NavigationStart) => {
                    if (evt) {

                    }
                }
            );
    }
    /**
     * 
     */
    ngOnInit(): void {
        this.messagingService.consumeEvent('groups-management', 'learnerList', (data) => this.learners = data);
        this.messagingService.consumeEvent('groups-management', 'groupsList', (data) => this.groups = data.filter(group => group.archived === false));
        this.messagingService.consumeEvent('groups-management', 'workgroupsList', (data) => this.wgroups = data.filter(wgroups => wgroups.archived === false));
        this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_COUNT, (data) => this.threadsListSize = data);
        this.messagingService.loadThreads();    
    }
    /**
     * 
     */
    ngOnDestroy(): void {
        this.messagingService.resetEventBus();
    }
    /**
     * Single selection change handler for all search criterias
     * @param $event 
     * @param criteria 
     */
    filterCriteriaSelected($event: MatSelectChange, criteria: string): void {
        switch (criteria) {
            case 'group':
                if (typeof $event.value !== 'undefined' && $event.value !== 'all') {
                    this.query.learners = ['all'];
                }
                break;
            case 'wgroup':
                if (typeof $event.value !== 'undefined' && $event.value !== 'all') {
                    this.query.learners = ['all'];
                }
                break;
            case 'learners':
                if ($event.value.find(O => O === 'all')) {
                    this.query.learners = ['all'];
                } else {
                    this.query.learners = $event.value;
                }
                break;
            default:
                break;
        }
    }
    /**
     * 
     */
    computeLearnersIds(): number[] {
        const _earnersIds = [];
        const isAllLearners = this.query.learners.length === 1 && this.query.learners[0] === 'all';
        const tmpResult = this._filterLearnersByGroupAndWGroups(
            this.learners, this.query.group, this.query.wgroup ? [this.query.wgroup] : []
        );
        if (isArray(tmpResult)) {
            tmpResult.forEach(l => {
                if (isAllLearners || this.query.learners.find(item => item === l.id)) {
                    _earnersIds.push(isNumber(l.id) ? l.id : parseInt(l.id, 10));
                }
            });
        }
        return _earnersIds;
    }
    /**
     * 
     */
    computeGroupId(): number {
        let result;
        if (isArray(this.wgroups)) {
            this.wgroups.forEach(wg => {
                if (this.query.wgroup === wg.workgroupname) {
                    result = wg.id;
                }
            });
        }
        return result;
    }
    /**
     * 
     * @param $event 
     */
    includeArchivedFilterChange($event: MatCheckboxChange): void {
        this.query.archived = $event.checked;
    }
    /**
     * call search service
     */
    doSearch(): void {
        this.messagingService.loadThreads({
            members: this.computeLearnersIds(),
            group: this.computeGroupId(),
            text: this.query.text,
            archived : this.query.archived
        });
    }
    /**
     * 
     */
    private _filterLearnersByGroupAndWGroups(learners: any[], group: any, wgroup: any[]): any[] {
        if (Array.isArray(learners)) {
            let filtredList = learners;
            if (typeof group !== 'undefined') {
                filtredList = filtredList.filter(item => group === this.allLabel || item.groups.includes(group));
            }
            if (typeof wgroup !== 'undefined' && wgroup.length > 0) {
                filtredList = filtredList.filter(item => (wgroup && wgroup.length === 1 && wgroup[0] === this.allLabel) 
                        || item.workgroups.filter(value => wgroup.includes(value)).length > 0 );
            }
            return filtredList;
        } else {
            return [];
        }
    }
}
