import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {HttpClient} from '@angular/common/http';
const { detect } = require('detect-browser');

@Component({
    selector: 'app-modal-page',
    templateUrl: './modal-page.component.html',
    styleUrls: ['./modal-page.component.scss']
})
export class ModalPageComponent implements OnInit, OnDestroy {

    public alias: string;
    private unsubscribeInTakeUntil = new Subject();
    public page: DataEntity;
    public downloading = false;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
        public dialogRef: MatDialogRef<ModalPageComponent>,
        private translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private http: HttpClient,
        private pageService: BasicPageService
    ) {
        if (data && data.alias) {
            this.alias = data.alias;
        }
    }

    ngOnInit() {
        if (this.alias) {
            this.getPage();

            this.translate.onLangChange.takeUntil(this.unsubscribeInTakeUntil).subscribe(() => {
                this.getPage();
            });
        }
    }

    getPage(): void {
        this.pageService.loadPage(this.alias).subscribe((page) => {
            this.page = page;
            this.page.attributes['body'] = this.domSanitizer.bypassSecurityTrustHtml(this.page.get('body'));
            this.page.get('pages').forEach((subpage) => {
                subpage.body = this.domSanitizer.bypassSecurityTrustHtml(subpage.body);
            });
        });
    }

    downloadDocument(): void {
        this.downloading = true;

        this.http
            .get(this.page.get('document'), {responseType: 'blob'})
            .subscribe((data: Blob) => {
                const browser = detect();
                const link = document.createElement("a");
                let pdfData;

                link.target = '_blank';
                link.download = this.page.get('document').substr(this.page.get('document').lastIndexOf('/') + 1);
                link.setAttribute('visibility', 'hidden');

                if (['ie', 'edge'].indexOf(browser.name) === -1) {
                    pdfData = window.URL.createObjectURL(data);
                    link.href = pdfData;
                } else {
                    link.href = this.page.get('document');
                }

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                if (pdfData) {
                    window.URL.revokeObjectURL(pdfData);
                }

                this.downloading = false;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}
