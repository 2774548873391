import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {LicensingService} from '@modules/licensing/core/licensing.service';
import {LicensingContentComponent} from '@modules/licensing/core/licensing-content/licensing-content.component';
import {RouterModule, Routes} from '@angular/router';
import {LicensingDisplayComponent} from './licensing-display/licensing-display.component';
import {LicensingMethodAddComponent} from './licensing-method-add/licensing-method-add.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {LicensingRestrictedComponent} from './licensing-restricted/licensing-restricted.component';

const routes: Routes = [
    {
        path: 'licensing',
        component: LicensingDisplayComponent,
    },
    {
        path: 'licensing-restricted',
        component: LicensingRestrictedComponent,
    }];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule
    ],
    declarations: [
        LicensingContentComponent,
        LicensingDisplayComponent,
        LicensingMethodAddComponent,
        LicensingRestrictedComponent,
    ],
    entryComponents: [
        LicensingContentComponent,
        LicensingMethodAddComponent
    ]
})
export class LicensingModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: LicensingModule,
            providers: [
                LicensingService
            ]
        };
    }
    constructor(private communicationCenter: CommunicationCenterService) {

        this.communicationCenter
            .getRoom('licensing')
            .next('licenses_content', LicensingContentComponent);
        this.communicationCenter
            .getRoom('licensing')
            .next('licenses_add', LicensingMethodAddComponent);
    }
}
