import {Component, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-profile-delete-confirm-modal',
    templateUrl: './profile-delete-confirm-modal.component.html'
})
export class ProfileDeleteConfirmModalComponent implements OnDestroy{
    public isLoading = false;
    private unsubscribeTakeUntil = new Subject();

    constructor(
        public dialogRef: MatDialogRef<ProfileDeleteConfirmModalComponent>,
        public profileService: ProfileService
    ) {
    }

    confirm(): void {
        this.isLoading = true;
        this.profileService
            .deleteCurrentProfile()
            .takeUntil(this.unsubscribeTakeUntil)
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }
}
