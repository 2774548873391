import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRoute, NavigationEnd, Router, RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule, MatExpansionModule, MatIconModule} from '@angular/material';
import {LangChangeEvent, TranslateModule, TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';

import {OctopusConnectModule} from 'octopus-connect';
import {IsUserLogged} from './is-user-logged.class';
import {ServiceInstantiator} from './service-instantiator.class';
import {DashboardModule} from '@modules/dashboard';
import {AccountManagementModule} from '@modules/account-management';
import {GroupsManagementModule} from '@modules/groups-management';
import {AuthenticationModule} from '@modules/authentication/core/authentication.module';
import {TralaTranslationLoaderService} from './trala-translation-loader.service';
import {HomepageComponent} from './homepage/homepage.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {SharedTranslateModule} from './shared/shared-translate.module';
import {BrowserTestModule} from '@modules/browser-test';
import {CommunicationCenterModule, CommunicationCenterService} from '@modules/communication-center';
import {ProjectsManagementModule} from '@modules/projects-management';

import {cookiesConsent, defaultApiURL, defaultNodeURL, defaultRoute} from './settings';
import {NavigationModule} from './navigation/navigation.module';
import {DynamicNavigationService} from './navigation/dynamic-navigation.service';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import {NotificationModule} from '@modules/notification';
import {CorpusModule} from '@modules/corpus';
import {ResearchSheetModule} from '@modules/research-sheet';
import {SvgIconList} from './themes/erasme/svgIconList';
import {ActivitiesModule, LessonsModule, ThemesModule} from '@modules/activities';
import {AssignationModule} from '@modules/assignation';
import {IdeasWallModule} from '@modules/ideas-wall';
import {LicensingModule} from '@modules/licensing';
import {GamificationModule} from '@modules/gamification';
import {GraphAssignationModule} from '@modules/graph-assignation/core/graph-assignation.module';
import {ConfigurationModule} from '@modules/configuration';
import {FullPageComponent} from 'fuse-core/components/basic-page/full-page/full-page.component';
import {MessagingModule} from '@modules/messaging';
import {IsUserAnonymous} from './is-user-anonymous.class';
import {AgendaModule} from '@modules/agenda';
import {DataVisualizationModule} from '@modules/data-visualization';
import {TimelineModule} from '@modules/timeline';
import {Angulartics2Module} from 'angulartics2';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = cookiesConsent;

const classPath = '';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: defaultRoute,
        pathMatch: 'full',
    },
    {
        path: 'homepage',
        component: HomepageComponent
    },
    {
        path: 'page/:alias',
        component: FullPageComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HomepageComponent,
        MaintenanceComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        // import Angulartics2GoogleGlobalSiteTag in root ngModule
        Angulartics2Module.forRoot(),

        SharedTranslateModule,
        TranslateModule.forRoot(),

        // Material moment date module
        AuthenticationModule.forRoot(),
        NavigationModule.forRoot(),
        MatMomentDateModule,

        // Mat expansion panel
        MatExpansionModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,

        GroupsManagementModule.forRoot(),
        DashboardModule.forRoot(),
        AccountManagementModule.forRoot(),
        ProjectsManagementModule.forRoot(),
        NotificationModule.forRoot(),
        CorpusModule.forRoot(),
        ActivitiesModule.forRoot(),
        AssignationModule.forRoot(),
        LessonsModule.forRoot(),
        ThemesModule.forRoot(),
        ResearchSheetModule.forRoot(),
        IdeasWallModule.forRoot(),
        LicensingModule.forRoot(),
        GamificationModule.forRoot(),
        ConfigurationModule.forRoot(),
        MessagingModule.forRoot(),
        AgendaModule.forRoot(),
        TimelineModule.forRoot(),
        BrowserTestModule.forRoot({
            desktopSize: {
                minWidth: 1024,
                minHeight: 768
            },
            browsersBlackList: [
                {
                    name: 'ie',
                    minVersion: '11'
                },
                {
                    name: 'chrome',
                    minVersion: '72'
                },
                {
                    name: 'firefox',
                    minVersion: '65'
                },
                {
                    name: 'edge',
                    minVersion: '16'
                },
                {
                    name: 'safari',
                    minVersion: '10'
                }
            ]
        }),
        CommunicationCenterModule.forRoot(),
        GraphAssignationModule.forRoot(),
        DataVisualizationModule.forRoot(),
        OctopusConnectModule.forRoot({
            defaultInterface: 'localstorage',
            maxRetry: 5,
            retryTimeout: 50000,
            liveRefreshService: 'refresh',
            configuration: {
                localstorage: {
                    prefix: 'test'
                },
                http: {
                    apiUrl: defaultApiURL,
                    useApiExtension: true,
                    headers: {
                        'Content-type': 'application/json'
                    }
                },
                nodejs: {
                    socketUrl: defaultNodeURL
                }
            },
            map: {
                'users': 'http',
                'learners': 'http',
                'trainers': 'http',
                'licenses': 'http',
                'migrations': {
                    type: 'http',
                    useLanguage: true
                },
                'licenses-import': 'http',
                'groups': 'http',
                'institutions': 'http',
                'user-registration': 'http',
                'reset-password': 'http',
                'userfiles': 'http',
                'projects': 'http',
                'contact-message': 'http',
                'notification': {
                    type: 'http',
                    refreshEnabled: true
                },
                'corpus': 'http',
                'lesson': 'http',
                'activity': 'http',
                'media': 'http',
                'qcm': 'http',
                'assignations': {
                    type: 'http',
                    refreshEnabled: true
                },
                'assignations-group': 'http',
                'event-assignation': 'http',
                'granule': {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference'
                    },
                    useLanguage: true
                },
                'difficulty': 'http',
                'granule-activity': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity'
                    }
                },
                'granule-lesson': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-sequence': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-form': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    }
                },
                'granule-format': {
                    type: 'http',
                    cached: true
                },
                'regions': {
                    type: 'http',
                    cached: true
                },
                'tralabar_links': {
                    type: 'http',
                    cached: true
                },
                'educational_level': {
                    type: 'http',
                    cached: true,
                    useLanguage: true
                },
                'tags': {
                    type: 'http',
                    refreshEnabled: true
                },
                'bookmarks': 'http',
                'bookmarks-theme': 'http',
                'consulted': 'http',
                'evaluation': 'http',
                'corpus-text-ressource': 'http',
                'corpus-url-ressource': 'http',
                'metadatas': 'http',
                'corpus-set': {
                    type: 'http',
                    embeddings: {
                        'corpus-list': 'corpus'
                    }
                },
                'research-sheets': {
                    type: 'http',
                    refreshEnabled: true
                },
                'sections': {
                    type: 'http',
                    refreshEnabled: true
                },
                'draft-sections': {
                    type: 'http',
                    refreshEnabled: true
                },
                'section-definitions-set': {
                    type: 'http',
                    embeddings: {
                        sections: 'section-definitions'
                    }
                },
                'section-definitions': {
                    type: 'http',
                    embeddings: {
                        readingGrids: 'reading-grid'
                    }
                },
                'refresh': 'nodejs',
                'comments': 'http',
                'user-save': {
                    type: 'http',
                    embeddings: {
                        userActivity: 'user-activity'
                    }
                },
                'user-activity': 'http',
                'answer': 'http',
                'qcm-save': 'http',
                'ord-save': 'http',
                'app-save': 'http',
                'rb-save': 'http',
                'genericsave': 'http',
                'reading-grids': 'http',
                'draft-questions': 'http',
                'logical-links-set': 'http',
                'logical-links': 'http',
                'ideas-wall': {
                    type: 'http',
                    refreshEnabled: true
                },
                'wall-idea': {
                    type: 'http',
                    refreshEnabled: true
                },
                'wall-category': {
                    type: 'http',
                    refreshEnabled: true
                },
                'corpus_search/Granule': {
                    type: 'http',
                    refreshEnabled: true,
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'reference'
                    },
                    useLanguage: true
                },
                'basic_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas',
                        reference: 'activity'
                    },
                    useLanguage: true
                },
                'assignation_search': {
                    type: 'http',
                },
                'lesson_granule_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'themes': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'theme_search': {
                    type: 'http',
                    embeddings: {
                        format: 'granule-format',
                        metadatas: 'metadatas'
                    },
                    useLanguage: true
                },
                'variables/instance': {
                    type: 'http',
                    useLanguage: true
                },
                'assignation_state': 'http',
                'groups_search': {
                    type: 'http'
                },
                'variables/postalCodes': {
                    type: 'http'
                },
                'chapters': {
                    type: 'http',
                    useLanguage: true
                },
                'skills': {
                    type: 'http',
                    useLanguage: true
                },
                'concepts': {
                    type: 'http',
                    useLanguage: true
                },
                'authenticated': {
                    type: 'nodejs',
                },
                'join-wall': {
                    type: 'nodejs',
                },
                'pages': {
                    type: 'http',
                    useLanguage: true
                },
                'user-points': 'http',
                'badges-type': 'http',
                'badges': {
                    type: 'http',
                    useLanguage: true
                },
                'levels': {
                    type: 'http',
                    useLanguage: true
                },
                'users-import': 'http',
                'threads': {
                    type: 'http'
                },
                'messages': {
                    type: 'http',
                    refreshEnabled: true
                },
                'error-reporting': {
                    type: 'http',
                    useLanguage: true
                },
                'events': {
                    type: 'http',
                    useLanguage: true
                },
                'analytics-listing': 'http',
                'timeline': {
                    type: 'http',
                },
                'marker_type': {
                    type: 'http',
                    cached: true
                },
                'video_marker': 'http',
                'summary': 'http',
                'user-dashboard': 'http',
                'progress': 'http',
                'metrics': 'http',

            }
        }),
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    providers: [
        IsUserLogged,
        IsUserAnonymous,
        ServiceInstantiator,
        TralaTranslationLoaderService,
        SvgIconList,
        CommunicationCenterService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslateService
    ) {
        dynamicNavigation.registerModuleMenu('level0', {
                'id': 'homepage',
                'title': 'Homepage',
                'translate': 'navigation.homepage',
                'type': 'item',
                'icon': 'homepage',
                'url': '/homepage'
            }
        );

        dynamicNavigation.registerModuleMenu('level0', {
            'id': 'help',
            'title': 'Help',
            'translate': 'navigation.general_help',
            'type': 'item',
            'icon': 'help',
            'url': '/page/aide-aux-ressources'
        });

        dynamicNavigation.registerModuleMenu('level0', {
            'id': 'legalnotice',
            'title': 'Mentions légales',
            'translate': 'navigation.legalnotice',
            'type': 'item',
            'icon': 'new_releases',
            'url': '/page/conditions-generales'
        });

        dynamicNavigation.registerModuleMenu('level0', {
            'id': 'needhelp',
            'title': 'Need help',
            'translate': 'navigation.needhelp',
            'type': 'link',
            'icon': 'help',
            'url': '/'
        });

        dynamicNavigation.registerModuleMenu('level0', {
            'id': 'privacy',
            'title': 'Privacy',
            'translate': 'navigation.privacy',
            'type': 'link',
            'icon': 'shield',
            'url': '/page/privacy'
        });

        router.events.filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })
            .filter(route => route.outlet === 'primary')
            .mergeMap(route => {
                if (route.data) {
                    return route.data;
                } else {
                    return null;
                }
            })
            .subscribe(evt => {
                let menuId: string;

                if (evt && evt['menu']) {
                    menuId = evt['menu'];
                } else {
                    menuId = 'level0';
                }

                dynamicNavigation.generateMenu(menuId);
                dynamicNavigation.switchClassPath(menuId);

            });

        // waiting for translation to be loaded to replace link placeholder
        this.translate.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
            if (event.lang === this.translate.currentLang) {
                this.updateHelpLink();
            }
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateHelpLink();
        });
    }

    /**
     * Replace help link with appropriate url
     */
    private updateHelpLink(): void {
        this.dynamicNavigation.clearMenuItem('level0', 'needhelp');
        this.translate.get('navigation.needhelp_url').subscribe((url: string) => {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'needhelp',
                'title': 'Need help',
                'translate': 'navigation.needhelp',
                'type': 'link',
                'icon': 'help',
                'url': url
            });
        });
    }
}
