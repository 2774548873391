import {Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {fuseAnimations} from 'fuse-core/animations';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {AuthenticationService} from '@modules/authentication';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';


@Component({
    selector: 'fuse-corpus-details-sidenav',
    templateUrl: './corpus-details.component.html',
    styleUrls: ['./corpus-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CorpusDetailsSidenavComponent implements OnInit, OnDestroy {
    public isShareableCommunity: boolean;
    public selected: CorpusRessource;
    public sharedCommunity: boolean;
    public videoShortImage: any;
    public videoThumbnailTime = 5;

    private accessMatrix: { [key: string]: { [key: string]: string[] } } = {};
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        public corpusService: CorpusService,
        private authService: AuthenticationService,
        private dialog: MatDialog,
        private http: HttpClient,
        private mediaUploadService: MediaUploadService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit(): any {
        this.accessMatrix = this.corpusService.settings.accessMatrix;
        this.corpusService.onFileSelected
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(selected => {
                this.selected = selected;

                if (!!selected) {
                    this.isShareableCommunity = !!this.selected.ressourceEntity && this.corpusService.isUserCorpus && this.corpusService.settings.shareableResourceToCommunity;
                    this.sharedCommunity = !!this.selected.ressourceEntity && !!this.selected.ressourceEntity.get('shared');

                    if (selected.videoShortImage) {
                        const url = selected.videoShortImage;
                        if (url.includes('vimeo')) {
                            const vimeoApiUrl = url;
                            this.http
                                .get(vimeoApiUrl)
                                .subscribe(res => {
                                    this.videoShortImage = res['thumbnail_url'];
                                });
                        } else {
                            if (selected.videoShortImage !== 'noThumb') {
                                this.videoShortImage = selected.videoShortImage;
                            }
                        }
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.corpusService.onFileSelected.next(null);
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public get haveCustomDate() {
        const customDate = this.selected.metadatasEntity.get('scolomDate');
        return customDate !== null && customDate.length;
    }

    getDataSelected(field): any {
        if (this.selected && this.selected.metadatasEntity) {
            if (field === 'source-author') {
                if (this.selected.metadatasEntity.attributes[field] !== null) {
                    return this.selected.metadatasEntity.attributes[field];
                } else {
                    return false;
                }
            }
            if (this.selected.metadatasEntity.attributes &&
                this.selected.metadatasEntity.attributes[field] &&
                this.selected.metadatasEntity.attributes[field].length > 0) {
                return this.selected.metadatasEntity.attributes[field];
            } else {
                if (field === 'source' || field === 'source-author') {
                    return '';
                }
            }
        }
        return false;
    }

    displayField(name: string): boolean {
        return this.corpusService.settings.detailsFields.indexOf(name) > -1;
    }

    public share(evt): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            bodyDialog: 'corpus.confirm_share_community_resource',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.cancel'
        };

        if (!evt.checked) {
            dialogConfig.data.bodyDialog = 'corpus.confirm_not_share_community_resource';
        }

        for (const term in dialogConfig.data) {
            this.translate.get(dialogConfig.data[term]).subscribe((translation: string) => dialogConfig.data[term] = translation);
        }

        const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.corpusService.editGranule(this.selected.ressourceEntity, {'shared': evt.checked ? '1' : '0'});
                this.sharedCommunity = evt.checked;
            } else {
                evt.source.checked = !evt.checked;
                this.sharedCommunity = !evt.checked;
            }
        });
    }

    get chapters(): any {
        if (this.selected) {
            const chapters = this.selected.metadatasEntity.get('chapters') ? this.selected.metadatasEntity.get('chapters') : [];
            return chapters.map((chapter) => {
                if (chapter.attributes) {
                    return {
                        id: chapter.id,
                        label: chapter.get('label'),
                    };
                }

                return chapter;
            });
        }

        return [];
    }

    get concepts(): any {
        if (this.selected) {
            const concepts = this.selected.metadatasEntity.get('concepts') ? this.selected.metadatasEntity.get('concepts') : [];
            return concepts.map((concept) => {
                if (concept.attributes) {
                    return {
                        id: concept.id,
                        label: concept.get('label'),
                    };
                }

                return concept;
            });
        }

        return [];
    }

}
