import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '@modules/projects-management/core/projects.service';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Project } from '@modules/projects-management/core/project.model';
import { ProjectsFormComponent } from '@modules/projects-management/core/projects-form/projects-form.component';
import { FormGroup } from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '@modules/authentication';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-projects-list',
    templateUrl: './projects-list.component.html',
    styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {
    displayedColumns = ['checkbox', 'name', 'group', 'workgroups', 'mentors', 'buttons'];
    dataSource: ProjectsDataSource;
    user: any;
    checkboxes: {};
    dialogRef: any;
    defaultArchive = 'false';

    archived: boolean;
    projects: Project[];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    selectAll: boolean;

    constructor(
        public projectsService: ProjectsService,
        public authService: AuthenticationService,
        public dialog: MatDialog,
        private router: Router,
        private translate: TranslateService
    ) {
        this.dataSource = new ProjectsDataSource(this.projectsService);
    }

    ngOnInit(): any {
        this.projectsService.filterArchived(false);
        this.dataSource.data.subscribe((projects: Project[]) => {
            this.reset(projects);
        });
    }


    reset(entities): void {
        this.projects = [];
        this.projects.push(...entities);
        this.checkboxes = {};
        if (entities) {
            for (const entity of entities) {
                this.checkboxes[entity.id] = false;
            }
            this.selectAll = false;
        }
    }

    filterSelectedEntities(): string[] | number[] {
        const selectedEntities = [];
        for (const id in this.checkboxes) {
            if (this.checkboxes[id]) {
                selectedEntities.push(...this.projects.filter((entity) => entity.id.toString() === id));
                this.checkboxes[id] = false;
            }
        }

        return selectedEntities;
    }

    archiveListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();
        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.archive',
                bodyDialog: 'groups-management.sure_archive_project_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_archive_project';
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    for (const project of selectedEntities) {
                        project['archived'] = true;
                        this.projectsService.saveProject(project);
                    }                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });

        }
    }
    dearchiveListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();

        if (selectedEntities.length) {

            const data = {
                titleDialog: 'generic.unarchive',
                bodyDialog: 'groups-management.sure_dearchive_project_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_dearchive_project';
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {

                    for (const project of selectedEntities) {
                        project['archived'] = false;
                        this.projectsService.saveProject(project);
                    }
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    updateCheck(event): void {
        this.selectAll = event.checked;
        for (const entity of this.projects) {
            this.checkboxes[entity.id] = event.checked;
        }
    }

    applyFilters(event): void {
        this.archived = event.value;
        this.projectsService.filterArchived(event.value);
    }

    onSelectedChange(id): void { }

    getLink(project: Project): string {
        return '/projects/' + project.id;
    }

    goToProject(project: Project): void {
        this.projectsService.selectProject(project);
        this.router.navigate([this.getLink(project)]);
    }

    editProject(project: Project): void {
        this.dialogRef = this.dialog.open(ProjectsFormComponent, {
            panelClass: 'toto-project-form-dialog',
            data      : {
                action: 'edit',
                project: project
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                this.projectsService.saveProject(response.getRawValue());
            });
    }

    archiveProject(project: Project): void {
        const fields = {
            titleDialog: 'generic.archive',
            bodyDialog: 'projects-management.sure_archive_project',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        };
        for (const myField in fields){
            if (fields[myField] !== ''){
                this.translate.get(fields[myField]).subscribe((translation: string) => fields[myField] = translation);
            }
        }
        this.dialogRef = this.dialog.open(FuseConfirmDialogComponent, {data : fields});

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                project.archived = true;
                this.projectsService.saveProject(project);
            });
    }

    deArchiveProject(project: Project): void {
        const fields = {
            titleDialog: 'generic.archive',
            bodyDialog: 'projects-management.sure_dearchive_project',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        };
        for (const myField in fields){
            if (fields[myField] !== ''){
                this.translate.get(fields[myField]).subscribe((translation: string) => fields[myField] = translation);
            }
        }
        this.dialogRef = this.dialog.open(FuseConfirmDialogComponent, {data : fields});

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                project.archived = false;
                this.projectsService.saveProject(project);
            });
    }

    deleteProject(project: Project): void {
        const fields = {
            titleDialog: 'generic.delete',
            bodyDialog: 'projects-management.sure_remove_project',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        };
        for (const myField in fields){
            if (fields[myField] !== ''){
                this.translate.get(fields[myField]).subscribe((translation: string) => fields[myField] = translation);
            }
        }
        this.dialogRef = this.dialog.open(FuseConfirmDialogComponent, {data : fields});

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                this.projectsService.deleteProject(project);
            });
    }

}

export class ProjectsDataSource extends DataSource<any> {
    data: BehaviorSubject<Project[]>;

    constructor(
        private projectsService: ProjectsService
    ) {
        super();
        this.data = new BehaviorSubject<Project[]>(this.projectsService.projects);

        this.projectsService.onProjectsChanged.subscribe((data: Project[]) => {
            this.data.next(data);
        });
    }

    connect(): Observable<any> {
        return this.projectsService.onProjectsChanged;
    }

    disconnect(): any {}
}
