import {Component, OnDestroy, OnInit} from '@angular/core';
import {TimelineService} from '@modules/timeline/core/timeline.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnDestroy {

    constructor(public timelineService: TimelineService,
                private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.getDataByRouteId();
    }

    /**
     * Get the current id and launch loading data
     */
    public getDataByRouteId(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                const id = params['id'];
                this.timelineService.setTimelineDataById(id);
            }
        });
    }

    ngOnDestroy(): void {
    }
}
