import {ChangeDetectorRef, EventEmitter, Output} from '@angular/core';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {AuthenticationService} from '@modules/authentication';
import {Subject} from 'rxjs';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-chapter-selector',
    templateUrl: './chapter-selector.component.html',
    styleUrls: ['./chapter-selector.component.scss']
})
export class ChapterSelectorComponent implements OnInit, OnDestroy {

    @Input() availableMethods: any[];
    @Input() disabled: boolean;
    @Output() onValidation = new EventEmitter<{ method: any, chapters: any[] }>();

    public select: FormControl = new FormControl();
    public availableChapters = [];
    public childrenChapters = [];
    public selectedChapters = [];
    public displayedColumns = ['select', 'title'];
    public chaptersLoading = false;

    private unsubscribeInTakeUntil = new Subject();

    constructor(
        public authService: AuthenticationService,
        private changeDetectorRef: ChangeDetectorRef,
        private corpusService: CorpusService) {
    }

    ngOnInit(): void {
        this.onValidation.subscribe(() => {
            this.reset();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public displayField(name: string): boolean {
        const role = this.authService.accessLevel;
        let assetField = this.corpusService.settings.assetFields[role];
        if (assetField === undefined) {
            assetField = this.corpusService.settings.assetFields['default'];
        }
        return assetField.indexOf(name) > -1;
    }

    public displayRequiredField(name: string): boolean {
        return this.corpusService.settings.assetRequiredFields.indexOf(name) > -1;
    }

    public onMethodSelected(event: any): void {
        if (event && event.value !== null) {
            this.loadChapters();
        }
    }

    public masterToggle(): void {
        this.selectedChapters = this.isAllSelected() ? [] : this.childrenChapters.slice();
    }

    public isAllSelected(): boolean {
        return this.selectedChapters.length === this.childrenChapters.length;
    }

    public toggleSelect(chapter: any): void {
        if (this.isSelected(chapter)) {
            this.selectedChapters = this.selectedChapters.filter(selectedChapter => selectedChapter !== chapter);
        } else {
            this.selectedChapters.push(chapter);
        }
    }

    public isSelected(chapter: any): boolean {
        return this.selectedChapters.includes(chapter);

    }

    private reset(): void {
        this.select.reset();
        this.availableChapters = [];
        this.selectedChapters = [];
        this.availableMethods = this.availableMethods.slice();
        this.changeDetectorRef.detectChanges();
    }

    private loadChapters(): void {
        this.chaptersLoading = true;
        this.availableChapters = [];
        this.selectedChapters = [];
        this.corpusService.getChapters(this.select.value.id).subscribe(data => {
            this.chaptersLoading = false;
            this.availableChapters = data.entities;
            this.childrenChapters.push(...this.availableChapters.filter(chapter => chapter.attributes.parent.toString() !== this.select.value.id.toString()).slice());
            this.changeDetectorRef.detectChanges();
        });
    }

    public validateSelectedChapters(): void {
        this.onValidation.emit({
            method: this.select.value,
            chapters: this.selectedChapters.slice()
        });
    }

    public isChildrenChapter(chapter: any): boolean {
        return this.childrenChapters.some(childrenChapter => childrenChapter === chapter);
    }
}
