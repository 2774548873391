export const cards = {
    cardDisplay: {
        showButtonThemeLesson: false,
        showButtonBookmarks: true,
        isTextButton: false,
    },
    cardFields: {
        manager: ['title', 'menu', 'lessonStats', 'keywords', 'metadatas', 'duplication'],
        default: ['title', 'menu', 'lessonStats', 'keywords', 'metadatas', 'assigned']
    },
    cardDefaultHeader: '/assets/lamorim/images/backgrounds/header.png',
    cardPlayOpenMenu: false,
};
