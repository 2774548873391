import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {FlagService} from '../../../app/shared/flag.service';
import {brand} from '../../../app/settings';
import {Router} from '@angular/router';
import {IContentDetails, ITypeOfCard} from 'fuse-core/components/card/models/card.models';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CardComponent implements OnInit {

    @Input('dataCard') dataCard: any;

    public headerTitleIcon: string;
    public thumb: string;
    public headerTitle: string;
    public lessons: string;
    public brand: string = brand;
    public isShareableCommunity: boolean;
    public isShareableModel: boolean;
    public sharedCommunity: boolean;
    public sharedModel: boolean;

    private settings: { [key: string]: any };
    private _bookmarksParams: { endPoint: string, type: string } = {endPoint: 'bookmarks', type: 'node'};
    /***
     * params for calling bookmarks
     */
    get bookmarksParams(): { endPoint: string, type: string } {
        return this._bookmarksParams;
    }

    get fileType(): any {
        return FileType;
    }

    private _typeOfCard: ITypeOfCard = {
        isTheme: false,
        isLesson: false,
        isSequence: false,
        isVideo: false,
        isVideoUrl: false,
        isImage: false,
        isAudio: false,
        isDocument: false,
        isForm: false
    };
    /***
     * type of element in card if is inside = true
     */
    get typeOfCard(): ITypeOfCard {
        return this._typeOfCard;
    }

    private _contentDetails: IContentDetails = {
        document: '',
        reference: [],
        skills: [],
        educationalLevel: '',
        difficulty: '',
        image: {id: null, uri: ''},
        theme: {id: null, label: ''},
        files: [],
    };
    /***
     * contentDetails infos
     */
    get contentDetails(): IContentDetails {
        return this._contentDetails;
    }

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        public cardsService: CardsService,
        public flagService: FlagService,
        private http: HttpClient,
        private dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService,
        private translate: TranslateService
    ) {
        this.settings = this.cardsService.settings;
    }

    ngOnInit(): void {
        this.setTypeOfCard();
        this.initValueByLabel();
        this.setContentDetailsInfo();
        this.setBookmarksInfos();
        // add bookmarks-theme because service use api name : bookmark-theme => patch to make it exists
        this.dataCard.resource.set('bookmarks-theme', this.dataCard.resource.get('bookmarks'));
        this.headerTitle = this.dataCard.resource.get('metadatas').title;

        this.isShareableCommunity = this.dataCard.isShareableCommunity;
        this.isShareableModel = this.dataCard.isShareableModel;
        if (this.isShareableCommunity) {
            this.sharedCommunity = !!this.dataCard.resource.get('shared');
        }

        if (this.isShareableModel) {
            this.sharedModel = !!this.dataCard.resource.get('model');
        }
    }

    public share(evt, type): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            bodyDialog: type === 'shared' ? 'activities.confirm_share_community_lesson' : 'activities.confirm_share_model_lesson',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.cancel'
        };

        if (!evt.checked) {
            dialogConfig.data.bodyDialog = type === 'shared' ? 'activities.confirm_not_share_community_lesson' : 'activities.confirm_not_share_model_lesson';
        }

        for (const term in dialogConfig.data) {
            this.translate.get(dialogConfig.data[term]).subscribe((translation: string) => dialogConfig.data[term] = translation);
        }

        const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataCard.share(this.dataCard.resource, {[type]: evt.checked ? '1' : '0'});
                if (type === 'shared') {
                    this.sharedCommunity = evt.checked;
                }
                if (type === 'model') {
                    this.sharedModel = evt.checked;
                }
            } else {
                evt.source.checked = !evt.checked;
                if (type === 'shared') {
                    this.sharedCommunity = !evt.checked;
                }
                if (type === 'model') {
                    this.sharedModel = !evt.checked;
                }
            }
        });
    }

    private setBookmarksInfos(): void {
        if (this.typeOfCard.isTheme) {
            this._bookmarksParams.endPoint = 'bookmarks-theme';
            this._bookmarksParams.type = 'taxonomy_term';
        }
    }

    private setContentDetailsInfo(): void {
        try {
            this._contentDetails.difficulty = this.dataCard.resource.get('metadatas').difficulty.label;
            this._contentDetails.educationalLevel = this.dataCard.resource.get('metadatas').educationalLevel.label;
            this._contentDetails.skills = this.dataCard.resource.get('metadatas').skills;
            this._contentDetails.reference = this.dataCard.resource.get('reference');
            this._contentDetails.document = this.dataCard.resource.get('document');
            this._contentDetails.image = this.dataCard.resource.get('image');
            this._contentDetails.theme = this.dataCard.resource.get('theme');

            if (this.getLabelToUse() === 'lesson') {
                const file = this.dataCard.resource.get('file');
                if (file) {
                    this._contentDetails.document = file.uri;
                }
                if (this.dataCard.resource.get('metadatas') && this.dataCard.resource.get('metadatas').thumbnail) {
                    // image set by user in back
                    this._contentDetails.image = this.dataCard.resource.get('metadatas').thumbnail;
                } else {
                    // image by default
                    this._contentDetails.image = {id: 0, uri: this.defaultHeader};
                }
            }

            if (this.dataCard.resource.get('metadatas') && this.dataCard.resource.get('metadatas').files !== null
                && this.dataCard.resource.get('metadatas').files !== undefined && this.dataCard.resource.get('metadatas').files.length > 0) {
                this._contentDetails.files = this.dataCard.resource.get('metadatas').files;
            }

        } catch (ex) {
            console.error('cardComponent error-158');
        }
    }

    private setTypeOfCard(): void {
        if (this.dataCard.resource.type === 'theme_search') {
            this._typeOfCard.isTheme = true;
        }
        this._typeOfCard.isLesson = this.isFormat('lesson');
        this._typeOfCard.isSequence = this.isFormat('sequence');
        this._typeOfCard.isVideo = this.isFormat('video');
    }

    private initValueByLabel(): void {
        switch (this.getLabelToUse()) {
            case 'video':
                this.headerTitleIcon = 'movies';
                this.thumb = this.dataCard.resource.get('reference').uri;
                break;
            case 'videoUrl':
                this.headerTitleIcon = 'movies';
                if (this.dataCard.originalResource.videoShortImage.includes('vimeo')) {
                    const vimeoApiUrl = this.dataCard.originalResource.videoShortImage;
                    this.http
                        .get(vimeoApiUrl)
                        .subscribe(res => {
                            this.thumb = res['thumbnail_url'];
                        });
                } else {
                    if (this.dataCard.originalResource.videoShortImage !== 'noThumb') {
                        this.thumb = this.dataCard.originalResource.videoShortImage;
                    }
                }
                break;
            case 'image':
                this.headerTitleIcon = 'photo';
                this.thumb = this.dataCard.resource.get('reference').uri;
                break;
            case 'audio':
                this.headerTitleIcon = 'headset';
                this.thumb = '/assets/' + brand + '/icons/' + this.dataCard.resource.get('format').label + '.svg';
                break;
            case 'parcours':
                this.headerTitleIcon = 'lessons';
                this.thumb = 'no format';
                break;
            case 'themes':
                this.headerTitleIcon = 'book_open';
                this.thumb = 'no format';
                break;
            case 'lesson':
                this.headerTitleIcon = 'lessons';
                this.thumb = 'no format';
                break;
            case 'document':
                this.headerTitleIcon = 'file_text';
                this.thumb = '/assets/' + brand + '/icons/' + this.dataCard.resource.get('format').label + '.svg';
                break;
            case 'url':
                this.headerTitleIcon = 'link';
                this.thumb = '/assets/' + brand + '/icons/' + this.dataCard.resource.get('format').label + '.svg';
                break;
            default :
                this.headerTitleIcon = 'layers';
                this.thumb = 'no format' + this.dataCard.resource.get('format').label;
        }
    }

    private getLabelToUse(): string {
        let label = '';
        // for theme no format so I overide to use the type as value for switch
        if (this.typeOfCard.isTheme) {
            label = 'themes';
        } else {
            label = this.dataCard.resource.get('format').label;
        }
        return label;
    }

    /**refacto to do all of this method are to set in the init only once**/
    public lessonCount(): number {
        return this.dataCard.resource.get('infos').lessons;
    }

    public activityCount(): number {
        return this.dataCard.resource.get('infos').activities;
    }

    public otherCount(): number {
        return this.dataCard.resource.get('infos').others;
    }

    public get assignedCount(): number {
        return this.dataCard.resource.get('assignatedCount');
    }

    public get duplicationCount(): number {
        return this.dataCard.resource.get('duplicationCount');
    }

    public get settingsCardsDisplay(): any {
        return this.settings.cardDisplay;
    }

    public get defaultHeader(): any {
        return this.cardsService.settings.cardDefaultHeader;
    }

    public get metadatas(): any {
        return this.dataCard.resource.get('metadatas');
    }

    /**en off all of this method are to set in the init only once**/

    displayField(name: string): boolean {
        const role = this.authService.accessLevel;
        let cardFields = this.settings.cardFields[role];
        if (cardFields === undefined) {
            cardFields = this.settings.cardFields['default'];
        }
        return cardFields.indexOf(name) > -1;
    }

    displayMenuLink(name: string): boolean {
        const role = this.authService.accessLevel;
        let menuLinks = this.settings.menuLinks[role];
        if (menuLinks === undefined) {
            menuLinks = this.settings.menuLinks['default'];
        }
        return menuLinks.indexOf(name) > -1;
    }

    /***
     * filter by lessons or sequence element
     */
    get isLessonOrSequence(): Boolean {
        return this.typeOfCard.isSequence || this.typeOfCard.isLesson;
    }

    /***
     * show or hide contentDetail part in form
     */
    public get showContentDetails(): boolean {
        return this.displayField('contentDetails')
            &&
            (this.isFormat('lesson') ||
                this.isFormat('sequence') ||
                this.isFormat('themes'));
    }

    /***
     * is pdf student is allowed and url of pdf exist
     */
    public get isPdfAllowedAndExist(): boolean {
        return (this.typeOfCard.isSequence || this.typeOfCard.isTheme || this.typeOfCard.isLesson)
            && this.contentDetails.reference
            && this.contentDetails.reference.length > 0
            && this.contentDetails.reference[0].media !== ''
            && this.contentDetails.reference[0].media !== undefined
            && this.contentDetails.reference[0].media !== null;
    }

    /***
     * is pdf in lesson is allowed and url of pdf exist
     */
    public get isPdfLessonAllowedAndExist(): boolean {
        return (this.typeOfCard.isLesson)
            && this.contentDetails.files.filter(file => file.filemime = 'application/pdf').length > 0
            && this.contentDetails.files.filter(file => file.filemime = 'application/pdf')[0].uri !== null
            && this.contentDetails.files.filter(file => file.filemime = 'application/pdf')[0].uri !== undefined
            && this.contentDetails.files.filter(file => file.filemime = 'application/pdf')[0].uri !== '';
    }


    /***
     * @param format : 'videoUrl' or 'video' or 'image' or 'sequence' or 'lesson' or 'form'
     */
    private isFormat(format: string): boolean {
        return (this.getLabelToUse() === format);
    }

    /***
     * check if isEditableAndErasable method exist and if exist return result from this ressource
     */
    public get isEditableAndErasable(): boolean {
        return this.dataCard.isEditableAndErasable && this.dataCard.isEditableAndErasable(this.dataCard.resource);
    }

    public openAssignments(): void {
        this.router.navigate(['followed', 'list'], {queryParams: {title: this.headerTitle}});
    }

    /**
     * download document not at the same place in case of theme or sequence
     * is the old use? not sure if not put it in themeservice
     */
    public download(path?: string): void {
        if (this.typeOfCard.isTheme) {
            this.dataCard.downloadDoc(this.contentDetails.document);
        } else if (this.typeOfCard.isLesson) {
            this.dataCard.downloadDoc(this.contentDetails.document);
        } else {
            // make a play old implementation always use
            this.dataCard.download(this.dataCard.resource);
        }
    }

    /**
     * download file use the good data regarding of origin of call lesson theme etc.
     */
    public downloadFile(idFileType: Number): void {
        switch (idFileType) {
            case FileType.lessonPdf:
                this.dataCard.downloadDoc(this.contentDetails.files.filter(file => file.filemime = 'application/pdf')[0].uri);
                break;
            case FileType.themePedagoDocPdf:
                this.dataCard.downloadDoc(this.contentDetails.reference[0].media);
                break;
            default:
                console.error('error type is not existing');
                break;
        }
    }


    /***
     * is a pdf of the lesson exist and is allowed to download
     */
    public get isAllowedDownloadLesson(): boolean {
        return this.typeOfCard.isTheme && this.isContentDetailContainReference();
    }

    /***
     * is a lesson to play is asociate with the card in themes
     */
    public get isLessonAllowedAndExist(): boolean {
        return this.typeOfCard.isTheme && this.isContentDetailContainReference();
    }

    private isContentDetailContainReference(): boolean {
        return this.contentDetails.reference !== null &&
            this.contentDetails.reference !== undefined &&
            this.contentDetails.reference.length > 0;
    }

    /**
     * play lesson link to current theme
     */
    public playLesson(): void {
        this.dataCard.play(this.contentDetails.reference[0].id);
    }

    /***
     * favorites is selected or not if it's a theme use flag bookmarks-theme
     */
    public get isFavorite(): boolean {
        if (this.typeOfCard.isTheme) {
            return this.dataCard.resource.get('bookmarks-theme') === true;
        } else {
            return this.dataCard.resource.get('bookmarks') === true;
        }
    }

    public bookmark(): void {
        this.flagService.flagEntity(this.dataCard.resource, this.bookmarksParams.type, this.bookmarksParams.endPoint)
            .subscribe((result) => {
                this.cardsService.settingBookmark.next();
            });
    }

    /**
     * launch lesson or under menu : settings rules
     * @param dataCard : datacard info
     */
    public play(dataCard: any): void {
        if (this.cardsService.settings.cardPlayOpenMenu) {
            // open undermenu before running lesson
        } else {
            // launch lesson
            this.dataCard.play(dataCard.resource);
        }
    }

    /**
     * open pop up to set the number of player
     */
    public playerNumberModal(dataCard: any): void {
        // TODO - get out of card component, should be passed as parameter
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: 'generic.run_lesson',
            panelClass: 'entity-form-dialog',
            labelConfirmDialog: 'activities.lesson.dialog_confirm', // todo add empathic traduction
            labelTrueDialogPlaceHolder: 'activities.lesson.dialog_confirmPlaceHolder',
            bodyDialog: 'activities.lesson.dialog_confirm_body'
        };
        for (const term in dialogConfig.data) {
            this.translate.get(dialogConfig.data[term]).subscribe((translation: string) => dialogConfig.data[term] = translation);
        }

        // open modal construct by passing html
        const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (Number.isNaN(Number(result))) {
                    // if ask string instead of number ( field comment to store a class for filter in follow)
                    if (this.settings.islaunchLessonAskClass) {
                        this.launchLessonAutoAssignement(dataCard, null, result);
                    }
                    return;
                }
                this.launchLessonAutoAssignement(dataCard, result, null);
            }
        });
    }

    /**
     * launch lesson and assign lesson to current user
     */
    launchLessonAutoAssignement(dataCard: any, nbrePeople: number, comment: string): void {
        // TODO - get out of card component, should be passed as parameter
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((user: DataEntity) => {
                this.createUserAssignment(user, dataCard, nbrePeople, comment);
            });
    }

    /**
     * prepare data and lanch create user assignement in assignation
     * @param user : current user info
     * @param dataCard : datacard info
     * @param nbrePeople : nbre of people who will see the lesson
     */
    private createUserAssignment(user: any, dataCard: any, nbrePeople: number, comment: string): void {
        // TODO - get out of card component, should be passed as parameter
        const data = {
            learners: [
                {
                    id: user.id,
                }
            ],
            assignatedCount: nbrePeople,
            comment: comment,
            project: null,
            rating_base: 4,
            startDate: Date.now(),
            startTime: null,
            dueDate: null,
            dueTime: null,
            nodeId: dataCard.resource.id,
            group: []
        };

        this.communicationCenter
            .getRoom('assignment')
            .next('createAssignment', {
                assigment: data, callback: assignment => {
                    // lock lesson
                    if (dataCard.resource.get('locked') !== '1') {
                        const entity = new DataEntity('granule-lesson', dataCard.resource.attributes, this.octopusConnect, dataCard.resource.id);
                        entity.set('locked', true);
                        entity.save();
                    }
                    // launch assignment
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('launchAssignment', assignment);
                    // run lesson
                    this.dataCard.play(dataCard.resource);
                }
            });
    }
}

export enum FileType {
    'themePedagoDocPdf',
    'lessonPdf'
}
