import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FuseGroupsFormDialogComponent} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {defaultApiURL} from '../../../../settings';
import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-users-import',
    templateUrl: './users-import.component.html',
    styleUrls: ['./users-import.component.scss']
})
export class UsersImportComponent implements OnInit {

    public done = false;
    public result: DataEntity;
    public fileName = 'generic.nofile';
    public fileError: string;
    public objectKeys = Object.keys;
    public uploaded = false;
    public uploadedFid: number;
    public page: DataEntity;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                public dialogRef: MatDialogRef<FuseGroupsFormDialogComponent>,
                private octopusConnect: OctopusConnectService,
                private http: HttpClient,
                private accountManagementProvider: AccountManagementProviderService,
                private domSanitizer: DomSanitizer
    ) {

    }

    ngOnInit(): void {
        const sub = this.octopusConnect.loadEntity('pages', 'import-eleves').take(1).subscribe((page) => {
            this.page = page;
            this.page.attributes['body'] = this.domSanitizer.bypassSecurityTrustHtml(this.page.get('body'));
        });
    }

    importLearners(): void {
        this.octopusConnect.createEntity('users-import', {fid: this.uploadedFid}).subscribe((data) => {
            this.done = true;
            this.result = data;
        });
    }

    uploadFile(file: File): void {
        if (file.type === 'application/vnd.ms-excel' && file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2) === 'csv') {
            const formData = new FormData();
            formData.append('file', file);
            this.fileName = file.name;
            this.fileError = '';


            this.http
                .post<any>(defaultApiURL + 'api/file-upload', formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                .subscribe(res => {
                    this.uploaded = true;
                    this.uploadedFid = +res['data'][0][0]['id'];
                });
        } else {
            this.fileError = 'groups-management.csv.file';
        }
    }

    onFileChanged(evt: Event): void {
        this.uploadFile(evt.target['files'][0]);
    }
}
