import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource} from '@angular/material';
import {fuseAnimations} from 'fuse-core/animations';
import {Router} from '@angular/router';
import 'rxjs/add/operator/map';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';
import {Subject} from 'rxjs/Subject';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'fuse-app-lessons-list',
    templateUrl: './lessons-selection.component.html',
    styleUrls: ['./lessons-selection.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class LessonsSelectionComponent implements OnInit, OnDestroy {
    public displayedColumns = ['label'];
    public dataSource = new MatTableDataSource([]);
    public methods: any;
    public resources: any;
    public tags: any;
    public entity: any = {};
    public activities: any = {};
    public type: string;
    public lessonsSelected: object[] = [];
    entityForm: FormGroup;
    public titleFilter: FormControl;
    public resourcesSubscription: Subscription;
    private optionsInterface:  CollectionOptionsInterface;
    private unsubscribeInTakeUntil = new Subject();
    pageIndex = 0;
    lessonsSelectedButton: boolean;
    private loadPaginatedLessons: any;

    public tagsChanged: BehaviorSubject<any> = new BehaviorSubject([]);


    constructor(
        public dialog: MatDialog,
        private router: Router,
        public dialogRef: MatDialogRef<LessonsSelectionComponent>,
        private communicationCenter: CommunicationCenterService,
        @Inject(MAT_DIALOG_DATA) private data: {
            activities: DataEntity[];
            currentUserRoles: number[];
            allowedRoleIdsForModelsCreation: number[];
        },
    ) {
        this.activities = data.activities;

        this.optionsInterface = {
            filter: {},
            page: 1,
            range: 12
        };

        this.communicationCenter
            .getRoom('activities')
            .getSubject('loadPaginatedLessonsCallback')
            .subscribe((callback: any) => {
                this.loadPaginatedLessons = callback;
            });

    }

    ngOnInit(): void {
        this.lessonsSelectedButton = false;
        this.resourcesSubscription = this.refreshList();
        this.titleFilter = new FormControl('');
        this.titleFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.launchSearch();
            });

    }

    launchSearch(): void{
        this.resourcesSubscription.unsubscribe();
        this.resourcesSubscription = this.refreshList();
    }

    refreshList(): Subscription{
        const searchValue = this.titleFilter ? this.titleFilter.value : '';
        const userIsAllowedToCreateModels = this.data.allowedRoleIdsForModelsCreation.some( allowedId => this.data.currentUserRoles.includes(allowedId));
        this.type = userIsAllowedToCreateModels ? 'byRole' : 'currentUser';

        return this.resourcesSubscription = this.loadPaginatedLessons(this.type, this.data.allowedRoleIdsForModelsCreation, searchValue, this.optionsInterface)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(resources => {
                if (!resources) return;
                this.resources = resources;
            });
    }

    filter(name: string): Array<string> {
        return this.tags.filter(chip =>
            chip.name.toLowerCase().indexOf(name.trim().toLowerCase()) > -1);
    }

    resetTags(): void {
        this.lessonsSelected = [];
        if (this.titleFilter && !this.titleFilter.value){
            this.dataSource.data = [];
        }
    }

    selectedLesson(event: object): void{
        const position = this.checkSelected(event);
        if (position > -1) {
            this.lessonsSelected.splice(position, 1);
            this.lessonsSelectedButton = false;
        } else {
            this.lessonsSelected = []; // empty object
            if (event['attributes'].locked !== '1'){
                this.lessonsSelected.push(event);
                this.lessonsSelectedButton = true;
            }
        }
    }

    checkSelected(entity): number{
        return this.lessonsSelected.findIndex(obj => entity['id'] === obj['id']);
    }

    ngOnDestroy(): void {

    }

}

