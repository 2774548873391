import {Structures} from 'octopus-model';

export const corpus = {
    accessMatrix: {
        global: {
            view: ['trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            selectAll: ['learner', 'trainer', 'manager', 'administrator']
        },
        userCorpus: {
            view: ['trainer', 'manager', 'administrator'],
            add: ['trainer', 'manager', 'administrator'],
            edit: ['trainer', 'manager', 'administrator'],
            delete: ['trainer', 'manager', 'administrator'],
            print: ['trainer', 'manager', 'administrator'],
            selectAll: ['learner', 'trainer', 'manager', 'administrator']
        },
    },
    addToLesson: false,
    allowedExtensions: 'pdf',
    allowErrorReporting: false,
    assetFields: {
        default: ['name', 'difficulty', 'scolomDate', 'source', 'description', 'author']
    },
    assetRequiredFields: ['name'],
    assetTextEditorFields: ['description'],
    detailsFields: ['scolomDate', 'source-author', 'source', 'format', 'description'],
    authorRolesGeneralCorpus: ['3', '4'], // 3 => administrator, 4 => gestionnaire, 5 => formateur, 6 => apprenant
    cardLayout: 'card-split-media-info',
    columns: {
        default: ['icon', 'title', 'difficulty', 'buttonsAction']
    },
    fileMaxSize: '1',
    filesWhiteList: [
        'application/pdf'
    ],
    filters: {
        default: ['difficulty', 'title', 'launchSearch'] // order in ui like in array
    },
    filterFormatForced: ['video'], // active filter for limiting corpus content at vidéo type for users
    globalCorpus: '11057',
    mediaTypes: ['audio', 'document', 'image', 'text', 'url', 'video'], // 'audio', 'document', 'image', 'media', 'text',  'url', 'video', 'videoUrl'
    showButtonOptions: true,
    terms: {
        globalCorpus: {
            title: 'generic.title_global_corpus',
            columns: {
                type: 'generic.type_global_corpus',
                author: 'generic.author_global_corpus',
                group: 'generic.group_global_corpus',
            }
        },
        research: {
            title: 'projects-management.research_space',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            }
        },
        lesson: {
            title: 'generic.title_lesson_corpus',
            columns: {
                type: 'generic.type',
                author: 'generic.author',
                group: 'generic.group',
            }
        },
    },
    textEditor: {
        enabled: true,
        config: {toolbar: ['bold', 'italic']},
    },
    userCorpus: true, // define if there is a corpus to list current user assets
    uploadAllowed: {
        localFile: false,
        url: true,
        urlAllowed: 'https://www.youtube.com/',
    },
    displayHeader: false,
};
