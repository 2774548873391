import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMultipleChoice} from '@modules/activities/core/player-components/multiple-choice-grid/multiple-choice/models/imultiple-choice';

@Component({
    selector: 'app-multiple-choice',
    templateUrl: './multiple-choice.component.html',
    styleUrls: ['./multiple-choice.component.scss']
})
export class MultipleChoiceComponent implements OnInit {
    // format to set the question and pass the possible answer to the question with their id
    @Input('data') data: IMultipleChoice;
    @Input('multipleChoices') multipleChoices: string = '0';  // false = 0 true =1 due to back data : is multiple choice enable for the question
    @Output('selectedValues') selectedValues: EventEmitter<IMultipleChoice> = new EventEmitter<IMultipleChoice>(); // data emit each time a change occur

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * manage value selected for checkbox type of response
     * @param evt : contain true or false in evt.checked
     * @param element : current element
     */
    onSelectCheckBox(evt: any, element: any): void {
        // change the value to the good state checked or not
        this.data.answers.filter(elem => elem.id === element.id)[0].select = evt.checked
        // send data to parent
        this.selectedValues.emit(this.data);
    }

    /**
     * manage value selected for radiobuttontype
     * @param element : current answer
     */
    onSelectRadio(element: any): void {
        // reset old value for this question from true to false
        this.data.answers.forEach(elem => {
            if (elem.id !== element.id) {
                elem.select = false;
            }
        });
        // change the selected value to true
        this.data.answers.filter(elem => elem.id === element.id)[0].select = true;
        // emit data to parent
        this.selectedValues.emit(this.data);
    }
}
