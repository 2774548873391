import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs/Observable';
import {Group, Trainer} from '@modules/groups-management/core/definitions';
import {Subscription} from "rxjs/Subscription";
import {localizedDate} from "../../../../shared/utils";
import {MatDialog, MatDialogRef} from '@angular/material';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TrainerService {
  private userData: DataEntity;
  private groupsList: Group[] = [];
  private trainersCollection: {[key: number]: DataEntity} = {};
  private trainersList: Trainer[] = [];
  private trainersSubscription: Subscription = null;
  public onTrainersChanged: BehaviorSubject<any> = new BehaviorSubject([]);

  private types = ['trainer', 'mentor'];

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(    private octopusConnect: OctopusConnectService,
                  private communicationCenter: CommunicationCenterService,
                  public dialog: MatDialog,
                  private translate: TranslateService,
  ) {

    this.communicationCenter
      .getRoom('authentication')
      .getSubject('userData')
      .subscribe((data: DataEntity) => {
          if (data) {
              this.userData = data;
              this.postAuthentication();
          } else {
              this.postLogout();
          }
      });
  }

    private postLogout(): void {
      if (this.trainersSubscription) {
          this.trainersSubscription.unsubscribe();
          this.trainersSubscription = null;
      }
    }

  private postAuthentication(): void {
    if (!this.trainersSubscription) {
      const subjectGroupsList = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('groupsList');

      this.trainersSubscription = this.loadTrainers()
        .combineLatest(subjectGroupsList)
        .subscribe((data: [DataCollection, Group[]]) => {
          this.groupsList = data[1];
          this.trainersList = [];

          for (const entity of data[0].entities) {
            const groupsId = entity.get('groups') || [];
            const groups: string[] = this.groupsList
              .filter((group: Group) => groupsId.indexOf(group.id.toString()) > -1)
              .map((group: Group) => group.groupname);

              this.trainersList.push({
                id: (entity.id ? parseInt(entity.id.toString()) : -1),
                avatar: entity.get('picture'),
                username: entity.get('label'),
                email: entity.get('email'),
                password: '',
                type: entity.get('type'),
                access: (entity.get('access') === '0' ? 'Never' : this.localeDate(entity.get('access')))
              });

            this.trainersCollection[entity.id] = entity;
          }

          this.communicationCenter
            .getRoom('groups-management')
            .next('trainersList', this.trainersList);
          this.onTrainersChanged.next(this.trainersList);
        });
    }
  }

  getGroups(): string[] {
    return this.groupsList.map((group: Group) => group.groupname);
  }

  getTypes(): string[] {
    return this.types;
  }
  loadTrainers(): Observable<DataCollection> {
    return this.octopusConnect.loadCollection('trainers');
  }

  groupsListToId(learner): number[] {
    const groups: number[] = [];
    if (learner.groups) {
      groups.push(...this.groupsList
        .filter((group: Group) => learner.groups.indexOf(group.groupname) > -1)
        .map((group: Group) => group.id));
    }

    return groups;
  }

  addTrainer(trainer): Observable<DataEntity> {
    const obs = this.octopusConnect
      .createEntity('user-registration', {
        label: trainer.username,
        email: trainer.email,
        password: trainer.password,
        picture: trainer.avatar,
        newsletter: false,
        role: 5,
        type: trainer.type

      });
      obs.subscribe((data: DataEntity) => {
        this.loadTrainers();
      }, (error: Object) => {
          const errorField = {
              titleDialog: '',
              bodyDialog: 'generic.error-mail-already-existed',
              labelTrueDialog: '',
              labelFalseDialog: 'generic.close',
          };

          this.showErrorMessage(errorField);

      })

      return obs;
  }

  saveTrainer(trainer): Observable<DataEntity> {
    const learnerEntity = this.trainersCollection[trainer.id];

    if (learnerEntity) {
      if (trainer.password) {
        learnerEntity.set('password', trainer.password);
      }

      learnerEntity.set('label', trainer.username);
       learnerEntity.set('groups', this.groupsListToId(trainer));

      learnerEntity.set('type', trainer.type);
      learnerEntity.set('email', trainer.email);

      // TODO set other fields
      return learnerEntity.save();
    }
  }


  deleteTrainer(trainer) {
    const learnerEntity = this.trainersCollection[trainer.id];

    if (learnerEntity) {
      learnerEntity.remove();
    }
  }

  localeDate(date) {
    return localizedDate(date);
  }


    showErrorMessage(fields) {
        for (const myField in fields){
            if (fields[myField] !== ''){
                this.translate.get(fields[myField]).subscribe((translation: string) => fields[myField] = translation);
            }
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data      : fields
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
            this.confirmDialogRef = null;
        });
    }

}
