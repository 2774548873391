import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {DataEntity} from 'octopus-connect';
import {Router} from '@angular/router';
import {GenericModalService} from '../../generic-modal.service';
import {RenameDraftModalComponent} from '../../section-draft-display/rename-draft-modal/rename-draft-modal.component';
import {ResearchSheetService} from '../../research-sheet.service';
import {ResearchTemplate} from '../../definitions';
import {ResearchTemplateService} from '../research-template.service';

@Component({
  selector: 'app-research-template-item',
  templateUrl: './research-template-item.component.html',
  styleUrls: ['./research-template-item.component.scss']
})
export class ResearchTemplateItemComponent implements OnInit {

    @Input('template') template: ResearchTemplate;
    @Input('templates') templates: ResearchTemplate[];
    @Input('mode') mode = 'normal';

    userName: string;

    constructor(
        private sheetService: ResearchSheetService,
        private router: Router,
        private genericModal: GenericModalService,
        private dialog: MatDialog,
        private templatesService: ResearchTemplateService
    ) { }

    ngOnInit(): void {
      //console.log("TEMPLATE ENTITY", this.template, this.templateEntity);

      this.userName = this.templateEntity.get('username');
    }

    clickAction(template: DataEntity): void {
        if (this.isMine()) {
            this.editTemplate(template);
        } else {
            this.viewTemplate(template);
        }
    }

    get templateEntity(): DataEntity {
        return this.sheetService.templatesCollection[this.template.id];
    }

    isMine(): boolean {
      return this.sheetService.isMine(+this.templateEntity.get('uid'));
    }

    editTemplate(template: DataEntity): void {
        this.router.navigate([this.templatesService.getTemplateEditLink(+template.id)]);
    }

    viewTemplate(template: DataEntity): void {
        this.router.navigate([this.templatesService.getTemplateViewLink(+template.id)]);
    }

    deleteTemplate(template: ResearchTemplate): void {
        this.genericModal.openYesNoDialog('generic.delete').subscribe((resp: string) => {
            if (resp === 'yes') {
                this.getTemplateEntityById(template.id).remove();
            }
        });
    }

    getTemplateEntityById(id: number): DataEntity {
        return this.sheetService.templatesCollection[id];
    }

    duplicateTemplate(template: ResearchTemplate): void {
        this.sheetService.duplicateTemplace(template).subscribe(entity => console.log("Tmpl entity", entity));
    }

    renameTemplate(template: ResearchTemplate): void {
        this.dialog.open(RenameDraftModalComponent, {
            width: '500px',
            data: this.getTemplateEntityById(this.template.id)
        });
    }

    getTemplateModificationDate(template: ResearchTemplate): string {
        const date = new Date(+this.templateEntity.get('updated') * 1000);
        return date.toLocaleDateString((navigator.languages && navigator.languages[0]) || navigator.language);
    }

}
