export const accountManagement = {
    allowedMaxSize: 1,
    canEditAvatar: ['trainer', 'manager', 'administrator'],
    canSelfDelete: ['trainer'],
    displayDialogMinorAdult: false,
    fields: {
        minor : ['label', 'contact_email', 'password', 'you_are', 'find_us', 'newsletter', 'picture'],
        default : ['email', 'password', 'you_are', 'picture', 'region', 'level']
    },
    selfSignup: true,
};
