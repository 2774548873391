import {Component, ViewChild, ElementRef, OnInit, AfterViewInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {IImage, IMove, IParamsZoneAround, IStyleZoneBeginEnd} from '@modules/timeline/core/models/timeline-data.models';

@Component({
    selector: 'app-viewer',
    templateUrl: './viewer.component.html',
    styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('divToScrollMultiImage') private divToScrollMultiImage: ElementRef;

    @Input() moveTo: IMove = {position: 0, type: 'auto', numeroImage: 0, ajustementPositionPx: 0};
    @Input() globalImageWidth: number;
    @Input() images: IImage[];
    @Input() paramsZoneAround: IParamsZoneAround = {width: 0, startColor: '', endColor: ''};
    @Input() activateGesture: boolean = false;
    @Output() openDocument = new EventEmitter<number>();
    @Output() currentImage = new EventEmitter<number>();

    public beginImageStyles: IStyleZoneBeginEnd = {height: '', width: '', backgroundColor: ''};
    public endImageStyles: IStyleZoneBeginEnd = {height: '', width: '', backgroundColor: ''};
    public commonStyles: { height: string } = {height: ''};
    public cadreImageStyles: { width: string } = {width: ''};

    constructor() {
    }

    ngOnInit(): void {
        const myHeight = this.images[0].height + 'px';
        this.beginImageStyles.height = myHeight;
        this.endImageStyles.height = myHeight;
        this.commonStyles.height = myHeight;
    }

    ngAfterViewInit(): void {
        // begin bloc before image
        this.beginImageStyles.width = this.paramsZoneAround.width + 'px';
        this.beginImageStyles.backgroundColor = this.paramsZoneAround.startColor;
        // end image after image
        this.endImageStyles.width = this.paramsZoneAround.width + 'px';
        this.endImageStyles.backgroundColor = this.paramsZoneAround.endColor;

        this.cadreImageStyles.width = this.globalImageWidth + 'px';
        // scroll to good position
        this.moveToPositionById();
    }

    /**
     * each time the moveTo object change scrollTo the position in the timeline value in pixel
     */
    ngOnChanges(): void {
        try {
            if (this.moveTo.type === 'auto') {
                this.divToScrollMultiImage.nativeElement.scrollTo({left: this.moveTo.position, top: 0, behavior: this.moveTo.type});
            } else {
                // scroll to good position by id smooth
                this.moveToPositionById();
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * center the image using id and adjustment
     */
    private moveToPositionById(): void {
        const element = document.getElementById('loupe' + this.moveTo.numeroImage);

        if (element) {
            setTimeout(() => {
                    const position = element.offsetLeft;

                    this.divToScrollMultiImage.nativeElement.scrollTo({left: position + this.moveTo.ajustementPositionPx, top: 0, behavior: this.moveTo.type});
                }
                , 100);
        }
    }

    /**
     * launch open document details in parent.
     * @param image contain id of period with the dot info to open
     */
    public openDocumentEmitter(image: IImage): void {
        this.openDocument.emit(+image.idPeriod);
    }

    /**
     * gesture to move image by image by left swipe
     * @param evt :not use for moment
     */
    public onSwipeLeft(evt: any): void {
        if (!this.activateGesture) {
            return;
        }
        if (this.moveTo.numeroImage < this.images.length - 1) {
            this.moveTo.numeroImage = +this.moveTo.numeroImage + 1;
            this.movetoImage();
        }
    }

    /**
     * gesture to move image by image by right swipe
     * @param evt :not use for moment
     */
    public onSwipeRight(evt: any): void {
        if (!this.activateGesture) {
            return;
        }
        if (this.moveTo.numeroImage !== 0) {
            this.moveTo.numeroImage = +this.moveTo.numeroImage - 1;
            this.movetoImage();
        }
    }

    /**
     * launch move to image and emit info to parent
     */
    private movetoImage(): void {
        this.moveToPositionById();
        this.currentImage.emit(this.moveTo.numeroImage);
    }
}
