import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {IdeasWallDdService} from '../ideas-wall-dd.service';
import {IdeaDrop} from '../idea-drop.interface';
import {IdeasWallService} from '../ideas-wall.service';
import {CategoryItemComponent} from '../category-item/category-item.component';
import {MatDialog} from '@angular/material';
import {IdeaEditionModalComponent} from '../idea-edition-modal/idea-edition-modal.component';
import {MatDialogConfig} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
declare var require: any;
const Draggable = require('gsap/Draggable');
const TweenLite = require('gsap/TweenLite');

@Component({
    selector: 'idea-item',
    templateUrl: './idea-item.component.html',
    styleUrls: ['./idea-item.component.scss']
})
export class IdeaItemComponent implements OnInit, OnDestroy {

    @Input('idea') idea: DataEntity;
    @Input('dropEnabled') dropEnabled = true;
    @ViewChild('element') element: ElementRef;
    @ViewChild('mainContainer') mainContainer: ElementRef;
    @ViewChild('elementContent') elementContent: ElementRef;
    clone: HTMLElement;
    private draggable: any;

    beforePlaceholder = false;
    afterPlaceholder = false;
    placeholderHeight: number;
    originalHeight: number;

    dragged = false;

    constructor(
        private ddService: IdeasWallDdService,
        private wallsService: IdeasWallService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private globalElement: ElementRef
    ) { }

    ngOnInit(): void {
        if (this.dropEnabled) {
            this.ddService.registerIdea(this);
        }

        let startParentTop: number;

        this.draggable = Draggable.create(this.element.nativeElement, {
            onDragStart: () => {
                // ghost creation
                this.clone = this.element.nativeElement.cloneNode(true);
                this.clone.style.position = 'fixed';
                this.clone.style.width = this.element.nativeElement.clientWidth + 'px';
                this.clone.style.opacity = '0.5';
                document.body.appendChild(this.clone);

                this.setClonePosition();

                this.originalHeight = this.element.nativeElement.getBoundingClientRect().height;

                this.dragged = true;

                setTimeout(() => {
                    startParentTop = this.element.nativeElement.parentElement.getBoundingClientRect().top;
                });

                this.ddService.resetIdeasComponents();

            },
            onDrag: () => {
                this.ddService.overColumnNumber = null;
                this.ddService.overColumn = false;
                this.ddService.resetIdeasComponents();
                this.ddService.resetCategoriesComponents();

                const parentTop: number = this.element.nativeElement.parentElement.getBoundingClientRect().top;
                const gap: number = Math.floor(parentTop - startParentTop);

                this.setClonePosition(gap);

                const dropIdea: IdeaDrop = this.ddService.checkIfOnIdea(this.draggable, gap);

                if (dropIdea) {
                    this.ddService.resetIdeasComponents();

                    if (dropIdea.idea.idea.get('category').length === 0) {
                        // hors categorie
                        // highlight de la colonne
                        this.ddService.overColumnNumber = dropIdea.idea.idea.get('column');
                    } else {
                        // dans une categorie
                        const categoryId: number = dropIdea.idea.idea.get('category');

                        // highlight de la categorie
                        const categoryItem: CategoryItemComponent = this.ddService.categories.find(item => +item.category.id === +categoryId);

                        if (categoryItem) {
                            categoryItem.over = true;
                        }
                    }

                    if (dropIdea.position === 'top') {
                        dropIdea.idea.displayBeforePlaceholder(this);
                    } else if (dropIdea.position === 'bottom') {
                        dropIdea.idea.displayAfterPlaceholder(this);
                    }

                    return;

                } else {
                    this.ddService.resetIdeasComponents();
                }


                const dropCategory: CategoryItemComponent = this.ddService.checkIfOnCategory(this.draggable);

                if (dropCategory) {
                    this.ddService.overPlaceholderHeight = this.originalHeight;
                    dropCategory.setOver();
                    return;
                }


                const colNum: number = this.ddService.checkIfOnColumn(this.draggable);

                if (colNum !== -1) {
                    this.ddService.overColumn = true;
                    this.ddService.overPlaceholderHeight = this.originalHeight;
                    this.ddService.overColumnNumber = colNum;
                }
            },
            onDragEnd: () => {

                this.ddService.resetCategoriesComponents();
                this.ddService.resetIdeasComponents();
                this.ddService.overColumnNumber = null;
                this.ddService.overColumn = false;

                document.body.removeChild(this.clone);
                this.clone = null;

                let colNum: number;
                const dropIdea: IdeaDrop = this.ddService.checkIfOnIdea(this.draggable);

                if (dropIdea) {
                    colNum = dropIdea.idea.idea.get('column');

                    this.idea.set('column', colNum);
                    this.idea.set('category', []);

                    if (dropIdea.position === 'top') {
                        this.idea.set('position', this.wallsService.getPreviousMeanPosition(dropIdea.idea.idea, this.idea, this.wallsService.completeIdeasList));
                    } else if (dropIdea.position === 'bottom') {
                        this.idea.set('position', this.wallsService.getNextMeanPosition(dropIdea.idea.idea, this.idea, this.wallsService.completeIdeasList));
                    }

                    this.idea.set('category', dropIdea.idea.idea.get('category'));
                    this.idea.save(true);

                    dropIdea.idea.hidePlaceholders();
                    this.dragged = false;

                    this.resetPosition();

                    return;
                }

                const dropCategory: CategoryItemComponent = this.ddService.checkIfOnCategory(this.draggable);

                if (dropCategory) {
                    dropCategory.folded = false;
                    this.idea.set('category', [dropCategory.id]);
                    this.idea.save(true);
                    return;
                }

                // check if drop on column
                colNum = this.ddService.checkIfOnColumn(this.draggable);

                if (colNum !== -1) {

                    this.dragged = false;
                    this.resetPosition();

                    this.idea.set('column', colNum);
                    this.idea.set('category', []);
                    this.idea.set('position', this.wallsService.maxPosition(this.wallsService.completeIdeasList) + this.wallsService.ideasInterval);
                    this.idea.save(true);
                } else {
                    this.resetPosition();
                }

                this.dragged = false;


                this.hidePlaceholders();
                this.ddService.resetIdeasComponents();
            }
        })[0];
    }

    setClonePosition(gap: number = 0): void {
        this.clone.style.top = (this.element.nativeElement.getBoundingClientRect().top - gap) + 'px';
        this.clone.style.left = this.element.nativeElement.getBoundingClientRect().left + 'px';
    }

    displayBeforePlaceholder(dropIdea: IdeaItemComponent): void {
        this.placeholderHeight = dropIdea.originalHeight;
        this.beforePlaceholder = true;
    }

    displayAfterPlaceholder(dropIdea: IdeaItemComponent): void {
        this.placeholderHeight = dropIdea.originalHeight;
        this.afterPlaceholder = true;
    }

    hidePlaceholders(): void {
        this.beforePlaceholder = false;
        this.afterPlaceholder = false;
    }

    deleteIdea(): void {
        const dialogConfig = new MatDialogConfig();
        let dialogYes = '';
        let dialogNo = '';
        let dialogDelete = '';

        this.translate.get('generic.yes').subscribe((translation: string) => dialogYes = translation);
        this.translate.get('generic.no').subscribe((translation: string) => dialogNo = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => dialogDelete = translation);

        dialogConfig.data = {
            bodyDialog: dialogDelete,
            labelTrueDialog: dialogYes,
            labelFalseDialog: dialogNo
        };

        this.dialog.open(FuseConfirmDialogComponent, dialogConfig).afterClosed().subscribe((result) => {
            if (result) {
                this.idea.remove();
            }
        });
    }

    editIdea(): void {
        this.dialog.open(IdeaEditionModalComponent, {
            data: {
                text: this.idea.get('text'),
                type: 'idea',
                phase: 'edition'
            },
            autoFocus:false
        }).beforeClose().subscribe((text: string) => {
            if (text !== this.idea.get('text') && text !== '' && text !== undefined) {
                this.idea.set('text', text);
                this.idea.save(true);
            }
        });
    }

    resetPosition(): void {
        TweenLite.set(this.element.nativeElement, {
            clearProps: 'all'
        });
    }

    get id(): number {
        return +this.idea.id;
    }

    get locked(): boolean {
        return this.wallsService.lockedIdeas;
    }

    public isMine():boolean{
        return +this.idea.get('uid') === +this.wallsService.currentUser.id;
    }

    ngOnDestroy(): void {
        this.ddService.unRegisterIdea(this);
    }

    get isLearner(): boolean {
        return this.wallsService.isLearner;
    }

    get isTeacher(): boolean {
        return this.wallsService.isTeacher;
    }
}
