import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../profile.service';
import { fuseAnimations } from '@fuse/animations';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {TralaTranslationLoaderService} from '../../../../../../trala-translation-loader.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {langs} from '../../../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {MatDialog} from '@angular/material/dialog';
import {ProfileDeleteConfirmModalComponent} from '@modules/account-management/core/profile/profile-delete-confirm-modal/profile-delete-confirm-modal.component';

@Component({
    selector   : 'fuse-profile-about',
    templateUrl: './about.component.html',
    styleUrls  : ['./about.component.scss'],
    animations : fuseAnimations
})
export class FuseProfileAboutComponent implements OnInit
{
    userInformation: DataEntity;
    defaultUserInformation: FormProfile;
    findUsValue: string;
    regionValue: string;
    levelValue: string;
    youAreValue: string;

    public settings: {[key: string]: any};

    constructor(
        public authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private profileProvider: ProfileService,
        private translationLoader: TralaTranslationLoaderService,
        private octopusConnect: OctopusConnectService
    ) {
        this.defaultUserInformation = new FormProfile({}, this.octopusConnect);
        this.settings = this.profileProvider.settings;

        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.translationLoader.loadTranslations(...defaultLang);
    }

    ngOnInit(): any
    {
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            this.userInformation = userInformation;

            this.findUsValue = this.getValue(this.settings.findUsValues, this.userInformation.get('find_us'));

            if (this.userInformation.get('region') && this.userInformation.get('region').hasOwnProperty('label')) {
                this.regionValue = this.userInformation.get('region').label;
            }

            if (this.userInformation.get('level') && this.userInformation.get('level').hasOwnProperty('label')) {
                this.levelValue = this.userInformation.get('level').label;
            }

            this.youAreValue = this.getValue(this.settings.youAreValues, this.userInformation.get('you_are'));
        });
    }

    setMode(mode: boolean): void{
        this.profileProvider.editMode = mode;
    }

    private getValue(field, value): string{
        const found = field.find((find) => find === value);
        return found ? found : 'generic.to-complete';
    }

    getTranslate(): string {
        if (this.userInformation) {
            if (this.userInformation.get('newsletter')){
                return 'generic.yes';
            } else {
                return 'generic.no';
            }
        }

        return 'generic.to-complete';
    }

    ifEmptyValue(val): string{
        if (this.userInformation && this.userInformation.get(val)){
            return this.userInformation.get(val);
        }
        return 'generic.to-complete';
    }

    displayField(name: string): boolean {
        const role = this.authenticationService.accessLevel;

        let fields = this.settings.fields[role];
        if (fields === undefined) {
            fields = this.settings.fields['default'];
        }

        return fields.indexOf(name) > -1;
    }

    public deleteCurrentAccount(): void {
        this.dialog
            .open(ProfileDeleteConfirmModalComponent);
    }

    public canDeleteCurrentAccount(): boolean {
        return this.profileProvider.settings['canSelfDelete'].includes(this.authenticationService.accessLevel);
    }
}
