import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import { CorpusListComponent } from './components/corpus-list/corpus-list.component';
import {RouterModule, Routes} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {IsUserLogged} from '../../../is-user-logged.class';
import { CorpusDisplayComponent } from './components/corpus-display/corpus-display.component';
import { CorpusRootComponent } from './components/corpus-root/corpus-root.component';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {CorpusDetailsSidenavComponent} from '@modules/corpus/core/components/corpus-sidenavs/corpus-details/corpus-details.component';
import {CorpusMainSidenavComponent} from '@modules/corpus/core/components/corpus-sidenavs/corpus-main/corpus-main.component';
import {CorpusFileListComponent} from '@modules/corpus/core/components/corpus-file-list/corpus-file-list.component';
import { FuseSharedModule } from '@fuse/shared.module';
import {LayoutModule} from '../../../layout/layout.module';
import { ResourceUploadModalComponent } from './components/resource-upload-modal/resource-upload-modal.component';
import { ResourceUploadModalStep1Component } from './components/resource-upload-modal-step1/resource-upload-modal-step1.component';
import { ResourceUploadModalStep2Component } from './components/resource-upload-modal-step2/resource-upload-modal-step2.component';
import { ResourceUploadModalErrorComponent } from './components/resource-upload-modal-error/resource-upload-modal-error.component';
import {FileDropModule} from 'ngx-file-drop';
import { ResourceEditionModalComponent } from './components/resource-edition-modal/resource-edition-modal.component';
import {CorpusMenuResolve} from '@modules/corpus/core/corpus-menu.resolve';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {MatPaginatorIntl, MatProgressSpinnerModule} from '@angular/material';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import { ExternalCorpusDisplayComponent } from './components/external-corpus-display/external-corpus-display.component';
import {SharedModule} from '../../../shared/shared.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {DividerUploadModalComponent} from '@modules/corpus/core/components/divider-upload-modal/divider-upload-modal.component';
import {ChaptersModule} from 'fuse-core/components/chapters-selection/chapters.module';
import {TagsModule} from 'fuse-core/components/tags-selection/tags.module';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {userCorpusTest} from './is-user-corpus.class';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { CorpusFavoritesComponent } from './components/widget/corpus-favorites/corpus-favorites.component';
import {DatacardService} from '../../../shared/datacard.service';
import {CorpusConsultedComponent} from '@modules/corpus/core/components/widget/corpus-consulted/corpus-consulted.component';
import {CorpusCreatedComponent} from '@modules/corpus/core/components/widget/corpus-created/corpus-created.component';
import { ResourceUploadModalStep3Component } from './components/resource-upload-modal-step3/resource-upload-modal-step3.component';
import { ResourceUploadModalStep4Component } from './components/resource-upload-modal-step4/resource-upload-modal-step4.component';
import { ChapterSelectorComponent } from './components/resource-upload-modal-step3/chapter-selector/chapter-selector.component';
import { ChapterRecapComponent } from './components/resource-upload-modal-step3/chapter-recap/chapter-recap.component';
import {SearchFiltersModule} from 'fuse-core/components';

const routes: Routes = [
    {
        path     : 'corpus',
        component: CorpusRootComponent,
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: CorpusListComponent
            },
            {
                path: ':id',
                component: CorpusDisplayComponent,
                resolve: {
                    menu: CorpusMenuResolve
                },
            },
            {
                path: 'pdf/:id',
                component: CorpusDisplayComponent,
                resolve: {
                    menu: CorpusMenuResolve
                },
            },
            {
                path: 'community/:uid',
                component: CorpusDisplayComponent,
                resolve: {
                    menu: CorpusMenuResolve
                },
                canActivate: [userCorpusTest],
            },
            {
                path: 'user/:uid',
                component: CorpusDisplayComponent,
                resolve: {
                    menu: CorpusMenuResolve
                },
                canActivate: [userCorpusTest],
            }
        ],
        resolve  : {
            files: CorpusService
        }
    }
];

const settingsStructure: ModelSchema = new ModelSchema({
    accessMatrix: Structures.object()
});

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        LayoutModule,
        FuseSharedModule,
        SharedModule,
        MatButtonToggleModule,
        FileDropModule,
        MatProgressSpinnerModule,
        ChaptersModule,
        TagsModule,
        CKEditorModule,
        SearchFiltersModule,
    ],
    declarations: [
        ChapterRecapComponent,
        ChapterSelectorComponent,
        CorpusConsultedComponent,
        CorpusCreatedComponent,
        CorpusDetailsSidenavComponent,
        CorpusDisplayComponent,
        CorpusFavoritesComponent,
        CorpusFileListComponent,
        CorpusListComponent,
        CorpusMainSidenavComponent,
        CorpusRootComponent,
        DividerUploadModalComponent,
        ExternalCorpusDisplayComponent,
        ResourceEditionModalComponent,
        ResourceUploadModalComponent,
        ResourceUploadModalErrorComponent,
        ResourceUploadModalStep1Component,
        ResourceUploadModalStep2Component,
        ResourceUploadModalStep3Component,
        ResourceUploadModalStep4Component
    ],
    providers: [
        DatacardService,
        userCorpusTest
    ],
    entryComponents: [
        CorpusConsultedComponent,
        CorpusCreatedComponent,
        CorpusDisplayComponent,
        CorpusDetailsSidenavComponent,
        CorpusFavoritesComponent,
        CorpusFileListComponent,
        CorpusListComponent,
        CorpusMainSidenavComponent,
        CorpusRootComponent,
        DividerUploadModalComponent,
        ExternalCorpusDisplayComponent,
        ResourceEditionModalComponent,
        ResourceUploadModalComponent,
        ResourceUploadModalStep1Component,
        ResourceUploadModalStep2Component,
        ResourceUploadModalErrorComponent
    ],
    exports: [
        CorpusDisplayComponent,
        CorpusDetailsSidenavComponent,
        CorpusFileListComponent,
        CorpusListComponent,
        CorpusMainSidenavComponent,
        CorpusRootComponent,
        DividerUploadModalComponent,
        ExternalCorpusDisplayComponent,
        ResourceEditionModalComponent,
        ResourceUploadModalComponent,
        ResourceUploadModalErrorComponent,
        ResourceUploadModalStep1Component,
        ResourceUploadModalStep2Component,
    ]
})

export class CorpusModule {
    private static isMenuSet = false;
    public settings: {[key: string]: any};

    constructor(
        private corpusService: CorpusService,
        private mediaUploadService: MediaUploadService,
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusDisplayComponent', CorpusDisplayComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusFavoritesComponent', CorpusFavoritesComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusConsultedComponent', CorpusConsultedComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusCreatedComponent', CorpusCreatedComponent);

        this.communicationCenter
            .getRoom('corpus')
            .next('corpusService', this.corpusService);

        this.communicationCenter
            .getRoom('corpus')
            .next('openDividerCreationModal', (item: DataEntity) => this.mediaUploadService.openDividerCreationModal(item));

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Corpus',
                type: 'NEW_RESSOURCE_IN_RESEARCH_CORPUS',
                icon: 'business',
                text: 'corpus.resource_research',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['userName'],
                        projectName: datas['projectName']
                    };
                },
                action: (data: Object) => {
                    return ['projects', data['projectId'], 'tools', 'corpus', data['corpusId']];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Corpus',
                type: 'NEW_RESSOURCE_IN_COURSE_CORPUS',
                icon: 'business',
                text: 'corpus.resource_course',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['userName'],
                        projectName: datas['projectName']
                    };
                },
                action: (data: Object) => {
                    return ['projects', data['projectId'], 'tools', 'corpus', data['corpusId']];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Corpus',
                type: 'NEW_RESSOURCE_IN_GLOBAL_CORPUS',
                icon: 'business',
                text: 'corpus.resource_global',
                textTransform: (text: string, datas: Object) => {
                    return {
                        userName: datas['userName'],
                        projectName: datas['projectName']
                    };
                },
                action: (data: Object) => {
                    return ['corpus', data['corpusId']];
                }
            });

        this.settings = settingsStructure.filterModel(modulesSettings.corpus);
    }

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: CorpusModule,
            providers: [
                CorpusService,
                CorpusMenuResolve,
                MediaUploadService,
                {provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl}
            ]
        };
    }

    private postLogout(): void{
        CorpusModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'corpus');
    }

    private postAuthentication(): void {
        if (!CorpusModule.isMenuSet && this.corpusService.settings.globalCorpus && this.settings && this.authService.hasLevel(this.settings.accessMatrix['global']['view'])) {

            // console.log('this.corpusService.settings', this.corpusService.settings);

            if (!this.corpusService.settings.userCorpus){ // check if there is a user corpus in addition to general corpus
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id'   : 'corpus',
                    'title': 'Corpus',
                    'translate': 'corpus.title',
                    'type' : 'item',
                    'icon' : 'corpus',
                    'url'  : '/corpus/' + this.corpusService.settings.globalCorpus
                });
            } else {
                const children = [
                    {
                        'id'   : 'corpus-global',
                        'title': 'Ressources Lamorim',
                        'translate': 'corpus.title.generic',
                        'type' : 'item',
                        'url'  : '/corpus/' + this.corpusService.settings.globalCorpus
                    },
                    {
                        'id'   : 'corpus-user',
                        'title': 'Mes ressources',
                        'translate': 'corpus.title.user',
                        'type' : 'item',
                        'url'  : '/corpus/user/' + this.corpusService.currentUser.id,
                    },
                ];
                if (this.corpusService.settings.shareableResourceToCommunity){
                    children.push({
                        'id'   : 'corpus-community',
                        'title': 'Ressources de la communité',
                        'translate': 'corpus.corpus_community',
                        'type' : 'item',
                        'url'  : '/corpus/community/' + this.corpusService.settings.globalCorpus,
                    });
                }
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id'   : 'corpus',
                    'title': 'Corpus',
                    'translate': 'corpus.title',
                    'type' : 'collapse',
                    'icon' : 'corpus',
                    'children': children
                });
            }

            this.dynamicNavigation.registerModuleMenu('level0', {
                'id'   : 'corpusPdf',
                'title': 'CorpusPdf',
                'translate': 'corpus.title.pdf',
                'type' : 'item',
                'icon' : 'corpus_pdf',
                'url'  : '/corpus/pdf/' + this.corpusService.settings.globalCorpus
            });

            CorpusModule.isMenuSet = true;
        }
    }
}
