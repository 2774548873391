import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DynamicNavigationService} from './dynamic-navigation.service';
import {FuseNavigationModule} from 'fuse-core/components/navigation/navigation.module';

@NgModule({
    imports: [
        CommonModule,
        FuseNavigationModule
    ],
    declarations: [],
    providers: [

    ]
})
export class NavigationModule {

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: NavigationModule,
            providers: [
                DynamicNavigationService
            ]
        };
    }
}
