import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ResearchSectionTemplate, ResearchTemplate} from '../../definitions';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchSheetService} from '../../research-sheet.service';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'app-section-choice-item',
    templateUrl: './section-choice-item.component.html',
    styleUrls: ['./section-choice-item.component.scss']
})
export class SectionChoiceItemComponent implements OnInit {

    @Input('tmpl') tmpl: ResearchTemplate;
    @Input('template') template: ResearchSectionTemplate;
    @ViewChild('titleInput') titleInput: ElementRef;
    title: string;
    titleEdition = false;

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement): void {
        const clickedInside = this.titleInput.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.stopEditionTest();
        }
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private element: ElementRef,
        private sheetService: ResearchSheetService
    ) { }

    ngOnInit(): void {
        this.title = this.template.name;
    }

    editTitle(evt: MouseEvent, stopEvents: boolean = false): void {
        if (this.isMine()) {
            this.titleEdition = true;

            setTimeout(() => {
                this.titleInput.nativeElement.focus();
            });

            if (stopEvents) {
                evt.preventDefault();
                evt.stopPropagation();
            }
        }
    }

    isMine(): boolean {
        if (this.tmpl) {
            const templateEntity: DataEntity = this.sheetService.templatesCollection[+this.tmpl.id];
            return this.sheetService.isMine(+templateEntity.get('uid'));
        } else {
            return false;
        }
    }

    getSectionInitials(section: ResearchSectionTemplate): string {
        return section.name.charAt(0).toUpperCase() + (section.name.charAt(1) ? section.name.charAt(1).toLowerCase() : '');
    }

    stopEditionTest(): void {
        if (this.titleEdition) {
            this.titleEdition = false;

            const sectionEntity: DataEntity = this.sheetService.templatesSectionsCollection[this.template.id];
            sectionEntity.set('name', this.title);

            // En attendant de trouver mieux
            this.template.name = this.title;

            sectionEntity.save();
        }
    }

    sectionAction(section: ResearchSectionTemplate): void {
        if (this.isMine()) {
            this.editSection(section);
        } else {
            this.viewSection(section);
        }
    }

    viewSection(section: ResearchSectionTemplate): void {
        this.router.navigate(['view', section.id], {
            relativeTo: this.route
        });
    }

    editSection(section: ResearchSectionTemplate): void {
        this.router.navigate(['edit', section.id], {
            relativeTo: this.route
        });
    }
}
