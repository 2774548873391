import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataVisualizationService} from './data-visualization.service';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {Subscription} from 'rxjs/Subscription';
import {Subject} from 'rxjs/index';
import {Group, Learner} from '@modules/groups-management/core/definitions';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {localizedDate} from '../../../shared/utils';
import {FormControl} from '@angular/forms';
import {DateAdapter} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import Chart from 'chart.js';

declare var ADL: any;

@Component({
  selector: 'app-data-visualization',
  templateUrl: './data-visualization.component.html',
  styleUrls: ['./data-visualization.component.scss']
})
export class DataVisualizationComponent implements OnInit, OnDestroy {

  //TEST//
  public ctx = null;
  public myChart: Chart;
  /////
  private optionsInterface: CollectionOptionsInterface = {
    filter: {},
    page: 1,
  };
  assignmentGroupListSubscription: Subscription;
  private unsubscribeInTakeUntil = new Subject();

  public displayedFilters: string[] = [];

  assignmentGroupList: any = [];
  learnersList: any[] = [];
  groupsList: any[] = [];
  public allTypes: any[] = [];

  graphConfigObs: Subject<any> = new Subject();
  graphOptions: any;
  averageLearnerGrade = '';
  averageGroupGrade = '';

  /* Filters Form */
  startDateControl = new FormControl('');
  dueDateControl = new FormControl('');
  groupControl = new FormControl('');
  typeControl = new FormControl('');
  learnerControl = new FormControl('all');

  urlImgProfile: string;
  isLearnerInformationShowed = false;

  noData = '';
  minDueDate: any;
  maxStartDate: any;
  chartOptions = {
    responsive: true,
    event: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontSize: 12,
        boxWidth: 40,
        usePointStyle: false, // permet aux labels d'avoir une forme corrspondante aux style des point sur le graph
      }
    },
    title: {
      display: true,
      text: 'resultats moyenne'
    },
    scale: {
      ticks: {
        beginAtZero: true,
        max: 20
      }
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    },
    tooltips: {
      enabled: true,
      // Tooltip Element
      custom: (tooltipModel) => {

      },
      callbacks: {
      }
    },
  }

  ready: boolean;

  chartData = [];
  chartLabels = [];
  chartColors = [];

  chartType: string = 'radar';
  legend = true;
  

  constructor(
    private graphService: DataVisualizationService,
    private octopusConnect: OctopusConnectService,
    private adapterLocalDate: DateAdapter<any>,
    private translate: TranslateService
  ) {
    // Tests to access the LRS, get and send data
        let conf = {
            'endpoint' : 'https://iboost.lrs.tralalere.com/data/xAPI/',
            'auth' : 'Basic YWVlYjIxMmM5NTY2ZGZmZmU2ODQ5YjE2Zjc1YzIxZTNjYWFiY2RiODo5ZWFhNWMyNGQwOGFjZDExNDM5MWY2Y2E4YTZkYmY5YjNmMGMzYTEw',
        };
        ADL.XAPIWrapper.changeConfig(conf);
    console.log("Get Statements");
      ADL.XAPIWrapper.getStatements(null, null,
        function (r) {console.log(JSON.parse(r.response).statements)});
    console.log("Get Verb");
      ADL.XAPIWrapper.getStatements({"verb":"http://adlnet.gov/expapi/verbs/launched"}, null,
        function (r) {console.log(r)});
      console.log("Get Activities");
      ADL.XAPIWrapper.getActivities("http://adlnet.gov/expapi/assignment/3807",
         function (r) {console.log(r);})
    //
    this.startDateControl.valueChanges.subscribe((data) => {
      if (data) {
        this.applyFilters(data.format('X'), 'date_from');
        this.minDueLimit = new Date(+data.format('x'));
      } else {
        this.applyFilters(null, 'date_from');
        this.minDueLimit = null;
      }
    });


  
    this.dueDateControl.valueChanges.subscribe((data) => {
      if (data) {
        const newDate = new Date(+data.format('x'));
        newDate.setHours(23);
        newDate.setMinutes(59);
        this.applyFilters(newDate.getTime() / 1000, 'date_to');
        this.maxStartLimit = new Date(+data.format('x'));
      } else {
        this.applyFilters(null, 'date_to');
        this.maxStartLimit = null;
      }
    });

    this.typeControl.valueChanges.subscribe((data) => {
      if (data) {
        this.applyFilters(data.id, 'type_term');
      } else {
        this.applyFilters(null, 'type_term');
      }
    });

    this.adapterLocalDate.setLocale(this.translate.currentLang);

    this.translate.onLangChange.subscribe(() => {
      this.adapterLocalDate.setLocale(this.translate.currentLang);

    });

    this.groupControl.valueChanges.subscribe((data) => {
      if (data) {
        this.learnersList = [...this.learners.filter((learner) => learner.groups.indexOf(data.groupname) !== -1)];
        this.learnerControl.setValue('all');
        this.applyFilters(data.id, 'groups');
      } else {
        this.applyFilters(null, 'groups');
      }

    });
  }

  ngOnInit(): any {
    this.translate
      .get('graph-assignation.no-data')
      .subscribe((translation: string) => this.noData = translation);

    if (this.graphService.graphSettings) {
      if (this.isUserTrainer) {
        this.displayedFilters = this.graphService.graphSettings['default'];
      } else {
        this.displayedFilters = this.graphService.graphSettings['learner'];
      }
    }
    this.assignmentGroupListSubscription = this.refreshList();

    this.graphService.loadAssignationsGroupTypes().subscribe(types => {
      this.allTypes = types;
    });
  }

  refreshList(): Subscription {
    return this.assignmentGroupListSubscription = this.graphService.loadPaginatedAssignmentGroupList(this.optionsInterface)
      .takeUntil(this.unsubscribeInTakeUntil)
      .subscribe((assignmentGroupList: DataEntity[]) => {
        this.assignmentGroupList = assignmentGroupList;

        this.graphService.setAssignments = this.assignmentGroupList;
        if (!this.assignmentGroupList) {
          return;
        }

        if (!this.groupControl.value) {
          this.groupsList = this.groups;
          this.learnersList = this.learners;
        }
        this.setConfig();
      });
  }

  prepareForRefresh(): void {
    this.resetTakeUntil();
    this.generate();
  }

  public displayFilters(name: string): boolean {
    return this.displayedFilters.includes(name);
  }

  applyFilters(val, type): void {
    if (val === 'all' || !val) {
      delete this.optionsInterface.filter[type];
    } else {
      this.optionsInterface.filter[type] = val;
    }
  }

  setConfig(): any {
 
    this.chartLabels.push(...this.assignmentGroupList.map((entity) => entity.get('assignated_node').title).slice(0, 5));
    const assignments = {
      data: this.assignmentGroupList.map((entity) => entity.get('grade')).slice(0,5),
      //data: [15, 19, 7, 14, 2],
      label: ['Progression'],
    };
    this.chartData.push(assignments)

    this.ready = true;
    // this.graphConfigObs.next(this.graphOptions);
  }

  public getStyle(data): any {
    const style = {
      homework: '#24B7C7',
      assessment: '#006D86',
      default: '#A9A9A9'
    };
    return style[data] ? style[data] : style.default;
  }

  public get groups(): Array<Group> {
    if (this.graphService.groupsList) {
      return this.graphService.groupsList;
    }

    return [];
  }

  public get learners(): Array<Learner> {
    if (this.graphService.learnersList) {
      return this.graphService.learnersList;
    }

    return [];
  }

  public get groupsFiltered(): Array<Group> {
    if (this.groupsList) {
      return this.groupsList;
    }

    return this.groups;
  }

  public get learnersFiltered(): Array<Learner> {
    if (this.learnersList) {
      return this.learnersList;
    }

    return this.learners;
  }

  public set setAverageLearner(data) {
    this.averageLearnerGrade = this.calculateResult(+data) + ' / 20';

  }
  public set setAverageGroup(data) {
    this.averageGroupGrade = this.calculateResult(+data) + ' / 20';
  }

  calculateResult(data): number {
    if (data > 0) {
      return Math.round(+data * 100) / 100;
    } else {
      return 0;
    }
  }

  public get averageLearner(): string {
    return this.averageLearnerGrade ? this.averageLearnerGrade : this.noData;
  }
  public get averageGroup(): string {
    return this.averageGroupGrade ? this.averageGroupGrade : this.noData;
  }

  public get validGraph(): boolean {
    return this.groupControl.value || this.learnerControl.value;
  }

  public generate(): void {
    if (this.assignmentGroupListSubscription) {
      this.assignmentGroupListSubscription.unsubscribe();
    }
    this.assignmentGroupListSubscription = this.refreshList();
  }

  public get typeList(): any[] {
    return this.allTypes;
  }

  public localizedType(type: string): string {
    return `assignment.type.${type}`;
  }

  resetTakeUntil(): void {
    if (this.unsubscribeInTakeUntil) {
      this.unsubscribeInTakeUntil.next();
      this.unsubscribeInTakeUntil.complete();
    }
    this.unsubscribeInTakeUntil = new Subject();
  }

  public get showAverageLabel(): boolean {
    return !this.graphOptions ||
      !this.graphOptions['chartData'] ||
      this.graphOptions['chartData'].length === 0;
  }

  public get isUserTrainer(): boolean {
    return this.graphService.isUserTrainer;
  }

  public set minDueLimit(data) {
    this.minDueDate = data;
  }

  public set maxStartLimit(data) {
    this.maxStartDate = data;
  }

  public get dateLimit(): any {
    return this.minDueDate;
  }

  public get maxDateLimit(): any {
    return this.maxStartDate;
  }

  ngOnDestroy(): any {
    this.unsubscribeInTakeUntil.next();
    this.unsubscribeInTakeUntil.complete();
  }

  private resetAvatar(): void {
    this.urlImgProfile = null;

    // On part du principe que si le User n'est pas Trainer, c'est qu'il est le learner donc le selectedLearner
    if (this.isUserTrainer === false) {
      this.urlImgProfile = this.learnerControl.value.attributes.picture;
    } else if (this.learnerControl.value) {
      this.urlImgProfile = this.learnerControl.value.avatar;
    }
  }
}
