import { Component, OnInit, EventEmitter, Input, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Output } from '@angular/core';
import { GamificationService } from '../gamification.service';
import { DataEntity } from 'octopus-connect';
import { Creature, BadgeType } from '../definitions';

@Component({
  selector: 'app-accessories-popup',
  templateUrl: './accessories-popup.component.html',
  styleUrls: ['./accessories-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccessoriesPopupComponent implements OnInit {
  isSaveButtonActive = false;
  private _origUniverses = new Array<DataEntity>();
  private _universes = new Array<{ id: string | number, attributes: any }>();
  private _origAccessories = new Array<DataEntity>();
  private _accessories = new Array<{ id: string | number, attributes: any }>();
  private isLoading = false;

  @Output()
  saveUserImage = new EventEmitter<boolean>();

  @Output()
  update = new EventEmitter<Array<DataEntity> | null>();

  private _creature: Creature;
  @Input()
  set creature(creature: Creature) {
    this._creature = creature;
  }

  get creature(): Creature {
    return this._creature;
  }

  @Input()
  set accessories(accessories: Array<DataEntity>) {
    this._origAccessories = accessories;
    this.resetAccessoriesDifferences();
  }

  get accessories(): Array<DataEntity> {
    return this._accessories as Array<DataEntity>;
  }

  @Input()
  set universes(universes: Array<DataEntity>) {
    this._origUniverses = universes;
    this.resetUniversesDifferences();
  }

  get universes(): Array<DataEntity> {
    return this._universes as Array<DataEntity>;
  }

  constructor(
    private gamificationService: GamificationService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {}

  switchTab(tabName: 'accessories' | 'universes'): void {
    this.gamificationService.activeTab = tabName;
  }

  openPopup(): void {
    this.gamificationService.isShowPopup = true;
  }

  closePopup(): void {
    if (this.isLoading) {
      return;
    }
    this.gamificationService.isShowPopup = false;
    this.gamificationService.activeTab = 'accessories';
    this.gamificationService.accessories = {};
    this.resetAccessoriesDifferences();
    this.resetUniversesDifferences();
    this.emitUpdate();
  }

  onAccessoryClick(item: DataEntity): void {
    if (this.isLoading) {
      return;
    }
    if (item.attributes.unLocked === true) {
      item.attributes.selected = true;
      if (item && item.attributes.stuffType) {
        this.gamificationService.accessories[item.attributes.stuffType.name] = item;
      }
      this.accessories.filter(ac =>
        ac.id !== item.id &&
        (!ac.attributes.stuffType && !item.attributes.stuffType || ac.attributes.stuffType.id === item.attributes.stuffType.id)
      ).forEach(ac => ac.attributes.selected = false);
      this.emitUpdate();
      this.updateAreButtonActive();
    } else if (this.gamificationService.userPoints >= item.attributes.price) {
      this.gamificationService.openBuyPopup(item, (res) => {
      });

    }
  }

  onUniverseClick(item: DataEntity): void {
    if (this.isLoading) {
      return;
    }
    if (item.attributes.unLocked === true && item.attributes.selected === false) {
      item.attributes.selected = true;
      this.gamificationService.selectedUniverse = item;
      this.universes.filter(u =>
        u.id !== item.id
      ).forEach(u => u.attributes.selected = false);
      this.emitUpdate();
      this.updateAreButtonActive();
    } else if (item.attributes.unLocked === false && this.gamificationService.userPoints >= item.attributes.price) {
      this.gamificationService.openBuyPopup(item, (res) => {
      });

    }
  }

  emitUpdate(): void {
    const elements = [].concat(...this.accessories.filter(ac => ac.attributes.selected) as any, this.universes.find(u => u.attributes.selected) as any)
      .filter((element) => !!element);
    this.update.emit(elements);
  }

  updateAreButtonActive(): void {
    this.isSaveButtonActive = this.getDifferences().length > 0;
  }

  getDifferences(): DataEntity[] {
    return [
      this.universes.find(u => u.attributes.selected === true && u.id !== this.creature.creature.attributes.backgroundBadge),
      ...this._origAccessories.filter(ac => !!this.accessories.find(oa => oa.id === ac.id && oa.attributes.selected !== ac.attributes.selected))]
      .filter(e => e);
  }
  reset(): void {
    if (this.isLoading || !this.isResetButtonActive) {
      return;
    }
    this.accessories.forEach(ac => ac.attributes.selected = false);
    this.updateAreButtonActive();
    this.update.emit([this.universes.find(u => u.attributes.selected === true)]);
  }

  private resetAccessoriesDifferences(): void {
    if (this.isLoading) {
      return;
    }
    this._accessories = [...this._origAccessories.map(ac => {
      const accessorie = { id: ac.id, attributes: { ...ac.attributes } };
      const accessorieInCache = this.gamificationService.accessories[ac.attributes.stuffType.name];
      if (accessorieInCache && accessorieInCache.id === accessorie.id) {
          this.isSaveButtonActive = true;
          accessorie.attributes['selected'] = true;
      }
      return accessorie;
    })];
  }

  private resetUniversesDifferences(): void {
    if (this.isLoading) {
      return;
    }
    this._universes = [...this._origUniverses.map(ac => {
      let isSelected = ac.attributes.selected;
      if (this.selectedUniverse) {
        isSelected = this.selectedUniverse.id === ac.id;
      }

      return {
        id: ac.id,
        attributes: {
          ...ac.attributes,
          selected: isSelected
        }
      };
    })];
  }

  get isResetButtonActive(): boolean {
    return !!this.accessories.find(ac => ac.attributes.selected === true);
  }

  save(): void {
    this.gamificationService.accessories = {};
    if (this.isLoading) {
      return;
    }
    if (!this.isSaveButtonActive) {
      return;
    }
    this.isLoading = true;
    this.isSaveButtonActive = false;
    Promise.all(this.getDifferences().map(e => {
      if (e.attributes.type.name === BadgeType.Accessoire) {
        return this.gamificationService.setSelectedCreatureOrAccessory(this._origAccessories, e, !e.attributes.selected);
      } else if (e.attributes.type.name === BadgeType.Univers) {
        return this.gamificationService.setSelectedUniverse(this.creature, e);
      }
    })).then(res => {
      this.isLoading = false;
      this.resetAccessoriesDifferences();
      this.resetUniversesDifferences();
      this.saveUserImage.emit(false);
    }).catch(err => {
      this.isLoading = false;
      this.resetAccessoriesDifferences();
      this.resetUniversesDifferences();
      console.log(err);
    });
  }

    public get showPopup(): boolean {
        return this.gamificationService.isShowPopup;
    }
    public get activeTab(): 'accessories' | 'universes' {
        return this.gamificationService.activeTab;
    }
    public get selectedUniverse(): DataEntity {
        return this.gamificationService.selectedUniverse;
    }

}
