export const accountManagement = {
    allowedMaxSize: 1,
    canEditAvatar: ['learner', 'trainer', 'manager', 'administrator'],
    canSelfDelete: ['trainer'],
    displayDialogMinorAdult: false,
    fields: {
        manager: ['email', 'password', 'picture'],
        default: ['label', 'email', 'password', 'institution', 'you_are', 'find_us', 'picture']
    },
    selfSignup: true,
    youAreValues: ['teacher', 'district', 'other']
};
