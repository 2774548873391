import {DataEntity} from 'octopus-connect';
import {GamificationService} from './gamification.service';

export class CreatureCollection {

    private _gamificationService: GamificationService;

    creaturesStatus: 'loading' | 'loaded' | 'not-loaded';
    universesStatus: 'loading' | 'loaded' | 'not-loaded';
    creatures: Array<Creature>;
    universes: Array<DataEntity>;


    constructor(gamificationService: GamificationService) {
        this._gamificationService = gamificationService;
        this.creaturesStatus = 'not-loaded';
        this.universesStatus = 'not-loaded';
        this.creatures = new Array<Creature>();
        this.universes = new Array<DataEntity>();
    }

    /**
     * Loads collection if not loaded
     */
    loadCreatures(force?: boolean): void {
        if (this.creaturesStatus === 'not-loaded' || force) {
            this.creaturesStatus = 'loading';
            this._gamificationService.getBadges(BadgeType.Creature).subscribe(animals => {
                this.creatures = animals.entities.map(creature => {
                    return new Creature(this._gamificationService, creature, this);
                });
                this.creaturesStatus = 'loaded';
            });
        }
    }

    /**
     * Loads universes if not loaded
     */
    loadUniverses(force?: boolean, openEditPopup = false): void {
        if (this.universesStatus === 'not-loaded' || force) {
            this.universesStatus = 'loading';
            this._gamificationService.getBadges(BadgeType.Univers).subscribe(universes => {
                this.universes = universes.entities;
                this.universesStatus = 'loaded';
                if (openEditPopup) {
                    this._gamificationService.activeTab = 'universes';
                }
            });
        }
    }

    updateBadge(res: DataEntity, forceReload: boolean = false, openEditPopup = false): void {
        if (res.attributes.type.name === BadgeType.Accessoire) {
            const creatureIndex = this.creatures.findIndex(cr => !!res.attributes.parent.find(p => p === cr.creature.id));
            if (creatureIndex >= 0) {
                const accessoryIndex = this.creatures[creatureIndex].accessories.findIndex(ac => ac.id === res.id);
                if (accessoryIndex >= 0) {
                    this.creatures[creatureIndex].accessories[accessoryIndex] = res;
                }
                this.creatures[creatureIndex].loadAccessories(forceReload, openEditPopup);
            }
        } else if (res.attributes.type.name === BadgeType.Creature) {
            const creatureIndex = this.creatures.findIndex(cr => cr.creature.id === res.id);
            if (creatureIndex >= 0) {
                this.creatures[creatureIndex].creature = res;
            }
            this.loadCreatures(forceReload);
        } else if (res.attributes.type.name === BadgeType.Univers) {
            const universeIndex = this.universes.findIndex(u => u.id === res.id);
            if (universeIndex >= 0) {
                if (openEditPopup) {
                    this._gamificationService.selectedUniverse = res;
                }
                this.universes[universeIndex] = res;
            }
            this.loadUniverses(forceReload, openEditPopup);
        }
    }
}

export class Creature {

    private _gamificationService: GamificationService;
    private _collection: CreatureCollection;

    creature: DataEntity;
    status: 'loading' | 'loaded' | 'not-loaded';
    accessories: Array<DataEntity>;

    constructor(gamificationService: GamificationService, creature: DataEntity, collection: CreatureCollection) {
        this.creature = creature;
        this._collection = collection;
        this._gamificationService = gamificationService;
        this.status = 'not-loaded';
        this.accessories = new Array<DataEntity>();
    }

    /**
     * Loads collection if not loaded
    */
    loadAccessories(force?: boolean, openEditPopup = false): void {
        if (this.status === 'not-loaded' || force) {
            this.status = 'loading';
            this._gamificationService.getBadges(BadgeType.Accessoire, this.creature.id.toString()).subscribe(accessories => {
                this.accessories = accessories.entities.map((entity) => {
                    const accessorieInCache = this._gamificationService.accessories[entity.get('stuffType').name];
                    if (accessorieInCache && accessorieInCache.id === entity.id) {
                        entity.set('selected', true);
                    }
                    return entity;
                });
                this.status = 'loaded';
                this._gamificationService.isShowPopup = openEditPopup;
            });
        }
    }

    get universes(): Array<DataEntity> {
        const universes = this._collection.universes.map(u => {
            u.attributes.selected = this.creature.attributes.backgroundBadge === u.id;
            return u;
        });
        if (universes.filter(u => u.attributes.selected === true).length === 0) {
            const firstUnlockedUniverseIndex = universes.findIndex(u => u.attributes.unLocked === true);
            if (firstUnlockedUniverseIndex >= 0) {
                universes[firstUnlockedUniverseIndex].attributes.selected = true;
            }
        }
        return universes;
    }
}

export enum BadgeType {
    Creature = 'créature',
    Accessoire = 'accessoire',
    Univers = 'univers'
}
