import {Component, OnDestroy, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {MatDialog} from '@angular/material/dialog';
import {LessonMetadataDialogComponent} from '@modules/activities/core/lessons/lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {DataEntity, OrderDirection, OctopusConnectService} from 'octopus-connect';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-lessons-created',
    templateUrl: './lessons-created.component.html',
    styleUrls: ['./lessons-created.component.scss']
})
export class LessonsCreatedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private optionsInterface: CollectionOptionsInterface;
    private type = 'byRole';
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        public lessonsService: LessonsService,
        private activitiesService: ActivitiesService,
        private datacardService: DatacardService,
        private dialog: MatDialog,
        private octopusConnect: OctopusConnectService,
    ) {
        this.optionsInterface = {
            filter: {},
            orderOptions: [
                {field: 'created', direction: OrderDirection.DESC}
            ],
            page: 1,
            range: 6
        };
    }

    ngOnInit(): void {
        const callbacks = {
            'isEditableAndErasable': (resource) => this.datacardService.isEditableAndErasable(resource, this.type),
            'openAssign': (resource) => this.openAssign(resource),
            'openDialog': (resource) => this.lessonsService.openDialog(resource),
            'openDuplicate': (id, action, type) => this.lessonsService.launchEditor(id, 'copy', 'lesson'),
            'openEditor': (id, action, type) => this.lessonsService.launchEditor(id, 'edit', 'lesson'),
            'openMetaDialog': (event, metadata) => this.openMetaDialog(event, metadata),
            'play': (resource) => this.lessonsService.navigateToLesson(resource)
        };

        this.lessonsService.loadPaginatedLessons(this.type, this.lessonsService.getAllowedRoleIdsForModelsCreation(), '', this.optionsInterface)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(resources => {
                if (!resources) {
                    return;
                }

                this.dataCards = this.datacardService.processResources(resources, callbacks, this.type);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private openMetaDialog(e, metadatas): void {
        e.stopPropagation();
        this.dialog.open(LessonMetadataDialogComponent, {
            data: {
                metadatas
            }
        });
    }

    private openAssign(lesson): void {
        if (this.activitiesService.assignmentView) {
            const dialogRef = this.dialog.open(this.activitiesService.assignmentView, {
                data: {
                    nodeId: lesson.id
                }
            });

            dialogRef.afterClosed().subscribe((data) => {
                if (data && (!lesson.get('locked') || lesson.get('locked') === '0')) {
                    const entity = new DataEntity('granule-lesson', lesson.attributes, this.octopusConnect, lesson.id);
                    entity.set('locked', true);
                    entity.save()
                        .subscribe(success => {
                            if (success) {
                                this.lessonsService.userLessonsPaginated.paginator.reload();
                            }
                        });
                }
            });
        }
    }
}
