import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from "@angular/router";

import {AccountManagementProviderService} from './account-management-provider.service';
import {AuthenticationService} from '@modules/authentication';
import {ProfileService} from "./profile/profile.service";

import {IsUserLogged} from "../../../is-user-logged.class";
import {OctopusConnectModule} from 'octopus-connect';
import {ReCaptchaModule} from "angular5-recaptcha";
import {FuseSharedModule} from "@fuse/shared.module";

import {FuseRegisterComponent} from "./register/register.component";
import {FuseProfileComponent} from "./profile/profile.component";
import {FuseProfileAboutComponent} from "./profile/tabs/about/about.component";
import {FuseFormsComponent} from "./profile/tabs/edit-profile/forms.component";
import {UploadFileComponent} from "./profile/upload-file/upload-file.component";

import {ValidateEmailModule} from '@modules/account-management/core/validate-email/validate-email.module';
import {FuseValidateEmailComponent} from '@modules/account-management/core/validate-email/validate-email.component';
import {FuseRequestSubscribeComponent} from '@modules/account-management/core/request-subscribe/request-subscribe.component';
import {FuseMailConfirmComponent} from '@modules/account-management/core/mail-confirm/mail-confirm.component';
import {SharedModule} from "../../../shared/shared.module";
import {ShowProfileComponent} from './profile/tabs/show-profile/show-profile.component';
import {LicensingModule} from '@modules/licensing';
import { ProfileDeleteConfirmModalComponent } from './profile/profile-delete-confirm-modal/profile-delete-confirm-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const routes = [
    {
        path: 'profile',
        canActivate: [IsUserLogged],
        component: FuseProfileComponent,
        children:
            [
                {path: '', redirectTo: 'show-profile', pathMatch: 'full'},
                {
                    path: 'show-profile', component: ShowProfileComponent,
                    resolve: {
                        profile: ProfileService
                    }
                },
                {
                    path: '', loadChildren: () => LicensingModule
                }
            ]
    },
    {
        path: 'editprofile',
        canActivate: [IsUserLogged],
        component: FuseFormsComponent
    },
    {
        path: 'register',
        component: FuseRegisterComponent,
    },
    {
        path: 'requestsubcribe',
        component: FuseRequestSubscribeComponent,
    },
    {
        path: 'user/email-validation/:token',
        component: FuseValidateEmailComponent,
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatProgressSpinnerModule,
        FuseSharedModule,
        SharedModule,
        ReCaptchaModule,
        ValidateEmailModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        FuseProfileComponent,
        ShowProfileComponent,
        FuseRegisterComponent,
        FuseProfileAboutComponent,
        FuseFormsComponent,
        UploadFileComponent,
        FuseRequestSubscribeComponent,
        FuseMailConfirmComponent,
        ProfileDeleteConfirmModalComponent
    ],
    entryComponents: [
        ProfileDeleteConfirmModalComponent
    ],
    providers: [

    ]
})
export class AccountManagementModule {

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: AccountManagementModule,
            providers: [
                AccountManagementProviderService,
                AuthenticationService,
                ProfileService
            ]
        };
    }
}
