import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResearchSheetService} from '../../research-sheet.service';
import {ResearchTemplateService} from '../research-template.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ResearchSectionTemplate, ResearchTemplate} from '../../definitions';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Subject, Subscription} from 'rxjs/index';

@Component({
    selector: 'app-edit-template-section',
    templateUrl: './edit-template-section.component.html',
    styleUrls: ['./edit-template-section.component.scss']
})
export class EditTemplateSectionComponent implements OnInit, OnDestroy {

    editedTemplate: ResearchTemplate;
    editedSection: ResearchSectionTemplate;
    editedSectionEntity: DataEntity;
    displayMode = 'edition';

    consigne: string;
    readingGrid: string;

    openings: Object[];
    feedbacks: Object[];

    private unsubscribeInTakeUntil = new Subject();
    routeSubscription: Subscription;

    // todo set hideFeedback with data from entity section
    hideFeedback = null;

    constructor(
        private sheetService: ResearchSheetService,
        private sectionService: ResearchTemplateService,
        private route: ActivatedRoute,
        private router: Router,
        private octopusConnect: OctopusConnectService
    ) { }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.displayMode = data['mode'];
        });

        this.sheetService.onTemplatesChanged
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((templates: ResearchTemplate[]) => {

            this.clearRouteSubscription();

            this.routeSubscription = this.route.params.subscribe((params: Params) => {

                if (templates) {
                    this.editedTemplate = templates.find(template => template.id === +params['sheetid']);

                    if (this.editedTemplate) {
                        this.editedSection = this.editedTemplate.sections.find(section => section.id === +params['sectionid']);
                        this.editedSectionEntity = this.sheetService.templatesSectionsCollection[this.editedSection.id];

                        this.hideFeedback = this.editedSectionEntity.get('hideFeedbacks');

                        if (this.editedSectionEntity.get('logicalLinkSet') && this.editedSectionEntity.get('logicalLinkSet')['logicalLinks']) {
                            this.openings = this.editedSectionEntity.get('logicalLinkSet')['logicalLinks'];
                        }
                        this.feedbacks = this.editedSection.draftQuestions;

                        if (this.editedSection) {
                            this.consigne = this.editedSectionEntity.get('consigne');
                            this.readingGrid = this.editedSectionEntity.get('readingGrids')['text'];
                        }
                    }

                }


            });
        });
    }

    temp(): void {

    }

    validateSection(): void {
        const sectionEntity: DataEntity = this.sheetService.templatesSectionsCollection[this.editedSection.id];
        sectionEntity.set('consigne', this.consigne);
        sectionEntity.set('hideFeedbacks', this.hideFeedback);
        sectionEntity.save();

        const gridEntity: DataEntity = new DataEntity('reading-grids', sectionEntity.get('readingGrids'), this.octopusConnect);
        gridEntity.set('text', this.readingGrid);

        // le code dans le subscribe est là pour palier à un défaut de la structure de données
        gridEntity.save().take(1).subscribe(() => this.editedSectionEntity.attributes['readingGrids']['text'] = this.readingGrid);
        this.cancelSection();
    }

    openTab(): void {

    }

    cancelSection(): void {
        this.router.navigate(['../..'], {
            relativeTo: this.route
        });
    }

    private clearRouteSubscription(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    }

    onSelectedChange(val): void{
        this.hideFeedback = val;
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.clearRouteSubscription();
    }
}
