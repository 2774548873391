import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {brand, customHomePageBanner} from 'app/settings';

const settingsStructure: ModelSchema = new ModelSchema(({}));
@Injectable({
    providedIn: 'root'
})
export class BasicPageService {

    public settings: {[key: string]: any};
    public brand: string = brand;
    public isCustomBanner: boolean = customHomePageBanner;

    constructor(
        private octopusConnect: OctopusConnectService
    ) {}

    public loadPage(alias: string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('pages', alias).take(1);
    }
}
