import {Injectable, QueryList} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs/Observable';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunicationCenterService} from '../../communication-center';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {MatCheckbox, MatDialog, MatDialogConfig} from '@angular/material';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {Subscription} from 'rxjs/Subscription';
import {IFormatIdentifier, LessonActivityRoutes} from '@modules/activities/core/models/lessonsActivityRoutes';
import {Subject} from 'rxjs/Subject';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {ReplaySubject} from 'rxjs';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';

declare var require: any;
const urlParser = require('js-video-url-parser');

const settingsStructure = new ModelSchema({
    saveOnDestroy: Structures.array([]),
    addFromActivities: Structures.boolean(true),
    allowErrorReporting: Structures.boolean(false),
    allowedThumbnailExtensions: Structures.array(['image/jpg', 'image/jpeg', 'image/png']),
    columns: Structures.object({
        default: ['checkbox', 'type', 'title', 'author', 'level', 'difficulty', 'changed', 'actions']
    }),
    filters: Structures.object({
        default: ['title', 'keywords', 'type']
    }),
    filterTitleToTranslate: Structures.string(''),
    filtertoApplyOnLessonsByUrl:  Structures.array([]),
    gradeCalculation: Structures.object(),
    hideAddButtonLessonForModel: Structures.boolean(false),
    hideAddButtonLessonForCommunity: Structures.boolean(false),
    latexKeyboard: Structures.boolean(false),
    lessonDialogFields: Structures.object({
        default: ['title', 'educationnalLevel', 'method', 'tags', 'description']
    }),
    lessonStep: Structures.object(),
    levels: Structures.object({primary: [], secondary: []}),
    loadLessonWithSublesson: Structures.object(),
    maxSizeThumbnail: Structures.string('600000'),
    searchFields: Structures.array(['title', 'educationnalLevel', 'method', 'launchSearch', 'countEntities', 'bookmarks']),
    shareableModel: Structures.number(0)
});

@Injectable()
export class ActivitiesService {
    activities: any = [];
    private selectedActivities: DataEntity[] = [];
    savedAnswers: any = [];
    lessonsAnswers: { [key: string]: DataEntity } = {};
    downloadedActivity: any;
    lessons: DataEntity[];
    sequences: DataEntity[];
    savedAnswersSubscription: Subscription;
    activitiesSubscription: Subscription;
    activitiesObservable: Observable<DataEntity[]>;
    formsSubscription: Subscription;
    onFilesChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onFileSelected: BehaviorSubject<any> = new BehaviorSubject({});
    skillsChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onSelectedResourcesChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    i: number;
    dialogYes: string;
    dialogCancel: string;
    dialogTitle: string;
    dialogDeleteMessage: string;
    userData: DataEntity;
    sequencesSubscription: Subscription;
    activitiesArray: any = [];
    presentArrayElementIndex = 0;
    assignmentView: any = null;
    visitedMediaActivity: number[] = [];
    public activitiesSelection: string;
    /*
    * This variable stores the information of the following :
    *  1) userId
    *  2) expires
    *  3) state
    *  4) type
    *  */
    public currentAssignment: DataEntity;

    /**
     * An object of the type Subject which holds the Boolean values. These
     * values are responsible for the decide upon the state of the activity.
     *      -- STATE like : Revoir Ma Response or Tester la reponse, Reinitiliser, etc.
     */
    public checkAnswers: Subject<any> = new Subject();
    /**
     * Trigger a action in application like on Activities component
     * * The `actionLabel` is the unique identifier of the action (like `saveAnswer`)
     * * The `endSubject` is for subscribe/emit when the associated action are ended.
     */
    public userActionWaiting = new Subject<{actionLabel: string, endSubject: Subject<any>}>();
    /**
     * Type of Subject with Boolean value. That helps to decide whether the user
     * do reply or not.
     * IMPORTANT : It is not the behaviorSubject. There is a difference in the two.
     */
    public doesUserResponsed: Subject<boolean> = new Subject();
    public activityChange: Subject<boolean> = new Subject();
    // for lesson play screen attributes
    public isLessonPlay = false;
    public playScreenStatus = 1; // 0=> activity 1 => introduction 2=> restart 3=> result;
    public endScreenSeen = false;
    public activityAnswerResult: any[] = [];
    public currentLesson: any;
    private currentActivities: DataEntity[] = [];
    public saving = false;
    public isSaveReady = false;

    public userSelectedOption: any[] = [];
    public userAnswerTempSave: any[] = [];
    private answerAPICalled: any[] = [];

    public pushLessonFromAssignment = new Subject();
    public activityActionsHandler = new Subject();
    public userAnswer = new Subject<DataEntity>();
    public isUserAnswerStatus = new Subject<any>();
    private unsubscribeInTakeUntil = new Subject();
    public userAnswerOnChange = new Subject<any>();
    public displayActions = new ReplaySubject<boolean>(1);
    public currentAssignmentID: any;

    public activitiesPaginated: any;
    private activitiesPaginatedObs: Observable<DataEntity[]>;

    private localShortAnswers: string[];

    public chapters: Array<object> = [];
    public chaptersSubscription: Subscription = null;
    public chaptersChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    public tags: Array<object> = [];
    public tagsChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    public activityEntities: DataEntity[] = [];

    public settings: { [key: string]: any };
    public licensingMethods = new BehaviorSubject<object[]>([]);
    public licensingSettings: { [key: string]: any };

    public onLatexKeyboardDisplayChange = new Subject<boolean>();

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.activities);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('view')
            .subscribe((type: any) => {
                this.assignmentView = type;
            });

        this.onFilesChanged.subscribe((data: any[]) => {
            for (const activity of data) {
                const activityEntity = new DataEntity('granule-activity', activity, this.octopusConnect);
                const findIndex = this.activityEntities.findIndex((element) => +element.id === +activity.id);

                if (findIndex !== -1) {
                    this.activityEntities[findIndex] = activityEntity;
                } else {
                    this.activityEntities.push(activityEntity);
                }
            }
        });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((data) => {
                this.currentAssignment = data;
                if (data && data.id) {
                    this.currentAssignmentID = data.id;
                } else {
                    this.currentAssignmentID = null;
                }
                /*userId, expires, state*/
            });
        this.localShortAnswers = [];
    }

    postAuthentication(): void {
        this.communicationCenter.getRoom('licenses').getSubject('methods').subscribe(methods => {
            const methodsTmp = [];
            methods.forEach(method => {
                if (method.get('uid') === this.userData.id) {

                    const access = method.get('access');
                    methodsTmp.push({
                        id: access.id,
                        label: access.name
                    });
                }
            });

            const onlyDisctinctMethods = [];
            methodsTmp.forEach(loopMethod => {
                if (onlyDisctinctMethods.some(selectedMethod => selectedMethod['id'] === loopMethod['id']) === false) {
                    onlyDisctinctMethods.push(loopMethod);
                }
            });
            this.licensingMethods.next(onlyDisctinctMethods);
        });
        this.communicationCenter.getRoom('licenses').getSubject('settings').take(1).subscribe(settings => {
            this.licensingSettings = settings;
        });
    }

    getConcepts(): any {
        return this.octopusConnect.loadCollection('concepts').take(1);
    }

    /**
     * get the list of possible educationnal level
     */
    public getEducationnalLevel(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('educational_level')
            .map((levels: DataCollection) => levels.entities);
    }

    /**
     * get the list of skills
     */
    public getSkills(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('skills')
            .map((skills: DataCollection) => skills.entities);
    }

    /**
     * get the list of difficulty
     */
    public getDifficulties(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('difficulty')
            .map((difficulty: DataCollection) => difficulty.entities);
    }

    /**
     * get the list of themes
     */
    public getThemes(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('themes')
            .map((theme: DataCollection) => theme.entities);
    }

    public isPrimary(activity): boolean {
        return this.settings.levels['primary'].includes(activity.level[0].alias);
    }

    saveAnswer(entity, type = null): any {
        if (!this.currentAssignmentID) {
            const labelAnswer = entity.answer;
            const observable = new ReplaySubject<DataEntity>();
            entity = new DataEntity(type, {
                answer: labelAnswer
            }, null, this.localShortAnswers.length);
            this.localShortAnswers[entity.id] = labelAnswer;
            observable.next(entity);
            return observable;
        } else if (entity.id) {
            entity.save();
        } else if (type && entity.answer !== undefined) {
            return this.octopusConnect.createEntity(type, entity);
        } else {
            const observable = new ReplaySubject(1);
            observable.next(null);
            return observable;
        }

        return entity;
    }

    createSavedAnswers(gid, context = null, answerId, type): Subscription {
        const activityId = gid;
        // i did correction API Call
        // return this.octopusConnect.createEntity(type, {answers: [answerId]}).take(1).subscribe((type: DataEntity) => {
        // tslint:disable-next-line:no-shadowed-variable

        return this.octopusConnect.createEntity(type, {answers: answerId}).take(1).subscribe((type: DataEntity) => {
            this.octopusConnect.createEntity('user-activity', {entitySave: type.id}).take(1).subscribe((data: DataEntity) => {
                this.octopusConnect.createEntity('user-save', {
                    granule: activityId,
                    context: context,
                    userActivity: data.id,
                });
            });
        });
    }

    loadSavedAnswers(gid, context): Observable<DataEntity[]> {
        if (this.savedAnswersSubscription) {
            this.savedAnswersSubscription.unsubscribe();
        }
        if (!context && this.currentAssignmentID) {
            context = this.currentAssignmentID;
        }
        const obj = {granule: gid};
        if (context) {
            obj['context'] = context;
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('user-save', obj).map(collection => {
            return collection.entities;
        });
        this.savedAnswersSubscription = obs.subscribe(entities => {
            this.savedAnswers = entities;
        });
        return obs;
    }

    /**
     *
     * @param {string} granuleId
     * @param {string} contextId
     * @param {string} getAllUserSave => if activity summary, we need all usersave for polls
     * @returns {Observable<DataEntity> || any[]}
     */
    public loadUserSave(granuleId: string, contextId?: string, getAllUserSave = null, step = null): any {
        const filters = {granule: granuleId};
        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }
        if (contextId) {
            filters['context'] = contextId;
        }
        if (!filters['context']) {
            const observable = new ReplaySubject<DataEntity>();
            observable.next(this.lessonsAnswers[step || step === 0 ? filters['granule'] + '-' + step.toString() : filters['granule'] + '-' + this.presentArrayElementIndex.toString()]);
            // if no assign (context) sett isSaveReady to true because, we dont load save from endpoint.
            this.isSaveReady = true;
            return observable;
        }
        return this.octopusConnect.loadCollection('user-save', filters)
            .take(1)
            .map((collection: DataCollection) => {
                this.isSaveReady = true;
                if (collection.entities.every((usersave) => usersave.get('step') && usersave.get('step') !== '')) {
                    // there is two save for the same question the diff will be by step value

                    if (getAllUserSave) {
                        return collection.entities;
                    }
                    return collection.entities.filter((entity) => +entity.get('step') === this.presentArrayElementIndex)[0];
                } else {
                    return collection.entities.sort((a, b) => a.id > b.id ? -1 : 1)[0];
                }
            });
    }

    loadActivities(): Observable<Object[]> {
        if (!this.activitiesObservable) {
            this.activitiesObservable = this.octopusConnect.loadCollection('granule-activity').map(collection => collection.entities);
            this.activitiesSubscription = this.activitiesObservable.subscribe(entities => this.activities = entities);
        }

        return this.activitiesObservable;
    }

    loadPaginatedActivities(filterOptions = {}): Observable<Object[]> {
        this.activitiesPaginated = this.octopusConnect.paginatedLoadCollection('basic_search', filterOptions);
        this.activitiesPaginatedObs = this.activitiesPaginated.collectionObservable.map(collection => collection.entities);

        return this.activitiesPaginatedObs;
    }

    loadActivityTypes(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('variables/instance').take(1);
    }

    loadLessons(): Observable<Object[]> {
        if (this.activitiesSubscription) {
            this.activitiesSubscription.unsubscribe();
        }
        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-lesson').map(collection => collection.entities);
        this.activitiesSubscription = obs.subscribe(entities => this.activities = entities);
        return obs;
    }

    loadSequences(): Observable<Object[]> {
        if (this.sequencesSubscription) {
            this.sequencesSubscription.unsubscribe();
        }
        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-sequence').map(collection => collection.entities);
        this.sequencesSubscription = obs.subscribe(entities => this.activities = entities);
        return obs;
    }

    launchActivity(singleActivity: any): any {
        this.isSaveReady = false;
        return Observable.create(observer => {

            if (singleActivity.isLoadBeforeLaunch && singleActivity.isLoadBeforeLaunch !== 'false') {
                observer.next(this.downloadedActivity.attributes);
                return;
            }
            const findIndex = this.activityEntities
                .findIndex((element) => +element.id === +singleActivity.id);
            if (this.activityEntities[findIndex]) {
                observer.next(this.activityEntities[findIndex].attributes);
                return;
            }

        });
    }

    openDialog(entity: any, checkBoxesList: QueryList<MatCheckbox> = null): void {

        // get translation
        this.translate.get('generic.yes').subscribe((translation: string) => this.dialogYes = translation);
        this.translate.get('generic.cancel').subscribe((translation: string) => this.dialogCancel = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => this.dialogTitle = translation);

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: this.dialogTitle,
        };

        const checkboxes = document.getElementsByName('corpusCheckboxe');
        const checkboxesChecked = [];
        // loop over them all
        for (this.i = 0; this.i < checkboxes.length; this.i++) {
            // And stick the checked ones onto an array...
            if (checkboxes[this.i]['checked']) {
                checkboxesChecked.push(checkboxes[this.i].id.replace('-input', ''));
            }
        }

        // Return the array if it is non-empty, or null
        // return checkboxesChecked.length > 0 ? checkboxesChecked : null;

        if (entity !== 'multiple') { // for 1 entity
            this.translate.get('generic.confim_delete_single_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
            dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
            dialogConfig.data.labelTrueDialog = this.dialogYes;
            dialogConfig.data.labelFalseDialog = this.dialogCancel;

            const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    entity.remove();
                }
            });

        } else { // for 1 or multiple entities
            if (checkboxesChecked.length > 0) {
                this.translate.get('generic.confim_delete_multiple_files').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
                dialogConfig.data.labelTrueDialog = this.dialogYes;
                dialogConfig.data.labelFalseDialog = this.dialogCancel;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result === true) {
                        for (this.i = 0; this.i < checkboxesChecked.length; this.i++) {
                            // tslint:disable-next-line:no-shadowed-variable
                            this.octopusConnect.loadEntity('node', checkboxesChecked[this.i]).take(1).subscribe(entity => entity.remove());
                        }
                    }
                });

            } else { // no checked checkbox
                this.translate.get('generic.confim_action_no_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
            }
        }
    }

    pushValueIntoSubscriber(response: any): void {
        this.onFilesChanged.next(response);
    }

    // generic function to get the exact object from the nestedObject.
    getPropertyFromNestedObject(mainObject: Object, pathToAttribute: Array<string>): any {
        return pathToAttribute.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, mainObject);
    }

    getVideoLinkInfo(url: string): string {
        if (url) {
            const parsed: Object = urlParser.parse(url);

            if (parsed) {
                return urlParser.create({
                    videoInfo: parsed,
                    format: 'embed'
                });
            }
        }

    }

    setActivitiesListWithIds(activitiesArray: Array<any>): any {
        this.activitiesArray = activitiesArray;
    }

    loadActivitiesFromId(id: string): Observable<DataEntity> {
        const obs: Observable<DataEntity> = this.octopusConnect.loadEntity('granule', id);

        obs.take(1).subscribe(entity => {
            this.downloadedActivity = entity;
        }, error => {
            return error;
        }, () => {

        });

        return obs;
    }

    /**
     * Add or Update Activities currently used ({@link currentActivities}) and currently loaded ({@link activityEntities})
     * TODO : The order param is useless because the only caller use the same array for the two params.
     * @param activities List of activity currently used
     * @param order List of activities but in specific order. If there are activities first param but not in order, they will be ignored.
     */
    setCurrentActivities(activities: DataEntity[], order: any[]): void {
        this.currentActivities = order.map(activity => activities.find(entity => +entity.id === +activity.id));

        for (const activity of this.currentActivities) {
            this.updateActivityEntities(activity);
        }
    }

    getCurrentActivity(index: number): DataEntity {
        if (this.activitiesArray[index] && this.currentActivities) {
            const currentActivity = this.currentActivities.find((activity) => +activity.id === +this.activitiesArray[index].id);
            if (currentActivity) {
                this.downloadedActivity = currentActivity;
                return currentActivity;
            }
        }

        return null;
    }

    public getActivityEntity(id: number): DataEntity {
        return this.activityEntities.find((element) => +element.id === id);
    }

    /**
     * (Re)load activities and add/overwrite current loaded activities in service
     * @param activitiesRef
     * @param forSubLesson if true, it will not add/overwrite current loaded activity
     */
    public setActivitiesAfterLoaded(activitiesRef, forSubLesson = false): Observable<DataEntity[]> {
        const activitiesObs: Observable<DataEntity>[] = [];

        let obs;
        for (const ref of activitiesRef) {
            obs = this.loadActivitiesFromId(ref.id);
            obs.take(1).subscribe((entity) => {
                if (!forSubLesson) {
                    this.updateActivityEntities(entity);
                }
            }, (error) => {
                console.error(error);
            });

            activitiesObs.push(obs);
        }

        return activitiesObs.length > 0 ? combineLatest(activitiesObs) : Observable.of([]);
    }

    /**
     * Add activity (or Update if already exist) in {@link activityEntities}
     * @param activity To add or update in list. A same activity than another already in {@link activityEntities} will be ignored.
     */
    private updateActivityEntities(activity: DataEntity): void {
        const findIndex = this.activityEntities.findIndex((element) => +element.id === +activity.id);
        if (findIndex === -1) {
            this.activityEntities.push(activity);
        } else if (this.activityEntities[findIndex] !== activity) {
            this.activityEntities[findIndex] = activity;
        }
    }

    loadLessonById(id: string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('granule-lesson', id);
    }

    constructNavParams(loadBeforeLaunch: boolean, activityId?: string): any {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        return {
            relativeTo: currentRoute,
            queryParams: {
                isLoadBeforeLaunch: loadBeforeLaunch,
                id: activityId
            }
        };
    }

    loadNextActivity(): boolean {
        if (this.presentArrayElementIndex < this.activitiesArray.length) {
            this.presentArrayElementIndex += 1;
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id)
                    .take(1)
                    .subscribe(data => {
                        this.navigateHere(data);
                    });
            }

            return this.presentArrayElementIndex !== this.activitiesArray.length - 1;
        } else {
            this.presentArrayElementIndex = 0;
        }

        return false;
    }

    loadFirstActivity(altPath = false): boolean {
        this.presentArrayElementIndex = 0;

        if (this.presentArrayElementIndex < this.activitiesArray.length) {
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity, false, altPath ? '..' : null);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id)
                    .take(1)
                    .subscribe(data => {
                        this.navigateHere(data, false, altPath ? '..' : null);
                    });
            }
            return this.presentArrayElementIndex !== this.activitiesArray.length - 1;
        }

        return true;
    }

    loadActivityByStep(index, altPath = false): boolean {
        this.presentArrayElementIndex = index;

        if (this.presentArrayElementIndex < this.activitiesArray.length) {
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity, false, altPath ? '..' : null);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id)
                    .take(1)
                    .subscribe(data => {
                        this.navigateHere(data, false, altPath ? '..' : null);
                    });
            }
            return this.presentArrayElementIndex !== this.activitiesArray.length - 1;
        }

        return true;
    }

    loadPreviousActivity(): boolean {
        if (this.presentArrayElementIndex !== 0) {
            this.presentArrayElementIndex -= 1;
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id)
                    .take(1)
                    .subscribe(data => {
                        this.navigateHere(data);
                    });
            }
        } else if (this.presentArrayElementIndex === 0) {
            this.router.navigate(['../..']);
        }

        return true;
    }

    resetArrayindex(): boolean {
        this.presentArrayElementIndex = 0;
        return true;
    }

    loadForms(): Observable<Object[]> {
        if (this.formsSubscription) {
            this.formsSubscription.unsubscribe();
        }
        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-form').map(collection => collection.entities);
        this.formsSubscription = obs.subscribe(entities => {
            this.activities = entities[0].attributes.reference;
        });
        return obs;
    }

    /**
     * Directly execute routing to an url for execute the good activity player from the params
     * @param {DataEntity} data Activity to play (can be a lesson to play as a multi-activity)
     * @param loadBeforeLaunch todo
     * @param relativePath Force the relative path used to calculate new path. If no argument is passed the default value is '../..' (or '../../../..' in case of multi).
     */
    navigateHere(data: any, loadBeforeLaunch?: boolean, relativePath?: string): void {
        const urlFragments = [];
        const type = data.attributes.format.label;

        if (type === 'activity') {
            const {label} = this.getExactRoute(type, data);
            urlFragments.push(LessonActivityRoutes[label]);
        } else if (type === 'divider') {
            urlFragments.push('divider');
        } else if (type === 'lesson') {
            urlFragments.push('multi');
        } else {
            urlFragments.push('media');
        }

        urlFragments.push(data.id);

        if (!relativePath) {
            relativePath = this.router.url.indexOf('multi') > -1 ? '../../../..' : '../..';
        }

        this.router.navigate([
                relativePath,
                ...urlFragments
            ],
            this.constructNavParams(!!loadBeforeLaunch, data.id)
        );
    }

    getExactRoute(type, data): IFormatIdentifier {
        if (type === 'activity') {
            return this.getPropertyFromNestedObject(data['attributes'], ['metadatas', 'typology']);
        } else {
            return {label: this.getPropertyFromNestedObject(data['attributes'], ['format', 'label'])};
        }
    }

    public getContentTypeIcon(data, setFormat?: string): any {

        let type: string = null;
        let format: string = null;
        let filemime: string = null;

        if (data.get('reference') && data.get('reference').filemime) {
            filemime = data.get('reference').filemime;
        }

        if (data.get('format')) {
            format = data.get('format').label;
            if (data.get('metadatas').typology) {
                type = data.get('metadatas').typology.label;
            }
        } else {
            format = setFormat;
            if (data.attributes.hasOwnProperty('typology')
                && data.get('typology') !== null) {
                type = data.get('typology').label;
            }
        }

        let iconObj = {
            name: '',
            translate: ''
        };

        switch (format) {
            case 'divider':
                iconObj = {
                    name: 'collections_bookmark',
                    translate: 'generic.photo'
                };
                break;
            case 'media':
            case 'audio':
            case 'video':
            case 'document':
            case 'image':
                switch (filemime) {
                    case ('image/png'):
                    case ('image/jpeg'):
                    case ('image/gif'):
                        iconObj = {
                            name: 'photo',
                            translate: 'generic.photo'
                        };
                        break;
                    case ('audio/mp3'):
                    case ('audio/mpeg'):
                        iconObj = {
                            name: 'video',
                            translate: 'generic.video'
                        };
                        break;
                    case ('application/pdf'):
                        iconObj = {
                            name: 'document',
                            translate: 'generic.document'
                        };
                        break;
                    default:
                        iconObj = {
                            name: 'photo',
                            translate: 'generic.photo'
                        };
                        break;
                }
                break;
            case 'url':
                iconObj = {
                    name: 'link',
                    translate: 'corpus.import_url'
                };
                break;
            case 'videoUrl':
                iconObj = {
                    name: 'video',
                    translate: 'generic.video'
                };
                break;
            case 'activity':
                switch (type) {
                    case 'APP':
                        iconObj = {
                            name: 'view_day',
                            translate: 'activities.questionTypeName.app-appaire'
                        };
                        break;
                    case 'CRT':
                        iconObj = {
                            name: 'free_answer',
                            translate: 'activities.questionTypeName.app-short-answer'
                        };
                        break;
                    case 'ORD':
                        iconObj = {
                            name: 'view_list',
                            translate: 'activities.questionTypeName.app-ordon'
                        };
                        break;
                    case 'QCM':
                        iconObj = {
                            name: 'qcm',
                            translate: 'activities.questionTypeName.app-qcm'
                        };
                        break;
                    case 'QCMU':
                        iconObj = {
                            name: 'qcmu',
                            translate: 'activities.questionTypeName.app-qcu'
                        };
                        break;
                    case 'RB':
                        iconObj = {
                            name: 'view_stream',
                            translate: 'activities.questionTypeName.app-fill-in-blanks'
                        };
                        break;
                    case 'VF':
                        iconObj = {
                            name: 'view_week',
                            translate: 'activities.questionTypeName.app-true-false'
                        };
                        break;
                    case 'EXT':
                        iconObj = {
                            name: 'ext',
                            translate: 'activities.questionTypeName.ext'
                        };
                        break;
                }
                break;
        }

        return iconObj;
    }

    // i did this set Save answer values API
    setSaveAnswer(answers): void {
        this.userSelectedOption = answers.filter(item => item.select);
    }

    public clearLessonState(resetAssignment: boolean): void {
        if (resetAssignment) {
            this.communicationCenter
                .getRoom('assignment')
                .next('current', null);
        }

        this.currentLesson = null;
        this.isLessonPlay = false;
        this.playScreenStatus = 1;
        this.presentArrayElementIndex = 0;
        this.activitiesArray = [];
        this.visitedMediaActivity = [];

        this.activityAnswerResult = [];
        this.userAnswerTempSave = [];
        this.answerAPICalled = [];
        this.lessonsAnswers = {};
        this.localShortAnswers = [];
    }

    /* Function to be called on the component ngOnDestroy is called
    * This is just to implement the ONDestroy logic via Service.
    * */
    public onModuleChange(): void {
    }

    public setSelectionMode(backUrl: string = ''): void {
        this.activitiesSelection = backUrl;
    }

    public clearSelection(): void {
        this.selectedActivities = [];
        this.onSelectedResourcesChanged.next(this.selectedActivities);
    }

    public toggleActivitySelection(activity: DataEntity, keep?: boolean): void {
        const index = this.selectedActivities.findIndex((element: DataEntity) => element.id === activity.id);

        if (index > -1) {
            if (!keep) {
                this.selectedActivities.splice(index, 1);
            }
        } else {
            if (keep === undefined || keep) {
                this.selectedActivities.push(activity);
            }
        }

        this.onSelectedResourcesChanged.next(this.selectedActivities);
    }

    public get selectionCount(): number {
        return this.selectedActivities.length;
    }

    /* Handling Activity actions */
    activityActionHandler(testAnswer: boolean, seeSolution: boolean, resetAll: boolean, reviewAnswer: boolean): void {
        const State = {
            testAnswer: testAnswer,
            seeSolution: seeSolution,
            resetAll: resetAll,
            reviewAnswer: reviewAnswer,
        };
        this.activityActionsHandler.next(State);
    }

    /* method get presentArray activity ID */
    getLessonActivityID(): number {
        return this.activitiesArray[this.presentArrayElementIndex] ? this.activitiesArray[this.presentArrayElementIndex].id : null;
    }

    public getAnswerData(activityId: string, contextId: string): Observable<any> {
        const answerObservable = new Observable((observer) => {
            if (this.authenticationService.isLearner()) {
                if (!this.answerAPICalled.includes(this.presentArrayElementIndex) || !this.activitiesArray.length) {
                    this.loadSavedAnswers(activityId, contextId)
                        .takeUntil(this.unsubscribeInTakeUntil)
                        .subscribe(savedAnswers => {
                            if (savedAnswers && savedAnswers[0]) {
                                const totalSave = savedAnswers.length;
                                const answers = (savedAnswers[totalSave - 1] as DataEntity);
                                if (answers.get('userActivity').entitySave) {
                                    const userAnswer = answers.get('userActivity').entitySave.answers;
                                    this.setAnswerTempSave(JSON.stringify(userAnswer));
                                    this.answerAPICalled.push(this.presentArrayElementIndex);
                                    observer.next(userAnswer);
                                    observer.complete();
                                }
                            }
                        });
                } else {
                    const tempAnswer = this.getActivityTempSaveAnswer();
                    if (tempAnswer) {
                        observer.next(tempAnswer);
                    }
                    observer.complete();
                }
            } else {
                const tempAnswer = this.getActivityTempSaveAnswer();
                if (tempAnswer) {
                    observer.next(tempAnswer);
                }
                observer.complete();
            }
        });
        return answerObservable;
    }

    public getUserSave(activityId: string, contextId?: string, getAllUserSave = null, step = null): Observable<DataEntity> {
        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }

        return this.loadUserSave(activityId, contextId, getAllUserSave, step);
    }

    setAnswerTempSave(answer: any): void {
        this.userAnswerTempSave[this.presentArrayElementIndex] = answer;
    }

    getActivityTempSaveAnswer(): any {
        let answer = null;
        if (this.userAnswerTempSave.includes(this.presentArrayElementIndex)) {
            answer = this.userAnswerTempSave[this.presentArrayElementIndex];
            answer = JSON.parse(answer);
        }
        return answer;
    }

    unsetAnswerTempSave(): void {
        this.userAnswerTempSave[this.presentArrayElementIndex] = 0;
        this.activityAnswerResult[this.presentArrayElementIndex] = null;
    }

    resetActivityAnswerData(): ReplaySubject<boolean> {
        const observable = new ReplaySubject<boolean>();

        this.activityAnswerResult = [];
        this.userAnswerTempSave = [];
        this.answerAPICalled = [];

        this.visitedMediaActivity = [];

        if (this.currentAssignmentID) {
            // Reset progress
            {
                this.communicationCenter
                    .getRoom('activities')
                    .getSubject('saveProgress')
                    .next(0);
            }

            const data = new DataEntity('user-save', {}, this.octopusConnect, 'context/' + this.currentAssignmentID);
            this.octopusConnect.deleteEntity(data).take(1).subscribe(() => {
                observable.next(true);
            });
        } else {
            // no assignmentID = no user-save
            observable.next(true);
        }

        return observable;
    }

    public createUserSave(activityId: string, contextId: string, answers: string[] | string, state: string, type: string, step: number = null): Observable<DataEntity> {
        const observable = new Subject<DataEntity>();

        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }
        // for genericsave endpoint the name of field to save is content for the other it's answers
        let data: any;
        if (type === 'genericsave') {
            data = {'content': answers};
        } else {
            data = {answers: answers};
        }

        this.octopusConnect.createEntity(type, data).take(1)
            .flatMap((save: DataEntity) => {
                return this.octopusConnect.createEntity('user-activity', {entitySave: save.id}).take(1)
                    .flatMap((userActivity: DataEntity) => {
                        return this.octopusConnect.createEntity('user-save', {
                            granule: activityId,
                            context: contextId,
                            state: state,
                            step: step ? step : this.presentArrayElementIndex,
                            userActivity: userActivity.id
                        }).take(1);
                    });
            })
            .subscribe((userSave: DataEntity) => {
                observable.next(userSave);
            }, (error) => {
                observable.error(error);
            });

        return observable;
    }

    /**
     * Save answer of user
     * @param activityId : id of current activity
     * @param contextId : id of assignation or lessonId(not sure for lessonId...)
     * @param selectedAnswers : answers of user
     * @param status : status of activity validated close incomplete(not finish) correct etc..
     * @param type : type of save 'qcm-save' etc... it's equal to the endpoint name genericSAve etc..
     * @param save : if already have a user save
     * @param step : index og activity in current lesson
     */
    public saveUserSave(activityId: string, contextId: string, selectedAnswers: Array<any> | string, status: number, type: string, save?: DataEntity, step: number = null): Observable<DataEntity> {
        let observable: Observable<DataEntity>;
        let state: string;

        this.saving = true;

        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }

        switch (status) {
            case 1:
                state = 'validated';
                break;
            case 2:
                state = 'incomplete';
                break;
            case 3:
                state = 'correct';
                break;
            default:
                state = 'closed';
                break;
        }

        if (!contextId) {
            observable = new ReplaySubject<DataEntity>(1);
            const answers = <any>selectedAnswers;
            const filter = ['rb-save', 'app-save'];
            if (!filter.includes(type)) {
                for (let i = 0; i < answers.length; i++) {
                    if (!answers[i]) {
                        continue;
                    }
                    const _answer = this.localShortAnswers[answers[i]] ? this.localShortAnswers[answers[i]] : answers[i].answer || answers[i];
                    answers[i] = {id: answers[i].id || answers[i], answer: _answer};
                }
            }

            const entity = new DataEntity(type, {
                granule: activityId,
                context: null,
                state: state,
                step: step !== null ? step : this.presentArrayElementIndex,
                userActivity: {
                    entitySave: {
                        answers: answers,
                    }
                }
            });
            this.lessonsAnswers[step || step === 0 ? activityId + '-' + step.toString() : activityId + '-' + this.presentArrayElementIndex.toString()] = entity;
            (<ReplaySubject<DataEntity>>observable).next(entity);
        } else {
            if (!save) {
                observable = this.createUserSave(activityId, contextId, selectedAnswers, state, type, step);
            } else {
                const entitySaveData = save.get('userActivity').entitySave;
                const entitySave = new DataEntity(type, entitySaveData, this.octopusConnect);
                observable = new ReplaySubject<DataEntity>(1);

                // for genericsave endpoint the name of field to save is content for the other it's answers
                if (type === 'genericsave') {
                    entitySave.set('content', selectedAnswers);
                } else {
                    entitySave.set('answers', selectedAnswers);
                }

                // entitySave.set('answers', selectedAnswers);
                entitySave.save()
                    .take(1)
                    .subscribe((saveData) => {
                        save.set('state', state);
                        save.save(true)
                            .take(1)
                            .subscribe((userSave) => {
                                console.log('save complete', userSave);
                                (<ReplaySubject<DataEntity>>observable).next(userSave);
                            }, (error) => {
                                (<ReplaySubject<DataEntity>>observable).error(error);
                            });
                    });
            }
        }

        observable.subscribe((userSave: DataEntity) => {
            this.saving = false;
            this.userAnswerOnChange.next(userSave);
        }, (error) => {
            this.saving = false;
            this.userAnswerOnChange.error(error);
        });

        return observable;
    }

    public saveUserAnswer(id: string, content: string, answerStatus: any, contextId: string, endPoint: string, answerId: string): Observable<any> {
        if (!answerId) {
            answerId = content;
        }
        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }

        if (!contextId) {
            return new Observable();
        }

        return new Observable((observer) => {
            if (this.authenticationService.isLearner()) {
                this.saveAnswer({answer: content, correct_answer: answerStatus}, 'answer')
                    .take(1)
                    .subscribe(dataEntity => {
                        const userAnswer = dataEntity;
                        this.setAnswerTempSave(userAnswer);
                        this.createSavedAnswers(id, contextId, answerId, endPoint);
                        observer.next(userAnswer);
                    });
            }
            this.userAnswerOnChange.next(this.getActivityTempSaveAnswer());
            observer.complete();
        });
    }

    /* change activity button action */
    public userActionButtonState(showAnswers: boolean, withoutAnyUserResponse: boolean, reinitializeOptions: boolean): void {
        const finalObj = {
            showAnswers: showAnswers,
            withoutAnyUserResponse: withoutAnyUserResponse,
            reinitializeOptions: reinitializeOptions
        };
        this.checkAnswers.next(finalObj);
    }

    /**   Chapter load   **/


    getMethods(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('chapters', {parent: 'null'});
    }

    getChapters(methodId): void {
        this.octopusConnect.loadCollection('chapters', {parent: methodId}).take(1).subscribe(data => {
            this.chapters = [];
            for (const entity of data.entities) {
                this.chapters.push({
                    id: entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                    parent: entity.get('parent')[0]
                });
            }
            this.chaptersChanged.next(this.chapters);
        });
    }

    /*******    Tags    *******/
    getTags(type): void {
        this.octopusConnect.loadCollection(type).takeUntil(this.unsubscribeInTakeUntil).subscribe(data => {
            this.tags = [];
            for (const entity of data.entities) {
                this.tags.push({
                    id: entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                });
            }
            this.tagsChanged.next(this.tags);
        });
    }

    private postLogout(): void {
        if (this.chaptersSubscription) {
            this.unsubscribeAll();
        }
    }

    unsubscribeAll(): void {
        this.chaptersSubscription.unsubscribe();
    }

    /**
     * Save a Corpus Resource granule as part of activity's content.
     * @param activity should be a 'media' activity type but no controls are done before save
     * @param resource Granule resource should be provided by Corpus
     */
    public addCorpusResourceToActivity(activity: DataEntity, resource: DataEntity): Observable<DataEntity> {
        return this.replaceCorpusResourcesOfActivity(activity, [...activity.get('activity_content')[0]['granule'].map(g => g.id), +resource.id]);
    }

    /**
     * Remove a Corpus Resource granule from the list of activity's content.
     * @param activity should be a 'media' activity type but no controls are done before save
     * @param resource Granule resource to remove
     */
    public removeCorpusResourceFromActivity(activity: DataEntity, resource: DataEntity): Observable<DataEntity> {
        return this.replaceCorpusResourcesOfActivity(activity, activity.get('activity_content')[0]['granule'].map(g => +g.id).filter(id => +id !== +resource.id));
    }

    /**
     * Save a Corpus Resource granule list as activity's content.
     *
     * @remarks
     * The resources will erased the already existed resources in the activity content
     *
     * @param activity should be a 'media' activity type but no controls are done before save.
     * @param resourceIds Id list of resources initially provided by Corpus
     */
    public replaceCorpusResourcesOfActivity(activity: DataEntity, resourceIds: number[]): Observable<DataEntity> {
        const entity = new DataEntity(
            'media',
            activity.get('activity_content')[0],
            this.octopusConnect,
            activity.get('activity_content')[0].id
        );

        entity.set('granule', resourceIds);
        return entity.save();
    }

    /**
     * load the next lesson in multiassignement case
     */
    public loadNextLesson(): void {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }
        const lessons = this.currentAssignment.get('assignated_nodes');
        const nextIndex = this.getIndexOfCurrentLesson(lessons) + 1;
        if (nextIndex < lessons.length) {
            const route = this.lessonRoutePath(this.router.url, lessons[nextIndex].id);
            this.router.navigate(route, {relativeTo: currentRoute});
        }
    }

    /**
     * return the array to navigate to lesson
     * @param url : current url
     * @param id : id of lesson where to go
     */
    private lessonRoutePath(url: string, id: string): string[] {
        const urlEnd = url.split('lessons')[1];
        const countOccurence = urlEnd.split('/').length - 1; // -1 : string begin with '/' => first item is empty array
        let path = '';
        for (let i = 0; i < countOccurence; i++) {
            path = path + '../';
        }
        let route = [path];
        route.push(id);
        return route;
    }

    /**
     * in multiassigment lesson is there another lesson to launch after the current one
     */
    public isNextLesson(): boolean {
        let lessons;
        if (this.currentAssignment && this.currentAssignment.get('assignated_nodes')) {
            lessons = this.currentAssignment.get('assignated_nodes');
        }
        if (lessons) {
            const index = this.getIndexOfCurrentLesson(lessons);
            return index + 1 < lessons.length;
        }
        return false;
    }


    /**
     * return index of current lesson in regard of the array of lessons in assignements
     * @param lessons current lesson
     */
    private getIndexOfCurrentLesson(lessons): number {
        const idCurrentLesson = this.currentLesson.id;
        const index = lessons.findIndex(lesson => lesson.id === idCurrentLesson);
        return index;
    }
}
