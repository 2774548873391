import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThemesService} from '@modules/activities/core/themes/themes.service';
import {DatacardService} from '../../../../shared/datacard.service';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings/lamorim';
import {AuthenticationService} from '@modules/authentication';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Component({
    selector: 'app-themes',
    templateUrl: './themes.component.html',
    styleUrls: ['./themes.component.scss']
})
export class ThemesComponent implements OnInit, OnDestroy {

    public datas: any[];
    public isLoading: boolean = true;
    public settings: { [key: string]: any };
    public fieldsToDisplay: string[] = [];
    public countEntities: number = 50;

    constructor(private themesService: ThemesService,
                private lessonsService: LessonsService,
                private datacardService: DatacardService,
                private authService: AuthenticationService,
    ) {
        this.setSettings();
        this.setDisplayField();
    }

    /**
     * get settings and set it localy
     */
    private setSettings(): void {
        const settingsStructure = new ModelSchema({
            filtersTheme: Structures.object({
                default: ['title', 'skills', 'difficulty', 'bookmarks']
            }),
            filterThemeUsePosition: Structures.boolean(false),
        });
        this.settings = settingsStructure.filterModel(modulesSettings.activities);
    }

    /**
     * set array with fields to show in regard to settings
     */
    public setDisplayField(): void {
        const role = this.authService.accessLevel;
        let filtersByRole = this.settings.filtersTheme[role];
        if (filtersByRole === undefined) {
            filtersByRole = this.settings.filtersTheme['default'];
        }
        this.fieldsToDisplay = filtersByRole;
    }

    ngOnInit(): void {
        this.loadData({filter: {}, page: 1, range: 12});
    }

    /**
     * load data with filter rules if exists
     * @param optionsInterface : filter to apply
     */
    public loadData(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): void {
        this.isLoading = true;

        this.themesService.getThemes(optionsInterface)
            .take(1)
            .subscribe(resources => {
                    if (!resources) {
                        return;
                    }

                    const callbacks = {
                        'downloadDoc': (resource) => this.datacardService.downloadDoc(resource),
                        'play': (resource) => this.lessonsService.loadAndNavigateToLesson(resource)
                    };

                    this.datas = this.datacardService.processResources(resources, callbacks, 'themes');
                    this.isLoading = false;

                    this.setPaginator();

                },
                (err) => {
                    console.log(err);
                    this.isLoading = false;
                });
    }

    /**
     * init the paginator data with current value
     */
    public setPaginator(): void {
        if (this.themesService.themePaginated.paginator) {
            this.countEntities = this.themesService.themePaginated.paginator.count;
            this.themesService.pageIndex = this.themesService.themePaginated.paginator.page - 1;
        }
    }
    ngOnDestroy(): void {
    }
}

