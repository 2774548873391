import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'fuse-edit-profile-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FuseFormsComponent implements OnInit {
    form: FormGroup;
    formErrors: any;

    public settings: { [key: string]: any };
    public canEditAvatar: string[];
    public allowedExtensions: string;
    public allowedMaxSize: string;
    public isPngTooBig = false;
    public formIsReady = false;
    public displayGenericError = false;

    userInformation: DataEntity;
    defaultUserInformation: FormProfile;

    constructor(
        private formBuilder: FormBuilder,
        private profileProvider: ProfileService,
        public authenticationService: AuthenticationService,
        private octopusConnect: OctopusConnectService
    ) {
        this.defaultUserInformation = new FormProfile({}, this.octopusConnect);
        this.settings = this.profileProvider.settings;
        // Reactive form errors
        this.formErrors = {
            label: {},
            email: {},
            you_are: {},
            find_us: {},
            region: {},
            level: {},
            password: {},
            passwordConfirm: {},
        };
    }

    ngOnInit(): void {
        this.defaultUserInformation.completeLoading.subscribe(() => {
            this.initialize();
        });
    }

    private initialize(): void {
        this.allowedExtensions = this.settings.allowedExtensions.join(', ');
        this.allowedMaxSize = this.settings.allowedMaxSize.toString();
        this.canEditAvatar = this.settings.canEditAvatar.includes(this.authenticationService.accessLevel);
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            this.userInformation = userInformation;
        });


        // Reactive Form
        this.form = this.formBuilder.group({
            label: [this.userInformation.get('label'), Validators.required],
            email: [{value: this.userInformation.get('email'), disabled: this.userInformation.get('sso')}, [Validators.required, Validators.email]],
            you_are: [this.userInformation.get('you_are'), this.requiredFields('you_are')],
            find_us: [this.userInformation.get('find_us'), this.requiredFields('find_us')],
            region: [this.userInformation.get('region') ? +this.userInformation.get('region').id : '', this.requiredFields('region')],
            level: [this.userInformation.get('level') ? +this.userInformation.get('level').id : null, this.requiredFields('level')],
            newsletter: [this.userInformation.get('newsletter')],
            password: [''],
            passwordConfirm: ['', confirmPassword],
            institution: this.userInformation.get('institution')
        });

        this.form.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });

        this.formIsReady = true;
    }

    requiredFields(name): ValidationErrors | void {
        if (this.displayField(name) && !this.userInformation.get('sso')) {
            return Validators.required;
        }
    }

    /***
     * this.isPngTooBig is use to change color of text
     * @param isSizeTooBig : true if file is too big to be upload
     */
    public changeColorIfTooBig(isSizeTooBig: boolean): void {
        this.isPngTooBig = isSizeTooBig;
    }

    onFormValuesChanged(): void {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.formErrors[field] = {};

            // Get the control
            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }

            if (this.form.get('password').value !== '' && this.form.get('passwordConfirm').value === '') {
                this.form.get('passwordConfirm').setErrors({passwordsNotMatch: true});
                this.formErrors['passwordConfirm'] = this.form.get('passwordConfirm').errors;
            } else {
                if (this.form.get('password').value === '' && this.form.get('passwordConfirm').value === '') {
                    this.form.get('passwordConfirm').setErrors(null);
                }
            }

        }
    }

    editProfile(): void {
        this.displayGenericError = false;

        if (!this.form.invalid) {
            const user = {
                label: this.form.value.label,
                email: this.form.value.email,
                you_are: this.form.value.you_are,
                find_us: this.form.value.find_us,
                region: this.form.value.region.id,
                level: this.form.value.level,
                newsletter: this.form.value.newsletter,
                role_field: this.form.value.role_field,
                password: this.form.value.password,
                institution: this.form.value.institution
            };

            this.profileProvider.editUser(user).subscribe(isSuccess => {
                if (isSuccess === false) {
                    this.displayGenericError = true;
                }
            });
        }

    }

    setMode(mode: boolean): void {
        this.form = this.formBuilder.group({
            label: [this.userInformation.get('label'), Validators.required],
            email: [this.userInformation.get('email'), [Validators.required, Validators.email]],
            you_are: [this.userInformation.get('you_are'), Validators.required],
            find_us: [this.userInformation.get('find_us'), Validators.required],
            region: [this.userInformation.get('region'), Validators.required],
            level: [this.userInformation.get('level'), Validators.required],
            newsletter: [this.userInformation.get('newsletter')],
            password: [''],
            passwordConfirm: ['', confirmPassword],
            institution: ['']

        });

        this.profileProvider.editMode = mode;
    }

    get userIsAdult(): boolean {
        if (!this.userInformation.get('contact_email')) {
            return true;
        }
        return false;
    }

    displayField(name: string): boolean {
        const role = this.authenticationService.accessLevel;

        let fields = this.settings.fields[role];
        if (fields === undefined) {
            fields = this.settings.fields['default'];
        }

        return fields.indexOf(name) > -1;
    }
}


function confirmPassword(control: AbstractControl) {
    if (!control.parent || !control) {
        return;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return;
    }

    if (password.value !== '' && passwordConfirm.value === '') {
        return {
            passwordsNotMatch: true
        };
    }

    if (passwordConfirm.value === '') {

        return;

    }

    if (password.value !== passwordConfirm.value) {
        return {
            passwordsNotMatch: true
        };
    }
}
