import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {ActivatedRoute, Router} from '@angular/router';
import {localizedDate} from '../../../../../shared/utils';
import {fuseAnimations} from 'fuse-core/animations';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
  selector: 'app-widget-forms',
  templateUrl: './widget-forms.component.html',
  styleUrls: ['./widget-forms.component.scss'],
  animations: fuseAnimations
})
export class WidgetFormsComponent implements OnInit {

    @Input('formsListSubscription') formsListSubscription: ReplaySubject<any>;

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    displayedColumns: string[] = ['name', 'date', 'owner'];
    resource: boolean;


    constructor(private router: Router, private route: ActivatedRoute, private communicationCenter: CommunicationCenterService) {
    }

    ngOnInit(): any {

        this.formsListSubscription
            .subscribe((data) => {
                this.dataSource.data = data.slice(0, 3);
                this.resource = !!data.length;
            });
    }


    goToForm(data): any{
        this.communicationCenter
            .getRoom('assignment')
            .next('launchAssignment', data);
        this.router.navigate(['..' , 'tools', 'followed', 'assignment', 'forms', data.get('assignated_node').id, 'player'], { relativeTo: this.route});
    }

    translateDate(data): string {
      if (data) {
          return localizedDate(data.get('changed'));
      }
      return '';
    }


    public get empty(): boolean {
        return !this.resource;
    }

    public get align(): string {
        if (!this.empty) {
            return 'start center';
        }
        return 'center center';
    }

}
