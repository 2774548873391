import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsUserLogged} from '../../../is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {OctopusConnectModule} from 'octopus-connect';
import {ActivitiesComponent} from '@modules/activities/core/activities.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {ActivitiesListComponent} from './activities-list/activities-list.component';
import {MatButtonModule, MatGridListModule, MatIconModule, MatPaginatorIntl} from '@angular/material';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {LayoutModule} from '../../../layout/layout.module';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {DragulaModule} from 'ng2-dragula';
import {FlagService} from '../../../shared/flag.service';
import {ActivityServicesModule} from '@modules/activities/core/services/activity-services.module';
import {PlayersModule} from '@modules/activities/core/player-components/players.module';
import {ActivityResolver} from '@modules/activities/core/activity.resolver';
import {DialogComponent} from './shared-components/dialog/dialog.component';
import {Ng2DragDropModule} from 'ng2-drag-drop';
import {ArchiveService} from '../../../shared/archive.service';
import {ActivitiesSharedModule} from '@modules/activities/core/activities-shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {ChaptersModule} from 'fuse-core/components/chapters-selection/chapters.module';
import {TagsModule} from 'fuse-core/components/tags-selection/tags.module';
import {EditorsModule} from '@modules/activities/core/editor-components/editors.module';

const routes: Routes = [
    {
        path: 'activities',
        canActivate: [IsUserLogged],
        resolve: {
            files: ActivityResolver
        },
        component: ActivitiesComponent,
        children: [
            {
                path: 'list',
                component: ActivitiesListComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        OctopusConnectModule,
        RouterModule.forChild(routes),
        ActivitiesSharedModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        LayoutModule,
        DragulaModule,
        ActivityServicesModule.forRoot(),
        PlayersModule,
        ActivitiesPipesModule,
        Ng2DragDropModule.forRoot(),
        ChaptersModule,
        TagsModule,
        EditorsModule
    ],
    declarations: [
        ActivitiesComponent,
        DialogComponent,
    ],
    entryComponents: [
        DialogComponent
    ],
    exports: [RouterModule],
})
export class ActivitiesModule {

    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ActivitiesModule,
            providers: [
                ArchiveService,
                ActivityResolver,
                FlagService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
        ActivitiesModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'tools');
        this.dynamicNavigation.clearMenuItem('level0', 'Activities');
    }

    private postAuthentication(): void {
        if (!ActivitiesModule.isMenuSet && this.authService.hasLevel(['trainer', 'manager', 'administrator'])) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'Activities',
                'title': 'Activities',
                'translate': 'activities.title',
                'type': 'item',
                'icon': 'activity',
                'url': '/activities/list'
            });

            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'tools',
                'title': 'Outils',
                'translate': 'generic.tools',
                'type': 'collapse',
                'icon': 'outils2',
            });
            this.dynamicNavigation.addChildTo('level0', 'tools', {
                'id': 'param-child',
                'title': 'Modèles de formulaires de séances',
                'translate': 'activities.forms_model_title',
                'type': 'item',
                'url': '/forms/list',
                'weight': 1
            });

            ActivitiesModule.isMenuSet = true;
        }
    }
}
