import { Component, OnInit } from '@angular/core';
import {ResearchSheetService} from '../../research-sheet.service';
import {Router} from '@angular/router';
import {ResearchSectionTemplate, ResearchTemplate} from '@modules/research-sheet/core/definitions';

@Component({
  selector: 'app-section-type-selection',
  templateUrl: './section-type-selection.component.html',
  styleUrls: ['./section-type-selection.component.scss']
})
export class SectionTypeSelectionComponent implements OnInit {

  definitions: ResearchSectionTemplate[];

  constructor(
      private sheetService: ResearchSheetService,
      private router: Router
  ) { }

  ngOnInit() {
    if (!this.sheetService.currentDraft) {
      this.router.navigate([this.sheetService.getDraftListLink()]);
      return;
    }

    const selectedTemplate: ResearchTemplate = this.sheetService.templatesList.filter(elem => elem.id === this.sheetService.currentDraft.template)[0];
    this.definitions = selectedTemplate.sectionsDefinitions;
  }

  getSectionPrefix(definition: ResearchSectionTemplate): string {
    return this.sheetService.getSectionPrefix(definition.name);
  }

  selectType(definition: ResearchSectionTemplate) {
    this.sheetService.currentDraft.section = definition.id;
    this.sheetService.createResearchDraft(this.sheetService.currentDraft).take(1).subscribe(entity => {
      // TEMP: redirection vers la liste des brouillons
        this.router.navigate([this.sheetService.getDraftListLink()]);
    });
  }
}
