import {Component, OnInit, Input, OnDestroy, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SectionDisplayService} from '@modules/research-sheet/core/section-display/section-display.service';
import {AuthenticationService} from '@modules/authentication';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {ResearchSection, ResearchSectionTemplate} from '@modules/research-sheet/core/definitions';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService, ExternalCorpusDisplayComponent} from '@modules/corpus';
import {Subscription} from 'rxjs/Subscription';
import {DataEntity} from 'octopus-connect';
import {Subject} from "rxjs";

@Component({
  selector: 'fuse-section-detail',
  templateUrl: './section-detail.component.html',
  styleUrls: ['./section-detail.component.scss']
})
export class SectionDetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input('sectionDetail') sectionDetail: ReplaySubject<object>;

  @ViewChild('corpus') corpus: ExternalCorpusDisplayComponent;

  @Input('editionEnabled') editionEnabled = false;

  sectionDetailEntity: ResearchSection;
  sectionDefinition: ResearchSectionTemplate;
  oldText: string;
  editedText: string;
  formSection: FormGroup;
  formErrors: any;
  settings: any;

  accessTrainer: string[] = ['trainer'];
  accessLearner: string[] = ['learner'];

  sectionParentText: string;

  private unsubscribeInTakeUntil = new Subject();

  private states = {
    validated: 'chat_happy',
    correct: 'chat_sad',
    updated: 'chat_sad',
    pending: 'hourglass',
    closed: 'chat_close'
  };

  resources: CorpusRessource[];

  constructor(
      private formBuilder: FormBuilder,
      private sectionService: SectionDisplayService,
      private authService: AuthenticationService,
      public corpusService: CorpusService
  ) { }

  ngOnInit(): void {
      this.sectionService.sectionNotReady();
      this.sectionDetail.subscribe((data: any) => {
          this.sectionDetailEntity = data.currentSection;
          this.sectionDefinition = data.sectionDefinition;
          this.oldText = data.currentSection ? data.currentSection.text : '';
          this.editedText = this.oldText;
          this.sectionParentText = data.sectionParent ? data.sectionParent.text : '';
          this.accessTrainer = data.accessTrainer;
          this.accessLearner = data.accessLearner;

          if (this.formSection) {
              this.formSection.setValue({
                  text: this.editedText
              });
          }
      });

    this.formSection = this.formBuilder.group({
      text: [this.editedText, Validators.required]
    });

    this.checkIfControlIsEnabled();

    this.formSection.valueChanges.subscribe(() => {
      this.onSectionFormValuesChanged();
    });

    this.settings = this.sectionService.settings;

    this.formErrors = {
      sectionNewValue: {}
    };

    this.sectionService.sectionTextReady
        .takeUntil(this.unsubscribeInTakeUntil)
        .subscribe((ready: boolean) => {

      if (this.formSection.valid && ready){
          this.sectionService.getDataForm(this.formSection.value, this.sectionDetailEntity);
        } else {
          this.sectionService.unlockSave();
      }
    });

    // this.sectionService.corpus = this.corpus;

    this.corpusService.loadCorpusRessources(String(this.corpusId))
        .takeUntil(this.unsubscribeInTakeUntil)
        .subscribe(ress => this.resources = ress);
  }

    get corpusId(): number {
        if (this.corpusService.getCurrentCorpusSet(this.sectionService.researchSheetService.currentProjectId)){
            return this.corpusService.getCurrentCorpusSet(this.sectionService.researchSheetService.currentProjectId).corpusArray[0].id;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['editionEnabled']) {
            this.checkIfControlIsEnabled();
        }

    }

    checkIfControlIsEnabled(): void {
        if (this.formSection) {
            if (this.editionEnabled === false) {
                this.formSection.controls['text'].disable();
            } else {
                this.formSection.controls['text'].enable();
            }
        }

    }

  ngOnDestroy(){
      this.unsubscribeInTakeUntil.next();
      this.unsubscribeInTakeUntil.complete();
  }

  onSectionFormValuesChanged(){
    for ( const field in this.formErrors )
    {
      if ( !this.formErrors.hasOwnProperty(field))
      {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.formSection.get(field);


      if ( control && control.dirty && !control.valid )
      {
        this.formErrors[field] = control.errors;
      }
    }


  }

  checkAccessTrainer(){
    return this.authService.hasLevel(this.accessTrainer);
  }

  checkAccessLearner(){
    // return this.authService.hasLevel(this.accessLearner);
    return true;
  }

  public get icon(): string {
    return this.sectionDetailEntity ? this.states[this.sectionDetailEntity.state] : '';
  }

  getDate(dateTimestamp){
    if (dateTimestamp){
      return this.sectionService.localeDate(dateTimestamp);
    }
    return '';
  }

  displayResource(resource: CorpusRessource) {
    this.corpusService.openRessource(resource);
  }

  removeResource(resource: CorpusRessource) {
    const index: number = this.sectionService.linkedResources.indexOf(resource);
    this.sectionService.linkedResources.splice(index, 1);

    const ids: number[] = [];

    this.sectionService.linkedResources.forEach(resourceItem => {
      ids.push(+resourceItem.id);
    });

    this.sectionService.setMedias(ids);
  }

  getLinkedResources(){
    if (this.sectionService && this.sectionService.linkedResources){
      return this.sectionService.linkedResources;
    }

    return [];
  }

  useDraft(selectedDraft: DataEntity) {
    if (this.editedText.length > 0) {
      this.editedText += '\n\n';
    }

    this.editedText += selectedDraft.get('text');

    // ajout des médias
    console.log(selectedDraft);

    const addedMedias: string[] = selectedDraft.get('medias');

    const newMediasList: string[] = [];
    const oldMediasList: string[] = this.getLinkedResources().map(ressource => String(ressource.id));

    newMediasList.push(...oldMediasList);

    addedMedias.forEach(media => {
      if (newMediasList.indexOf(media) === -1) {
        newMediasList.push(media);
      }
    });

    // console.log("NEW MEDIAS LIST", newMediasList);

    this.sectionService.setMedias(newMediasList);

    // mise à jour des linkedRessources
      this.sectionService.linkedResources = newMediasList.map(id => this.resources.find(ress => +ress.id === +id));

  }

  getLast(i: number): boolean{
    if (this.sectionDetailEntity && this.sectionDetailEntity.comments){
      return i === 0;
    }
    return false;
  }

  showMediaCard(){
    const ressource = this.getLinkedResources();
    return ressource.length === 0;
  }

}
