import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GraphComponent} from 'fuse-core/components/graph/graph.component';
import {ChartsModule} from 'ng2-charts';
import {InfoGraphComponent} from 'fuse-core/components/graph/info-graph/info-graph.component';
import {MatCardModule, MatIconModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {GraphMixedComponent} from 'fuse-core/components/graph/graph-mixed/graph-mixed.component';
import {GraphDetailsModalComponent} from 'fuse-core/components/graph/graph-details-modal/graph-details-modal.component';

@NgModule({
  imports: [
    TranslateModule,
    MatCardModule,
    MatIconModule,
    CommonModule,
    ChartsModule
  ],
  declarations: [
      GraphComponent,
      InfoGraphComponent,
      GraphMixedComponent,
      GraphDetailsModalComponent
  ],
  entryComponents: [GraphComponent, InfoGraphComponent, GraphMixedComponent, GraphDetailsModalComponent],
  exports: [GraphComponent, GraphMixedComponent, GraphDetailsModalComponent]
})
export class GraphModule { }
