import {DataEntity} from 'octopus-connect/src/data-structures/data-entity.class';

export class Project {
  id: number = -1;
  name: string = '';
  theme: string = '';
  groups: string[] = [];
  groupsIds: number[] = [];
  workgroups: string[] = [];
  corpus: any[] = [];
  trainers: string[] = [];
  archived: boolean = false;
  originalEntity: DataEntity;

  constructor(project?: Project) {
    if (project) {
      this.id = project.id || -1;
      this.name = project.name || '';
      this.theme = project.theme || '';
      this.groups = (project.groups && project.groups.slice()) || [];
      this.workgroups = (project.workgroups && project.workgroups.slice()) || [];
      this.corpus = (project.corpus && project.corpus.slice()) || [];
      this.trainers = (project.trainers && project.trainers.slice()) || [];
      this.archived = project.archived || false;
    }
  }
}

export interface DashWidgetConf {
    headerTitle: string;
    headerIcon: string;
    headerLink: any;
    dashWidgetClassName: string;
}