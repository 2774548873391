import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatTableModule, MatToolbarModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {LessonsSelectionComponent} from 'fuse-core/components/lessons-selection/lessons-selection.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatToolbarModule,
    FuseSharedModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule
  ],
  declarations: [LessonsSelectionComponent],
  entryComponents: [LessonsSelectionComponent],
})
export class FuseLessonsModule { }
