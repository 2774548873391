import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Group, Learner, Workgroup} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subject, Subscription} from 'rxjs/index';
import {FormControl} from '@angular/forms';
import {DataEntity} from 'octopus-connect';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {cloneObject, localizedDate} from '../../../../shared/utils';
import {TranslateService} from '@ngx-translate/core';
import {ngxCsv} from 'ngx-csv';

@Component({
    selector: 'app-graph-group-management',
    templateUrl: './graph-group-management.component.html',
    styleUrls: ['./graph-group-management.component.scss']
})
export class GraphGroupManagementComponent implements OnInit, OnDestroy {

    private optionsInterface: any;
    public graphConfigObs: Subject<any> = new Subject();
    public learnerControl = new FormControl('');
    public activitiesControl = new FormControl('');
    public activitiesTypeControl = new FormControl('');
    public learnersListFiltered: Learner[] = [];
    public activitiesListFiltered: any[] = [];
    public activitiesTypeListFiltered: any[] = [];
    public groupsListFiltered: any[];
    public workgroupsListFiltered: any[];
    public displayedFilters: string[] = [];
    public graphDatas: DataEntity[] = [];
    public graphOptions: any;
    private graphSubscription: Subscription;
    private unsubscribeInTakeUntil = new Subject();
    private noData = '';

    /* Filters Form */
    public startDateControl = new FormControl('');
    public dueDateControl = new FormControl('');
    public groupControl = new FormControl('');
    public workgroupControl = new FormControl('');
    public dataControl = new FormControl([]);
    public minDueDate: any;
    public maxStartDate: any;
    public modalContent: any = {};
    public modeView = 'progress';
    public dataFilterFields = [];

    constructor(private communicationCenter: CommunicationCenterService,
                private groupManagementService: GroupsManagementService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): any {
        this.dataFilterFields = this.groupManagementService.settings['graph'][this.modeView].dataFilterFields;
        this.displayedFilters = this.groupManagementService.settings['graph'][this.modeView].displayedFilters;
        this.translate
            .get('graph-assignation.no-data')
            .subscribe((translation: string) => this.noData = translation);
        this.startDateControl.valueChanges.subscribe((data) => {
            if (data) {
                this.applyFilters( data.format('X'), 'date_from' );
                this.minDueLimit =  new Date(+data.format('x'));
            } else {
                this.applyFilters( null, 'date_from' );
                this.minDueLimit =  null;
            }
        });

        this.groupManagementService.loadActivityTypes().subscribe(collection => {
            if (collection.entities[0]) {
                this.communicationCenter
                    .getRoom('activities')
                    .getSubject('activitiesType').next(collection.entities[0].get('activityTypes'))
            }
        });

        this.dueDateControl.valueChanges.subscribe((data) => {
            if (data) {
                const newDate = new Date(+data.format('x'));
                newDate.setHours(23);
                newDate.setMinutes(59);
                this.applyFilters(newDate.getTime() / 1000, 'date_to' );
                this.maxStartLimit =  new Date(+data.format('x'));
            } else {
                this.applyFilters( null, 'date_to' );
                this.maxStartLimit =  null;
            }
        });

        this.groupControl.valueChanges.subscribe((data) => {
           if (data) {
               this.learnersListFiltered = data === 'all' ? this.learners : this.learners.filter((learner) => {
                    return learner.groups.indexOf(data.groupname) !== -1;
               });
               this.changeDetector.detectChanges();
           }
        });

        this.workgroupControl.valueChanges.subscribe((data) => {
           if (data) {
               this.learnersListFiltered = data === 'all' ? this.learners : this.learners.filter((learner) => {
                    return learner.workgroups.indexOf(data.groupname) !== -1;
               });
               this.changeDetector.detectChanges();
           }
        });

        this.learnerControl.valueChanges.subscribe((data) => {
           if (data) {
               this.changeDetector.detectChanges();
               this.applyFilters(data.id, 'uid');
           }
        });

        this.activitiesTypeControl.valueChanges.subscribe((data) => {
           if (data) {
               this.changeDetector.detectChanges();
               this.applyFilters(data.id, 'id');
           }
        });

        this.dataControl.valueChanges.subscribe((data) => {
           if (data) {
               this.changeDetector.detectChanges();
           }
        });

        this.graphSubscription = this.refreshList();
    }
    ngOnDestroy(): any {
        this.unsubscribeInTakeUntil.complete();
    }

    refreshList(): Subscription {
        this.dataFilterFields = this.groupManagementService.settings['graph'][this.modeView].dataFilterFields;
        return this.groupManagementService.loadPaginatedGraphDatas(this.modeView, this.optionsInterface)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((graphDatas: DataEntity[]) => {
                this.graphDatas = graphDatas;
                if (!this.graphDatas) {
                    return;
                }

                this.setConfig();

            });
    }

    private applyFilters(val, type): void {
        if (!this.optionsInterface) {
            this.optionsInterface = {
                filter: {}
            };
        }
        if (val === 'all' || !val) {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = val;
        }
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    public generate(): void {
        if (this.graphSubscription) {
            this.graphSubscription.unsubscribe();
        }
        this.graphSubscription = this.refreshList();
    }

    setConfig(): any {
        this.graphOptions = {};
        this.graphOptions['chartData'] = [];
        this.graphOptions['chartLabels'] = [];
        this.graphOptions['chartColors'] = [];
        this.graphOptions['chartConfig'] = {};
        this.graphOptions['modalContent'] = [];
        const pointTitles = [];

        const options = this.groupManagementService.settings['graph'][this.modeView]['options'];
        const defaultOptions = this.groupManagementService.settings['graph'][this.modeView]['defaultOptions'];
        const fields = this.groupManagementService.settings['graph'][this.modeView]['popUpFields'];
        const prePath = this.groupManagementService.settings['graph'][this.modeView]['prePath'];

        this.graphOptions['chartConfig'] = this.groupManagementService.settings['graph'][this.modeView]['config'];

        let title;
        this.translate.get('success').subscribe((val) => title = val);
        fields.forEach((val, key) => {
            this.translate.get(val).subscribe((translatedField) => fields[key] = translatedField);
        });

        if (this.modeView === 'progress' &&
            this.learnerControl.value &&
            this.learnerControl.value !== '' || this.modeView === 'metrics') {

            this.graphDatas.forEach((data) => {
                /*depending on the endpoint called,
                the path to the data is different,
                so it is retrieved in the settings*/
                const path = (prePath ?
                    data.get('data')[this.learnerControl.value.id][prePath] :
                    data.get('data')[this.learnerControl.value.id]);



                /*we translate the fields that we will see in the popup data*/
                const translatedField = fields.map((field, index) => '<li>' + field + ':' + (path[index] && path[index]['progress'] ? path[index]['progress'] : '') + '</li>');

                /**
                 * filter for display line in graph
                 */
                let dataFieldFiltered: string[];
                if (this.dataControl.value.length === 0 || this.dataControl.value.indexOf('all')) {
                    dataFieldFiltered = this.groupManagementService.settings['graph'][this.modeView]['dataFields'];
                } else {
                    dataFieldFiltered = this.groupManagementService.settings['graph'][this.modeView]['dataFields'].filter((field) => {
                        return this.dataControl.value.indexOf(field) !== -1;
                    });
                }

                /* We process all the data displayed in the graph
                (dataFields are each field name of a data object retrieved from the back) */
                this.graphOptions['chartData'] = dataFieldFiltered.map((dataField, index) => {
                    /**
                     *important, path here is the path to the data so its value will be the data retrieved from the API.
                     */
                    return this.generateDataForGraph(translatedField, defaultOptions, options, path, title, dataField, index);
                });

                if (this.modeView === 'progress') {
                    this.graphOptions['chartLabels'].push(...path.map((detail) => {
                        return localizedDate(detail.date);
                    }));
                }
                if (this.modeView === 'metrics') {
                    for (const idUser in data.get('data')) {
                        const username = this.learners.find((learner) => +learner.id === +idUser) ? this.learners.find((learner) => +learner.id === +idUser).username : 'eleve-' + idUser;
                        this.graphOptions['chartLabels'].push(username);
                    }
                }
            });

            this.graphOptions['titles'] = pointTitles;
            this.graphOptions['types'] = [];

            this.graphConfigObs.next(this.graphOptions);
        }
    }

    /**
     *we treat the graphics options and the configuration of the graph as well as the data displayed in the popups
     * @param translatedField
     * @param defaultOptions
     * @param options
     * @param path
     * @param popupTitle
     * @param dataFieldName
     * @param index
     * @returns {any}
     */
    generateDataForGraph(translatedField, defaultOptions, options, allDataFromBack, popupTitle, dataFieldName, index): any {
        this.graphOptions['chartColors'][index] = {...cloneObject(defaultOptions['style']), ...cloneObject(options[index] ? options[index]['style'] : {})};

        const graphData = {
            data: this.generateEachLinePoint(allDataFromBack, dataFieldName),
            label: [dataFieldName],
            type: options[index] ? options[index]['type'] : defaultOptions['type']
        };

        this.graphOptions['modalContent'].push(this.generateEachPointPopup(allDataFromBack, translatedField, popupTitle));

        return  graphData;
    }


    /**
     * we process the data of each point of a line
     * for each field in object data from the back, we retrieve
     * @param path
     * @param dataFieldName
     * @returns {any}
     */
    generateEachLinePoint(allDataFromBack, dataFieldName): any {
        return allDataFromBack.map((data) => {
            return data[dataFieldName];
        });
    }

    /**
     *  generate each popup for each point in line
     * @param path
     * @param translatedField
     * @param title
     * @returns {any}
     */
    generateEachPointPopup(path, translatedField, title): any {
        return path.map((field, index) => {
            return {
                header: '<label>' + title + ':' + localizedDate(path[index]['date']) + '</label>',
                content: '<ul>' + translatedField.join('') + '</ul>',
                footer: ''
            };
        });
    }

    public setModeView(type): void {
        this.modeView = type;
        this.generate();
    }

    exportList(): void {
        let list = [];
        const headers = this.groupManagementService.settings.graph[this.modeView].csv.header;

        list = this.graphDatas.map((data) => {
            const prePath = this.groupManagementService.settings['graph'][this.modeView]['prePath'];
            const path = (prePath ?
                data.get('data')[this.learnerControl.value ? this.learnerControl.value.id : '2853'][prePath] :
                data.get('data')[this.learnerControl.value ? this.learnerControl.value.id : '2853']);
            const lines = path.map((detail) => {
               if (this.modeView === 'progress') {
                   return {
                       learner: this.learnerControl.value.username ? this.learnerControl.value.username : 'no data',
                       date: localizedDate(detail['date']),
                       hours: this.transformDate(detail['date']),
                       activity: this.activitiesControl.value ? this.activitiesControl.value : 'no data',
                       activity_type: this.activitiesTypeControl.value ? this.activitiesTypeControl.value.label : 'no data',
                       duration: detail['progress'],
                       nb1: +detail['firstAnswer'],
                       nb2: +detail['secondAnswer'],
                       'nb-': +detail['wrongAnswer'],
                       'nb+': +detail['firstAnswer'] + +detail['secondAnswer'],
                   };
               }

               if (this.modeView === 'metrics') {

               }

            });

            return lines;
        });

        list = [].concat(...list);
        for (const field in headers) {
            this.translate
                .get(headers[field])
                .subscribe((translation: string) => headers[field] = translation);
        }

        this.translate
            .get('groups-management.csv.filename_graph')
            .subscribe((translation: string) => {
                const csv = new ngxCsv(list, translation, {showLabels: true, headers: headers, fieldSeparator: ';'});
            });

    }

    transformDate(data): string {
        const d = new Date(data * 1000);
        const hours   = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();

        let timeString = '' + ((hours > 12) ? hours - 12 : hours);
        timeString  += ((minutes < 10) ? ':0' : ':') + minutes;
        timeString  += ((seconds < 10) ? ':0' : ':') + seconds;

        return timeString;

    }

    public get learnersFiltered(): Array<Learner> {
        if (this.learnersListFiltered) {
            return this.learnersListFiltered;
        }

        return this.learners;
    }

    public get groupsFiltered(): Group[] {
        if (this.groupsListFiltered && this.groupsListFiltered.length) {
            return this.groupsListFiltered;
        }

        return this.groups;
    }

    public get workgroupsFiltered(): Workgroup[] {
        if (this.workgroupsListFiltered && this.workgroupsListFiltered.length) {
            return this.workgroupsListFiltered;
        }

        return this.workgroups;
    }

    public get activitiesTypeFiltered(): DataEntity[] {
        if (this.activitiesTypeListFiltered && this.activitiesTypeListFiltered.length) {
            return this.activitiesTypeListFiltered;
        }

        return this.activitiesType;
    }

    public get activitiesFiltered(): DataEntity[] {
        if (this.activitiesListFiltered && this.activitiesListFiltered.length) {
            return this.activitiesListFiltered;
        }

        return this.activities;
    }

    public get groups(): Group[] {
        if (this.groupManagementService.groupList && this.groupManagementService.groupList.length) {
            return this.groupManagementService.groupList;
        }

        return [];
    }
    public get workgroups(): Workgroup[] {
        if (this.groupManagementService.workgroupList && this.groupManagementService.workgroupList.length) {
            return this.groupManagementService.workgroupList;
        }

        return [];
    }

    public get learners(): Learner[] {
        if (this.groupManagementService.learnerList) {
            return this.groupManagementService.learnerList;
        }

        return [];
    }

    public get activitiesType(): DataEntity[] {
        if (this.groupManagementService.activitiesTypes) {
            return this.groupManagementService.activitiesTypes;
        }

        return [];
    }

    public get activities(): DataEntity[] {
        if (this.groupManagementService.activities) {
            return this.groupManagementService.activities;
        }

        return [];
    }

    public set minDueLimit(data) {
        this.minDueDate = data;
    }

    public set maxStartLimit(data) {
        this.maxStartDate = data;
    }

    public get dateLimit(): any {
        return this.minDueDate;
    }

    public get maxDateLimit(): any {
        return this.maxStartDate;
    }

    public get validGraph(): boolean {
        return !!this.graphDatas.length;
    }
}
