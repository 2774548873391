import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatDialogRef, MatTableDataSource} from '@angular/material';
import {CalendarEvent} from 'angular-calendar';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {InstitutionGroupService} from '@modules/groups-management/core/institution-group/institution-group.service';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {fuseAnimations} from 'fuse-core/animations';
import {DataCollection} from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../../settings';
import {GroupService} from '@modules/groups-management/core/group/group.service';
import {CommunicationCenterService} from '@modules/communication-center';

const settingsStructure = new ModelSchema({
    levelsWidget: Structures.string('input'),
    levelsRequired: Structures.boolean(false)
});


@Component({
    selector: 'fuse-groups-form-dialog',
    templateUrl: './groups-form.component.html',
    styleUrls: ['./groups-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class FuseGroupsFormDialogComponent implements OnInit, OnDestroy {
    public displayedColumns = ['field'];
    public dataSource = new MatTableDataSource();

    event: CalendarEvent;
    dialogTitle: string;
    entityForm: FormGroup;
    action: string;
    fields: string[];
    colors: string[];
    selects: {[key: string]: string[]};
    entity: any;
    chips: string[];
    allChips: string[];

    entityType: string;

    groupname: string[];
    workgroupname: string[];

    typeEntity: string;

    visible: boolean = true;
    selectable: boolean = true;
    removable: boolean = true;
    addOnBlur: boolean = false;

    separatorKeysCodes = [ENTER, COMMA];

    chipsCtrl = new FormControl();
    filteredChips: Observable<any[]>;

    myCodePostalControl = new FormControl();
    filteredPostalCodes: Observable<string[]>;

    institutions: Array<any> = [];

    institutionSelected: any;

    entityFormErrors: any;

    postalCodes: any;

    levels: DataCollection;

    institutionTitleFormControl: FormControl = new FormControl();

    countEntities = null;
    pageIndex = 0;

    /** number of retrieve result from server per page**/
    pageRange = 10;

    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 10
    };

    /****** workgroup chips *******/
    workgroupChips = []; // array of workgroup Chips selected
    workgroupCtrl = new FormControl();
    workgroupAddOnBlur = false;
    workgroupSeparatorKeysCodes = [ENTER, COMMA];
    workgroupSelectable = true;
    workgroupRemovable = true;
    workgroupFilteredChips: Observable<any[]>;

    workgroupAllChips = [];

    public settings: {[key: string]: any};

    originEntity: any;

    @ViewChild('groupsChipInput') groupsChipInput: ElementRef;
    @ViewChild('workgroupChipInput') workgroupChipInput: ElementRef;

    constructor(
        private translate: TranslateService,
        public dialogRef: MatDialogRef<FuseGroupsFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private formBuilder: FormBuilder,
        private institutionService: InstitutionGroupService,
        private groupService: GroupService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.groupsManagement);

        this.originEntity = data;
        this.action = this.originEntity.data.action;
        this.dialogTitle = 'groups-management.' + this.originEntity.data.title;
        this.fields = this.originEntity.data.fields;
        this.selects = this.originEntity.data.selects;
        this.colors = this.originEntity.data.colors;
        this.chips = [];
        this.institutionSelected = [];

        this.groupname = this.originEntity.data.selects && this.originEntity.data.selects.class ? this.originEntity.data.selects.class() : [];
        this.workgroupname = this.originEntity.data.selects && this.originEntity.data.selects.workgroups ? this.originEntity.data.selects.workgroups() : [];
        this.postalCodes = this.mergePostalCodes(this.originEntity.data.selects && this.originEntity.data.selects.metadata ? this.originEntity.data.selects.metadata() : {});
        this.typeEntity = this.originEntity.data.typeEntity;

        if (this.originEntity.data.action === 'edit') {
            this.allChips = this.originEntity.data.item.groups ? this.originEntity.data.selects.groups().filter((group) => this.originEntity.data.item.groups.indexOf(group) === -1) : [];
            this.workgroupAllChips = this.originEntity.data.item.workgroups ? this.originEntity.data.selects.workgroups().filter((workgroup) => this.originEntity.data.item.workgroups.indexOf(workgroup) === -1) : [];
        } else {
            this.allChips = this.originEntity.data.selects && this.originEntity.data.selects.groups ? this.originEntity.data.selects.groups() : [];
            this.workgroupAllChips = this.originEntity.data.selects && this.originEntity.data.selects.workgroups ? this.originEntity.data.selects.workgroups() : [];
        }




        if (this.fields && this.fields.indexOf('groupname') !== -1 || this.fields && this.fields.indexOf('workgroupname') !== -1) {
            this.entityFormErrors = {
                groupname: {},
                workgroupname: {},
                username: {}
            };
        }

        if (this.fields && this.fields.indexOf('username') !== -1 && (this.typeEntity === 'learner' || this.typeEntity === 'trainer')) {
            this.entityFormErrors = {
                username: {},
                groups: {}
            };
            if (this.fields.indexOf('passwordConfirm') !== -1) {
                this.entityFormErrors['passwordConfirm'] = {};
            }
        }

        if (this.fields && this.fields.indexOf('institution') !== -1) {
            this.entityFormErrors = {
                institution: {}
            };
        }

        this.institutions = this.originEntity.data.selects && this.originEntity.data.selects.parent ? this.originEntity.data.selects.parent() : [];

        this.dataSource.data = this.institutions;


        this.entityType = 'groups-management.' + this.originEntity.data.entityType;

        this.filteredChips = this.chipsCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: string | null) => chip ? this.filter(chip, 'groups') : this.allChips.slice()));

        this.workgroupFilteredChips = this.workgroupCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: string | null) => chip ? this.filter(chip, 'workgoup') : this.workgroupAllChips.slice()));

        if (this.action === 'edit') {
            this.entity = this.originEntity.data.item;
            this.chips = this.originEntity.data.item.groups ? this.originEntity.data.item.groups : [];
            this.workgroupChips = this.originEntity.data.item.workgroups ? this.originEntity.data.item.workgroups.slice() : [];

            this.entityForm = this.editEntityForm(this.originEntity.data.fields);

        } else {
            // set value by default when (select value) init
            this.myCodePostalControl.reset(null);

            this.entity = {};
            this.entityForm = this.createEntityForm(this.originEntity.data.fields);
            if (this.entityForm.get('groups')) {
                this.entityForm.get('groups').setValue([]);
                this.entityForm.get('groups').setValidators((control) => this.chipsGroupsValidate(control))
                this.chips = this.entityForm.value.groups;
            }
        }

        this.entityForm.valueChanges.subscribe(() => {
            this.onRegisterFormValuesChanged();
        });

    }


    /********* institution filter**********/
    ngOnInit(): any {

        this.getLevels();
        this.translate.onLangChange.subscribe(() => {
            this.getLevels();
        });

        this.setPaginator();

        // filteredPostalCodes is used to retrieve the postalcodes filtered when value change
        this.filteredPostalCodes = this.myCodePostalControl.valueChanges
            .pipe(
                startWith<string | any>(''),

                // return the filtered postalCodes or an copy of postalCodes array empty
                map(value => value ? this._filterPostalCodes(value) : this.postalCodes.slice())
            );

        this.myCodePostalControl.valueChanges
            .subscribe(query => {
                if (!query || query === '') {
                    delete this.optionsInterface.filter.postalCode;
                } else {
                    this.applyFilters({value: query}, 'postalCode');
                }
            });

        this.institutionTitleFormControl.valueChanges
            .subscribe(query => {
                this.applyFilters({value: query}, 'label');
            });

    }

    getLevels(): void {
        this.groupService.loadLevels().subscribe((data) => {
            this.levels = data;
        });
    }

    ngOnDestroy(): any {
        this.resetInstitution();
    }

    hasField(field: string): boolean {
        return this.fields && this.fields.indexOf(field) > -1;
    }

    hasPasswordField(): boolean {
        return !(this.action === 'edit' && this.entity['sso']);
    }

    hasLaunchSearchButton(): boolean {
        return this.originEntity.data.typeEntity === 'institution' && this.originEntity.data.action === 'new';
    }

    chipsGroupsValidate(control: AbstractControl): any {
        if (this.chips.length > 0 && this.entityForm.get('groups').valid) {
            return;
        } else {
            return {required: true};
        }
    }

    createEntityForm(fields: string[]): FormGroup {
        const config = {};
        fields.forEach((field: string) => {
            if (field === 'groupname' || field === 'workgroupname') {
                config[field] = [this.entity[field], [Validators.required, (control) => this.nameAlreadyExist(control)]];
            } else if (field === 'username' && this.typeEntity === 'learner') {
                config[field] = [this.entity[field], [Validators.required, (control) => this.checkNameNotEmail(control)]];
            } else if (field === 'level' && this.settings.levelsRequired) {
                config[field] = [this.entity[field], [Validators.required]];
            } else if (field === 'institution') {
                config[field] = [this.entity[field], (control) => this.checkInstitutionSelected(control)];
            } else if (field === 'passwordConfirm') {
                config[field] = ['', [Validators.required, (control) => this.confirmPassword(control)]];
            } else {
                config[field] = [this.entity[field]];
            }
        });

        return this.formBuilder.group(config);
    }

    editEntityForm(fields: string[]): FormGroup {
        const config = {};
        fields.forEach((field: string) => {
            if (field === 'groupname' || field === 'workgroupname') {
                config[field] = [this.entity[field], [Validators.required, (control) => this.nameAlreadyExist(control)]];
            } else if (field === 'username' && this.typeEntity === 'learner') {
                config[field] = [{value: this.entity[field], disabled: this.entity['sso']}, [Validators.required, (control) => this.checkNameNotEmail(control)]];
            } else if (field === 'institution') {
                config[field] = [this.entity[field], (control) => this.checkInstitutionSelected(control)];
            } else if (field === 'parent' && this.entity[field]) {
                config[field] = this.institutions.find((institution) => this.entity[field].id === institution.id);
            } else if (field === 'passwordConfirm') {
                config[field] = ['', [Validators.required, (control) => this.confirmPassword(control)]];
            } else {
                config[field] = [this.entity[field]];
            }
        });
        return this.formBuilder.group(config);
    }

    colorSelected(color: string): boolean {
        return this.entityForm.get('color').value === color;
    }

    onRegisterFormValuesChanged(): void {
        const controlGroups = this.entityForm.get('groups');
        const controlGroupName = this.entityForm.get('groupname');
        const controlWorkgroupName = this.entityForm.get('workgroupname');
        const controlUserName = this.entityForm.get('username');
        const controlInstitution = this.entityForm.get('institution');
        const controlPassword = this.entityForm.get('password');
        const controlPasswordConfirm = this.entityForm.get('passwordConfirm');

        if (this.entityFormErrors) {
            // use to know form errors

            for (const field in this.entityFormErrors) {
                this.entityFormErrors[field] = {};
            }

        }

        if (controlGroups && this.chipsCtrl.dirty && !controlGroups.valid) {
            this.entityFormErrors['groups'] = controlGroups.errors;
        }
        if (controlGroupName && controlGroupName.dirty && !controlGroupName.valid) {
            this.entityFormErrors['groupname'] = controlGroupName.errors;
        }
        if (controlWorkgroupName && controlWorkgroupName.dirty && !controlWorkgroupName.valid) {
            this.entityFormErrors['workgroupname'] = controlWorkgroupName.errors;
        }

        if (controlUserName && controlUserName.dirty && !controlUserName.valid) {
            this.entityFormErrors['username'] = controlUserName.errors;
        }

        if (controlInstitution && controlInstitution.dirty && !controlInstitution.valid) {
            this.entityFormErrors['institution'] = controlInstitution.errors;
        }

        if (controlPassword && controlPassword.dirty && !controlPassword.valid) {
            this.entityFormErrors['password'] = controlPassword.errors;
        }

        if (controlPasswordConfirm && controlPasswordConfirm.dirty && !controlPasswordConfirm.valid) {
            this.entityFormErrors['passwordConfirm'] = controlPasswordConfirm.errors;
        }

    }

    remove(chip: any, type): void {

        let index: number;
        if (type === 'groups') {
            index = this.chips.indexOf(chip);
            if (index >= 0) {
                this.chips.splice(index, 1);
                this.allChips.push(chip);
                this.setGroupsAndWorkgroups('groups');
            }
        } else {
            index = this.workgroupChips.indexOf(chip);
            if (index >= 0) {
                this.workgroupChips.splice(index, 1);
                this.workgroupAllChips.push(chip);
                this.setGroupsAndWorkgroups('workgroups');
            }
        }

    }

    filter(name: string, type): Array<string> {
        if (type === 'groups') {
            return this.allChips.filter(chip =>
                chip.toLowerCase().indexOf(name.toLowerCase()) === 0);
        } else {
            return this.workgroupAllChips.filter(chip =>
                chip.toLowerCase().indexOf(name.toLowerCase()) === 0);
        }

    }

    selected(event: MatAutocompleteSelectedEvent, type): void {
        let index;
        if (type === 'groups') {
            this.chipsCtrl.setValue(null);
            if (!this.alreadyExisting(event.option.viewValue, type)) {
                this.chips.push(event.option.viewValue);
                this.groupsChipInput.nativeElement.value = '';

                index = this.allChips.indexOf(event.option.viewValue);
                this.allChips.splice(index, 1);
                this.setGroupsAndWorkgroups('groups');
            }
        } else {
            this.workgroupCtrl.setValue(null);
            if (!this.alreadyExisting(event.option.viewValue, type)) {
                this.workgroupChips.push(event.option.viewValue);
                this.workgroupChipInput.nativeElement.value = '';

                index = this.workgroupAllChips.indexOf(event.option.viewValue);
                this.workgroupAllChips.splice(index, 1);
                this.setGroupsAndWorkgroups('workgroups');
            }
        }

        this.blurAllChipsList();
    }

    setGroupsAndWorkgroups(type): void {

        if (type === 'groups') {
            this.entityForm.get('groups').setValue(this.chips);
            this.filteredChips = this.chipsCtrl.valueChanges.pipe(
                startWith(null),
                map((chip: string | null) => chip ? this.filter(chip, 'groups') : this.allChips.slice()));
        } else {
            this.entityForm.get('workgroups').setValue(this.workgroupChips);
            this.workgroupFilteredChips = this.workgroupCtrl.valueChanges.pipe(
                startWith(null),
                map((chip: string | null) => chip ? this.filter(chip, 'workgoup') : this.workgroupAllChips.slice()));
        }
    }

    blurAllChipsList(): void {
        this.groupsChipInput.nativeElement.blur();
        this.workgroupChipInput.nativeElement.blur();
    }

    alreadyExisting(name: string, type): boolean {
        if (type === 'groups') {
            return this.chips.indexOf(name) !== -1;
        } else {
            return this.workgroupChips.indexOf(name) !== -1;

        }
    }

    selectType(type): string {
        if (type) {
            return 'groups-management.' + type.toString();
        }
    }

    resetInstitution(): void {

        if (this.institutionService.institutionInUserSubscription) {
            this.institutionService.resetOptionInterfaces();
        }

    }

    selectedInstitution(event: MatAutocompleteSelectedEvent): void {
        this.institutionSelected = event;
        this.entityForm.get('institution').setValue(this.institutionSelected);
    }

    private _filterPostalCodes(name: string): Array<any> {

        const filterValue = name.toLowerCase();

        return this.postalCodes.filter(option => option.toLowerCase()
            .includes(filterValue));
    }

    confirmPassword(control: AbstractControl): any {
        if (!control.parent || !control) {
            return;
        }

        const password = control.parent.get('password');
        const passwordConfirm = control.parent.get('passwordConfirm');

        if (!password || !passwordConfirm) {
            return;
        }

        if (passwordConfirm.value === '') {
            return;
        }

        if (password.value !== passwordConfirm.value) {
            return {
                passwordsNotMatch: true
            };
        }
    }

    nameAlreadyExist(control: AbstractControl): any {
        let name;
        let type;

        if (!control || !control.parent) {
            return;
        }

        if (this.groupname && this.groupname.length > 0) {
            name = control.parent.get('groupname');
            type = 'groupname';
        }

        if (this.workgroupname && this.workgroupname.length > 0) {
            name = control.parent.get('workgroupname');
            type = 'workgroupname';
        }

        if (!name || name.value === '') {
            return;
        }


        if (this.action === 'edit' && name.value === this.entity[type]) {
            return;
        }

        if (this.checkIfNameExist(type, name.value)) {
            return {
                alreadyExist: true
            };
        }

    }

    checkNameNotEmail(control: AbstractControl): any {
        let name;
        if (!control || !control.parent) {
            return;
        }

        name = control.parent.get('username');

        if (!name || name.value === '') {
            return;
        }

        if (this.validateEmail(name.value)) {
            return {
                nameInvalid: true
            };
        }
    }

    checkIfNameExist(type: string, entry: string): boolean {
        if (type) {
            return this[type].indexOf(entry) !== -1;
        }
    }

    validateEmail(email): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkInstitutionSelected(control: AbstractControl): any {

        if (!control || !control.parent) {
            return;
        }

        const institution = control.parent.get('institution');

        if (!institution) {
            return;
        }

        if (this.institutionSelected && !('id' in this.institutionSelected)) {
            return {
                institutionNotSelected: true
            };
        }

        return;

    }

    mergePostalCodes(data): Array<any> {
        const allPostalCodes = [];
        const datas = data.postalCodes;

        for (const country in datas) {
            if (country) {
                allPostalCodes.push(...datas[country]);
            }
        }

        return allPostalCodes;

    }


    applyFilters(event, type): void {
        if (event.option && event.option.value !== '') {
            this.optionsInterface.filter[type] = event.option.value;
        } else {
            if (event.value === '') {
                delete this.optionsInterface.filter[type];
            } else {
                this.optionsInterface.filter[type] = event.value;
            }
        }


    }


    setPaginator(): void {
        if (this.institutionService.paginatedCollection.paginator) {
            this.countEntities = this.institutionService.paginatedCollection.paginator.count;
            this.pageIndex = this.institutionService.paginatedCollection.paginator.page - 1;
            this.pageRange = this.institutionService.paginatedCollection.paginator.range;
        }
    }

    onPaginateChange(event): void {
        const page = event.pageIndex + 1;
        this.optionsInterface.page = page;

        this.launchSearch();
    }

    launchSearch(): void {
        this.institutionService.launchSearch(() => this.setDataSource(), this.optionsInterface);
    }

    setDataSource(): void {
        this.dataSource.data = this.institutionService.getAllInstitutionsFormated();
        this.setPaginator();
    }

    close(entityForm): void{
        this.originEntity.callback(entityForm).subscribe((data) => {
                this.dialogRef.close();
            }, (error) => {
                if (error.data.response.title === 'account-management.username_taken') {
                    this.entityForm.controls['username'].setErrors({'taken': true});
                    this.entityFormErrors.username.taken = true;
                }
            }
        );
    }
}

