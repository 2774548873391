import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {extractUrls} from '../../../../../../shared/utils';

@Component({
    selector: 'app-video-markers',
    templateUrl: './video-markers.component.html',
    styleUrls: ['./video-markers.component.scss']
})
export class VideoMarkersComponent implements OnInit, OnDestroy, AfterViewInit {


    public form0: FormGroup;
    public form1: FormGroup;
    public isEditor: boolean;
    public markerType: any;
    public step: number = 0;
    public answer: any;
    public showSpinner: boolean;
    public openMarkerAutomatic: boolean;
    public urlandString: { isUrl: boolean, text: string } [] = [];
    public marker: any;
    private saveMarker: any;
    private removeMarker: any;
    private videoConfig: any;
    private fields: string[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                public dialogRef: MatDialogRef<VideoMarkersComponent>,
                private activitiesService: ActivitiesService) {
    }

    ngOnInit(): any {
        this.setVideo();
        this.markerType = this.data.markerType;
        this.marker = this.data.marker;
        this.isEditor = this.data.isEditor;
        this.saveMarker = this.data.callback;
        this.answer = this.data.answer;
        this.openMarkerAutomatic = this.data.videoConfig.openMarkerAutomatic;
        this.removeMarker = this.data.videoConfig.removeMarker;
        this.form0 = this.createForm('form0');
        this.form1 = this.createForm('form1');
        this.fields = this.settings ? this.settings.markerFields : [];
        if (!this.isEditor) {
            this.form0.disable();
            if (this.data && this.data.step) {
                this.step = this.data.step;
            }
            // check if url field contain url if yes we will use an array of url and text instead of basic input
            if (this.useUrlLink()) {
                this.urlandString = this.splitStringAndUrl(this.marker.description[5]);
            }

        } else {
            this.form1.disable();
        }
    }

    ngAfterViewInit(): any {
    }

    ngOnDestroy(): any {
    }

    private createForm(form): any {
        const config = {};
        if (form === 'form0') {
            const fields = ['title', 'location', 'question', 'clue', 'tracks', 'url'];
            fields.forEach((field, key) => {
                if (field === 'title' || field === 'location') {
                    switch (field) {
                        case 'title':
                            if (this.marker && this.marker.title && this.marker.title !== '') {
                                config['title'] = [{value: this.marker.title, disabled: true}];
                            } else {
                                // type can be dataEntity "markerType" or object from dataEntity "marker"
                                if (this.markerType && this.markerType.attributes) {
                                    config['title'] = [this.markerType && this.markerType.id && this.markerType.get('description') ? {
                                        value: this.markerType.get('description')[key],
                                        disabled: true
                                    } : ''];
                                } else {
                                    config['title'] = [this.markerType && this.markerType.id && this.markerType.description ? {
                                        value: this.markerType.description[key],
                                        disabled: true
                                    } : ''];
                                }
                            }
                            break;
                        case 'location':
                            config['location'] = [this.marker && this.marker.time && this.marker.time !== '' ? {
                                value: new Date(Math.round(+this.marker.time) * 1000).toISOString().substr(11, 8),
                                disabled: true
                            } : ''];
                            break;
                    }
                } else {
                    if (this.marker && this.marker.description && this.marker.description[key] && this.marker.description[key] !== '') {
                        config[field] = [this.marker.description[key]];
                    } else {
                        // type can be dataEntity "markerType" or object from dataEntity "marker"
                        if (this.markerType && this.markerType.attributes) {
                            config[field] = [this.markerType && this.markerType.id && this.markerType.get('description') ? this.markerType.get('description')[key] : ''];
                        } else {
                            config[field] = [this.markerType && this.markerType.id && this.markerType.description ? this.markerType.description[key] : ''];
                        }
                    }
                }
            });
        } else {
            config['survey-notes'] = [this.answer && this.answer.answer ? this.answer.answer : ''];
        }

        return new FormBuilder().group(config);
    }

    private setVideo(): void {
        this.videoConfig = this.data.videoConfig;
        this.videoConfig['config']['controls'] = false;
        this.videoConfig['markers'] = [this.data.marker];
        this.videoConfig['startTime'] = this.data.marker.time;
        this.videoConfig['isMarkerOpened'] = true;
    }

    public next(): void {
        if (this.step === 1) {
            this.save();
        } else {
            this.step = 1;
        }
    }

    public previous(): void {
        this.step = 0;
    }

    public save(): void {
        if (!this.showSpinner) {
            this.showSpinner = true;
            const form = this.isEditor ? this.form0 : this.form1;
            let obsMarker;

            if (form.controls['location']) {
                // set data in seconds before saving
                form.controls['location'].setValue(Math.round(+this.marker.time));
            }
            obsMarker = this.isEditor ? this.saveMarker(form.getRawValue()) : this.saveMarker(this.marker, form.getRawValue());

            obsMarker.subscribe((entity) => {
                this.showSpinner = false;
                this.close(entity);
            });
        }
    }

    delete(): void {
        if (!this.showSpinner) {
            this.removeMarker(this.marker);
            this.close();

        }
    }

    displayField(field): boolean {
        return this.fields.indexOf(field) !== -1;
    }

    public close(result = null): void {
        this.dialogRef.close(result);
    }

    private get settings(): any {
        if (this.activitiesService.settings && this.activitiesService.settings.lessonStep) {
            return this.activitiesService.settings.lessonStep;
        }
    }

    public get titleType(): string {
        const label = this.markerType && this.markerType.attributes ? this.markerType.get('label') : this.markerType.label;
        if (label && label === 'activities.personalize' && this.form0 && this.form0.get('title').value !== '') {
            return this.form0.get('title').value;
        }
        return label;
    }

    public get addOrEditTitle(): string {
        return this.marker && this.marker.id ? 'activities.edit_marker' : 'activities.add_marker';
    }

    /**
     * check if read only field and if content is url or text => show a link
     */
    public useUrlLink(): boolean {
        return this.form0.disabled && !!extractUrls(this.marker.description[5]);
    }

    /**
     * split a full string on an array of text and of url
     * @param fullText:  string to convert
     */
    private splitStringAndUrl(fullText: string): { isUrl: boolean, text: string } [] {
        let result: { isUrl: boolean, text: string }[] = [];
        const txt = fullText.split(' ');
        txt.forEach(content => {
            result.push({
                isUrl: !!extractUrls(content),
                text: content
            });
        });
        return result;
    }
}

