import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {ModelSchema, Structures} from 'octopus-model';
import {defaultURL, modulesSettings} from '../../../settings';

const settingsStructureAuth: ModelSchema = new ModelSchema({
    enableSSO: Structures.boolean(false),
    urlSSO: Structures.object(),
    logoutUrlSSO: Structures.object(),
    displayLoginLogo: Structures.boolean(false)
});

const settingsStructureAccount: ModelSchema = new ModelSchema({
    selfSignup: Structures.boolean(true),
    signup: Structures.boolean(true)
});

@Injectable()
export class AuthenticationService {

    /**
     * Mapping between role name and role id.
     * It can be given by the server but in all the instance we work to always use the same name/id combination
     */
    private static readonly roleMapping = {
        administrator: 3,
        manager: 4,
        trainer: 5,
        learner: 6
    };

    loggedUser: DataEntity;
    isAuthenticated: boolean = false;
    subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    errorHttpAuthentication: ReplaySubject<any> = new ReplaySubject<any>(1);
    public settings: { [key: string]: any };

    constructor(
        private octopusConnect: OctopusConnectService,
        private router: Router,
        private translate: TranslateService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.settings = settingsStructureAuth.filterModel(modulesSettings.authentication);
        Object.assign(this.settings, settingsStructureAccount.filterModel(modulesSettings.accountManagement));
        this.octopusConnect.getUnexpectedLogoutSubject('http').subscribe(() => {
            this.doLogout(null);
        }, (error: Object) => {
            this.errorHttpAuthentication.next(error);
        });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('do-logout')
            .subscribe((callback) => {
                this.logoutFrom('http');
                callback();
            });

        this.communicationCenter
            .getRoom('authentication')
            .next('roles', AuthenticationService.roleMapping);
    }

    loginSSO(code): void {
        this.octopusConnect.createEntity('user-registration', {code: code}).subscribe((userData: DataEntity) => {
            if (userData && userData.get('token')) {
                this.router.navigate(['/user/reset/', userData.get('token')]);
            }
        }, (error: Object) => {
            this.errorHttpAuthentication.next(error);
        });
    }

    authenticateIn(serviceName: string, login: string, password: string): Observable<DataEntity> {
        const obs: Observable<DataEntity> = this.octopusConnect.authenticate(serviceName, unescape(encodeURIComponent(login)), unescape(encodeURIComponent(password)));

        obs.take(1).subscribe((data: DataEntity) => {
            this.onAuthenticated(data);
        }, (err) => {
            this.errorHttpAuthentication.next(err);
        });

        return obs;
    }

    onAuthenticated(data: DataEntity): void{
        this.loggedUser = data;
        this.isAuthenticated = true;
        this.communicationCenter.getRoom('authentication').next('userData', data);
    }

    get userData(): any {
        if (this.loggedUser) {
            this.isAuthenticated = true;
            return this.loggedUser;
        }

        return null;
    }

    get accessLevel(): string {
        if (this.loggedUser) {
            const role = this.loggedUser.get('role') || [];

            if (role.indexOf(3) > -1) {
                return 'administrator';
            }
            if (role.indexOf(4) > -1) {
                return 'manager';
            }
            if (role.indexOf(5) > -1) {
                return 'trainer';
            }
            if (role.indexOf(6) > -1) {
                return 'learner';
            }
            if (role.indexOf(2) > -1) {
                return 'authenticated';
            }
        }

        return 'anonymous';
    }

    public isMe(id: string | number): boolean {
        if (this.loggedUser) {
            return this.loggedUser.id.toString() === id.toString();
        }

        return false;
    }

    public isAnonymous(): boolean {
        return this.accessLevel === 'anonymous';
    }

    public isAuthenticatedUser(): boolean {
        return this.loggedUser.get('role').indexOf(2) > -1;
    }

    public isSSO(): boolean {
        return this.loggedUser.get('sso');
    }

    public isLearner(): boolean {
        return this.accessLevel === 'learner';
    }

    public isAtLeastLearner(): boolean {
        return this.hasLevel(['learner', 'trainer', 'manager', 'administrator']);
    }

    public isTrainer(): boolean {
        return this.accessLevel === 'trainer';
    }

    public isAtLeastTrainer(): boolean {
        return this.hasLevel(['trainer', 'manager', 'administrator']);
    }

    public isManager(): boolean {
        return this.accessLevel === 'manager';
    }

    public isAtLeastManager(): boolean {
        return this.hasLevel(['manager', 'administrator']);
    }

    public isAdministrator(): boolean {
        return this.accessLevel === 'administrator';
    }

    public hasLevel(levels: string[]): boolean {
        return levels.indexOf(this.accessLevel) > -1;
    }

    logoutFrom(serviceName: string): void {
        let token;
        if (this.settings.enableSSO && this.loggedUser.get('sso')) {
            token = this.loggedUser.get('sso_token');
        }

        const data = new DataEntity('authenticated', {myType: 'authenticated'}, this.octopusConnect, this.loggedUser['id']);
        data.remove();

        this.octopusConnect.logout(serviceName).subscribe(() => {
            this.doLogout(token);
        });
    }

    forgotPassword(login: string): Observable<DataEntity> {
        return this.octopusConnect.createEntity('reset-password', {'mail': login, 'lang': this.translate.currentLang});
    }

    protected doLogout(token: any): void {
        this.isAuthenticated = false;
        this.communicationCenter.getRoom('authentication').next('userData', null);
        if (this.settings.enableSSO && token) {
            window.location.href = this.settings.logoutUrlSSO[this.translate.currentLang] + token + '&redirect_uri=' + defaultURL + 'login';
        } else {
            this.router.navigate(['/login']);
        }
    }
}
