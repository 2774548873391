import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ResearchSheetService} from '../../research-sheet.service';
import {ResearchSection, ResearchSheet} from '../../definitions';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchSheetFormComponent} from '@modules/research-sheet/core/research-sheet/research-sheet-form/research-sheet-form.component';
import {MatDialog} from '@angular/material';
import {FormGroup} from '@angular/forms';
import {fuseAnimations} from 'fuse-core/animations';
import {localizedDate} from '../../../../../shared/utils';
import {GenericModalService} from '../../generic-modal.service';
import {Subject} from 'rxjs';
import {AuthenticationService} from '@modules/authentication';

@Component({
  selector: 'app-research-sheet-list',
  templateUrl: './research-sheet-list.component.html',
  styleUrls: ['./research-sheet-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ResearchSheetListComponent implements OnInit, OnDestroy {
  private dialogRef: any;

  public sheetsList: ResearchSheet[] = [];

  private unsubscribeInTakeUntil = new Subject();

  constructor(
    public sheetService: ResearchSheetService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private genericModal: GenericModalService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
      this.sheetService.onSheetsChanged
          .takeUntil(this.unsubscribeInTakeUntil)
          .subscribe((sheets: ResearchSheet[]) => {
              console.log('init researchSheet', this.sheetsList);
              this.sheetsList = sheets.filter(sheet => sheet.group != null && sheet.project === this.sheetService.currentProjectId);
          });
  }

  ngOnDestroy() {
    this.unsubscribeInTakeUntil.next();
    this.unsubscribeInTakeUntil.complete();
  }

  public mostRecentActivity(sheet: ResearchSheet): ResearchSection[] {
    const sections = sheet.sections.slice();
    sections.sort((sectionA, sectionB) => sectionB.updated - sectionA.updated);
    return sections.slice(0, 2);
  }

  public goToSheet(sheet: ResearchSheet) {
    this.router.navigate([`../${sheet.id}`], {relativeTo: this.route});
  }

  public newSheet() {
    this.dialogRef = this.dialog.open(ResearchSheetFormComponent, {
      panelClass: 'research-sheet-form-dialog',
      data: {
        action: 'new'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }

        this.sheetService.addResearchSheet(response.getRawValue());
      });
  }

  public editSheet(sheet: ResearchSheet) {
    this.dialogRef = this.dialog.open(ResearchSheetFormComponent, {
      panelClass: 'research-sheet-form-dialog',
      data: {
        action: 'edit',
        sheet: sheet
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }

        this.sheetService.saveResearchSheet(response.getRawValue());
      });
  }

  public deleteSheet(sheet: ResearchSheet) {
      this.genericModal.openYesNoDialog('generic.delete').subscribe((resp: string) => {
          if (resp === 'yes') {
              this.sheetService.deleteResearchSheet(sheet);
          }
      });
  }

  public localeDate(date: number): string {
    return localizedDate(date);
  }
}
