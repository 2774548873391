export const cards = {
    cardDisplay: {
        showButtonThemeLesson: true,
        showButtonBookmarks: true,
        isTextButton: true,
    },
    cardFields: {
        default: ['title', 'titleIcon', 'menu', 'image', 'contentDetails']
    },
    cardDefaultHeader: '/assets/lamorim/images/backgrounds/header.png',
    cardPlayOpenMenu: false,
};
