export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    'accessorize': 'accessorize',
    'agenda': 'agenda',
    'allTypes': 'toustypes',
    'archive': 'archiver',
    'assignment_ind': 'assignment_ind',
    'assignment': 'assignment_ind',
    'avatar': 'avatar',
    'bookmark': 'collections_bookmark',
    'brand': 'brand',
    'chat_basic': 'chat_basic',
    'check': 'check',
    'search': 'search',
    'contacts': 'mesclasses',
    'corpus': 'ressources',
    'corpus_pdf': 'ressources',
    'credits': 'credits',
    'delete': 'delete',
    'document': 'document',
    'duplicate': 'duplicate',
    'expand': 'play',
    'gestioncompte': 'mesclasses',
    'help': 'help',
    'import_export': 'import_export',
    'info': 'info',
    'link': 'url',
    'lock': 'lock',
    'log_out': 'exit_to_app',
    'music': 'audio',
    'next': 'next',
    'notifications': 'notification',
    'pdf': 'ressources',
    'photo': 'image',
    'previous': 'previous',
    'rafraichir': 'rafraichir',
    'reset_answer': 'reset',
    'save': 'save',
    'see_solution': 'visibility',
    'shield': 'confi',
    'step_error': 'close',
    'step_success': 'check',
    'step_validated': 'check',
    'step_warning': 'warning',
    'transition': 'transition',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'unlock': 'unlock',
    'user': 'account_circle',
    'warning': 'warning',
}

