export const mainMenu = {
    'level0':
        [
            'dashboard',
            {
                'name': 'parameter',
                'translate': {
                    'parent': {
                        learner: 'generic.my_classes',
                        default: 'navigation.groups-management'
                    },
                    'childs': {
                        'groups': {
                            learner: 'groups-management.classes',
                            default: 'groups-management.classes'
                        }
                    }
                }
            },
            'analytics',
            'Activities',
            'corpus',
            'Lessons',
            {
                'name': 'followed',
                'translate': {
                    'parent': {
                        learner: 'generic.my_lessons',
                        default: 'assignment.title'
                    }
                }
            },
            'graph-followed',
            'gamification',
            'agenda',
            'messaging',
            'configuration-data',
            'needhelp',
            'privacy'
        ],
    'followed': [
        'graph-followed'
    ]
};