import 'rxjs/add/operator/map';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {AuthenticationService} from '@modules/authentication';
import {ChaptersSelectionComponent} from '@fuse/components/chapters-selection/chapters-selection.component';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataCollection, DataEntity} from 'octopus-connect';
import {DialogComponent} from './../shared-components/dialog/dialog.component';
import {FormControl} from '@angular/forms';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {ISingleActivityNavParams} from '@modules/activities/core/models';
import {LessonsSelectionComponent} from 'fuse-core/components/lessons-selection/lessons-selection.component';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {MatDialog, MatDialogConfig, MatInput, MatPaginator, MatSelect, MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Subscription} from 'rxjs/Subscription';
import {TagsSelectionModalWrapperComponent} from 'fuse-core/components/tags-selection/tags-selection-modal-wrapper/tags-selection-modal-wrapper.component';
import {TranslateService} from '@ngx-translate/core';
import {defaultLoginRoute} from '../../../../settings';
import {fuseAnimations} from 'fuse-core/animations';
import {isEmpty, localizedDate} from '../../../../shared/utils';

@Component({
    selector: 'fuse-app-activities-list',
    templateUrl: './activities-list.component.html',
    styleUrls: ['./activities-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ActivitiesListComponent implements OnInit, OnDestroy {
    public allControls: any;
    public allTypes = [];
    public buttonDisabled: boolean;
    public checkboxes: {};
    public countEntities = 50;
    public dataSource = new MatTableDataSource();
    public displayedColumns;
    public methods: any;
    public pageIndex = 0;
    public pageRangeOptions = [10];
    public selectAll = false;
    public settings: { [key: string]: any };
    public tags: any;
    public tagsSelected: object = {concepts: [], skills: [], chapters: []};

    private dialogRef: any;
    private dialogText: object;
    private displayedFilters: string[] = [];
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 10
    };
    private pageRange = 10;
    private paginator: MatPaginator;
    private ressources;
    private ressourcesSubscription: Subscription;
    private selectedActivities: DataEntity[] = [];
    private selectedLesson: DataEntity;
    private selectedResources: DataEntity[] = [];
    private sort: MatSort;
    private unsubscribeInTakeUntil = new Subject();

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
    }

    @ViewChild('typeSelect') typeSelect: MatSelect;
    @ViewChild('titleInput') titleInput: MatInput;

    constructor(
        public authService: AuthenticationService,
        public lessonsService: LessonsService,
        private activityService: ActivitiesService,
        private dialog: MatDialog,
        private router: Router,
        private translate: TranslateService,
    ) {
        this.settings = this.activityService.settings;
    }

    ngOnInit(): void {
        this.activityService.licensingMethods.take(1).subscribe((methods) => {
            const noLicensingMethods = this.activityService.licensingSettings
                && this.activityService.licensingSettings.restrict.includes('activities-list')
                && methods.length === 0;

            if (this.authService.isTrainer() && noLicensingMethods) {
                this.router.navigate(['/licensing-restricted']);
            }
        });

        this.buttonDisabled = true;

        if (this.authService.isAtLeastTrainer()) {
            this.activityService.clearSelection();

            this.activityService.onSelectedResourcesChanged
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((resources: DataEntity[]) => {
                    this.selectedActivities = [];
                    this.selectedResources = resources;
                    this.checkboxes = {};

                    for (const activity in resources) {
                        if (resources.hasOwnProperty(activity)) {
                            this.selectedActivities.push(resources[activity]);
                            this.checkboxes[resources[activity].id] = true;
                        }
                    }

                    this.buttonDisabled = isEmpty(this.checkboxes);
                });


            if (this.settings.filters[this.authService.accessLevel]) {
                this.displayedFilters = this.settings.filters[this.authService.accessLevel];
            } else {
                this.displayedFilters = this.settings.filters['default'];
            }

            this.loadTypes();

            this.ressourcesSubscription = this.refreshList();

            this.getMethods();

            this.translate.onLangChange.subscribe(() => {
                this.loadTypes();
                this.getMethods();
                this.refreshList();
            });

            this.allControls = {
                typeFilter: {
                    type: 'typology',
                    control: new FormControl('')
                },
                titleFilter: {
                    type: 'urlExtension',
                    control: new FormControl('')
                },
                keywordsFilter: {
                    type: 'indexation',
                    control: new FormControl('')
                },
                sourceFilter: {
                    type: 'source',
                    control: new FormControl('')
                },
                usedFilter: {
                    type: 'used',
                    control: new FormControl('')
                },
            };
        } else {
            this.router.navigate([defaultLoginRoute]);
        }
    }

    ngOnDestroy(): void {
        this.activityService.setSelectionMode();
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private loadTypes(): void {
        this.activityService.loadActivityTypes().subscribe(collection => {
            if (collection.entities[0]) {
                this.allTypes = collection.entities[0].get('activityTypes');
            }
        });
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.indexOf(name) > -1;
    }

    private getMethods(): void {
        this.activityService.getMethods().takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.methods = data.entities;
                }
            });
    }

    private applyFilters(event, type): void {
        if (type === 'urlExtension') {
            if (event.value === '') {
                delete this.optionsInterface.urlExtension;
                delete this.optionsInterface.filter['title'];
            } else {
                this.optionsInterface.filter['title'] = this.optionsInterface.urlExtension = event.value;
            }
        } else {
            if (event.value === 'all' || !event.value) {
                delete this.optionsInterface.filter[type];
            } else {
                this.optionsInterface.filter[type] = event.value;
            }
        }
    }

    public blurElementRef(): void {
        this.typeSelect.close();
        this.titleInput.focus();
    }

    public launchSearch(): void {
        for (const field in this.allControls) {
            this.applyFilters({value: this.allControls[field].control.value}, this.allControls[field].type);
        }

        this.ressourcesSubscription.unsubscribe();
        this.ressourcesSubscription = this.refreshList();
    }

    public resetTags(type: string): void {
        this.tagsSelected[type] = [];
        this.applyFilters({value: ''}, type);
        this.launchSearch();
    }

    public tagsPopUp(type): void {
        let component;
        let data;
        if (type === 'chapters') {
            component = ChaptersSelectionComponent;
            data = {
                data: this.tagsSelected[type],
                type: type,
                methods: this.methods,
                loadChapters: (event) => this.activityService.getChapters(event),
                chaptersChanged: this.activityService.chaptersChanged
            };
        } else {
            component = TagsSelectionModalWrapperComponent;
            data = {
                data: this.tagsSelected[type],
                type: type,
                tags: this.tags,
                loadTags: (event) => this.activityService.getTags(event),
                tagsChanged: this.activityService.tagsChanged

            };
        }

        this.dialogRef = this.dialog.open(component, {
            panelClass: 'entity-form-dialog',
            data: data
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tagsSelected[type] = result;
                this.applyFilters({value: result.map(o => o.id).join(',')}, type);
                this.launchSearch();
            }
        });
    }

    private refreshList(): Subscription {
        return this.activityService.loadPaginatedActivities(this.optionsInterface)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((resources: DataEntity[]) => {
                this.activityService.activities = resources['entities'];
                this.selectAll = false;
                this.activityService.onSelectedResourcesChanged.next(this.selectedResources);
                this.ressources = resources;
                if (!resources) {
                    return;
                }

                if (this.settings.columns[this.authService.accessLevel]) {
                    this.displayedColumns = this.settings.columns[this.authService.accessLevel];
                } else {
                    this.displayedColumns = this.settings.columns['default'];
                }

                this.dataSource.data = resources;

                this.setPaginator();

                this.selectAll = true;

                resources.some((activity: DataEntity) => {
                    if (!this.checkboxes[activity.id]) {
                        this.selectAll = false;
                        return true;
                    }
                });
            });
    }

    private setPaginator(): void {
        if (this.activityService.activitiesPaginated.paginator) {
            this.countEntities = this.activityService.activitiesPaginated.paginator.count;
            this.pageIndex = this.activityService.activitiesPaginated.paginator.page - 1;
            this.pageRange = this.activityService.activitiesPaginated.paginator.range;
        }
    }

    public checkUsed(values): boolean {
        if (values && values.length > 0 && values.indexOf(this.activityService.userData.id) > -1) {
            return true;
        }
        return false;
    }

    public formatArrayToJoinString(values, key = 'label'): string {
        if (values && values.length > 0) {
            const vals = values.map(val => val[key]);
            return vals.join(',');
        }
        return '';
    }

    public onPaginateChange(event): void {
        this.activityService.activitiesPaginated.paginator.page = event.pageIndex + 1;
    }

    public get inSelectionMode(): boolean {
        return !!this.activityService.activitiesSelection;
    }

    /**
     * Whether we can add activities to lesson from listing or not, depending on setting
     * returns boolean
     */
    public get canAddToLesson(): boolean {
        return this.settings.addFromActivities;
    }

    public addToLesson(): void {
        this.lessonsService.addActivitiesToLesson(this.selectedResources);
        this.router.navigateByUrl(this.activityService.activitiesSelection);
    }

    public onSelectedChange(activity: DataEntity): void {
        this.activityService.toggleActivitySelection(activity);
    }

    public get selectedCount(): number {
        return this.activityService.selectionCount;
    }

    public updateCheck(): void {
        this.ressources.forEach((activity: DataEntity) => {
            this.activityService.toggleActivitySelection(activity, this.selectAll);
        });
    }

    public navigateToMainActivity(row: DataEntity): void {
        this.activityService.loadActivitiesFromId(row.id.toString())
            .take(1)
            .subscribe((entity: DataEntity) => {
                const tempLabel = this.activityService.getPropertyFromNestedObject(entity.attributes, ['metadatas', 'typology']);
                /* Here Data is of Type : ISingleActivityNavParams */
                const data: ISingleActivityNavParams = {
                    id: row.id.toString(),
                    template: tempLabel.label,
                    isLoadBeforeLaunch: true,
                    title: row.attributes.title
                };
                this.dialog.open(DialogComponent, {
                    panelClass: 'activities-list-dialog',
                    width: '90%',
                    height: '100%',
                    data: data
                });
            });
    }

    public localeDate(date: number): string {
        return localizedDate(date);
    }

    public displayedFiltersLength(type: string): string {
        const tagsLength = this.tagsSelected[type].length;
        if (tagsLength) {
            return tagsLength.toString();
        }

        return 'generic.all';
    }

    public launchLessonListingModal(activityId): void {
        const data = {
            activities: this.selectedActivities,
            currentUserRoles: this.lessonsService.currentUser.attributes['role'],
            allowedRoleIdsForModelsCreation: this.lessonsService.getAllowedRoleIdsForModelsCreation()
        };

        this.dialogRef = this.dialog.open(LessonsSelectionComponent, {
            panelClass: 'entity-form-dialog',
            data: data
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedLesson = result.lessons[0];

                this.lessonsService.updateCurrentLesson(this.selectedLesson);

                // add activities
                this.lessonsService.addActivitiesToLesson(result.activities);

                this.lessonsService.saveFromModalToLesson(this.selectedLesson.attributes.reference);

                //confirmation modal
                this.dialogText = {
                    title: 'generic.add_to_lesson',
                    yes: 'activities.lesson.dialog_edit',
                    message: 'generic.added_activities',
                };
                for (const field in this.dialogText) {
                    this.translate.get(this.dialogText[field]).subscribe((translation: string) => this.dialogText[field] = translation);
                }

                const dialogConfig = new MatDialogConfig();

                dialogConfig.data = {
                    titleDialog: this.dialogText['title'],
                    panelClass: 'entity-form-dialog',
                };

                dialogConfig.data.bodyDialog = this.dialogText['message'] + '<br>' + this.selectedLesson.attributes.metadatas.title;
                dialogConfig.data.labelTrueDialog = this.dialogText['yes'];

                const confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

                confirmDialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.router.navigate(['lessons', this.selectedLesson.id, 'edit']);
                    }
                });

            }
        });

    }

    public getContentTypeIcon(data): object {
        return this.activityService.getContentTypeIcon(data, 'activity');
    }

    public roundRate(rate): any {
        if (rate !== '-') {
            return Math.round(+rate * 10) / 10;
        }

        return rate;
    }
}
