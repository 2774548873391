import { NgModule } from '@angular/core';
import {
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTooltipModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import {FuseQuickPanelComponent} from "./quick-panel.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        FuseQuickPanelComponent
    ],
    imports     : [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonModule,
        FuseSharedModule,
        TranslateModule
    ],
    exports: [
        FuseQuickPanelComponent
    ]
})
export class QuickPanelModule
{
}
