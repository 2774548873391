import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BasicPageComponent} from './basic-page/basic-page.component';
import {ModalPageComponent} from './modal-page/modal-page.component';
import {FullPageComponent} from './full-page/full-page.component';
import {MatButtonModule, MatDialogModule, MatExpansionModule, MatIconModule, MatProgressSpinnerModule} from '@angular/material';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslateModule,
        FlexLayoutModule
    ],
    declarations: [
        BasicPageComponent,
        ModalPageComponent,
        FullPageComponent
    ],
    exports: [
        BasicPageComponent,
        ModalPageComponent,
        FullPageComponent
    ],
    entryComponents: [
        ModalPageComponent
    ],
    providers: [
        BasicPageService
    ]
})
export class BasicPageModule {
}
