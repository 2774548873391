import {AfterViewInit, Component, OnDestroy, OnInit, Input} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {VideoMarkersComponent} from '@modules/activities/core/editor-components/video-editor/video-markers/video-markers.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {Observable} from 'rxjs/index';
import {combineLatest} from 'rxjs/observable/combineLatest';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input('activitySummary') activitySummary: DataEntity;
    @Input('config') config: any;

    private assignedCount = 20; // default value, same as in short-answer.component.ts
    private unsubscribeInTakeUntil = new Subject();
    private params: any;
    private activity: DataEntity;
    private dialogRef: MatDialogRef<VideoMarkersComponent>;
    private userSaves: DataEntity[];

    public summaryCallback: any;
    public markers: any[] = [];
    public videoConfig = {};
    public selectedMarker: any;
    public mode: string;
    public showSpinner: boolean;
    public polls: any[];
    public outsidePollColor: string;
    public insidePollColor: string;
    public answers = [];

    constructor(public lessonsService: LessonsService,
                public activitiesService: ActivitiesService,
                private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog) { }

    ngOnInit(): any {
        this.route.queryParams.subscribe(params => {
            this.params = params;
        });
        if (!this.activitySummary && !this.lessonsService.getCurrentActivity() ||
            this.lessonsService.getCurrentActivity() &&
            this.params.activityId && +this.params.activityId !== +this.lessonsService.getCurrentActivity().id) {
            this.showSpinner = true;
            this.activitiesService.loadActivitiesFromId(this.params.activityId.toString())
                .take(1)
                .subscribe((activity: DataEntity) => {
                    this.lessonsService.setCurrentActivity(activity);
                    this.initialize();
                    this.showSpinner = false;
                });
        } else {
            this.initialize();
        }

    }

    ngAfterViewInit(): any {}

    ngOnDestroy(): any {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private initialize(): void {
        if (this.config) {
            this.userSaves = this.config['userSaves'];
            this.summaryCallback = this.config['callback'];
        }
        if (this.activitySummary) {
            this.activity = this.activitySummary;
        } else {
            this.activity = this.lessonsService.getCurrentActivity();
        }

        if (this.activitiesService.currentAssignment) {
            this.assignedCount = +this.activitiesService.currentAssignment.get('assignatedCount');
        }

        if (this.activity && typeof this.activity.get('reference') === 'object') {
            if (this.activity.get('reference').config.markers) {
                this.mode = 'marker';
                this.processMarker();
            }
            if (this.activity.get('reference').config.poll) {
                this.mode = 'poll';
                this.processPoll();
            }
            if (this.activity.get('reference').config.qcmu) {
                this.mode = 'qcmu';
                this.processQcmu();
            }
        }

    }

    processMarker(): void {
        this.markers = this.activity.get('reference').activity_content[0].granule[0].reference.activity_content[0].marker;

        this.videoConfig = {
            config: {
                src: this.activity.get('reference').activity_content[0].granule[0].reference.activity_content[0].granule[0].reference.url
            }
        };
    }

    processQcmu(): void {
        if (this.config && this.config['userSaves']) {
            const activityReferenced = this.activity.get('reference').activity_content[0].granule;
            this.answers = activityReferenced.map((activity, index) => {
                const answersAvailable = activity.reference.activity_content.answers;
                if (this.config['userSaves'][index] && answersAvailable) {
                    const answer = answersAvailable
                        .find((availableAnswer) => availableAnswer.id === this.config['userSaves'][index].get('userActivity').entitySave.answers[0].id);

                    // answer can be '0' or '1' or true or false
                    return answer && (answer.correct_answer === true || answer.correct_answer === '1') ? 'correct' : 'wrong';
                }

                return 'wrong';
            });
        }
    }

    processPoll(): void {
        this.outsidePollColor = this.settings.outsidePollColor;
        this.insidePollColor = this.settings.insidePollColor;

        this.polls = this.activity.get('reference').activity_content[0].granule
            .map((activity, index) => {
                let activityReferenced: any[];
                if (this.config && this.config['userSaves']) {
                    activityReferenced = this.config['userSaves'].filter((userSave) => {
                        return Array.isArray(userSave.get('granule')) ? +userSave.get('granule')[0] === +activity.id : +userSave.get('granule') === +activity.id;
                    }).sort((a, b) => {
                        return +a.get('step') - +b.get('step');
                    });
                }
                const legend = this.activity.get('reference').activity_content[0].granule[index] &&
                typeof this.activity.get('reference').activity_content[0].granule[index].reference === 'object' ?
                    this.activity.get('reference').activity_content[0].granule[index].reference.instruction : '';
                const poll0 = activityReferenced && activityReferenced[0].get('userActivity').entitySave.answers[0] && +activityReferenced[0].get('userActivity').entitySave.answers[0].answer ? +activityReferenced[0].get('userActivity').entitySave.answers[0].answer : 0;
                const poll1 = activityReferenced && activityReferenced[1].get('userActivity').entitySave.answers[0] && +activityReferenced[1].get('userActivity').entitySave.answers[0].answer ? +activityReferenced[1].get('userActivity').entitySave.answers[0].answer : 0;
                return {
                    poll1: Math.round(poll0 * 100 / this.assignedCount),
                    poll2: Math.round(poll1 * 100 / +this.assignedCount),
                    legend: legend
                };
            });
    }

    /**
     * open modal with video-markers details.
     * if usersave exist, we are in the player,
     * so we hide the details of the markers and show only the second panel with the user answer
     * @param selectedMarker
     */
    showMarkersDetail(selectedMarker): void {
        this.selectedMarker = selectedMarker;
        if (this.userSaves && this.userSaves[0] && this.videoConfig) {
            const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
            // 0 == false return true
            if (index && index !== -1 || index === 0) {
                const answer = this.userSaves[0].get('userActivity').entitySave.answers[index];
                this.dialogRef = this.dialog.open(VideoMarkersComponent, {
                    data: {
                        markerType: selectedMarker.marker_type ? selectedMarker.marker_type : {label: 'activities.personalize'},
                        marker: selectedMarker,
                        isEditor: false,
                        answer: answer,
                        step: 1,
                        videoConfig: this.videoConfig,
                        callback: (marker, data) => this.summaryCallback(marker, data),
                    }
                });
                this.dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.showSpinner = false;
                    }
                });
            }
        }
    }


    public exit(): void {
        this.router.navigate(['lessons', 'list']);
    }

    public getIcon(marker): string {
        const label = marker && marker.marker_type ? marker.marker_type.label : '';
        switch (label) {
            case 'Cadrage':
                return 'cadrage';
            case 'Contexte d\'usage':
                return 'contexte';
            case 'Illusion optique':
                return 'illusion_optique';
            case 'Légende':
                return 'legende';
            case 'Ligne éditoriale':
                return 'ligne_editoriale';
            case 'Retouche':
                return 'retouche';
            case 'Source image':
                return 'source_image';
            case 'Source texte':
                return 'source_texte';
            default:
                return 'personnalisable';
        }
    }

    /**
     * Get type of marker
     * @param marker
     *
     * @return marker type by default except personalized marker, in this case return marker title
     */
    public getTitleMarkerType(marker): string {
        if (marker && marker.marker_type) {
            return marker.marker_type.localized;
        }

        return marker.title;
    }

    private get settings(): any {
        if (this.lessonsService.settings && this.lessonsService.settings.lessonStep) {
            return this.lessonsService.settings.lessonStep;
        }

        return null;
    }

}
