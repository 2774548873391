import {Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, ActivationStart, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {Location} from '@angular/common';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {UserActionsService} from '@modules/activities/core/services/user-actions.service';
import {Subject} from 'rxjs/Subject';
import {fuseAnimations} from 'fuse-core/animations';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {FullscreenService} from '@fuse/services/fullscreen.service';
import {AuthenticationService} from '@modules/authentication';
import {combineLatest} from 'rxjs';
import {defaultLoginRoute} from '../../../../settings';
import {LayoutAlign, StepItemType, StepperOptions} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';
import {StepperPosition} from '@modules/activities/core/shared-components/basic-stepper-container/stepper-wrapper.component';

@Component({
    selector: 'fuse-app-lessons',
    templateUrl: './lessons.component.html',
    styleUrls: ['./lessons.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LessonsComponent implements OnInit, OnDestroy {
    public lessonTitle: string;
    public activityTitle: string;
    public stepTitle: string;
    public checkAnswer: boolean;
    public withoutAnyUserResponse: boolean;
    public enabledUserActionButtons: boolean;
    public doUserSeeAnswer = false;
    public hideStepper = true;
    public currentActivityID: number;
    public userAnswered: any = [];
    public isFullscreen = false;
    public isLoading = true;
    public learnerInfo: { classes: string, groups: string, name: string, uid: string };
    public isLatexKeyboardDisplayed: boolean;
    public onStepItemClick = new Subject<any>();
    public stepperOptions: StepperOptions;
    public stepperPosition: StepperPosition|null = this.lessonsService.settings.stepper ? StepperPosition[<string>this.lessonsService.settings.stepper.position] : null;
    public currentStep: string = '1';

    public get isUserActionButtonsAllowed(): boolean {
        return this.lessonsService.settings.hideUserActionButtons === false && this.activitiesService.playScreenStatus === 0;
    }

    private unsubscribeInTakeUntil = new Subject();
    private visitedActivity: any = [];
    private unSeenFirst: number;


    constructor(
        private router: Router,
        private location: Location,
        public activitiesService: ActivitiesService,
        private userActionsService: UserActionsService,
        private activatedRoute: ActivatedRoute,
        private lessonsService: LessonsService,
        private authService: AuthenticationService,
        private fullscreenService: FullscreenService,
    ) {
        // use for multi assignment because component is not destroy before changing content
        this.activatedRoute.params.subscribe(params => {
                this.reset(false);
                this.initialiseState();
                this.initSubscribes();
        });
    }

    /**
     * all the constructor code is move here because we need to listen
     * load of same component with other id and launch all the code again in taht case
     */
    initialiseState(): void {
        this.currentActivityID = this.activitiesService.getLessonActivityID();
        this.activitiesService.endScreenSeen = false;
        this.activitiesService.doesUserResponsed
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((data: boolean) => {
                this.enabledUserActionButtons = data;
                this.doUserSeeAnswer = false;
            });
        this.activitiesService.isUserAnswerStatus
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((data) => {
                if (this.activitiesService.activitiesArray.length) {
                    if (data.status) {
                        this.activitiesService.activityAnswerResult[data.index] = data.status;
                        this.userAnswered = this.activitiesService.activityAnswerResult;
                        this.refreshStepper();
                    }
                }
            });
        this.activitiesService.checkAnswers.takeUntil(this.unsubscribeInTakeUntil).subscribe(tempValue => {
            this.withoutAnyUserResponse = tempValue['withoutAnyUserResponse'];
            this.checkAnswer = tempValue['showAnswers'];
        });

        if (this.authService.isAtLeastTrainer() && this.activitiesService.currentAssignment && this.lessonsService.displayLearnerInfo()) {
            this.learnerInfo = this.activitiesService.currentAssignment.get('assignated_user');
        }

        this.refreshStepper();
    }

    ngOnInit(): void {
    }

    /**
     * move from ngOninit for beeing able to launch it only
     * most of content are subscribing content
     */
    private initSubscribes(): void{
        /* hide and show stepper */
        if (!this.lessonsService.currentAssignment && !this.authService.isAtLeastTrainer()) {
            this.router.navigate([defaultLoginRoute]);
        } else {
            this.router.events.filter(event => event instanceof NavigationEnd)
                .subscribe((urlSegment) => {
                    this.stepTitle = this.lessonStep ? this.lessonStep.typeSteps[this.activitiesService.presentArrayElementIndex].label : '';
                });

            this.lessonsService.lessonButtonClicked
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe(value => {
                    this.userAnswered = this.activitiesService.activityAnswerResult;
                    const screenState = this.activitiesService.playScreenStatus;
                    this.hideStepper = false;
                    if ([0, 1, 3].includes(screenState)) {
                        this.hideStepper = true;
                    }
                    this.setActivityVisitInfo();
                });
            this.loadLessonById(this.activatedRoute.snapshot.params['lessonId']);

            this.activitiesService.activityActionsHandler
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe(data => {
                    if (data['resetAll']) {
                        this.activitiesService.unsetAnswerTempSave();
                        this.userAnswered = this.activitiesService.activityAnswerResult;
                    }
                });

            this.activitiesService.onLatexKeyboardDisplayChange.subscribe((isDisplayed: boolean) => {
                this.isLatexKeyboardDisplayed = isDisplayed;
            });

            this.onStepItemClick.subscribe(stepItem => {
                if (stepItem !== null) {
                    this.gotoCurrentPage(this.activitiesService.activitiesArray.indexOf(stepItem));
                    this.currentStep = (this.activitiesService.activitiesArray.indexOf(stepItem) + 1).toString();
                } else {
                    this.gotoEndPage();
                }
            });
        }

        this.lessonsService.activityIdChange.takeUntil(this.unsubscribeInTakeUntil).subscribe(() => {
            this.updateActivityTitle();
        });
    }

    private refreshStepper(): void {

        if (this.lessonsService.settings.stepper === null) {
            this.stepperOptions = null;
            return;
        }

        const IsStepActive = (actno) => {
            if (this.lessonsService.settings.stepper.stepValidationNeedSave) {
                return this.visitedMediaActivity.includes(actno) || this.userAnswered[actno] === 1 || this.userAnswered[actno] === 3;
            }
            return this.visitedActivity.includes(actno);
        };

        this.stepperOptions = {
            steps: this.activitiesService.activitiesArray.map((activity, actno) => {
                return {
                    encapsulatedObject: activity,
                    isActive: IsStepActive(actno),
                    isCurrent: (this.visitedActivity.includes(actno)),
                    isArrowActive: (this.activitiesService.presentArrayElementIndex === actno
                        && this.activitiesService.playScreenStatus !== 1
                        && this.activitiesService.playScreenStatus !== 3),
                    type: this.getActivityType(activity)
                };
            }),
            onStepItemClick: this.onStepItemClick,
            recap: {
                isActive: this.activitiesService.playScreenStatus === 3 || this.activitiesService.endScreenSeen,
                isAllowed: this.lessonsService.settings.stepper.showRecap,
                isArrowActive: this.activitiesService.playScreenStatus === 3,
            },
            stepperLayout: {
                align: LayoutAlign[<string>this.lessonsService.settings.stepper.align],
            }
        };
    }

    get visitedMediaActivity(): number[] {
        return this.activitiesService.visitedMediaActivity;
    }

    ngOnDestroy(): void {
        this.reset(true);
    }

    /**
     * reset all data and state
     */
    private reset(resetAssignment: boolean): void {
        if (this.unsubscribeInTakeUntil) {
            this.unsubscribeInTakeUntil.next();
            this.unsubscribeInTakeUntil.complete();
        }
        this.activitiesService.clearLessonState(resetAssignment);
        this.activitiesService.onModuleChange();
        this.userAnswered = [];

        this.lessonTitle = '';
        this.activityTitle = '';
        this.stepTitle = '';

        this.checkAnswer = false;
        this.withoutAnyUserResponse = false;
        this.enabledUserActionButtons = false;

        this.doUserSeeAnswer = false;
        this.hideStepper = true;
        this.currentActivityID = null;
        this.userAnswered = [];
        this.isFullscreen = false;
        this.isLoading = true;
        this.learnerInfo = null;

        this.isLatexKeyboardDisplayed = false;

        this.onStepItemClick = new Subject<any>();
        this.stepperOptions = null;
        this.stepperPosition = this.lessonsService.settings.stepper ? StepperPosition[<string>this.lessonsService.settings.stepper.position] : null;
        this.currentStep = '1';

        this.unsubscribeInTakeUntil = new Subject();
        this.visitedActivity = [];
        this.unSeenFirst = null;
    }

    @HostListener('window:beforeunload', ['$event'])
    checkSaved($event: any): void {
        if (this.lessonsService.savingAssignment) {
            $event.returnValue = true;
        }
    }

    public setActivityVisitInfo(): any {
        const actno = this.activitiesService.presentArrayElementIndex;
        const stepitem = this.activitiesService.activitiesArray[actno];
        const mediaTypes = ['divider', 'media', 'image', 'video', 'audio', 'document', 'url', 'videoUrl'];
        if (stepitem !== undefined && mediaTypes.includes(stepitem.type) && !this.visitedMediaActivity.includes(actno)) {
            this.visitedMediaActivity.push(actno);
        }
        if (this.activitiesService.playScreenStatus === 0) {
            this.visitedActivity.push(this.activitiesService.presentArrayElementIndex);
        }
        if (this.activitiesService.activitiesArray.length) {
            this.unSeenFirst = null;
            this.activitiesService.activitiesArray.forEach((item, index) => {
                if (!this.visitedActivity.includes(index) && !this.unSeenFirst) {
                    this.unSeenFirst = index;
                }
            });
        }
        this.refreshStepper();
    }

    public checkLastVisited(): boolean {
        return this.visitedActivity.includes(this.activitiesService.activitiesArray.length - 1);
    }

    public gotoEndPage(): void {
        if (this.activitiesService.playScreenStatus === 1) {
            return;
        }

        if (this.activitiesService.endScreenSeen || this.checkLastVisited() || !this.authService.isLearner()) {
            let allSeen = this.activitiesService.activitiesArray.length;
            if (this.authService.isLearner()) {
                allSeen = this.visitedActivity.length;
            }
            if (this.activitiesService.activitiesArray.length <= allSeen + 1) {
                this.activitiesService.playScreenStatus = 3;

                this.activitiesService.endScreenSeen = true;
                this.router.navigate(['recap'], {relativeTo: this.activatedRoute});

                this.checkAnswer = false;
                this.withoutAnyUserResponse = false;
                this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
                this.lessonsService.lessonButtonClicked.next(true);
            }
        }
    }

    public gotoCurrentPage(index): void {
        if (this.activitiesService.playScreenStatus === 1) {
            return;
        }

        if (
            !this.lessonsService.isLessonTest() &&
            !this.visitedActivity.includes(index) &&
            this.unSeenFirst !== index
        ) {
            return;
        }

        const altPath = this.activitiesService.playScreenStatus === 3;
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadActivityByStep(index, altPath);
        this.lessonsService.lessonButtonClicked.next(true);
        this.activitiesService.activityChange.next(true);
    }

    public resetAll(): void {
        this.doUserSeeAnswer = false;
        this.checkAnswer = this.enabledUserActionButtons = false;
    }

    private loadLessonById(lessonId: string): void {
        if (this.activitiesService.activitiesArray.length === 0) {
            this.activitiesService.isLessonPlay = true;
            this.activitiesService.playScreenStatus = 1;
            this.activitiesService.presentArrayElementIndex = 0;
            this.activitiesService.activityAnswerResult = [];
            this.userAnswered = this.activitiesService.activityAnswerResult;

            this.lessonsService.LoadLessonById(lessonId)
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((lessonEntity: DataEntity) => {
                    this.activitiesService.currentLesson = lessonEntity;
                    this.lessonsService.currentLesson = lessonEntity;
                    if (lessonEntity.get('metadatas')) {
                        this.lessonTitle = lessonEntity.get('metadatas').title;
                    }
                    this.activitiesService.pushLessonFromAssignment.next(lessonEntity);
                    const activitiesArray = this.activitiesService.getPropertyFromNestedObject(lessonEntity, ['attributes', 'reference']);
                    this.activitiesService.setActivitiesListWithIds(activitiesArray);

                    const obsList = [this.lessonsService.loadLessonActivities(lessonEntity)];
                    if (this.lessonsService.currentAssignment) {
                        obsList.push(this.lessonsService.loadUserSaves(this.lessonsService.currentAssignment.id.toString()));
                    }

                    combineLatest(obsList)
                        .takeUntil(this.unsubscribeInTakeUntil)
                        .subscribe((data: DataEntity[][]) => {
                            const activities = data[0];
                            const saves = data[1];
                            let unseenStep = 0;

                            if (saves && this.lessonsService.settings.stepperLoadStatus) {
                                let index: number;
                                let status: number;

                                for (const save of saves) {
                                    index = activities.findIndex(activity => activity.id === save.get('granule')[0]);

                                    if (index > -1) {
                                        switch (save.get('state')) {
                                            case 'validated':
                                                status = 1;
                                                break;
                                            case 'incomplete':
                                                status = 2;
                                                break;
                                            case 'correct':
                                                status = 3;
                                                break;
                                            default:
                                                status = 2;
                                                break;
                                        }

                                        if (!this.activitiesService.activityAnswerResult[index] || this.activitiesService.activityAnswerResult[index] === 2) {
                                            this.activitiesService.activityAnswerResult[index] = status;
                                        }

                                        if (index >= unseenStep) {
                                            unseenStep = index + 1;
                                        }
                                    }
                                }

                                const actno = unseenStep + 1;
                                const stepitem = this.activitiesService.activitiesArray[unseenStep];
                                // if media, url or divider is the last step go to recap
                                if (actno === this.activitiesService.activitiesArray.length
                                    && ['audio', 'video', 'image', 'document', 'media', 'divider', 'url', 'videoUrl']
                                        .includes(stepitem.type)
                                ) {
                                    unseenStep += 1;
                                }

                                this.userAnswered = this.activitiesService.activityAnswerResult;
                                // tslint:disable-next-line:no-shadowed-variable
                                for (let index = 0; index < this.activitiesService.activityAnswerResult.length; index += 1) {
                                    this.visitedActivity.push(index);

                                    if (
                                        ['audio', 'video', 'image', 'document', 'media', 'divider', 'url', 'videoUrl']
                                            .includes(this.activitiesService.activitiesArray[index].type)
                                    ) {
                                        this.visitedMediaActivity.push(index);
                                    }
                                }
                            }

                            if (this.activitiesService.playScreenStatus === 1) {
                                this.activitiesService.playScreenStatus = 0;
                                if (unseenStep < activities.length) {
                                    this.activitiesService.loadActivityByStep(unseenStep, true);
                                } else {
                                    this.lessonsService.loadRecapScreen();
                                }
                                this.lessonsService.lessonButtonClicked.next(true);
                            }

                            this.isLoading = false;
                        });
                });
        } else {
            if (this.activitiesService.currentLesson) {
                this.lessonTitle = this.activitiesService.currentLesson.get('metadatas').title;
            }
        }
    }

    fullscreenMode(): void {
        this.fullscreenService.fullscreen();
        this.isFullscreen = this.fullscreenService.mode;
    }

    private goBack(): void {
        if (this.lessonsService.currentAssignment) {
            this.router.navigate(['/followed/list']);
        } else {
            const backRoute = this.lessonsService.exitLessonUrl ? this.lessonsService.exitLessonUrl : '/lessons/list';
            this.lessonsService.exitLessonUrl = '';
            this.router.navigate([backRoute]);
        }
    }

    public isMedia(): boolean {
        if (this.activitiesService.activitiesArray.length === 0) {
            return false;
        }

        return this.activitiesService.activitiesArray[this.activitiesService.presentArrayElementIndex].type === 'media';
    }

    public getCurrentActivityOrMediaId(): number {
        if (this.activitiesService.activitiesArray.length === 0) {
            return 0;
        }

        return this.activitiesService.activitiesArray[this.activitiesService.presentArrayElementIndex].id;
    }

    public updateActivityTitle(): void {
        if (this.activitiesService.getCurrentActivity(this.activitiesService.presentArrayElementIndex)) {
            this.activityTitle = this.activitiesService.getCurrentActivity(this.activitiesService.presentArrayElementIndex).get('metadatas').title;
        } else {
            this.activityTitle = '';
        }
    }

    public get showActivityTitle(): boolean {
        return this.lessonsService.settings.showActivityTitle;
    }

    public get showLessonTitle(): boolean {
        return this.lessonsService.settings.showLessonTitle;
    }

    private get lessonStep(): any {
        return this.lessonsService.settings.lessonStep;
    }

    public get hasLessonSteps(): boolean {
        return this.lessonStep && this.lessonStep.typeSteps && this.lessonStep.typeSteps.length > 0;
    }

    private getActivityType(activity): StepItemType {
        switch (activity.type) {
            case 'audio' :
            case 'video' :
            case 'image' :
            case 'document' :
            case 'media' :
            case 'url' :
            case 'videoUrl' : {
                return StepItemType.media;
            }
            case 'divider' : {
                return StepItemType.divider;
            }
            default: {
                return StepItemType.activity;
            }
        }
    }

    public get isLessonWithStep(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.lessonStep;
    }

    public get displayFullscreenButton(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.displayFullscreenButton;
    }
}
