import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from 'fuse-core/animations';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {ProjectsService} from '@modules/projects-management/core/projects.service';



@Component({
  selector: 'app-widget-corpus-research-space',
  templateUrl: './widget-corpus-research-space.component.html',
  styleUrls: ['./widget-corpus-research-space.component.scss'],
  animations   : fuseAnimations
})

export class WidgetCorpusResearchSpaceComponent implements OnInit, OnDestroy {
  @Input('corpusSubscription') corpusSubscription: ReplaySubject<any>;
    displayedColumns = ['name', 'from', 'date'];
    dataSource = new MatTableDataSource();
    resource: boolean;
    corpusId: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public projectService: ProjectsService) { }

  ngOnInit(): any {
    this.corpusSubscription.subscribe((data) => {
      this.dataSource.data = data.data.slice(0, 3);
      this.resource = data && data.data.length;
      this.corpusId = data.id;

    });
  }

    goToCorpus(data): void {
        this.router.navigate(['../', 'tools', 'corpus', this.corpusId], { relativeTo: this.route, queryParams: { itemId: data.id }});
    }

    public get align(): string {
        if (!this.empty) {
            return 'start ';
        }
        return 'center ';
    }

    get empty(): boolean {
      return !this.resource;
    }

  ngOnDestroy(): any {
    this.corpusSubscription.complete();
  }

}
