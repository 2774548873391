import {Component, Input, OnInit} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import {Router} from '@angular/router';
import {RenameDraftModalComponent} from '../section-draft-display/rename-draft-modal/rename-draft-modal.component';
import {GenericModalService} from '../generic-modal.service';
import {MatDialog} from '@angular/material';
import {ResearchTemplate} from '@modules/research-sheet/core/definitions';

@Component({
    selector: 'app-section-draft-item',
    templateUrl: './section-draft-item.component.html',
    styleUrls: ['./section-draft-item.component.scss']
})
export class SectionDraftItemComponent implements OnInit {

    @Input('draft') draft: DataEntity;
    @Input('drafts') drafts: DataEntity[];
    @Input('mode') mode = 'normal';

    constructor(
        private sheetService: ResearchSheetService,
        private router: Router,
        private genericModal: GenericModalService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): any {
    }

    clickAction(draft: DataEntity): void {
        if (this.isMine(draft)) {
            this.editDraft(draft);
        } else {
            this.viewDraft(draft);
        }
    }

    isMine(draft: DataEntity): boolean {
        return this.sheetService.isMine(+draft.get('owner')['uid']);
    }

    editDraft(draft: DataEntity): any {
        this.router.navigate([this.sheetService.getDraftEditLink(+draft.id)]);
    }

    viewDraft(draft: DataEntity): any {
        this.router.navigate([this.sheetService.getDraftViewLink(+draft.id)]);
    }

    deleteDraft(draft: DataEntity): void {
        this.genericModal.openYesNoDialog('generic.delete').subscribe((resp: string) => {
            if (resp === 'yes') {
                draft.remove();
            }
        });
    }

    getSectionTypeNamePrefix(draft: DataEntity): string {
        if (this.sheetService.templatesSectionsCollection[+(draft.get('sectionDefinitions'))]) {
            return this.sheetService.getSectionPrefix(this.sheetService.templatesSectionsCollection[+(draft.get('sectionDefinitions'))].get('name'));
        } else {
            return '';
        }
    }

    duplicateDraft(draft: DataEntity): void {
        this.sheetService.duplicateDraft(draft, this.drafts);
    }

    renameDraft(draft: DataEntity): void {
        this.dialog.open(RenameDraftModalComponent, {
            width: '500px',
            data: draft
        });
    }

    getTemplateName(draft: DataEntity): string {
        const templateSectionId: number = +draft.get('sectionDefinitions');

        const template: ResearchTemplate = this.sheetService.templatesList.find(templ => {
            return templ.sections.find(section => section.id === templateSectionId) !== undefined;
        });

        return template ? template.name : '';
    }

    getSectionTypeName(draft: DataEntity): string {
        if (this.sheetService.templatesSectionsCollection[+(draft.get('sectionDefinitions'))]) {
            return this.sheetService.templatesSectionsCollection[+(draft.get('sectionDefinitions'))].get('name');
        } else {
            return '';
        }

    }

    getDraftCreationDate(draft: DataEntity): string {
        const date = new Date(draft.get('created') * 1000);
        return date.toLocaleDateString((navigator.languages && navigator.languages[0]) || navigator.language);
    }
}
