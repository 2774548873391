import {dateTimeOptions} from '../settings';
import * as moment from 'moment';
import * as _ from 'lodash';

export const localizedDate = function(date: number, lang?: string): string {
  return new Date(date * 1000).toLocaleDateString(lang ? lang : navigator.language, dateTimeOptions);
};

export const localizedTime = function(date: number, lang?: string): string {
  return new Date(date * 1000).toLocaleTimeString(lang ? lang : navigator.language, dateTimeOptions);
};

export const currentTimestamp = function(): number {
    const current = new Date();
    return Math.round(current.getTime() / 1000);
};

export const isEmpty = function(object: object): boolean {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const secondsToHoursMinutesSeconds = function(length: number): string {
    let duration = '';

    if (length) {
        const time = moment.duration(length, 'seconds');

        const hours = time.hours();
        const minutes = time.minutes();
        const seconds = time.seconds();

        if (hours) {
            duration += hours + ':';
        }

        if (minutes < 10 && hours) {
            duration += '0';
        }

        duration += minutes + ':';

        if (seconds < 10 && minutes) {
            duration += '0';
        }

        duration += seconds;
    }

    return duration;
};

export const cloneObject = function (obj: any): any {
    const cloned =  _.cloneDeep(obj);
    return cloned;
};

/**
 * extract in an array all url contain in a text
 * @param fullText : string with or without url inside
 */
export const extractUrls = function (fullText: string): string[] {
    return fullText ? fullText.match(/\bhttps?:\/\/\S+/gi) : null;
};
