import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-dash-widget',
    templateUrl: './dash-widget.component.html',
    styleUrls: ['./dash-widget.component.scss']
    // ,
    // encapsulation: ViewEncapsulation.None
})

export class DashWidgetComponent implements OnInit {


    @Input() DashWidgetConf: any;

    ngOnInit() {
    }

    constructor(
        private translate: TranslateService
    ) {

    }

}

