import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {FuseConfigService} from 'fuse-core/services/config.service';
import {fuseAnimations} from 'fuse-core/animations';
import {AuthenticationService} from '../authentication.service';
import {brand, modulesSettings, brandLogoSvg} from '../../../../settings';
import {Subject} from 'rxjs';
import {ModelSchema, Structures} from 'octopus-model';
import {defaultURL, defaultLoginRoute} from '../../../../settings';
import {TranslateService} from '@ngx-translate/core';
import {currentTimestamp} from '../../../../shared/utils';

const settingsAuthStructure: ModelSchema = new ModelSchema({
    enableSSO: Structures.boolean(false),
    urlSSO: Structures.object(),
    displayLoginLogo: Structures.boolean(false),
    activeChangePasswordStrategy: Structures.boolean(false)
});

@Component({
    selector: 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseLoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loginFormErrors: any;
    hide = true;
    hideNewPassword = true;
    hideConfirmPassword = true;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public renewPassword: boolean = false;
    message: any;
    private unsubscribeInTakeUntil = new Subject();
    public modeSSO: boolean = false;

    settings: { [key: string]: boolean };
    settingsAuth: { [key: string]: boolean };


    constructor(
        private fuseConfig: FuseConfigService,
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {
        this.loginFormErrors = {
            login: {},
            password: {},
            newPassword: {},
            confirmPassword: {},
        };

        this.settings = this.authenticationService.settings;
        this.settingsAuth = settingsAuthStructure.filterModel(modulesSettings.authentication);
    }

    ngOnInit(): void {

        if (this.route.snapshot.queryParams.code) {
            this.modeSSO = true;
            this.authenticationService.loginSSO(this.route.snapshot.queryParams.code);
        }

        this.loginForm = this.formBuilder.group({
            login: ['', Validators.required],
            password: ['', Validators.required],
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, {
            validator: this.mustMatchAndBeNewPassword('password', 'newPassword', 'confirmPassword')
        });


        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });

        this.authenticationService.errorHttpAuthentication
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(errorHttp => {
                if (errorHttp.code === 401) {
                    const controlLogin = this.loginForm.get('login');
                    const controlpassword = this.loginForm.get('password');
                    controlLogin.setErrors({invalid: true});
                    controlpassword.setErrors({invalid: true});
                    this.loginFormErrors['login'] = controlLogin.errors;
                    this.loginFormErrors['password'] = controlpassword.errors;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * custom validator to check that two fields match and are différent of original password
     *
     */
    private mustMatchAndBeNewPassword(passwordControlName: string, newPasswordcontrolName: string, confirmPasswordControlName: string): any {
        return (formGroup: FormGroup) => {
            const controlOriginalPasword = formGroup.controls[passwordControlName];
            const controlNewPassword = formGroup.controls[newPasswordcontrolName];
            const controlConfirmPassword = formGroup.controls[confirmPasswordControlName];

            if (controlConfirmPassword.errors && !controlConfirmPassword.errors.notMatch) {
                return;
            }

            if (controlOriginalPasword.value === controlNewPassword.value) {
                controlNewPassword.setErrors({samePasswordAsOrigine: true});
            } else {
                controlNewPassword.setErrors(null);
            }

            if (controlNewPassword.value !== controlConfirmPassword.value) {
                controlConfirmPassword.setErrors({notMatch: true});
            } else {
                controlConfirmPassword.setErrors(null);
            }
        };
    }

    /**
     * login user : with optionnal change password possibility force by rules
     */
    public login(): void {
        if (this.loginForm.value['login'] && this.loginForm.value['password']) {
            this.authenticationService.authenticateIn('http', this.loginForm.value['login'], this.loginForm.value['password'])
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((user: DataEntity) => {
                    // set value is need for pass form on valid state
                    this.loginForm.controls.login.setValue(this.loginForm.value['login']);

                    if (this.isNewPasswordToSet()) {
                        this.updatePassword();
                        return;
                    }
                    // must be after condition this.isNewPasswordToSet()
                    if (this.isExpirePassword(user)) {
                        this.renewPassword = true;
                        this.authenticationService.logoutFrom('http');
                        return;
                    }

                    this.navigate();
                });
        }
    }

    /**
     * update password and go to program if no error
     */
    private updatePassword(): void {
        this.authenticationService.loggedUser.set('password', this.loginForm.value['newPassword']);
        this.authenticationService.loggedUser.save().subscribe((userUpdate: DataEntity) => {
            this.renewPassword = false;
            this.navigate();
        }, error => {
            console.log(error);
        });
    }

    /**
     * is user expiration password date is passed
     * @param user: DataEntity
     */
    private isExpirePassword(user: DataEntity): boolean {
        return this.settingsAuth.activeChangePasswordStrategy && user.get('expirePassword')
            && currentTimestamp() >= user.get('expirePassword')
            && user.get('expirePassword') !== null && user.get('expirePassword') !== undefined;
    }

    /**
     * is the new password need to be set
     */
    private isNewPasswordToSet(): boolean {
        return this.settingsAuth.activeChangePasswordStrategy &&
            this.loginForm.value['newPassword'] && this.loginForm.value['newPassword'] !== null
            && this.loginForm.value['newPassword'] !== undefined && this.loginForm.value['newPassword'] !== '';
    }

    /**
     * navigate on the good route after being logged
     */
    private navigate(): void {
        const returnParam: string = this.route.snapshot.queryParams['return'];

        if (returnParam) {
            this.router.navigate([returnParam]);
        } else {
            this.router.navigate([defaultLoginRoute]);
        }
    }

    loginSSO(): void {
        if (this.settingsAuth.enableSSO) {
            window.location.href = this.settingsAuth.urlSSO[this.translate.currentLang] + defaultURL + 'login';
        }
    }

    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    get selfSignup(): boolean {
        return this.settings.selfSignup;
    }

    get signup(): boolean {
        return this.settings.signup;
    }
}
