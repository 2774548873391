export const LessonActivityRoutes = {
    'APP': 'appaire',
    'Audio': 'audio',
    'CRT': 'shortanswer',
    'IMG': 'image',
    'IMGI': 'interactiveimage',
    'ORD': 'ordon',
    'QCM': 'qcm',
    'QCMU': 'qcu',
    'RB': 'fillinblanks',
    'VF': 'truefalse',
    'video': 'video',
    'summary': 'summary',
    'GCM': 'gcm', // grid choice multiple
    'MULTI': 'medias',
    'EXT': 'external'
};

export const ActivitiesFullNames = {
    'QCM': 'Questions Chois Multiple',
    'QCMU': 'Questions Chois Unique',
    'VF': 'Vrai Ou Faux',
    'ORD': 'Ordanancement',
    'CRT': 'Réponse courte',
    'RB': 'Remplir Les Blancs',
    'media': 'Media',
    'APP': 'Appariement'
};

export interface IFormatIdentifier {
    label: string;
    id?: string;
}
