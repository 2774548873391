import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {CorpusMetadataInterface} from '@modules/corpus';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {AuthenticationService} from '@modules/authentication';
import {MatChipInputEvent, MatDialogRef} from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-resource-upload-modal-step2',
    templateUrl: './resource-upload-modal-step2.component.html',
    styleUrls: ['./resource-upload-modal-step2.component.scss'],
})
export class ResourceUploadModalStep2Component implements OnInit, OnDestroy {

    public editor;
    editorConfig;
    name = '';
    scolomDate = '';
    author = '';
    source = '';
    description = '';
    groups = [];
    skills = [];
    difficulty = [];
    indexation = [];
    educationalLevels = [];
    groupsName = [];
    public allSkills: any[] = [];
    public allDifficulty: any[] = [];
    public allEducationalLevel: any[] = [];
    public chips: any[];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    tagModified = false;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    private unsubscribeInTakeUntil = new Subject();

    @Input('mode') mode = 'creation';
    @Input('resource') resource: CorpusRessource;

    @Output('detailsValidated') detailsValidated: EventEmitter<CorpusMetadataInterface> = new EventEmitter<CorpusMetadataInterface>();
    @Output('canceled') canceled: EventEmitter<void> = new EventEmitter<void>();
    public autoOrderFields: boolean = false;
    public fields: string[] = [];

    constructor(
        public authService: AuthenticationService,
        public dialogRef: MatDialogRef<ResourceUploadModalStep2Component>,
        private corpusService: CorpusService,
        private translateService: TranslateService
    ) {
        this.chips = [];

        if (this.corpusService.settings.textEditor.enabled) {
            this.editor = ClassicEditor;
            this.editorConfig = this.corpusService.settings.textEditor.config;
            this.editorConfig.placeholder = 'Description';
        }
        // if true fields use order of field in setting array to move fields in html using a directive for
        this.autoOrderFields = this.corpusService.settings.assetFieldsAutoOrder;
        this.setListOfFields();
    }

    private setListOfFields(): void {
        const role = this.authService.accessLevel;
        this.fields = this.corpusService.settings.assetFields[role];
        if (this.fields === undefined) {
            this.fields = this.corpusService.settings.assetFields['default'];
        }
    }

    ngOnInit(): void {
        if (!this.corpusService.skills) { // get granule formats
            this.corpusService.loadSkills().subscribe(entities => {
                this.allSkills = this.corpusService.skills;
                if (this.mode === 'edition' && this.resource) {
                    this.getResourceSkills();
                }
            });
        } else {
            this.allSkills = this.corpusService.skills;
            if (this.mode === 'edition' && this.resource) {
                this.getResourceSkills();
            }
        }

        if (!this.corpusService.difficulty) { // get granule formats
            this.corpusService.loadDifficulty().subscribe(entities => {
                this.allDifficulty = this.corpusService.difficulty;
                if (this.mode === 'edition' && this.resource) {
                    this.getResourceDifficulty();
                }
            });
        } else {
            this.allDifficulty = this.corpusService.difficulty;
            if (this.mode === 'edition' && this.resource) {
                this.getResourceDifficulty();
            }
        }

        if (!this.corpusService.educationalLevel) { // get granule formats
            this.corpusService.loadEducationalLevel().subscribe(entities => {
                this.allEducationalLevel = this.corpusService.educationalLevel;
                if (this.mode === 'edition' && this.resource) {
                    this.getResourceEducationnalLevel();
                }
            });
        } else {
            this.allEducationalLevel = this.corpusService.educationalLevel;
            if (this.mode === 'edition' && this.resource) {
                this.getResourceEducationnalLevel();
            }
        }

        if (this.mode === 'edition' && this.resource) {
            this.author = this.resource.sourceAuthor;
            this.chips = this.resource.ressourceEntity.get('metadatas').indexation;
            this.description = this.resource.description;
            this.name = this.resource.title;
            this.scolomDate = this.resource.scolomDate;
            this.source = this.resource.source;
            this.tagModified = false; // init variable to false on modal load
            this.getResourceWorkgroups();
        }
    }

    validateDetails() {
        this.indexation = this.chips;

        if (this.mode === 'edition' && this.resource) {
            this.getResourceWorkgroupsName();
            this.resource.title = this.name;
            this.resource.scolomDate = this.scolomDate;
            this.resource.description = this.description;
            this.resource.sourceAuthor = this.author;
            this.resource.source = this.source;
            this.resource.groups = this.groups;
            this.resource.skills = this.skills.map((x) => +x.id);
            this.resource.difficulty = this.difficulty.map((x) => +x.id);
            this.resource.educationalLevel = this.educationalLevels.map((x) => +x.id);
            this.resource.groupsName = this.groupsName;
            this.resource.indexation = this.indexation;
            this.getResourceDifficultyName();
            this.getResourceEducationnalLevelName();
            this.getResourceSkillsName();
            this.getResourceIndexationName();
            this.resource.save().subscribe(() => {
            });
        }

        this.detailsValidated.emit({
            description: this.description,
            title: this.name,
            scolomDate: this.scolomDate,
            'source-author': this.author,
            source: this.source,
            groups: this.groups,
            skills: this.skills.map((x) => +x.id),
            difficulty: this.difficulty.map((x) => +x.id),
            indexation: this.indexation,
            educationalLevel: this.educationalLevels.map((x) => +x.id),
            language: ''
        });

    }

    get metadataAreCreating(): boolean {
        return this.corpusService.metadataAreCreating;
    }

    get allWorkgroups() {
        return this.corpusService.getWorkgroups();
    }

    public get skillsList(): any[] {
        return this.allSkills;
    }

    public get difficultyList(): any[] {
        return this.allDifficulty;
    }

    public get educationalLevelList(): any[] {
        return this.allEducationalLevel;
    }

    getResourceWorkgroups(): void {
        this.groups = [];
        const resourceGroups = this.resource.groups;
        this.groups.push(...this.allWorkgroups.filter((globalGroup) => {
            return resourceGroups.some(resourceGroup => +resourceGroup.id === +globalGroup.id);
        }));
    }

    getResourceWorkgroupsName() {
        this.groupsName = [];
        const resourceGroups = this.resource.ressourceEntity.get('groupsName');
        if (!resourceGroups || (!resourceGroups.length && this.groups.length > 0)) {
            this.groupsName.push(...this.groups.map((group) => group.workgroupname));
        } else {
            this.groupsName.push(...this.allWorkgroups.filter((group) => resourceGroups.indexOf(group.workgroupname) > -1));
        }
    }

    getResourceSkills(): void {
        this.skills = [];
        let resourceSkills;
        if (this.resource.skills) {
            if (typeof (this.resource.skills[0]) === 'object') {
                resourceSkills = this.resource.skills.map(x => x.id);
            } else {
                resourceSkills = this.resource.skills.map(x => x.toString());
            }
            this.skills.push(...this.allSkills.filter((x) => resourceSkills.indexOf(x.id.toString()) > -1));
        }
    }

    getResourceSkillsName(): void {
        this.getResourceSkills();
        this.resource.skillsString = this.skills.map(x => x.attributes.label).join(', ');
    }

    getResourceEducationnalLevel(): void {
        this.educationalLevels = [];

        if (this.resource.educationalLevel) {
            let resourceEducationalLevel = [];
            if (typeof (this.resource.educationalLevel[0]) === 'object') {
                resourceEducationalLevel = this.resource.educationalLevel.map(x => x.id);
            } else {
                resourceEducationalLevel = this.resource.educationalLevel.map(x => x.toString());
            }
            this.educationalLevels.push(...this.allEducationalLevel.filter((x) => resourceEducationalLevel.indexOf(x.id.toString()) > -1));
        }
    }

    getResourceEducationnalLevelName(): void {
        this.getResourceEducationnalLevel();
        this.resource.educationalLevelString = this.educationalLevels.map(x => x.attributes.label).join(', ');
    }

    getResourceIndexationName(): void {
        this.resource.indexationString = this.resource.indexation.map(x => x.label).join(', ');
    }

    getResourceDifficulty(): void {
        this.difficulty = [];
        let resourceDifficulty;

        if (this.resource.difficulty) {
            if (typeof (this.resource.difficulty[0]) === 'object') {
                resourceDifficulty = this.resource.difficulty.map(x => x.id);
            } else {
                resourceDifficulty = this.resource.difficulty.map(x => x.toString());
            }
            this.difficulty.push(...this.allDifficulty.filter((x) => resourceDifficulty.indexOf(x.id.toString()) > -1));
        }
    }

    getResourceDifficultyName(): void {
        this.getResourceDifficulty();
        this.resource.difficultyString = this.difficulty.map(x => x.attributes.label).join(', ');
    }

    cancel(): void {
        this.canceled.emit();
    }

    displayField(name: string): boolean {
        const role = this.authService.accessLevel;
        let assetField = this.corpusService.settings.assetFields[role];
        if (assetField === undefined) {
            assetField = this.corpusService.settings.assetFields['default'];
        }
        return assetField.indexOf(name) > -1;
    }

    displayRequiredField(name: string): boolean {
        return this.corpusService.settings.assetRequiredFields.indexOf(name) > -1;
    }

    displayTextEditorField(name: string): boolean {
        return this.corpusService.settings.assetTextEditorFields.indexOf(name) > -1;
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            if (this.chips.filter(function (e) {
                return e.label === value;
            }).length > 0) { /* check if chips array contains the element we're looking for */
            } else {
                // add tag
                this.chips.push({label: value.trim()});
                this.tagModified = true;
                // this.entityForm.get('tagModified').setValue(this.tagModified);
            }
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag): void {
        const index = this.chips.indexOf(tag);

        if (index >= 0) {
            this.chips.splice(index, 1);
            this.tagModified = true;
            // this.entityForm.get('tagModified').setValue(this.tagModified);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}
