import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {AccountManagementProviderService} from '../account-management-provider.service';
import {ReCaptchaComponent} from 'angular5-recaptcha';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {TralaTranslationLoaderService} from '../../../../trala-translation-loader.service';
import {brand, brandLogoSvg, langs, modulesSettings} from '../../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {OctopusConnectService} from 'octopus-connect';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';

const settingsStructure: ModelSchema = new ModelSchema({
    fields: Structures.object({
        default: Structures.array(['label', 'email', 'password', 'you_are', 'find_us', 'newsletter'])
    }),
    displayDialogMinorAdult: Structures.boolean(true)
});
const settingsAuthStructure: ModelSchema = new ModelSchema({
    displayLoginLogo: Structures.boolean(false),
    urlSSO: Structures.boolean(false),
});

@Component({
    selector: 'fuse-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations: fuseAnimations
})
export class FuseRegisterComponent implements OnInit {
    @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;

    captchaResp = false;
    userInformation: FormProfile;
    translateRegisterParams = {product: 'Marque Blanche', resources: 'google'};
    registerForm: FormGroup;
    registerFormErrors: any;
    termsAdultNotAccepted = false;
    userIsAdultOrMinor: any = null;
    brandLogoSvg = brandLogoSvg;

    public brand = brand;
    public settings: { [key: string]: any };
    public settingsAuth: { [key: string]: boolean };
    public settingsProfile: { [key: string]: boolean };
    public formData: any = {
        title: 'account-management.email_confirmation',
        content: ['account-management.email_confirmation_first_sentence', 'account-management.email_confirmation_second_sentence'],
        redirection: 'generic.back_to_dashboard',
        redirectionLink: '/dashboard'
    };
    public requestValidate: boolean;

    private fields: string[];

    constructor(
        private fuseConfig: FuseConfigService,
        private formBuilder: FormBuilder,
        public accountProvider: AccountManagementProviderService,
        private translationLoader: TralaTranslationLoaderService,
        private octopusConnect: OctopusConnectService,
        public profileService: ProfileService
    ) {

        this.userInformation = new FormProfile({}, this.octopusConnect);

        this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
        this.settingsAuth = settingsAuthStructure.filterModel(modulesSettings.authentication);
        this.settingsProfile = this.profileService.settings;
        this.fields = this.getFieldsFromSettings();

        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.translationLoader.loadTranslations(...defaultLang);

        this.registerFormErrors = {
            you_are: {},
            find_us: {},
            region: {},
            level: {},
            email: {},
            pseudo: {},
            contact_email: {},
            password: {},
            passwordConfirm: {},
            terms: {}
        };
    }

    ngOnInit(): void {
        this.requestValidate = false;
        this.generateRegisterForm();
        this.subscribeOnFormChanges();

        if (!this.settings.displayDialogMinorAdult) {
            this.selectMinorOrAdult('adult');
        }
    }


    requiredFields(name, customValidators: ValidatorFn[] = null): ValidatorFn | ValidatorFn[] {
        if (this.displayField(name)) {
            return customValidators ? customValidators : Validators.required;
        }
    }

    onRegisterFormValueChanged(field: string, control: FormControl): void {
        this.registerFormErrors[field] = {};

        if (!this.registerForm.get('terms').errors) {
            this.termsAdultNotAccepted = false;
        }

        if (control && control.dirty && !control.valid) {
            this.registerFormErrors[field] = control.errors;
        }

        if (field === 'pseudo' && !this.validateField(control.value)) {
            control.setErrors({invalid: true});
        }
    }

    handleCorrectCaptcha(event): void {
        this.captchaResp = true;
    }

    newRegister(): void {

        if (this.registerForm.get('terms').errors) {
            this.termsAdultNotAccepted = true;
        }

        if (this.captchaResp && !this.registerForm.invalid) {
            let newUser;
            newUser = {
                password: this.registerForm.value.password,
                newsletter: this.registerForm.value.newsletter,
                you_are: this.registerForm.value.you_are,
                find_us: this.registerForm.value.find_us,
                level: this.registerForm.value.level,
                region: this.registerForm.value.region,
                institution: this.registerForm.value.institution
            };

            if (this.displayField('label')) {
                newUser.label = this.registerForm.value.pseudo;
            }

            if (this.displayField('email')) {
                newUser.email = this.registerForm.value.email;
            }

            if (this.displayField('contact_email')) {
                newUser.contact_email = this.registerForm.value.contact_email;
            }

            this.accountProvider.createUser(newUser, () => this.getFormConfirmation(), (err) => this.handleRegistrationError(err));

        }
    }

    selectMinorOrAdult(mode: any): void {
        this.userIsAdultOrMinor = mode;

        if (this.displayField('label') === false) {
            delete this.registerFormErrors.pseudo;
        } else {
            this.registerFormErrors.pseudo = {};
        }

        if (this.displayField('contact_email') === false) {
            delete this.registerFormErrors.contact_email;
        } else {
            this.registerFormErrors.contact_email = {};
        }

        if (this.displayField('email') === false) {
            delete this.registerFormErrors.email;
        } else {
            this.registerFormErrors.email = {};
        }

        if (this.userIsAdultOrMinor === 'minor') {
            this.registerForm = this.formBuilder.group({
                contact_email: ['', [Validators.required, Validators.email]],
                pseudo: ['', [Validators.required, Validators.maxLength(60)]],
                you_are: ['', this.requiredFields('you_are')],
                find_us: ['', this.requiredFields('find_us')],
                region: ['', this.requiredFields('region')],
                level: ['', this.requiredFields('level')],
                password: ['', Validators.required],
                passwordConfirm: ['', [Validators.required, confirmPassword]],
                newsletter: [false],
                terms: [false, Validators.requiredTrue],
                institution: ['']
            });

            this.subscribeOnFormChanges();
        }

    }

    validateField(field): boolean {

        let re;
        re = /^[A-Za-z0-9-'_.]+$/;

        return re.test(String(field).toLowerCase());
    }

    getFormConfirmation(): void {
        this.requestValidate = true;
    }

    displayField(name: string): boolean {
        return this.fields.indexOf(name) > -1;
    }

    private getFieldsFromSettings(): string[] {
        let fields = this.settings.fields[this.userIsAdultOrMinor];

        if (fields === null || fields === undefined) {
            fields = this.settings.fields['default'];
        }
        return fields;
    }

    private handleRegistrationError(error: any): void {
        const mapField = (backendField: string) => {
            return backendField === 'label' ? 'pseudo' : backendField;
        };

        const handleFieldsError = (field: string, messages: string[]) => {
            const localField = mapField(field);

            if (Object.keys(this.registerFormErrors).indexOf(localField) > -1) {
                this.registerFormErrors[localField].customErrors = messages;
                const control = this.registerForm.get(localField);
                control.setErrors({invalid: true});
            } else {
                console.error(error);
            }
        };

        if (error.code !== 401) {
            console.error(error);
        } else if (error.data.response.hasOwnProperty('errors')) {
            Object.keys(error.data.response.errors).forEach((key) => {
                handleFieldsError(key, error.data.response.errors[key]);
            });
        }
    }

    private generateRegisterForm(): void {
        this.registerForm = this.formBuilder.group({
            terms: [false, Validators.requiredTrue]
        });

        this.fields.forEach(field => {
            switch (field) {
                case 'email': {
                    this.registerForm.addControl('email', new FormControl('', this.requiredFields('email', [Validators.required, Validators.email])));
                    break;
                }
                case 'find_us': {
                    this.registerForm.addControl('find_us', new FormControl('', this.requiredFields('find_us')));
                    break;
                }
                case 'region': {
                    this.registerForm.addControl('region', new FormControl('', this.requiredFields('region')));
                    break;
                }
                case 'level': {
                    this.registerForm.addControl('level', new FormControl('', this.requiredFields('level')));
                    break;
                }
                case 'newsletter': {
                    this.registerForm.addControl('newsletter', new FormControl(false));
                    break;
                }
                case 'password': {
                    this.registerForm.addControl('password', new FormControl('', this.requiredFields('password')));
                    this.registerForm.addControl('passwordConfirm', new FormControl('', this.requiredFields('passwordConfirm', [Validators.required, confirmPassword])));
                    break;
                }
                case 'label': {
                    this.registerForm.addControl('pseudo', new FormControl('', this.requiredFields('label', [Validators.required, Validators.maxLength(60)])));
                    break;
                }
                case 'institution': {
                    this.registerForm.addControl('institution', new FormControl(''));
                    break;
                }
                case 'you_are': {
                    this.registerForm.addControl('you_are', new FormControl('', this.requiredFields('you_are')));
                    break;
                }
            }
        });
    }

    private subscribeOnFormChanges(): void {
        Object.keys(this.registerForm.controls).forEach(key => {
            const control = <FormControl>this.registerForm.controls[key];
            control.valueChanges.subscribe(() => {
                this.onRegisterFormValueChanged(key, control);
            });
        });
    }
}

function confirmPassword(control: AbstractControl): any {
    if (!control.parent || !control) {
        return;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return;
    }

    if (passwordConfirm.value === '') {
        return;
    }

    if (password.value !== passwordConfirm.value) {
        return {
            passwordsNotMatch: true
        };
    }
}
