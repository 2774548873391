export const groupsManagement = {
    accessGroupsManager: ['manager', 'administrator'],
    accessGroupsTrainer: ['trainer', 'administrator'],
    authorizedRoles: ['learner', 'trainer', 'administrator'],
    canBeDelete: {
        workgroup: true,
        institution: true,
        learner: true,
        learners: false,
    },
    group: {
        columns: {
            trainer: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            administrator: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            manager: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            default: ['color', 'groupname', 'level', 'code']
        }
    },
    groupType: 2,
    canBeJoined: {
        group: true
    },
    institutionGroupType: 52,
    levelsWidget: 'select',
    levelsRequired: true,
    primaryColors: ['#C5629D', '#FF9EA1', '#EEDA78', '#6FF2C5', '#6DE6E2', '#305792'],
    workgroupType: 3,
    graph: {
        progress: {
            displayedFilters: ['group', 'learner', 'activities'],
            dataFilterFields: [
                {field: 'firstAnswer', translate: 'graph.first_answer'},
                {field: 'secondAnswer', translate: 'graph.second_answer'},
                {field: 'wrongAnswer', translate: 'graph.wrong_answer'},
                {field: 'totalGood', translate: 'graph.total_good'},
                {field: 'progress', translate: 'graph.progress'},
                {field: 'success', translate: 'graph.success'},
            ],
            prePath: 'detail',
            dataFields: ['completed', 'firstAnswer', 'secondAnswer', 'wrongAnswer', 'progress',  'success'],
            popUpFields: [
                'generic.duration_activity',
                'generic.number_session',
                'generic.time_per_session',
                'generic.level_session',
            ],
            csv: {
                header: ['groups-management.csv.learner',
                    'groups-management.csv.date',
                    'groups-management.csv.hours',
                    'groups-management.csv.activity',
                    'groups-management.csv.activity_type',
                    'groups-management.csv.duration',
                    'groups-management.csv.nb1',
                    'groups-management.csv.nb2',
                    'groups-management.csv.nb-',
                    'groups-management.csv.nb+',
                    'groups-management.csv.number_correct_jocker_answers',
                    'groups-management.csv.number_wrong_jocker_answers',
                ],
                fields: ['learner', 'date', 'hours', 'activity', 'activity_type', 'duration', 'nb1', 'nb2', 'nb-', 'nb+']
            },
            config: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 12,
                        boxWidth: 40,
                        usePointStyle: false,
                    }
                },
                scales: {
                    yAxes: [{
                        gridelines: {
                            drawTicks: true,
                        },
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 20,
                            stepSize: 10
                        },
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    },
                    {
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',

                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 20,
                            stepSize: 10
                        },

                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }],
                    xAxes: [{
                        offset: true,
                    }]
                },
            },
            defaultOptions: {
                type: 'line',
                style: {
                    showLine:  true,
                    pointRadius:  10,
                    pointHoverRadius:  10,
                    borderWidth: 2, // taille bordure
                    backgroundColor: 'transparent',
                    pointStyle: 'circle', // type de point etile, cercle, carré etc..
                    hoverBackgroundColor: 'transparent', // couleur d'une ligne en hover
                    pointBackgroundColor: '#fff',
                    // borderWidth: 5, // taille bordure
                    borderColor: 'transparent', // couleur bordure ligne
                    borderCapStyle: 'butt', // forme des extremité d'une ligne ( butt : carré, round: arrondi, square: carré et ajoute la taille de la largeur à la taille en hauteur.
                    borderDash: [], // pour faire un style de ligne dashed :  longueur des tirets et longueurs espace entre les tirets
                    borderDashOffset: 0, // l'espace avant le debut d'une ligne
                    borderJoinStyle: 'miter', // permet de definir l'affichage des joints d'une ligne : bevel, round, miter => https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineJoin
                    pointBorderColor: 'transparent', // couleur bordure point
                    pointBorderWidth: 1, // taille bordure point
                    // pointHitRadius: [1, 5, 10, 20], // au click sur le point la taille change;
                    // pointHoverBackgroundColor: 'transparent', // couleur du point en hover
                    // pointHoverBorderColor: 'transparent', // couleur du point en hover
                    // pointHoverBorderWidth: 1, // taille du point en hover
                    hoverBorderColor: 'transparent', // couleur de bordure d'une ligne en hover
                    // hoverBorderWidth: 1, // couleur de bordure d'une ligne en hover
                }
            },
            options:  [
                {
                    type: 'line',
                    style: {
                        pointBackgroundColor: '#fff',
                        borderColor: '#1A586', // couleur bordure ligne
                        pointBorderColor: '#1A586', // couleur bordure point,
                        hoverBorderColor: '#1A586', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'line',
                    style: {
                        pointBackgroundColor: '#fff',
                        borderColor: '#73D8A8', // couleur bordure ligne
                        pointBorderColor: '#73D8A8', // couleur bordure point,
                        hoverBorderColor: '#73D8A8', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'line',
                    style: {
                        pointBackgroundColor: '#fff',
                        borderColor: 'yellow', // couleur bordure ligne
                        pointBorderColor: 'yellow', // couleur bordure point,
                        hoverBorderColor: 'yellow', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'line',
                    style: {
                        pointBackgroundColor: '#fff',
                        borderColor: 'green', // couleur bordure ligne
                        pointBorderColor: 'green', // couleur bordure point,
                        hoverBorderColor: 'green', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'line',
                    style: {
                        pointBackgroundColor: '#fff',
                        borderColor: 'purple', // couleur bordure ligne
                        pointBorderColor: 'purple', // couleur bordure point,
                        hoverBorderColor: 'purple', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'bar',
                    style: {
                        backgroundColor: '#90DCD7',
                        hoverBackgroundColor: '#90DCD7', // couleur d'une ligne en hover
                        hoverBorderColor: '#90DCD7', // couleur de bordure d'une ligne en hover
                    }
                }
            ],
        },
        metrics: {
            displayedFilters: ['group', 'learner', 'activities'],
            dataFilterFields: [],
            prePath: null,
            dataFields: ['collective', 'individual', 'collaborative', 'pair'],
            popUpFields: [
                'generic.duration_activity',
                'generic.number_session',
                'generic.time_per_session',
                'generic.level_session',
            ],
            csv: {
                header: ['groups-management.csv.learner',
                    'groups-management.csv.date',
                    'groups-management.csv.hours',
                    'groups-management.csv.activity',
                    'groups-management.csv.activity_type',
                    'groups-management.csv.duration',
                    'groups-management.csv.nb1',
                    'groups-management.csv.nb2',
                    'groups-management.csv.nb-',
                    'groups-management.csv.nb+',
                    'groups-management.csv.number_correct_jocker_answers',
                    'groups-management.csv.number_wrong_jocker_answers',
                ],
                fields: ['learner', 'date', 'hours', 'activity', 'activity_type', 'duration', 'nb1', 'nb2', 'nb-', 'nb+']
            },
            config: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 12,
                        boxWidth: 40,
                        usePointStyle: false,
                    }
                },
                scales: {
                    yAxes: [{
                        stacked: true,
                        gridelines: {
                            drawTicks: true,
                        },
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 50,
                            stepSize: 10
                        },
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        drawOnChartArea: true, // only want the grid lines for one axis to show up
                    },
                    {
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',

                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 50,
                            stepSize: 10
                        },

                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }],
                    xAxes: [{
                        stacked: true,
                        offset: true,
                    }],
                }
            },
            defaultOptions: {
                type: 'bar',
                style: {
                    showLine:  true,
                    pointRadius:  10,
                    pointHoverRadius:  10,
                    borderWidth: 2, // taille bordure
                    backgroundColor: 'transparent',
                    pointStyle: 'circle', // type de point etile, cercle, carré etc..
                    hoverBackgroundColor: 'transparent', // couleur d'une ligne en hover
                    pointBackgroundColor: '#fff',
                    borderColor: 'transparent', // couleur bordure ligne
                    borderCapStyle: 'butt', // forme des extremité d'une ligne ( butt : carré, round: arrondi, square: carré et ajoute la taille de la largeur à la taille en hauteur.
                    borderDash: [], // pour faire un style de ligne dashed :  longueur des tirets et longueurs espace entre les tirets
                    borderDashOffset: 0, // l'espace avant le debut d'une ligne
                    borderJoinStyle: 'miter', // permet de definir l'affichage des joints d'une ligne : bevel, round, miter => https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineJoin
                    pointBorderColor: 'transparent', // couleur bordure point
                    pointBorderWidth: 1, // taille bordure point
                    hoverBorderColor: 'transparent', // couleur de bordure d'une ligne en hover
                }
            },
            options:  [
                {
                    type: 'bar',
                    style: {
                        backgroundColor: '#00AFEC',
                        hoverBackgroundColor: '#00AFEC', // couleur d'une ligne en hover
                        hoverBorderColor: '#00AFEC', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'bar',
                    style: {
                        backgroundColor: '#35CBFF',
                        hoverBackgroundColor: '#35CBFF', // couleur d'une ligne en hover
                        hoverBorderColor: '#35CBFF', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'bar',
                    style: {
                        backgroundColor: ' #8AE1FF',
                        hoverBackgroundColor: '#8AE1FF', // couleur d'une ligne en hover
                        hoverBorderColor: '#8AE1FF', // couleur de bordure d'une ligne en hover
                    }
                },
                {
                    type: 'bar',
                    style: {
                        backgroundColor: '#D2F3FF',
                        hoverBackgroundColor: '#D2F3FF', // couleur d'une ligne en hover
                        hoverBorderColor: '#D2F3FF', // couleur de bordure d'une ligne en hover
                    }
                }
            ],
        }

    },
};
