import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Component({
  selector: 'app-graph-details-modal',
  templateUrl: './graph-details-modal.component.html',
  styleUrls: ['./graph-details-modal.component.scss']
})
export class GraphDetailsModalComponent implements OnInit {

    @Input('infoSettings') infoSettings: ReplaySubject<any>;
    customSettingsTooltip: any;

    constructor(private router: Router,
                private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService) { }

    ngOnInit(): any {
        this.infoSettings.subscribe((data) => {
            this.customTooltip = data;
        });
    }

    public get isAtLeastTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    public action(): void {
    }

    public get settingsTooltip(): any {
        return this.customSettingsTooltip;
    }

    public set customTooltip(data){
        this.customSettingsTooltip = data;
    }

}
