import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Project } from '@modules/projects-management/core/project.model';
import { ProjectsService } from '@modules/projects-management/core/projects.service';

@Component({
  selector: 'app-projects-form',
  templateUrl: './projects-form.component.html',
  styleUrls: ['./projects-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectsFormComponent implements OnInit {
  dialogTitle: string;
  project: Project;
  projectForm: FormGroup;
  action: string;
  groupsList: string[];
  workgroupsList: string[];
  trainersList: string[];

  constructor(
    public dialogRef: MatDialogRef<ProjectsFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private projectsService: ProjectsService,
    private translate: TranslateService
  ) {
    this.action = data.action;

    if ( this.action === 'edit' )
    {
      this.translate.get('projects-management.edit_project').subscribe((translation: string) => this.dialogTitle = translation);
      this.project = data.project;
      this.projectForm = this.editProjectForm();
    }
    else
    {
      this.translate.get('projects-management.new_project').subscribe((translation: string) => this.dialogTitle = translation);
      this.project = new Project();
      this.projectForm = this.createProjectForm();
    }
  }

  ngOnInit(): any {
  }

  createProjectForm(): any {
    this.groupsList = this.projectsService.getGroups();
    this.workgroupsList = this.projectsService.getWorkgroups();
    this.trainersList = this.projectsService.getTrainers();

    return this.formBuilder.group({
      name: [this.project.name],
      groups: [this.project.groups],
      workgroups: [this.project.workgroups],
      trainers: [this.project.trainers]
    });
  }

  editProjectForm(): any {
    this.groupsList = this.projectsService.getGroups();
    this.workgroupsList = this.projectsService.getWorkgroups();
    this.trainersList = this.projectsService.getTrainers();

    return this.formBuilder.group({
      id: [this.project.id],
      name: [this.project.name],
      groups: [this.project.groups],
      workgroups: [this.project.workgroups],
      trainers: [this.project.trainers]
    });
  }
}
