import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import {DataEntity} from 'octopus-connect';

@Injectable()
export class GroupsListingService
{
    onSelectedEntityChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onEntityDataChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onSearchTextChanged: Subject<any> = new Subject();
    onFilterChanged: Subject<any> = new Subject();

    entities: {[key: number]: DataEntity} = {};

    filterBy: string;

    constructor()
    {}
}
