import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule, MatGridListModule, MatIconModule, MatCardModule, MatProgressSpinnerModule} from '@angular/material';
import {SharedTranslateModule} from '../../../../shared/shared-translate.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {DragulaModule} from 'ng2-dragula';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {Ng2DragDropModule} from 'ng2-drag-drop';
import {QcuEditorComponent} from '@modules/activities/core/editor-components/qcu-editor/qcu-editor.component';
import {QcmEditorComponent} from '@modules/activities/core/editor-components/qcm-editor/qcm-editor.component';
import {ShortAnswerEditorComponent} from '@modules/activities/core/editor-components/short-answer-editor/short-answer-editor.component';
import {VideoEditorComponent} from '@modules/activities/core/editor-components/video-editor/video-editor.component';
import {ShortAnswerMultiEditorComponent} from '@modules/activities/core/editor-components/short-answer-multi-editor/short-answer-multi-editor.component';
import {VideoMarkersComponent} from '@modules/activities/core/editor-components/video-editor/video-markers/video-markers.component';
import {SharedModule} from '../../../../shared/shared.module';
import {MultiEditorComponent} from './multi-editor/multi-editor.component';
import {RouterModule, Routes} from '@angular/router';
import { MultimediaEditorComponent } from './multimedia-editor/multimedia-editor.component';
import { AddMediaModalComponent } from './multimedia-editor/add-media-modal/add-media-modal.component';
import {FileDropModule} from 'ngx-file-drop';
import { AddTextModalComponent } from './multimedia-editor/add-text-modal/add-text-modal.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

const routes: Routes = [{
    path: 'multi/:subLessonId',
    children : [{
        path: '',
        component: MultiEditorComponent,
    }, {
        path: 'multimedia/:activityId',
        component: MultimediaEditorComponent
    }]
}];

@NgModule({
    imports: [
        ActivitiesPipesModule,
        CKEditorModule,
        CommonModule,
        CommonSharedModule,
        DragulaModule,
        FileDropModule,
        FuseSharedModule,
        MatButtonModule,
        MatCardModule,
        MatGridListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        Ng2DragDropModule.forRoot(),
        RouterModule.forChild(routes),
        SharedModule,
        SharedTranslateModule,
    ],
    declarations: [
        AddMediaModalComponent,
        AddTextModalComponent,
        MultiEditorComponent,
        MultimediaEditorComponent,
        QcuEditorComponent,
        QcmEditorComponent,
        ShortAnswerEditorComponent,
        ShortAnswerMultiEditorComponent,
        VideoEditorComponent,
        VideoMarkersComponent,
    ],
    entryComponents: [
        AddMediaModalComponent,
        AddTextModalComponent,
        VideoEditorComponent,
        VideoMarkersComponent,
    ],
    exports: []
})
export class EditorsModule {
}
