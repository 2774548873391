import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {DataSource} from '@angular/cdk/collections';
import {InstitutionGroupService} from '@modules/groups-management/core/institution-group/institution-group.service';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {InstitutionGroup} from '@modules/groups-management/core/definitions';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {DataEntity} from 'octopus-connect';
import {GroupService} from '@modules/groups-management/core/group/group.service';

@Component({
    selector: 'app-institution-group',
    templateUrl: './institution-group.component.html',
    styleUrls: ['./institution-group.component.scss']
})
export class InstitutionGroupComponent implements OnInit {
    public displayedColumns = ['checkbox', 'institutiongroupname', 'address', 'city', 'postalCode', 'country', 'buttons'];
    public dataSource: InstitutionDataSource | null;


    public newInstitutionGroup: any = {
        data: {
            action: 'new',
            title: 'add_institution_group',
            fields: ['id', 'title', 'institution', 'postalCode', 'country'],
            selects: {
                parent: () => this.institutionGroupService.getAllInstitutionsFormated(),
                metadata: () => this.institutionGroupService.getMetadata()
            },
            typeEntity: 'institution'
        },
        callback: (response) => this.newGroupCallback(response)
    };

    public editInstitutionGroup: any = {
        data: {
            action: 'edit',
            title: 'edit_institution_group',
            fields: ['id', 'institutiongroupname'],
        },
        callback: (response) => this.editGroupCallback(response)
    };

    public deleteInstitutionGroup: any = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_institution',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deleteGroupCallback(group),
        deleteList: (list) => this.deleteListCallback(list)

    };

    constructor(
        private institutionGroupService: InstitutionGroupService,
        private groupsManagementService: GroupsManagementService,
        private groupService: GroupService
    ) {
    }


    ngOnInit(): void {
        this.dataSource = new InstitutionDataSource(this.institutionGroupService);
        this.groupsManagementService.setHeaderTitle('groups-management.institutions');
    }

    public newGroupCallback(response: FormGroup): Observable<DataEntity> {
        if (response) {
            return this.institutionGroupService.addInstitutionGroup(response.getRawValue());
        }
    }

    public editGroupCallback(response: FormGroup): void {
        if (response) {
            this.institutionGroupService.saveInstitutionGroup(response.getRawValue());
        }
    }

    public archiveGroupCallback(group: any) {
        group.archived = true;
        this.institutionGroupService.saveInstitutionGroup(group);
    }

    public deleteGroupCallback(group: any) {
        this.institutionGroupService.deleteInstitutionGroup(group);
    }


    public deleteListCallback(groups: any): void {
        for (const group of groups){
            this.institutionGroupService.deleteInstitutionGroup(group);
        }
        this.groupService.loadGroups();
    }

}

export class InstitutionDataSource extends DataSource<any> {

    data: BehaviorSubject<InstitutionGroup[]>;

    constructor(private institutionGroupService: InstitutionGroupService) {
        super();
        this.data = new BehaviorSubject<InstitutionGroup[]>(this.institutionGroupService.groups);

        this.institutionGroupService.onInstitutionGroupsChanged.subscribe((data: InstitutionGroup[]) => {
            this.data.next(data);
        });
    }

    get groupService() {
        return this.institutionGroupService;
    }

    connect(): Observable<any[]> {
        return this.institutionGroupService.onInstitutionGroupsChanged;
    }

    disconnect() {
    }
}
