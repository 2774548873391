export const assignation = {
    columns: {
        default: ['type', 'assigned_node_title', 'start_date', 'end_date', 'assigned_user', 'class', 'group', 'state', 'progress', 'score', 'buttons'],
        learner: ['type', 'assigned_node_title', 'start_date', 'end_date', 'class', 'group', 'state', 'progress', 'score', 'buttons']
    },
    completionDate: ['assessment', 'homework'],
    completionStartDateOnly: ['training'],
    completionTime: ['assessment'],
    enableGradeType: ['assessment', 'homework'],
    filters: {
        default: ['type', 'title', 'group', 'workgroup', 'learner'],
        learner: ['type', 'title', 'group', 'workgroup']
    },
    formFields : {
        default : ['group', 'workgroup', 'learner', 'assignment_type', 'assignment_grade', 'start_date', 'due_date']
    },
    getStateFromDate: true,
    hasCompletionDate: true,
    hasCompletionTime: true,
    hasType: true,
    ratingBase: [1, 2, 4],
};