import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {
    MatCheckbox,
    MatDialog,
    MatDialogConfig, MatInput, MatSelect,
    MatTableDataSource
} from '@angular/material';
import {fuseAnimations} from 'fuse-core/animations';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/map';
import {AssignationService} from '@modules/assignation/core/assignation.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {Subject} from 'rxjs/index';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CommunicationCenterService} from '@modules/communication-center';
import {FlagService} from '../../../../../shared/flag.service';
import {displayHeader} from 'app/settings';

@Component({
    selector: 'app-followed-list',
    templateUrl: './followed-list.component.html',
    styleUrls: ['./followed-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class FollowedListComponent implements OnInit, OnDestroy {

    corpusId: string;
    resourcesSubscription: Subscription;
    resources;
    selectAll = false;
    isChecked = false;
    files: any;
    dataSource = new MatTableDataSource();
    selected;
    items: any[];
    types: string[];

    // https://github.com/angular/material2/issues/10205
    // private paginator: MatPaginator;
    // private sort: MatSort;
    private unsubscribeInTakeUntil = new Subject();
    private dialogTitle: string;
    private tooltipDeassign: string;
    private dialogBody: string;
    private dialogYes: string;
    private dialogNo: string;
    private optionsInterface: CollectionOptionsInterface;

    public displayedFilters: string[] = [];
    public displayedColumns: string[] = [];
    public titleFilter = new FormControl();
    public commentFilter = new FormControl();
    public typeFilter = new FormControl();
    public settings: { [key: string]: any };
    public allTypes = [];
    public allStates = [];
    public displayHeader: boolean = false;

    public formControls = {
        title: this.titleFilter,
        type: this.typeFilter,
        comment: this.commentFilter
    };

    public customFilter = {
        dueDateControl: new FormControl(''),
        educationalLevelControl: new FormControl(''),
        groupControl: new FormControl(''),
        startDateControl: new FormControl(''),
        workgroupControl: new FormControl(''),
    };

    countEntities = 50;
    pageIndex = 0;
    pageRange = 10;
    pageRangeOptions = [10];

    selectedResources;
    checkboxes: {};

    learnersList = [];

    filterWorkgroupActive: string;
    filterGroupActive: string;


    public educationalLevels: DataEntity[] = [];

    @ViewChild('groupChipInput') groupChipInput: ElementRef;
    @ViewChild('workgroupChipInput') workgroupChipInput: ElementRef;
    @ViewChild('learnerChipInput') learnerChipInput: ElementRef;

    @ViewChildren(MatCheckbox) checkBoxes: QueryList<MatCheckbox>;

    @ViewChild('typeSelect') typeSelect: MatSelect;
    @ViewChild('stateSelect') stateSelect: MatSelect;
    @ViewChild('groupSelect') groupSelect: MatSelect;
    @ViewChild('workgroupSelect') workgroupSelect: MatSelect;
    @ViewChild('learnerSelect') learnerSelect: MatSelect;
    @ViewChild('titleInput') titleInput: MatInput;
    @ViewChild('commentInput') commentInput: MatInput;

    constructor(
        public assignationService: AssignationService,
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthenticationService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private flagService: FlagService
    ) {
        this.displayHeader = displayHeader;

        this.translate.get('assignment.unassign').subscribe((translation: string) => this.dialogTitle = translation);
        this.translate.get('assignment.unassign.tooltip').subscribe((translation: string) => this.tooltipDeassign = translation);
        this.translate.get('assignment.confirm_unassign').subscribe((translation: string) => this.dialogBody = translation);
        this.translate.get('generic.yes').subscribe((translation: string) => this.dialogYes = translation);
        this.translate.get('generic.cancel').subscribe((translation: string) => this.dialogNo = translation);

        this.assignationService.onFilesChanged.subscribe(files => {
            this.files = files;

            this.checkboxes = {};
        });

        let filter = {};
        if (this.authService.accessLevel === 'learner') {
            filter = {'assignated_user': this.authService.userData.id};
        } else {
            filter = {'assignator': this.authService.userData.id};
        }

        this.optionsInterface = {
            filter: filter, // filter results by current user id
            page: 1,
            range: 10
        };

        this.assignationService.onSelectedResourcesChanged.subscribe(resources => {
            for (const id in this.checkboxes) {
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }

                this.checkboxes[id] = resources.includes(id);
            }
            this.selectedResources = this.resources;
        });

        this.assignationService.onFileSelected.subscribe(selected => {
            this.selected = selected;
        });

        this.assignationService.loadAssignationsTypes().subscribe(types => {
            this.allTypes = types;

            this.route.queryParams.subscribe(params => {
                const filterLabel = {
                    'title': 'assignated_node_title',
                    'type': 'assignments_type'
                };

                Object.keys(params)
                    .filter(key => Object.keys(this.formControls).includes(key))
                    .forEach((key) => {
                        let value = params[key];

                        // Cas particulier, le "type" est un label, il nous faut l'id
                        if (key === 'type') {
                            value = this.allTypes.find(t => t.label === params[key])['id'];
                        }

                        // Les filtres visibles dans l'ihm (FI) ne sont pas les filtres que l'ont envoie au server (FS).
                        // les FS sont dynamiquement généré dans les FI change
                        // Donc quand on les initialise au démarrage :
                        // Il faut changer initialValues pour les FI
                        this.formControls[key].setValue(value);
                        // Mais aussi optionsInterface.filter pour les FS
                        this.optionsInterface.filter[filterLabel[key]] = value;
                    });

                this.launchSearch();
            });
        });
    }

    ngOnInit(): void {
        this.settings = this.assignationService.settings;

        this.launchSearch();

        this.assignationService.loadAssignationsStates().subscribe(states => {
            this.allStates = states;
        });

        this.titleFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.applyFilters({value: query}, 'assignated_node_title');
            });

        this.commentFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                this.applyFilters({value: query}, 'comment');
            });

        if (this.settings.filters[this.authService.accessLevel]) {
            this.displayedFilters = this.settings.filters[this.authService.accessLevel];
        } else {
            this.displayedFilters = this.settings.filters['default'];
        }

        if (this.settings.columns[this.authService.accessLevel]) {
            this.displayedColumns = this.settings.columns[this.authService.accessLevel];
        } else {
            this.displayedColumns = this.settings.columns['default'];
        }

        this.communicationCenter.getRoom('licenses')
            .getSubject('methods')
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((entities: Array<DataEntity>) => {
                this.assignationService.learnerMethods = entities.map((entity) => {
                    return entity.attributes.access.id;
                });
            });

        this.assignationService.getEducationalLevels().subscribe((educationalLevels) => {
            this.educationalLevels = educationalLevels;
        });
    }

    public get stateWithIcon(): boolean {
        return this.settings.stateWithIcon;
    }

    public getTooltipDeassignLabel(assignation): string {
        return this.cantBeDeassign(assignation) ? this.tooltipDeassign : null;
    }

    public blurElementRef(type?: string): void {
        if (this.typeSelect) {
            this.typeSelect.close();
        }
        if (this.stateSelect) {
            this.stateSelect.close();
        }
        if (this.groupSelect) {
            this.groupSelect.close();
        }
        if (this.workgroupSelect) {
            this.workgroupSelect.close();
        }
        if (this.learnerSelect) {
            this.learnerSelect.close();
        }

        if (type === 'comment') {
            this.commentInput.focus();
        }else{
            this.titleInput.focus();
        }
    }

    applyFilters(event, type): void {
        this.blurElementRef(type);
        if (event.value === 'all') {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = event.value;
        }

    }

    launchSearch(): void {
        if (this.resourcesSubscription) {
            this.resourcesSubscription.unsubscribe();
        }
        this.resourcesSubscription = this.refreshList();
    }

    refreshList(): Subscription {
        return this.resourcesSubscription = this.assignationService.loadPaginatedAssignments(this.optionsInterface)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(resources => {

                this.resources = resources;
                this.assignationService.assignations = this.resources;
                if (!this.resources) {
                    return;
                }
                this.dataSource.data = this.resources;
                this.setPaginator();

            });
    }

    setPaginator(): void {
        if (this.assignationService.assignmentsPaginated.paginator) {
            this.countEntities = this.assignationService.assignmentsPaginated.paginator.count;
            this.pageIndex = this.assignationService.assignmentsPaginated.paginator.page - 1;
            this.pageRange = this.assignationService.assignmentsPaginated.paginator.range;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    onPaginateChange(event): void {
        this.assignationService.assignmentsPaginated.paginator.page = event.pageIndex + 1;
    }

    isSelected(row): boolean {
        return this.selected && (this.selected.id === row.id);
    }

    updateCheck(): void {
        this.isChecked = !this.isChecked;
    }

    openAssignment(assignment: DataEntity): void {
        // mono assignement
        const assignated_node = assignment.get('assignated_node');
        // multi assignement
        const assignated_nodes = assignment.get('assignated_nodes');
        let type = '';
        let idLesson = '';
        if (assignated_node === null && assignated_nodes.length > 0) {
            // in case of multi assignation we always take the info of the first assignation to launch
            // in a future us we will take the assignation where user stop before if he begin and stop before end
            type = assignated_nodes[0].type;
            idLesson = assignated_nodes[0].id;
        } else if (assignated_node !== null) {
            type = assignated_node.type;
            idLesson = assignated_node.id;
        } else {
            console.error('fol-334 : error because assignated_node is null and assignated_nodes.length <=0');
            return;
        }

        const route = ['..', 'assignment'];
        switch (type) {
            case 'form':
                route.push('forms');
                break;
            case 'lesson':
                route.push('lessons');
                break;
        }

        if (route.length) {
            route.push(idLesson);
        }

        route.push('player');

        const valid = this.dateIsValid(assignment.get('state'), assignment.get('type_term'), assignment.get('dates'));
        if (valid && valid.available) {
            if (this.assignationService.launchAssignment(assignment)) {
                // consulted flag
                let flaggingId;

                if (typeof (assignment.get('consulted')) === 'object') {
                    flaggingId = assignment.get('consulted').flagging_id;
                }
                this.flagService.updateFlagEntity(assignment.attributes.assignated_node, 'node', 'consulted', flaggingId);

                this.router.navigate(route, {relativeTo: this.route});
            } else {
                // pop up will advertising licence error will prompt
            }
        }
    }

    /**
     * set the title of assignment :
     * simple assignement : use title in assignment if only one lesson to launch,
     * multi lesson  : one assignment with multiple lesson : use main title or first title of array assignment_nodes
     * @param assignment current assignement for the row
     */
    public getTitleAssignment(assignment: DataEntity): string {
        let title = '';
        if (assignment.get('assignated_node')) {
            title = assignment.get('assignated_node').title;
        } else {
            if (assignment.get('title')) {
                title = assignment.get('title');
            } else {
                title = assignment.get('assignated_nodes')[0].title;
            }
        }
        return title;
    }

    public getConsultedAssignment(assignment: DataEntity): string {
        let consulted = '';
        if (assignment.get('assignated_node')) {
            consulted = assignment.get('assignated_node').consulted.consulted_bool;
        } else {
            if (assignment.get('consulted')) {
                consulted = assignment.get('consulted');
            } else {
                consulted = assignment.get('assignated_nodes')[0].consulted.consulted_bool;
            }
        }
        return consulted;
    }

    unassign(assignment: DataEntity): void {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: this.dialogTitle,
            bodyDialog: this.dialogBody,
            labelTrueDialog: this.dialogYes,
            labelFalseDialog: this.dialogNo
        };

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const entity = new DataEntity('assignations', assignment.attributes, this.octopusConnect, assignment.id);
                entity.remove()
                    .subscribe(success => {
                        if (success) {
                            this.communicationCenter
                                .getRoom('assignment')
                                .next('unassign', assignment.get('assignated_node'));
                            this.assignationService.assignmentsPaginated.paginator.reload();
                        }
                    });
            }
        });
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.includes(name);
    }

    public selectFilter(event, field): void {
        if (event.value === 'all') {
            delete this.optionsInterface.filter[field];
        } else {
            this.optionsInterface.filter[field] = event.value;
        }

        this.blurElementRef(field);
    }

    public localizedType(type: string): string {
        return `assignment.type.${type}`;
    }

    public localizedState(state: string): string {
        return `assignment.state.${state}`;
    }

    get groups(): Array<any> {
        if (this.assignationService.groupsList) {
            return this.assignationService.groupsList;
        }

        return [];
    }

    get workgroups(): Array<any> {
        if (this.assignationService.workgroupsList) {
            return this.assignationService.workgroupsList;
        }

        return [];
    }

    get learners(): Array<any> {
        if (this.assignationService.learnersList) {
            return this.assignationService.learnersList;
        }

        return [];
    }

    isAtLeastTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    private dateIsValid(state, type, date): any {
        const startDate = date ? date.value : null;
        const endDate = date ? date.value2 : null;
        const dateNow = Math.floor(Date.now() / 1000);

        if (type && this.assignationService.hasCompletionDate(type.label)) {
            if ((startDate && startDate < dateNow) && (endDate && endDate > dateNow)) {
                if (state === 'closed') {
                    return {
                        state: 'valid',
                        available: true,
                    };
                }
            } else if ((startDate && startDate > dateNow) && (endDate && endDate > dateNow)) {
                return {
                    state: 'assigned',
                    available: false,
                };
            } else if (endDate && endDate < dateNow) {
                return {
                    state: 'correct',
                    available: true,
                };
            }
        }

        if (startDate && startDate > dateNow && type.label === 'training') {
            return {
                state: 'assigned',
                available: false,
            };
        }

        return {
            state: 'available',
            available: true,
        };
    }


    public get stateFromDate(): boolean {
        return this.assignationService.settings.getStateFromDate;
    }

    public checkState(assignment): string {
        const state = (assignment.get('state_term') && assignment.get('state_term').label) || assignment.get('state');

        if (!this.stateFromDate) {
            return state;
        } else {
            if (assignment) {
                const valid = this.dateIsValid(state, assignment.get('type_term'), assignment.get('dates'));
                if (valid) {
                    return valid.state;
                }

            }

            return 'assigned';
        }
    }

    public getIconState(assignment): string {
        const state = this.checkState(assignment);
        switch (state) {
            case 'assigned':
                return 'tap_check';
            case 'pending':
                return 'hourglass';
            case 'closed':
                return 'chat_close';
        }
    }

    public checkAccess(user: string[]): boolean {
        if (user) {
            return this.authService.hasLevel(user);
        }
        return false;
    }

    getGrade(assignation): string {
        if (assignation) {
            if (this.checkState(assignation) === 'correct') {
                if (+assignation.get('progress') === 0) {
                    return '-';
                } else {
                    const newGrade = Math.min(20, Math.round(assignation.get('grade') * 10) / 10);

                    return newGrade.toString() + '/20';
                }
            }
        }
        return '';
    }

    public getProgress(assignation): string {
        if (assignation) {
            return (Math.round(+assignation.get('progress')) || '0') + '%';
        }

        return '';
    }

    cantBeDeassign(assignation): boolean {
        return +assignation.get('progress') > 0;
    }

    selectGroups(event, type): void {
        switch (type) {
            case 'group':
                this.filterGroupActive = event.value;
                break;
            case 'workgroup':
                this.filterWorkgroupActive = event.value;
                break;
        }

        this.learnersList = this.assignationService.learnersList;

        if (this.filterGroupActive && this.filterGroupActive !== 'all' && this.filterGroupActive !== '') {
            this.learnersList = this.learnersList.filter((learner) => learner.groups.indexOf(this.filterGroupActive) !== -1);
        }
        if (this.filterWorkgroupActive && this.filterWorkgroupActive !== 'all' && this.filterWorkgroupActive !== '') {
            this.learnersList = this.learnersList.filter((learner) => learner.workgroups.indexOf(this.filterWorkgroupActive) !== -1);
        }

        if (this.learnersList !== this.assignationService.learnersList) {
            const learnerIds = this.learnersList
                .map((learner) => learner.id);
            this.applyFilters({value: learnerIds.join(',')}, 'assignated_user');
        } else {
            this.applyFilters({value: 'all'}, 'assignated_user');
        }
    }

    selectLearners(event): void {
        this.applyFilters({value: event.value.join(',')}, 'assignated_user');
    }

    public getEducationalLevels(educationalLevels: any): string {
        return educationalLevels.map(e => e.label).join(', ');
    }

    public displayButton(buttonIdentifier: string): boolean {
        return this.assignationService.getAllowedActions().includes(buttonIdentifier);
    }

    public edit(row: DataEntity): void {
        this.communicationCenter
            .getRoom('activities')
            .next('launchLessonEditor', {id: row.attributes.assignated_node.id});
    }
}

