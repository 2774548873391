import {Component, OnDestroy, OnInit} from '@angular/core';
import {IAnswer, IMultipleChoice} from '@modules/activities/core/player-components/multiple-choice-grid/multiple-choice/models/imultiple-choice';
import * as _ from 'lodash';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {IActivity, IQueryParams} from '@modules/activities/core/player-components/interactive-image/models/interactive-image.models';
import {DataEntity} from 'octopus-connect';
import {Observable, Subject} from 'rxjs';

@Component({
    selector: 'app-multiple-choice-grid',
    templateUrl: './multiple-choice-grid.component.html',
    styleUrls: ['./multiple-choice-grid.component.scss']
})

export class MultipleChoiceGridComponent implements OnInit, OnDestroy {

    public answers = [];
    public instruction: string = '';
    public multipleChoiceDatas: IMultipleChoice[] = [];

    private activity: IActivity = {} as any;
    private activityStepIndex: number;
    private questions = [];
    private unsubscribeInTakeUntil = new Subject();
    private userSave: DataEntity;

    constructor(private activatedRoute: ActivatedRoute,
                private activityService: ActivitiesService) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: IQueryParams) => {
            this.activity.id = params.activityId;
            this.activity.isLoadBeforeLaunch = false;
            this.initialize();
        });
    }

    /**
     * initialize launch each time new activity is launch on the same route
     */
    private initialize(): void {
        this.reset(true);
        this.getActivityData();
        // init action button
        this.actionButtonSubscribe();
    }

    /**
     * listen action button like save
     * launch action in regard of which action button is use
     */
    private actionButtonSubscribe(): void {
        this.activityService.userActionWaiting
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((action) => {
                if (action.actionLabel === 'save') {
                    this.saveAnswer()
                        .take(1)
                        .subscribe((userSave: DataEntity) => {
                            if (userSave) {
                                this.userSave = userSave;
                            }
                            action.endSubject.next();
                        });
                }
            });
    }

    /**
     * reset state of component like if component was destroy and create again
     * @param resetAllSubscribe : reset or not all subscribe of component
     */
    private reset(resetAllSubscribe = false): void {
        // reset subscribe
        if (resetAllSubscribe) {
            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject();
        }
        // reset field state
        this.multipleChoiceDatas = [];
        this.questions = [];
        this.answers = [];
        this.instruction = '';
        this.unsubscribeInTakeUntil = new Subject();
        this.userSave = null;

        this.activityService.doesUserResponsed.next(false);
    }

    ngOnDestroy(): void {
        if (this.unsubscribeInTakeUntil) {
            this.unsubscribeInTakeUntil.next();
            this.unsubscribeInTakeUntil.complete();
        }
    }

    /**
     * get all the data of the current activity
     */
    private getActivityData(): void {
        this.activityService.launchActivity(this.activity)
            .take(1)
            .subscribe((data) => {
                this.setContentData(data);
                this.loadUserSave();
                this.activityStepIndex = this.activityService.presentArrayElementIndex;

            });
    }

    /**
     * save the answers in json format
     */
    private saveAnswer(): Observable<DataEntity> {
        return this.activityService.saveUserSave(this.activity.id.toString(), null, JSON.stringify(this.multipleChoiceDatas), 0, 'genericsave', this.userSave, this.activityStepIndex);
    }

    /**
     * load userSave with answers if exist
     */
    private loadUserSave(): void {
        this.activityService.getUserSave(this.activity.id, this.activityService.currentAssignmentID)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    const content = userSave.get('userActivity').entitySave.content;
                    // all data are store in back in a json object we parse it in current obect
                    this.multipleChoiceDatas = JSON.parse(content);
                }
            });
    }

    /**
     * set the back data in the format we need for the front
     * array of question with for each question an array of possible answers
     */
    private setContentData(data: DataEntity): void {
        // get all the data need
        const dataTemp = this.activityService.getPropertyFromNestedObject(data, ['reference']);
        this.instruction = dataTemp.instruction;
        this.questions = dataTemp.activity_content.questions;
        this.setAnswers(dataTemp.activity_content.answers);
        this.setMultipleChoiceDatas();
    }

    /**
     * set the answer array with select value by default false
     * @param answers all the possible answer for each question
     **/
    private setAnswers(answers: IAnswer[]): void {
        answers.forEach((answer: IAnswer) => {
            this.answers.push({
                id: answer.id,
                answer: answer.answer,
                value: answer.value,
                select: false
            });
        });
    }

    /**
     * create final object :
     * take data for back with an array of question and one array of answer and prepare data to
     * be use by the component
     */
    private setMultipleChoiceDatas(): void {
        this.questions.forEach(question => {
            this.multipleChoiceDatas.push({
                id: question.id,
                question: question.question,
                multiple_choice: question.multiple_choice,
                answers: _.cloneDeep(this.answers)
            });
        });
    }

    /**
     * event in return of change response
     * /!\ choiceData is equal to this.multipleChoiceDatas because he pass by reference /!\
     * so change of value in choiceData affect this.multipleChoiceData Directly
     * @param choiceData : IMultipleChoice object change in child return to parent
     */
    public updateAnswers(choiceData: IMultipleChoice): void {
        this.allowedSaveButton();
    }

    /**
     * Allowed save buton(enable) only if all question have an answer
     */
    private allowedSaveButton(): void {
        const numberOfQuestionAnswered = this.multipleChoiceDatas.filter(question => {
            return question.answers.filter(answer => answer.select === true).length > 0;
        }).length;
        // enable save button if all question was answered
        if (numberOfQuestionAnswered === this.multipleChoiceDatas.length) {
            this.activityService.doesUserResponsed.next(true);
            this.activityService.saving = false;
            this.activityService.isSaveReady = true;
        } else {
            this.activityService.doesUserResponsed.next(false);
        }
    }
}
