import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatInput} from '@angular/material';
import {DataCollection, DataEntity, OrderCriteria, OrderDirection} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {SearchFiltersService} from 'fuse-core/components/search-filters/search-filters.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * generic component to apply filter on data
 * /!\this component must be inside <mat-toolbar>/!\
 * exemple:
 *  <mat-toolbar class="filters">
 *      <app-search-filters
 *
 *      [fields]="fieldsToDisplay"
 *      list of fields ['title', 'label']
 *
 *      [countEntities]="countEntities" [orderFields]="false"
 *      use the order of fields to order the html fields
 *      need to put 'bookmarks', 'launchsearch' and all html element in settings
 *
 *      [overideEndpointFilterName]="[{originalFilterName: 'bookmarks', targetFilterName: 'bookmarks_theme'}]"
 *      in some case the name of the filter could be différent of the normalize name set corresonping fields here
 *
 *      [customList]="{methods:methods}" methods is an array of :
 *      { id: string,
 *        label: string
 *       }
 *       use to get list with complexe logic in parent get data for mutliple endpoint etc...
 *
 *      (launchSearch)="loadData($event)"></app-search-filters>
 *      emit the filter created to the parent button
 * </mat-toolbar>
 */
@Component({
    selector: 'app-search-filters',
    templateUrl: './search-filters.component.html',
    styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnInit, OnDestroy {
    @Output('launchSearch') filter: EventEmitter<CollectionOptionsInterface> = new EventEmitter<CollectionOptionsInterface>();

    @Input('fields') fields: string[] = []; // list of allowed fields ['title', 'skills' etc.]
    @Input('orderFields') orderFields: boolean = false; // pass true for order field like in setting array using appMoveElement directive
    @Input('countEntities') countEntities: number = 0;
    // use this customList to get list with complex logic in parent component
    // TODO change this method is for a field we must be able to have multiple custom list like type without other input
    @Input('customList') customList?: { methods?: { id: string, label: string }[], types?: { id: string, label: string }[] };
    // in some part of program name of filter could be different of normalize case so we use it to overide the default value
    @Input('overideEndpointFilterName') overideEndpointFilterName: { originalFilterName: string, targetFilterName: string }[] = [];
    // change the default name of field in translate
    @Input('customFieldName') customFieldName: { field: string, value: string }[] = [];

    @ViewChild('titleInput') titleInput: MatInput;
    // data of the lists fields
    public dataLists: { [key: string]: DataEntity[] } = {};
    // list of form control with endpoint Filter key( value to put in CollectionOptionsInterface filter value)
    public controls: { [key: string]: { endpointFilter: string, formControl: FormControl } } = {};
    // name of endpointfilter (filter name in back) and corresponding formControl in html
    private listOfFormControl: { endpointFilter: string, formControlField: string }[] =
        [{endpointFilter: 'title', formControlField: 'titleFilter'},
            {endpointFilter: 'skills', formControlField: 'searchSkillsFilter'},
            {endpointFilter: 'difficulty_id', formControlField: 'difficultyFilter'},
            {endpointFilter: 'licenseContent', formControlField: 'licenseFilter'},
            {endpointFilter: 'level', formControlField: 'educationnalLevelFilter'},
            {endpointFilter: 'theme', formControlField: 'searchThemeFilter'},
            {endpointFilter: 'bookmarks', formControlField: 'searchBookmarkFilter'},
            {endpointFilter: 'format', formControlField: 'typeFilter'},
            {endpointFilter: 'author:name', formControlField: 'authorFilter'},
            {endpointFilter: 'group:name', formControlField: 'groupFilter'}
        ];

    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 12,
        orderOptions: []
    };

    public defaultFieldTitle: string = '';

    constructor(private searchFiltersService: SearchFiltersService,
                private translateService: TranslateService) {
    }

    /**TODO get the list only if fields list are in form */
    ngOnInit(): void {
        this.overideSpecificEndpointNameFilter();
        this.initFormsControl();
        this.setAllLists();
        this.translateTitleField();
    }

    /**
     * in some part of code lesson and theme for example the name for filter a field could be different of other part
     * of code exemple bookmarks except for theme bookmarks_theme
     * this override with the good value .
     * TODO In back : Better way will be to add spécific endpoint with normalize fields name
     */
    private overideSpecificEndpointNameFilter(): void {
        if (this.overideEndpointFilterName && this.overideEndpointFilterName.length > 0) {
            this.overideEndpointFilterName.forEach(matchingFields => {
                this.listOfFormControl.filter(field => field.endpointFilter === matchingFields.originalFilterName)[0].endpointFilter = matchingFields.targetFilterName;
            });
        }
    }

    /**
     * init form control use a list of string to init all formcontrol
     * with value to '' for filter
     */
    private initFormsControl(): void {
        this.listOfFormControl.forEach(formControl => {
            this.controls[formControl.formControlField] = {endpointFilter: formControl.endpointFilter, formControl: new FormControl('')};
        });
    }

    /**
     * set all the list take the list of fields and search wich one is a list
     * when find one set the list calling the endpoint corresponding
     */
    private setAllLists(): void {
        for (let endpointFieldNameKey in endpointFieldName) {
            if (this.fields.filter(field => field === endpointFieldNameKey).length > 0) {
                this.setList(endpointFieldName[endpointFieldNameKey]);
            }
        }
    }

    /**
     * init list data by type
     * @param endpoint : type of the list = endpoint name
     */
    private setList(endpoint: string): void {
        this.searchFiltersService.getList(endpoint).take(1)
            .subscribe((data: DataCollection) => {
                this.dataLists[endpoint] = data.entities;
            }, error => {
                console.log(error);
            });
    }

    /**
     * launch search with filter if add
     */
    public launchSearch(): void {
        this.updateFilter();
        this.filter.emit(this.optionsInterface);
    }

    /**
     * update filter to apply use list of controls to create filter
     */
    private updateFilter(): void {
        // iterate on formControl name key titleFilter
        for (let control in this.controls) {
            // if on list of formControl present in view there is value for the current key add filter else remove it
            if (this.controls[control].formControl && this.controls[control].formControl.value !== ''
                && this.controls[control].formControl.value !== undefined && this.controls[control].formControl.value !== false) {
                this.optionsInterface.filter[this.controls[control].endpointFilter] = this.controls[control].formControl.value;
            } else {
                delete this.optionsInterface.filter[this.controls[control].endpointFilter];
            }
        }

        // for find on title or tag like before common search filter
        //  use urlExtension: '' // use it to pass data directly in url like /?filtredirect
        if (this.optionsInterface.filter.title && this.optionsInterface.filter.title !== '') {
            this.optionsInterface.urlExtension = this.optionsInterface.filter.title;
            delete this.optionsInterface.filter.title;
        } else {
            this.optionsInterface.urlExtension = '';
        }
    }

    /**
     * display or not field regard to allowedFields
     * @param field
     */
    public displayField(filterField: string): boolean {
        return this.fields.indexOf(filterField) > -1;
    }

    /**
     * some field can have multiple name in regard of where they're call
     * change the defaultField by those pass in Input
     * @param field: string name of the field
     */
    public getFieldTerms(field): string {
        if (this.customFieldName.filter(customField => customField.field === field).length > 0) {
            return this.customFieldName.filter(customField => customField.field === field)[0].value;
        } else {
            return '';
        }
    }

    /**
     * by defalut title value is a complex field title with tree field concat
     */
    private translateTitleField(): void {

        const data = {
            titleOne: 'generic.title',
            titleTwo: 'generic.or',
            titleThree: 'generic.tags'
        };

        for (const term in data) {
            this.translateService.get(data[term]).subscribe((translation: string) => {
                this.defaultFieldTitle = this.defaultFieldTitle + ' ' + translation;
            });
        }
    }

    ngOnDestroy(): void {
    }
}

/**
 * enum of fieldtypeand endpoint matching 'field' = 'endpoint name' to get the list except
 * spécific list pass by input
 */
export enum endpointFieldName {
    'difficulty' = 'difficulty',
    'skills' = 'skills',
    'theme' = 'themes',
    'educationnalLevel' = 'educational_level',
}

