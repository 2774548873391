import { Component, OnInit, Input } from '@angular/core';
import {DataEntity} from 'octopus-connect';

import {ReplaySubject} from 'rxjs/ReplaySubject';
import {ResearchSectionTemplate} from '@modules/research-sheet/core/definitions';

@Component({
  selector: 'fuse-section-guideline-and-grid',
  templateUrl: './section-guideline-and-grid.component.html',
  styleUrls: ['./section-guideline-and-grid.component.scss']
})
export class SectionGuidelineAndGridComponent implements OnInit {
  @Input('sectionGrid') sectionGridAndRecall: ReplaySubject<object>;


  sectionGridAndRecallData: any;
  sectionDefinition: ResearchSectionTemplate;
  readingGridText: any;

  settings: any;

  accessTrainer: string[];
  accessLearner: string[];


  constructor(
  ) { }

  ngOnInit() {
    this.sectionGridAndRecall.subscribe((data: any) => {
      this.sectionGridAndRecallData = data;
      this.sectionDefinition = data.sectionDefinition;
      console.log(this.sectionDefinition);
      this.readingGridText = data.readingGrid ? data.readingGrid : '';
    });
  }

}
