import {Component, Inject, OnInit} from '@angular/core';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CorpusService} from '@modules/corpus';

@Component({
    selector: 'app-corpus-modal',
    templateUrl: './corpus-modal.component.html',
    styleUrls: ['./corpus-modal.component.scss']
})
export class CorpusModalComponent implements OnInit {

    selection: CorpusRessource[] = [];

    constructor(
        private ref: MatDialogRef<CorpusModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CorpusRessource[],
        private corpusService: CorpusService
    ) {}

    ngOnInit() {
        this.selection = this.data['selection'];
    }

    onModalValidated(resources: CorpusRessource[]) {
        this.ref.close(resources);
    }

    get corpusId(): number {
        return this.corpusService.getCurrentCorpusSet(this.data['projectId']).corpusArray[0].id;
    }
}
