import {Component, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {AuthenticationService} from '@modules/authentication';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable()
export class IsUserLogged implements CanActivate {

    userInformation: DataEntity;

    constructor(
        private connector: OctopusConnectService,
        private usersProvider: AccountManagementProviderService,
        private authService: AuthenticationService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.take(1).subscribe((userData: DataEntity) => {
            subject.next(true);
        }, () => {
            subject.next(false);

            this.router.navigate(['/login'], {
                queryParams: {
                    return: state.url
                }
            });
        });

        return subject;
    }
}
