import {ModuleWithProviders, NgModule} from '@angular/core';
import {GroupsListingComponent} from './groups-listing/groups-listing.component';
import {IsUserLogged} from '../../../is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import { GroupsManagementComponent } from './groups-management.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import { FuseSharedModule } from '@fuse/shared.module';
import {FuseGroupsFormDialogComponent} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {FuseGroupsMainSidenavComponent} from '@modules/groups-management/core/groups-listing/sidenavs/main/main.component';
import {FuseGroupsListComponent} from '@modules/groups-management/core/groups-listing/groups-list/groups-list.component';
import {GroupsListingService} from '@modules/groups-management/core/groups-listing/groups-listing.service';
import { LearnerComponent } from './learner/learner.component';
import { GroupComponent } from './group/group.component';
import { WorkgroupComponent } from './workgroup/workgroup.component';
import { TrainerComponent } from './trainer/trainer.component';
import {TrainerService} from '@modules/groups-management/core/trainer/trainer.service';
import {LearnerService} from '@modules/groups-management/core/learner/learner.service';
import {GroupService} from '@modules/groups-management/core/group/group.service';
import {WorkgroupService} from '@modules/groups-management/core/workgroup/workgroup.service';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {AuthenticationService} from '@modules/authentication';
import {SharedModule} from '../../../shared/shared.module';
import { InstitutionGroupComponent } from './institution-group/institution-group.component';
import {InstitutionGroupService} from '@modules/groups-management/core/institution-group/institution-group.service';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import { JoinGroupComponent } from './join-group/join-group.component';
import {modulesSettings} from '../../../settings';
import { GroupsCustomActionComponent } from './groups-listing/groups-custom-action/groups-custom-action.component';
import { UsersImportComponent } from './users-import/users-import.component';
import {GraphGroupManagementComponent} from '@modules/groups-management/core/graph-group-management/graph-group-management.component';

const routes: Routes = [
    {
        path       : 'groups',
        canActivate: [IsUserLogged],
        children: [
            {
                path       : 'list',
                component  : GroupsManagementComponent,
                children: [
                    {
                        path: 'learners',
                        component: LearnerComponent,
                        data: {
                            icon: 'student_add'
                        }
                    },
                    {
                        path: 'groups',
                        component: GroupComponent,
                        data: {
                            icon: 'class_add'
                        }
                    },
                    {
                        path: 'workgroups',
                        component: WorkgroupComponent,
                        data: {
                            icon: 'workgroup_add'
                        }
                    },
                    {
                        path: 'trainers',
                        component: TrainerComponent,
                        data: {
                            icon: 'trainer_add'
                        }
                    },
                    {
                        path: 'institutions',
                        component: InstitutionGroupComponent,
                        data: {
                            icon: 'institution_add'
                        }
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'learners'
                    }
                ]
            },
            {
                path: 'graph',
                component: GraphGroupManagementComponent,
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    },
];

@NgModule({
    imports: [
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        GroupsListingComponent,
        GroupsManagementComponent,
        FuseGroupsFormDialogComponent,
        FuseGroupsMainSidenavComponent,
        FuseGroupsListComponent,
        LearnerComponent,
        GroupComponent,
        WorkgroupComponent,
        TrainerComponent,
        InstitutionGroupComponent,
        JoinGroupComponent,
        GroupsCustomActionComponent,
        UsersImportComponent,
        GraphGroupManagementComponent

    ],
    providers: [

    ],
    entryComponents: [
        FuseGroupsFormDialogComponent,
        JoinGroupComponent,
        GroupsCustomActionComponent,
        UsersImportComponent
    ]
})
export class GroupsManagementModule {
    private static isMenuSet = false;
    childrenMenu: Array<any> = [];
    private authorizedRoles: Array<string>;

    constructor(
        private authService: AuthenticationService,
        private dynamicNavigation: DynamicNavigationService,
        groupsService: GroupService,
        private groupsManagementService: GroupsManagementService,
        private communicationCenter: CommunicationCenterService
    ) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'ADDED_IN_GROUP',
                icon: 'business',
                text: 'groups-management.groups_notifications_add_in',
                textTransform: (text: string, data: Object) => {
                    return {
                        groupName: data['groupName']
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'REMOVED_FROM_GROUP',
                icon: 'business',
                text: 'groups-management.groups_notifications_remove',
                textTransform: (text: string, data: Object) => {
                    return {
                        groupName: data['groupName']
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'GROUP_NAME_CHANGED',
                icon: 'business',
                text: 'groups-management.groups_notifications_rename',
                textTransform: (text: string, data: Object) => {
                    return {
                        oldGroupName: data['oldName'],
                        newGroupName: data['newName']
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });

        this.communicationCenter.getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Groupes',
                type: 'ADDED_IN_YOUR_GROUP',
                icon: 'business',
                text: 'groups-management.groups_notifications_comrade_added',
                textTransform: (text: string, data: Object) => {
                    return {
                        studentName: data['studentName'],
                        groupName: data['groupName'],
                    };
                },
                action: (data: Object) => {
                    return ['groups', 'list', 'workgroups'];
                }
            });

    }

    private postLogout(): void{
        GroupsManagementModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'parameter');
    }

    private postAuthentication(): void {
        this.childrenMenu = [];


        if (this.authService.hasLevel(['manager', 'trainer', 'administrator']) &&
            this.groupsManagementService.settingsAccess.institutionsGroupAccess) {
            this.childrenMenu.push({
                'id': 'param-child',
                'title': 'Mes établissements',
                'translate': 'groups-management.institutions',
                'type': 'item',
                'url': '/groups/list/institutions',
            });
        }

        if (this.authService.hasLevel(['manager', 'administrator'])) {
            this.childrenMenu.push({
                'id': 'trainers',
                'title': 'Mes enseignants',
                'translate': 'groups-management.my_trainers',
                'type': 'item',
                'url': '/groups/list/trainers',
            });
        }

        this.childrenMenu.push(...[
            {
                'id': 'param-child',
                'title': 'Mes classes',
                'translate': 'groups-management.classes',
                'type': 'item',
                'url': '/groups/list/groups',
            },
            {
                'id': 'param-child',
                'title': 'Mes groupes',
                'translate': 'groups-management.workgroups',
                'type': 'item',
                'url': '/groups/list/workgroups',
            },
            {
                'id': 'param-child',
                'title': 'Mes élèves',
                'translate': 'groups-management.students',
                'type': 'item',
                'url': '/groups/list/learners',
            }]);

        if (this.authService.hasLevel(['learner'])) {
            this.childrenMenu = [
                {
                    'id': 'groups',
                    'title': 'Mes classes',
                    'translate': 'groups-management.classes',
                    'type': 'item',
                    'url': '/groups/list/groups',
                },
                {
                    'id': 'param-child',
                    'title': 'Mes groupes',
                    'translate': 'groups-management.workgroups',
                    'type': 'item',
                    'url': '/groups/list/workgroups',
                }];
        }

        this.authorizedRoles = modulesSettings.groupsManagement.authorizedRoles;

        if (!GroupsManagementModule.isMenuSet &&
            this.authService.hasLevel(this.authorizedRoles)) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'parameter',
                'title': 'Mes contacts',
                'translate': 'navigation.groups-management',
                'type': 'collapse',
                'icon': 'group',
                'children': this.childrenMenu
            });

            if (!!this.groupsManagementService.settings.graph) {
                this.dynamicNavigation.registerModuleMenu('level0', {
                    'id': 'graph-dashboard',
                    'title': 'graph-dashboard',
                    'translate': 'navigation.graph_dashboard',
                    'type': 'item',
                    'url': '/groups/graph',
                });
            }
            GroupsManagementModule.isMenuSet = true;
        }

    }

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: GroupsManagementModule,
            providers: [
                GroupsManagementService,
                GroupsListingService,
                TrainerService,
                LearnerService,
                GroupService,
                WorkgroupService,
                InstitutionGroupService
            ]
        };
    }
}
