import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChaptersSelectionComponent} from 'fuse-core/components/chapters-selection/chapters-selection.component';
import {ChaptersService} from 'fuse-core/components/chapters-selection/chapters.service';
import {TranslateModule} from '@ngx-translate/core';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {FuseSharedModule} from 'fuse-core/shared.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatToolbarModule,
    FuseSharedModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule

  ],
  declarations: [
      ChaptersSelectionComponent
  ],
    entryComponents: [ChaptersSelectionComponent],
    providers: [
        ChaptersService
    ],
})
export class ChaptersModule { }
