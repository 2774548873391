import {Component, OnDestroy, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Subject} from 'rxjs/Subject';
import {ActivityInterface} from '@modules/activities/core/player-components/activity.interface';
import {Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-true-false',
    templateUrl: './true-false.component.html',
    styleUrls: ['./true-false.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TrueFalseComponent implements OnDestroy, OnInit, ActivityInterface {
    @ViewChild(OptionsComponent) optionsComponent: OptionsComponent;

    @Input('activityId') public activityId: any;
    @Input('contextId') public contextId: string;
    @Input('questionTypeName') questionTypeName: string;

    public activityPercentil = 0;
    public answerStatus = 2; // 1 => correct, 2 => missing, 3 => wrong;
    public displayFeedback = false;
    public hideMissing = false;
    public instruction: any;
    public isAnswered: boolean;
    public isTwoColumns = false;
    public options: any;
    public questionObject: any;
    public title: string;
    public wording: any;

    private activityStepIndex: number;
    private isSaving = false;
    private unsubscribeInTakeUntil = new Subject();
    private userAccessCorrection = false;
    private userSave: DataEntity;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private lessonsService: LessonsService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    initialize(): void {
        this.lessonsService.initDebugGrade();
        this.reset(true);

        this.activityService.launchActivity(this.activityId)
            .take(1)
            .subscribe(data => {
                if (data.reference.config) {
                    this.isTwoColumns = data.reference.config.doubleColumn !== 0;
                }

                this.questionObject = this.activityService.getPropertyFromNestedObject(data, ['reference']);
                this.instruction = this.questionObject.instruction;
                this.wording = this.questionObject.wording;
                this.options = [];
                for (const obj of this.questionObject.activity_content.answers) {
                    this.options.push(_.clone(obj));
                }
                this.loadUserSave();
                this.activityStepIndex = this.activityService.presentArrayElementIndex;

                if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.userAccessCorrection = true;
                }
            });

        this.activityService.userActionWaiting
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((action) => {
                switch (action.actionLabel) {
                    case 'test':
                    case 'save':
                        this.checkAnswer();
                        this.saveAnswer()
                            .take(1)
                            .subscribe((userSave: DataEntity) => {
                                if (this.isSaving && userSave) {
                                    this.isSaving = false;
                                    this.userSave = userSave;
                                }
                                action.endSubject.next();
                            });
                        break;
                    case 'reset':
                        this.reset(false, 'reset');
                        action.endSubject.next();
                        break;
                    case 'modify':
                        this.reset(false, 'modify');
                        action.endSubject.next();
                        break;
                    case 'see_solution':
                        this.displayFeedback = false;
                        action.endSubject.next();
                        break;
                    case 'see_answer':
                        this.displayFeedback = this.answerStatus !== 1 && this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer();
                        action.endSubject.next();
                        break;
                }
            });
    }

    private setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete' && this.options) {
            const answer = this.userSave.get('userActivity').entitySave.answers[0];

            if (answer) {
                this.options.forEach((option, key) => {
                    if (option.id === answer.id) {
                        this.options[key].select = true;
                        this.isAnswered = true;
                    }
                });

                this.checkAnswer();
                this.activityService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);

            }
        } else if (this.lessonsService.isTrainerSeeCorrection()) {
            this.checkAnswer();
        }
    }

    private loadUserSave(): void {
        this.activityService.getUserSave(this.activityId.id, this.contextId)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    this.setAnswer();

                    this.activityPercentil = Math.round(this.getGrade().oldGrade * 100);
                } else if (this.lessonsService.isMyAssignment()) {
                    this.saveDefault();
                } else if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.checkAnswer();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    reset(resetAllSubscribe: boolean = false, type = null): void {
        if (!type) {
            //  type is only set when we need usersave for calculating grade.
            this.userSave = type;
        }
        if (resetAllSubscribe) {
            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject();
        }

        this.isSaving = false;
        this.answerStatus = 2;
        this.displayFeedback = false;
        this.activityService.displayActions.next(true);
        this.isAnswered = false;
        this.optionsComponent.resetAllOptions();
    }

    public checkAnswer(): void {
        this.answerStatus = 3;

        this.options.forEach((option) => {
            if (option.select && +option.correct_answer) {
                this.answerStatus = 1;
            }
        });

        if (!this.options.some(option => +option.select)) {
            this.answerStatus = 2;
        }

        if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
            this.displayFeedback = (this.answerStatus === 3);
        }

        this.activityService.isUserAnswerStatus
           .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.userAccessCorrection) {
            this.activityService.checkAnswers.next({lessonCorrected: true});
        }

        this.optionsComponent.checkAnswers(this.userAccessCorrection);
    }

    getGrade(): any {
        let oldGrade = 0;
        let grade = 0;

        if (this.userSave) {
            const answer = this.userSave.get('userActivity').entitySave.answers[0];

            if (answer && !!answer.correct_answer) {
                oldGrade += 1;
            }
        }

        for (const option of this.options) {
            if (option.select && !!option.correct_answer) {
                grade += 1;
            }
        }

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    private saveDefault(): void {
        if (!this.isSaving && !this.userSave && !this.lessonsService.isLessonTest()) {
            this.isSaving = true;
            this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, [], this.answerStatus, 'qcm-save')
                .subscribe((userSave: DataEntity) => {
                    this.userSave = userSave;
                    this.isSaving = false;
                });
        }
    }

    public saveAnswer(): Observable<DataEntity> {
        this.isSaving = true;
        const grade = this.getGrade();
        this.lessonsService.setAssignGrade(grade['newGrade'], grade['oldGrade']);
        this.lessonsService.setProgress(this.userSave, this.answerStatus);

        if (this.userSave) {
            this.userSave.set('grade', +this.calculUserSavePercentil);
        }

        return this.optionsComponent.saveAnswer(this.options, this.answerStatus, this.userSave, this.activityStepIndex);
    }

    public get showPercentil(): string {
        if (this.isTrainerAndAssessment) {
            return this.activityPercentil ? ' ' + this.activityPercentil + '%' : ' 0%';
        }

        return '';
    }

    public get isTrainerAndAssessment(): boolean {
        return this.lessonsService.isAtLeastTrainer() && this.lessonsService.isLessonEvaluation() || this.userAccessCorrection;
    }

    private get calculUserSavePercentil(): number {
        return Math.round(this.getGrade().newGrade * 100);
    }
}
