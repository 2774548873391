import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {brand} from '../../../../../settings';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Component({
    selector: 'fuse-app-lessons-list',
    templateUrl: './lessons-list.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class LessonsListComponent {

    public brand: string = brand;

    public creatorsRolesFilter: number[] = this.lessonsService.getAllowedRoleIdsForModelsCreation();

    constructor(public lessonsService: LessonsService) {
    }

}
