import {Injectable, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {AccountManagementProviderService} from '../account-management-provider.service';
import {DataEntity} from 'octopus-connect';
import {defaultApiURL, modulesSettings} from '../../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject, Subject} from 'rxjs';
import {MatListSubheaderCssMatStyler} from '@angular/material/list';

const settingsStructure: ModelSchema = new ModelSchema({
    allowedExtensions: Structures.array(['*']),
    allowedMaxSize: Structures.number(10),
    canEditAvatar: Structures.array(['learner', 'trainer', 'manager', 'administrator']),
    canSelfDelete: Structures.array([]),
    fields: Structures.object({
        default: ['label', 'email', 'password', 'you_are', 'find_us', 'newsletter', 'picture']
    }),
    youAreValues: Structures.array(['teacher', 'parent', 'teen', 'district', 'other']),
    findUsValues: Structures.array(['advertisement', 'mouth', 'research', 'tradeshow', 'training', 'program', 'other'])
});

@Injectable()
export class ProfileService implements OnInit {
    public settings: { [key: string]: any };
    public userInformation: DataEntity;
    public userInformationOnChanged = new BehaviorSubject<any>({});

    private fileOnChanged = new BehaviorSubject<any>({});
    private file: any;
    private _selectedMode = false;
    private _urlFileUpload = defaultApiURL + 'api/file-upload';

    constructor(
        private http: HttpClient,
        private communicationService: CommunicationCenterService,
        private accountManagementProvider: AccountManagementProviderService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {

            this.accountManagementProvider.subject.subscribe(userInformation => {
                this.userInformation = this.accountManagementProvider.loggedUser;
                this.userInformationOnChanged.next(this.userInformation);
                resolve();

            });

        });
    }

    ngOnInit() {

        this.accountManagementProvider.subject.subscribe(userInformation => {
            this.userInformation = this.accountManagementProvider.loggedUser;
            this.userInformationOnChanged.next(this.userInformation);

        });

    }

    /**
     * Save user data
     * @param user
     * @return Subject with true if success, false if error
     */
    public editUser(user): Subject<boolean> {
        return this.file ? this.uploadFile(user, this.file) : this.editUserProfile(user);
    }

    private editUserProfile(user): Subject<boolean> {
        const editCompleteSubject = new Subject<boolean>();

        if (user.password && user.password === '') {
            delete user.password;
        }

        this.accountManagementProvider.editUser(user, (isSuccess: boolean) => {
            if (isSuccess) {
                this.editMode = false;
                this.userInformationOnChanged.next(this.accountManagementProvider.loggedUser);
            }

            editCompleteSubject.next(isSuccess);
        });

        return editCompleteSubject;
    }

    editValidateMail(userData) {
        this.accountManagementProvider.loggedUser = userData;
        this.accountManagementProvider.editUserValidateEmail();
    }

    private uploadFile(user, fileToUpload: File): Subject<boolean> {
        const editCompleteSubject = new Subject<boolean>();


        const formData = new FormData();
        formData.append('file', fileToUpload);

        this.http
            .post<any>(this._urlFileUpload, formData, {headers: {'access-token': this.accessToken}})
            .subscribe((res) => {
                user.picture = res.data[0][0].id;
                this.editfile = null;
                this.editUserProfile(user)
                    .subscribe(isSuccess => editCompleteSubject.next(isSuccess));
            });

        return editCompleteSubject;
    }

    set editMode(mode: boolean) {
        this._selectedMode = mode || false;
    }

    get selectedMode(): boolean {
        return this._selectedMode;
    }


    set editfile(data: any) {
        this.file = data || null;
        this.fileOnChanged.next(data);
    }


    get accessToken(): any {
        return this.accountManagementProvider.userAccessToken;
    }

    /**
     * Call Authentication service for logout current user
     * @returns {ReplaySubject} emitted while logout is complete
     */
    public deleteCurrentProfile(): ReplaySubject<void> {
        const onDeleteComplete = new ReplaySubject<void>(1);

        this.accountManagementProvider
            .deleteCurrentLoggedUser()
            .subscribe((successfullyDeletion) => {
                if (successfullyDeletion) {
                    this.communicationService
                        .getRoom('authentication')
                        .next('do-logout', () => onDeleteComplete.next());
                } else {
                    throw new Error('Unable to delete currently logged user');
                }
            });

        return onDeleteComplete;
    }
}
