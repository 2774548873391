import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {Subject} from 'rxjs';
import test from '@angular/cli/commands/test';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageComponent implements OnInit, OnDestroy {
    @Input() public activityId: any;
    @Input() public contextId: string;
    @Input() questionTypeName: string;

    public questionObject: any;
    public options: any;
    public instruction: string;
    public wording: string;
    public imageUrl: string;

    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private lessonsService: LessonsService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    private initialize(): void {
        this.lessonsService.initDebugGrade();
        this.activityService.launchActivity(this.activityId)
            .take(1)
            .subscribe(data => {
                this.questionObject = this.activityService.getPropertyFromNestedObject(data, ['reference']);
                this.instruction = this.questionObject.instruction;
                this.wording = this.questionObject.wording;
                this.imageUrl = this.questionObject.activity_content[0].image;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

}
