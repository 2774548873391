import {AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {MatCheckbox, MatDialog, MatDialogRef, MatInput, MatPaginator, MatSelect, MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/map';
import {FlagService} from '../../../../../../shared/flag.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Subject} from 'rxjs/Subject';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';
import {FormControl} from '@angular/forms';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {LessonMetadataDialogComponent} from '../lesson-metadata-dialog/lesson-metadata-dialog.component';
import {brand} from '../../../../../../settings';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash';

@Component({
    selector: 'fuse-app-lessons-tab',
    templateUrl: './lessons-tab.component.html',
    styleUrls: ['./lessons-tab.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LessonsTabComponent implements OnInit, OnDestroy, AfterViewInit {
    corpusId: string;
    resourcesSubscription: Subscription;
    resources;
    selectAll = false;
    isChecked = false;
    files: any;
    dataSource = new MatTableDataSource();
    displayedColumns;
    selected;
    numberOfActivities: number;
    items: any[];
    types: string[];
    private paginator: MatPaginator;
    private sort: MatSort;
    private optionsInterface: CollectionOptionsInterface;
    private unsubscribeInTakeUntil = new Subject();
    public levelsDataEntity: DataEntity[] = [];
    public fieldsToDisplay: string[] = [];


    @Input('type') type: string;
    @Input() creatorsRolesFilter: number[];

    countEntities = 0;
    pageIndex = 0;
    pageRange = 12;
    pageRangeOptions = [12];
    public customFieldName: { field: string, value: string }[] = [{field: 'title', value: ''}];

    allTypes = [];
    public titleFilter: FormControl;
    public licenseFilter: FormControl;
    public searchSkillsFilter: FormControl;
    public searchThemeFilter: FormControl;
    public educationnalLevelFilter: FormControl;

    public brand: string = brand;

    public dataCards: any[] = [];
    private callbacks: { [key: string]: any };
    private dataCardSettings: { [key: string]: any };

    selectedResources;
    checkboxes: {};

    dialogRef: MatDialogRef<any>;

    @ViewChild('titleInput') titleInput: MatInput;
    public skillsList: any;
    public themeList: any;


    @ViewChildren(MatCheckbox) checkBoxes: QueryList<MatCheckbox>;
    public methods: any[] = [];
    public get displayCreateLessonHelper(): boolean {
        return this.lessonsService.settings.displayCreateLessonHelper;
    }

    constructor(
        public activitiesService: ActivitiesService,
        public lessonsService: LessonsService,
        public flagService: FlagService,
        private router: Router,
        public dialog: MatDialog,
        private octopusConnect: OctopusConnectService,
        public datacardService: DatacardService,
        private authService: AuthenticationService,
    ) {

        if (this.displayField('educationnalLevel')) {
            this.setEducationnalLeveList();
        }

        if (this.displayField('skills')) {
            this.getSkills();
        }

        if (this.displayField('theme')) {
            this.getThemes();
        }

        this.lessonsService.onFilesChanged.subscribe(files => {
            this.files = files;
            this.checkboxes = {};
        });

        this.lessonsService.onSelectedResourcesChanged.subscribe(resources => {
            for (const id in this.checkboxes) {
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }
                this.checkboxes[id] = resources.includes(id);
            }
            this.selectedResources = this.resources;
        });

        this.lessonsService.onFileSelected.subscribe(selected => {
            this.selected = selected;
        });

        this.optionsInterface = {
            filter: {}, // filter results by current user id
            page: 1,
            range: 12
        };

    }

    ngAfterViewInit(): void {
        this.setDataSourceAttributes();
    }

    ngOnInit(): void {
        // init title filter with good value
        this.customFieldName[0].value = this.activitiesService.settings.filterTitleToTranslate;

        this.resourcesSubscription = this.refreshList();

        this.titleFilter = new FormControl('');
        this.licenseFilter = new FormControl('');
        this.searchSkillsFilter = new FormControl('');
        this.searchThemeFilter = new FormControl('');
        this.educationnalLevelFilter = new FormControl('');

        if (this.lessonsService.checkAccess(['manager'])) {
            this.activitiesService.getMethods().takeUntil(this.unsubscribeInTakeUntil).subscribe((methods) => {
                methods.entities.forEach(method => {
                    this.methods.push({
                        id: method.id,
                        label: method.get('name')
                    });
                });
            });
        } else {
            this.activitiesService.licensingMethods
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((methods) => {
                    this.methods = methods;
                });
        }

        this.callbacks = {
            'isEditableAndErasable': (resource) => this.datacardService.isEditableAndErasable(resource, this.type),
            'openAssign': (resource) => this.openAssign(resource),
            'openDialog': (resource) => this.lessonsService.openDialog(resource),
            'openDuplicate': (id, action, type) => this.lessonsService.launchEditor(id, 'copy', 'lesson'),
            'openEditor': (id, action, type) => this.lessonsService.launchEditor(id, 'edit', 'lesson'),
            'openMetaDialog': (event, metadata) => this.openMetaDialog(event, metadata),
            'play': (resource) => this.lessonsService.navigateToLesson(resource),
            'downloadDoc': (path) => this.datacardService.downloadDoc(path),
            'share': (resource, val) => this.lessonsService.editGranuleLesson(resource, val),
            'isShareableCommunity': this.lessonsService.isShareableCommunity,
            'isShareableModel': this.lessonsService.isShareableModel
        };

        this.dataCardSettings = {
            assignable: this.lessonsService.settings.assignableLesson
        };
        this.setDisplayField();
    }

    /**
     * set array with fields to show in regard to settings
     */
    public setDisplayField(): void {
        this.fieldsToDisplay = this.settings.searchFields;
    }

    /**
     * set the educationnal level list
     */
    private setEducationnalLeveList(): void {
        this.activitiesService.getEducationnalLevel().subscribe((dataEducationnalLevel: DataEntity[]) => {
            this.levelsDataEntity = dataEducationnalLevel;
        }, error => {
            console.log(error);
        });
    }

    /**
     * show or not filter Field
     */
    public displayField(filterField: string): boolean {
        return this.activitiesService.settings['searchFields'].filter(field => field === filterField).length > 0;
    }

    /**
     * load data with filter rules if exists
     * @param optionsInterface : filter to apply
     */
    private loadData(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): void {


    }

    launchSearch(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): void {
        this.resourcesSubscription.unsubscribe();
        this.resourcesSubscription = this.refreshList(optionsInterface);
    }

    refreshList(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): Subscription {
        this.optionsInterface = _.cloneDeep(optionsInterface);
        // some spécific filter not coming from search filter top component
        if (this.settings && this.settings.loadLessonWithSublesson) {
            this.optionsInterface.filter['multi_step'] = this.settings.loadLessonWithSublesson['multi_step'];
        }

        if (this.settings && !!this.settings.shareableModel && !!this.creatorsRolesFilter) {
            this.optionsInterface.filter['model'] = this.settings.shareableModel;
        }

        this.setFiltersByUrl();

        return this.resourcesSubscription = this.lessonsService.loadPaginatedLessons(this.type, this.creatorsRolesFilter, '', this.optionsInterface)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(resources => {
                // sort by created date desc
                if (!resources) {
                    return;
                }
                resources.forEach((resource) => {
                    resource['LocaleDateCreated'] = this.lessonsService.localeDate(resource.attributes.metadatas.created);
                    resource['LocaleDateChanged'] = this.lessonsService.localeDate(resource.attributes.metadatas.changed);
                    resource['GetDate'] = this.lessonsService.getDate(resource);
                });

                const entities = resources;

                this.dataCards = this.datacardService.processResources(entities, this.callbacks, this.type, this.dataCardSettings);

                // TODO directly get sort from webservice
                this.resources = resources.sort((a, b) => 0 - (a['attributes'].metadatas.changed > b['attributes'].metadatas.changed ? 1 : -1));
                this.displayedColumns = ['checkbox', 'type', 'title', 'utilisation', 'favori', 'evaluation', 'actions'];
                this.numberOfActivities = resources.length;
                this.setPaginator();
            });
    }

    /**
     * apply filter in regard of setting filtertoApplyOnLessonsByUrl and of current route
     * filter use taxonomy terme on back and field chapters
     */
    private setFiltersByUrl(): void {
        this.settings.filtertoApplyOnLessonsByUrl.forEach((urlToTest: { url: string, id: number }) => {
            if (this.router.url.includes(urlToTest.url)) {
                this.optionsInterface.filter['chapters'] = urlToTest.id;
            }
        });
    }

    public openMetaDialog(e, metadatas): void {
        e.stopPropagation();
        this.dialogRef = this.dialog.open(LessonMetadataDialogComponent, {
            data: {
                metadatas
            }
        });
    }

    setPaginator(): void {
        switch (this.type) {
            case 'currentUser':
                if (this.lessonsService.userLessonsPaginated.paginator) {
                    this.countEntities = this.lessonsService.userLessonsPaginated.paginator.count;
                    this.pageIndex = this.lessonsService.userLessonsPaginated.paginator.page - 1;
                    this.pageRange = this.lessonsService.userLessonsPaginated.paginator.range;
                }
                break;
            case 'byRole':
                if (this.lessonsService.lessonsPaginated.paginator) {
                    this.countEntities = this.lessonsService.lessonsPaginated.paginator.count;
                    this.pageIndex = this.lessonsService.lessonsPaginated.paginator.page - 1;
                    this.pageRange = this.lessonsService.lessonsPaginated.paginator.range;
                }
                break;
        }
    }

    onPaginateChange(event, type): void {
        switch (type) {
            case 'currentUser':
                this.lessonsService.userLessonsPaginated.paginator.page = event.pageIndex + 1;
                break;
            case 'byRole':
                this.lessonsService.lessonsPaginated.paginator.page = event.pageIndex + 1;
                break;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    setDataSourceAttributes(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    applyFilters(event, type): void {
        if (event.value === 'all' || event.value === '') {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = event.value;
        }
    }

    openAssign(lesson): void {
        if (this.activitiesService.assignmentView) {
            this.dialogRef = this.dialog.open(this.activitiesService.assignmentView, {
                data: {
                    nodeId: lesson.id
                }
            });
        }

        this.dialogRef.afterClosed().subscribe((data) => {
            if (data && lesson.get('locked') !== '1') {
                const entity = new DataEntity('granule-lesson', lesson.attributes, this.octopusConnect, lesson.id);
                entity.set('locked', true);
                entity.save()
                    .subscribe(success => {
                        if (success) {
                            this.lessonsService.userLessonsPaginated.paginator.reload();
                        }
                    });
            }
        });
    }

    updateCheck(): void {
        this.isChecked = !this.isChecked;
    }

    updateCheckBookmarks(event): any {
        this.optionsInterface.filter['bookmarks'] = event.checked;
        this.launchSearch(this.optionsInterface);
    }

    private get settings(): any {
        return this.activitiesService.settings;
    }

    public get showSpinner(): boolean {
        return this.lessonsService.showSpinner;
    }

    /**
     * focus on title filter after select a skill
     */
    public blurElementRef(): void {
        this.titleInput.focus();
    }

    /**
     * get the list of skills for the list in filter field
     */
    private getSkills(): void {
        this.lessonsService.getSkills().takeUntil(this.unsubscribeInTakeUntil)
            .take(1)
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.skillsList = data.entities;
                }
            });
    }

    /**
     * get all themes
     */
    private getThemes(): void {
        this.lessonsService.getThemes().takeUntil(this.unsubscribeInTakeUntil)
            .take(1)
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.themeList = data.entities;
                }
            });
    }

    /**
     * check if we show add button
     * @returns {boolean}
     */
    public get showAddLessonButton(): boolean {
        if ((this.type !== 'byRole') &&
            this.router.url.includes('community')) {
            return !this.settings.hideAddButtonLessonForCommunity;
        }
        return (this.type !== 'byRole') ||
            this.type === 'byRole'
            && this.lessonsService.getAllowedRolesForModelsCreation().includes(this.authService.accessLevel)
            && !this.settings.hideAddButtonLessonForModel;
    }
}
