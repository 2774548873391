import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FuseConfigService} from 'fuse-core/services/config.service';
import {TralaTranslationLoaderService} from '../../../../trala-translation-loader.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {ReCaptchaComponent} from 'angular5-recaptcha';
import {RequestSubscribeFormProfile} from '@modules/account-management/core/form-profil.class';
import {fuseAnimations} from 'fuse-core/animations';
import {brand, langs, brandLogoSvg} from '../../../../settings';

@Component({
  selector: 'app-request-subscribe',
  templateUrl: './request-subscribe.component.html',
  styleUrls: ['./request-subscribe.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations : fuseAnimations
})
export class FuseRequestSubscribeComponent implements OnInit {
  @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;

  captchaResp: boolean = false;

  requestSubscribeForm: FormGroup;
  requestSubscribeFormErrors: any;
  termsNotAccepted: boolean;

  userInformation: RequestSubscribeFormProfile = new RequestSubscribeFormProfile({});

  public brand = brand;
  public brandLogoSvg = brandLogoSvg;

  public formData: any = {
      title: 'account-management.subscribe_request_confirm_title',
      content: ['account-management.subscribe_request_confirm_first_sentence'],
      redirection: 'generic.back_to_authentication',
      redirectionLink: '/login'
  };

  public requestValidate: boolean;

  constructor(
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    public accountProvider: AccountManagementProviderService,
    private translationLoader: TralaTranslationLoaderService
  )
  {
    const defaultLang: string[] = langs.map((lang) => lang.id);
    this.translationLoader.loadTranslations(...defaultLang);


    this.requestSubscribeFormErrors = {
      name        : {},
      last_name        : {},
      you_are        : {},
      email          : {},
      terms: {},
      city: {},
      academy: {}
    };
  }


  ngOnInit()
  {
    this.requestValidate = false;

    this.requestSubscribeForm = this.formBuilder.group({
      name          : ['', Validators.required],
      last_name          : ['', Validators.required],
      city          : ['', Validators.required],
      email          : ['', [Validators.required, Validators.email]],
      you_are          : ['', Validators.required],
      terms: [false, Validators.requiredTrue],
      academy          : ['', Validators.required],
    });

    this.requestSubscribeForm.valueChanges.subscribe(() => {
      this.requestSubscribeFormValuesChanged();
    });


  }

  requestSubscribeFormValuesChanged()
  {
    for ( const field in this.requestSubscribeFormErrors )
    {
      if ( !this.requestSubscribeFormErrors.hasOwnProperty(field))
      {
        console.log(this.captcha.getResponse());
        continue;
      }

      // Clear previous errors
      this.requestSubscribeFormErrors[field] = {};

      // Get the control
      const control = this.requestSubscribeForm.get(field);

      if (!this.requestSubscribeForm.get('terms').errors){
        this.termsNotAccepted = false;
      }

      if ( control && control.dirty && !control.valid )
      {

        this.requestSubscribeFormErrors[field] = control.errors;
      }
    }

    if (!this.validateField( this.requestSubscribeForm.value.pseudo )){
      const controlPseudo = this.requestSubscribeForm.get('pseudo');
      controlPseudo.setErrors({invalid: true});
    }

  }

  handleCorrectCaptcha(event){
    this.captchaResp = true;
  }

  newRegister() {

    if (this.requestSubscribeForm.get('terms').errors) {
      this.termsNotAccepted = true;

    }

    if ( this.captchaResp && !this.requestSubscribeForm.invalid){
      let message;
      message = {
        name: this.requestSubscribeForm.value.name,
        email: this.requestSubscribeForm.value.email,
        last_name: this.requestSubscribeForm.value.last_name,
        you_are: this.requestSubscribeForm.value.you_are,
        academy: this.requestSubscribeForm.value.academy,
        city: this.requestSubscribeForm.value.city
      };

      this.accountProvider.sendMessage(message, this.getFormConfirmation());
    }


    if (this.captchaResp && !this.requestSubscribeForm.invalid) {
    }
  }

  validateField(field){

    let re;
    re = /^[A-Za-z0-9-'_.]+$/;

    return re.test(String(field).toLowerCase());
  }

  getFormConfirmation(){
    this.requestValidate =  true;
  }

}
