import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';

export enum LayoutAlign {
    'space-evenly',
    'center',
    'start'
}

export enum ArrowPosition {
    top,
    left,
    right,
    bottom,
}

export enum StepItemType {
    media,
    activity,
    divider
}

export interface StepItem {
    type: StepItemType;
    isActive: boolean;
    isArrowActive: boolean;
    isCurrent: boolean;
    encapsulatedObject: any;
}

export interface StepperOptions {
    steps: StepItem[];
    onStepItemClick?: Subject<StepItem>;
    stepperLayout?: {
        align?: LayoutAlign;
        arrowPosition?: ArrowPosition;
    };
    recap?: {
        isActive?: boolean;
        isAllowed?: boolean;
        isArrowActive?: boolean;
    };
}

@Component({
    selector: 'app-generic-stepper',
    templateUrl: './generic-stepper.component.html'
})
export class GenericStepperComponent implements OnInit {

    static defaultOptions: StepperOptions = {
        steps : [],
        stepperLayout: {
            align : LayoutAlign.start,
            arrowPosition: ArrowPosition.right,
        },
        recap: {
            isActive: false,
            isAllowed: false,
            isArrowActive: false
        }
    };

    private _options: StepperOptions;

    @Input() set options(options: StepperOptions) {
        this._options =  Object.assign(GenericStepperComponent.defaultOptions, options);
    }

    get options(): StepperOptions {
        return this._options;
    }

    public get layoutAlign(): string {
        return LayoutAlign[this.options.stepperLayout.align];
    }

    public get arrowPositionClass(): string {
        return ArrowPosition[this.options.stepperLayout.arrowPosition];
    }

    public get onlyActivitiesSteps(): StepItem[] {
        return this.options.steps.filter(s => s.type === StepItemType.activity);
    }

    public get layout (): string {
        return this.options.stepperLayout.arrowPosition === ArrowPosition.top || this.options.stepperLayout.arrowPosition === ArrowPosition.bottom ? 'row' : 'column';
    }

    public clickOnElem(step: StepItem): void {
        if (this.options.onStepItemClick && (( step && !step.isArrowActive) || !step )) {
            this.options.onStepItemClick.next(step ? step.encapsulatedObject : null);
        }
    }

    constructor() { }

    ngOnInit(): void {
    }

    public getLabelToDisplay(step: StepItem): string {
        switch (step.type) {
            case StepItemType.media : {
                return 'M';
            }
            case StepItemType.divider : {
                return 'i';
            }
            default: { // Attention, on ne gère pas le type "recap" car il est en dur coté html
                return (this.onlyActivitiesSteps.indexOf(step) + 1).toString();
            }
        }
    }
}
