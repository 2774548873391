import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/index';
import {combineLatest} from 'rxjs/observable/combineLatest';


@Component({
    selector: 'app-summary-activity',
    templateUrl: './summary-activity.component.html',
    styleUrls: ['./summary-activity.component.scss']
})
export class SummaryActivityComponent implements OnInit, OnDestroy {

    @Input() public activityId: any;
    @Input() public contextId: string;
    @Input() questionTypeName: string;

    public questionObject: any;
    public instruction: string;
    public wording: string;
    public activity: DataEntity;
    public activitiesReferenced: any[] = [];
    public userSaves: any[] = [];
    public isSaveLoading: boolean;
    public isSaving: boolean;
    public config: object = {};
    public isReady: boolean;

    private unsubscribeInTakeUntil = new Subject();
    private markers: any[] = [];
    private mode: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private lessonsService: LessonsService,
        private octopusConnect: OctopusConnectService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): any {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    ngOnDestroy(): any {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * Initializes the current component
     */
    private initialize(): void {
        this.reset();

        this.activityService.launchActivity((this.activityId))
            .take(1)
            .subscribe(data => {
                this.questionObject = this.activityService.getPropertyFromNestedObject(data, ['reference']);
                this.instruction = this.questionObject.instruction;
                this.wording = this.questionObject.wording;
                this.activity = new DataEntity('granule', data, this.octopusConnect, this.activityId.id);

                this.activitiesReferenced = [].concat(this.activity.get('reference') && typeof this.activity.get('reference') === 'object' ?
                    this.activity.get('reference').activity_content[0].granule : []);

                if (this.activity.get('reference').config && this.activity.get('reference').config.poll) {
                    this.mode = 'poll';
                }
                if (this.activity.get('reference').config && this.activity.get('reference').config.markers) {
                    this.markers = this.activitiesReferenced[0].reference.activity_content[0].marker;
                    this.config['callback'] = (selectedMarker, val) => this.saveUserNotes(selectedMarker, val);
                    this.mode = 'marker';
                }

                if (this.activity.get('reference').config && this.activity.get('reference').config.qcmu) {
                    this.mode = 'qcmu';
                }

                this.loadUserSave();
            });
    }

    private loadUserSave(): void {
        this.isSaveLoading = true;
        let obsUserSaveLoaded;
        if (this.lessonsService.isLessonTest()) {
           // const steps = [];

            if (this.mode === 'poll') {

                const steps = this.stepSettings.map((val, key) => {
                    if (val.type === 'LESSON') {
                        return key;
                    }
                }).filter((step) => step === 0 || !!step);

                obsUserSaveLoaded = [].concat(...this.activitiesReferenced.map((activity) => {
                    return steps.map((step) => this.activityService.getUserSave(activity.id.toString(), this.contextId, true,  step));
                }));
            }

            if (this.mode === 'marker') {
                const step = this.lessonsService.currentLesson
                    .get('reference')
                    .findIndex((activity) => +activity.id === +this.activitiesReferenced[0].id);

                obsUserSaveLoaded = [this.activityService.getUserSave(this.activitiesReferenced[0].id.toString(), this.contextId, true,  step)];
            }

            if (this.mode === 'qcmu') {
                obsUserSaveLoaded = this.activitiesReferenced
                    .map((activity) => {
                        return this.activityService.getUserSave(activity.id.toString(), this.contextId, true,  null);
                    });
            }

        } else {
            obsUserSaveLoaded = this.activitiesReferenced.map((activity) => {
                return this.activityService.getUserSave(activity.id.toString(), this.contextId, true);
            });
        }

        combineLatest(...obsUserSaveLoaded)
            .subscribe((userSavesEntities: any[]) => {

                let entities = userSavesEntities;

                if (this.lessonsService.isLessonTest()) {
                    if (this.mode === 'poll' || this.mode === 'qcmu') {
                        entities = this.activitiesReferenced
                            .map((activity) => {
                                return userSavesEntities.filter((userSave) => {
                                    return userSave && +userSave.get('granule') === +activity.id;
                                });
                            });
                    }

                    if (this.mode === 'marker') {
                        entities = [userSavesEntities];
                    }
                }

                this.isSaveLoading = false;
                const steps = [];
                if (this.stepSettings) {
                    this.stepSettings.forEach((val, key) => {
                        if (this.mode === 'poll' && val.type === 'LESSON') {
                            steps.push(key);
                        }
                    });
                }


                const isAllUserSaveValidated = entities.every((entity) => {

                    if (this.mode === 'poll') {
                        return entity && entity.length === steps.length;
                    }
                    if (this.mode === 'marker') {
                        return entity && entity.length > 0;
                    }
                    // keep this 'ugly' condition for easy understanding.
                    if (this.mode === 'qcmu' && !this.stepSettings) {
                        return true;
                    }
                    return false;
                });
                if (isAllUserSaveValidated) {
                    this.userSaves = [].concat(...entities);
                    this.config['userSaves'] = this.userSaves;
                    this.isReady = true;
                } else {
                    if (!this.isSaving && !this.isSaveLoading && this.userSaves.length  === 0) {
                        const userSavesExisted = entities.filter((userSave) => userSave.length > 0);
                        const obsDefaultUserSaves = entities
                            .map((userSave, index) => {
                                let answers;
                                if (this.mode === 'marker') {
                                    answers = this.markers.map((marker) => {
                                        return ' ';
                                    });
                                }
                                if (this.mode === 'poll') {
                                    answers = 0;
                                }

                                const id = this.activitiesReferenced[index].id;

                                if (this.mode === 'marker') {
                                    const step = this.lessonsService.currentLesson
                                        .get('reference')
                                        .findIndex((activity) => +activity.id === +this.activitiesReferenced[0].id);
                                  return [this.saveAnswer(answers, id, null,  step)];
                                }

                                const stepsMissing = steps.filter((step) => {
                                    return !userSave.find((save) => save.get('step') && +save.get('step') === +step);
                                });
                                return stepsMissing.map((step) => {
                                    return this.saveAnswer(answers, id, null, step);
                                });
                        });

                        this.isSaving = false;
                        combineLatest(...[].concat(...obsDefaultUserSaves))
                            .subscribe((userSaves) => {
                                this.isSaving = false;
                                this.userSaves = [].concat(...userSavesExisted, ...userSaves);
                                this.config['userSaves'] = this.userSaves;
                                this.isReady = true;
                            });
                    }
                }
            });
    }

    public saveAnswer(answers, id, userSave, step = null): Observable<DataEntity> {
        this.isSaving = true;
        return this.activityService.saveUserSave(id.toString(), this.contextId, answers, null, 'qcm-save', userSave, step).take(1);
    }

    public saveUserNotes(selectedMarker, data): Observable<DataEntity> {
        const index = this.markers.findIndex((marker) => marker.id === selectedMarker.id);
        const answers = this.userSaves && this.userSaves[0] && this.userSaves[0].get('userActivity').entitySave.answers.length > 0 ? this.userSaves[0].get('userActivity').entitySave.answers : this.markers.map((marker) => {
            return ' ';
        });

        let answersEntity;
        let obs;

        if (answers && answers[index] && !answers[index].id) {
            answers[index] = data['survey-notes'];
            obs = this.saveAnswerMarkers(answers).take(1);
        } else {
            answersEntity = answers.map((answer, key) => {
                const entity = new  DataEntity(
                    'answer',
                    answer,
                    this.octopusConnect,
                    answer.id
                );
                if (+key === +index) {
                    entity.set('answer',  data['survey-notes']);
                }
                return entity;
            });

            obs = this.saveAnswerMarkers(answersEntity, true).take(1);
        }

        return obs;
    }


    public saveAnswerMarkers(answers, isEntities = null): Observable<DataEntity> {
        const obsAnswer = answers.flatMap((answer) => {
            if (isEntities) {
                if (this.lessonsService.isLessonTest()) {
                    return this.activityService.saveAnswer({answer: answer.get('answer')}, 'answer');
                } else {
                    return answer.save();
                }
            } else {
                return this.activityService.saveAnswer({answer: answer}, 'answer');
            }
        });
        this.isSaving = true;
        return combineLatest(...obsAnswer)
            .take(1)
            .flatMap((entities: DataEntity[]) => {
                const obs = this.activityService
                    .saveUserSave(this.activitiesReferenced[0].id.toString(),
                        this.activityService.currentAssignment ? this.activityService.currentAssignment.id.toString() : null,
                        entities ? entities.map((entity) => entity.id.toString()) : [],
                        null,
                        'qcm-save',
                        this.userSaves[0],
                        this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.activitiesReferenced[0].id));
           obs.subscribe((data) => {
               this.userSaves[0] = data;
               this.isSaving = false;
           });
            return obs;
            });
    }

    /**
     * Resets the current component
     */
    private reset(): void {
        delete this.questionObject;
        delete this.instruction;
        delete this.wording;
    }

    private get stepSettings(): any {
        if (this.activityService.settings && this.activityService.settings.lessonStep) {
            return this.activityService.settings.lessonStep.typeSteps;
        }

        return null;
    }
}
