import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector: 'fuse-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: fuseAnimations
})
export class FuseProfileComponent implements OnInit {
    public navLinks: {path: string, keyTranslate: string}[];
    public settingsLicensing: {[key: string]: any};

    private subscribeSettings: Subscription;

    constructor(communicationCenter: CommunicationCenterService, private authenticationService: AuthenticationService) {
        this.subscribeSettings = communicationCenter.getRoom('licenses').getSubject('settings').subscribe((data) => {
            this.settingsLicensing = data;
        });
    }

    ngOnInit() {
        this.navLinks = [
            {path: 'show-profile', keyTranslate: 'account-management.my_data'},
        ];
        if (this.settingsLicensing['visible'] && !this.authenticationService.isManager())
            this.navLinks.push({path: 'licensing', keyTranslate: 'account-management.profile.licensing.title'});
    }

    ngOnDestroy() {
        this.subscribeSettings.unsubscribe();
    }
}
