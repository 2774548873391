import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {AuthenticationService} from '../../../../../authentication';
import {localizedDate, localizedTime} from '../../../../../../shared/utils';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from 'fuse-core/animations';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {TranslateService} from '@ngx-translate/core';
import {AssignationService} from '@modules/assignation/core/assignation.service';


@Component({
    selector: 'app-widget-assignments-lesson',
    templateUrl: './widget-assignments-lesson.component.html',
    styleUrls: ['./widget-assignments-lesson.component.scss'],
    animations: fuseAnimations
})
export class WidgetAssignmentsLessonComponent implements OnInit, OnDestroy {

    public displayedColumns: string[] = ['type', 'title', 'start_date', 'end_date', 'group', 'workgroup', 'progress'];
    private unsubscribeInTakeUntil = new Subject();

    entities: any;
    user: any;
    resource: boolean;
    assignments: Array<any> = [];

    constructor(
        public authService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private assignationService: AssignationService,
    ) {
    }

    ngOnInit(): any {

    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public getProgress(entity): number | string {
        if (entity) {
            return Math.round(+entity.attributes.progress * 10) / 10;
        }
        return 0;
    }

    openAssignment(entity): void {
        if (this.assignationService.launchAssignment(entity)) {
            this.router.navigate(['../', 'followed', 'assignment', 'lessons', entity.get('assignated_node').id, 'player'], {relativeTo: this.route});
        }
    }

    localeDate(date: number): string {
        if (+date) {
            return localizedDate(date);
        }
    }

    localeTime(date: number): string {
        if (+date) {
            return localizedTime(date);
        }
    }

    public get align(): string {
        if (this.resource) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        if (this.assignationService.dataSource.data.length > 0) {
            return true;
        } else {
            return false;
        }
    }
}
