import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {IPositionAndStyle} from '@modules/timeline/core/models/position-and-style.models';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

    @Input('value') currentValue: number = 0;
    @Input('max') maxValue: number = 100;
    @Input('min') minValue: number = 0;
    @Input('separators') positionSeparators: number[] = [];
    @Input('dots') dots: number[] = [];
    @Input('zoneColor') zoneColor: IPositionAndStyle [] = [{position: 0, color: 'rgb(218, 77, 31)', width: 100}];

    @Output() moveFrise = new EventEmitter<number>();
    @Output() clickFrise = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * emit to the parent the position when moving cursor
     * @param evt : position du curseur sur la frise.
     */
    public sliderMove(evt: any): void {
        this.moveFrise.emit(+evt.currentTarget.value);
    }

    /**
     * emit to the parent the position after click
     * @param evt : position du curseur sur la frise.
     */
    public sliderClick(evt: any): void {
        this.clickFrise.emit(+evt.currentTarget.value);
    }

    /**
     * return the position for popup whith current value => directive to do
     */
    public positionTooltip(): string {
        const pourcent = 97.8 * ((Math.abs(this.minValue) + this.currentValue) / (Math.abs(this.minValue) + Math.abs(this.maxValue)));
        return pourcent + '%';
    }
}