import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import {RouterModule, Routes} from '@angular/router';
import {IsUserLogged} from '../../../is-user-logged.class';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {NotifListComponent} from '@modules/notification/core/notif-list/notif-list.component';
import { FuseSharedModule } from '@fuse/shared.module';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {MatPaginatorIntl} from '@angular/material';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {SharedModule} from '../../../shared/shared.module';
import {CommunicationCenterService} from '../../communication-center';
import {AuthenticationService} from '../../authentication';
import {DataEntity} from 'octopus-connect';

const routes: Routes = [
    {
        path     : 'notifications',
        canActivate: [IsUserLogged],
        component: NotificationComponent,
        children : []
    }
];


@NgModule({
    declarations: [
        NotificationComponent,
        NotifListComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule
    ],
    providers: [

    ]
})
export class NotificationModule {

    private static isMenuSet = false;

    constructor(
        private notificationsService: NotificationsService,
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        notificationsService.registerNotification({
            name: 'Projet',
            type: 'ACTION_ON_PROJECT',
            icon: 'business',
            text: 'Action à faire sur le projet {{ projectId }}',
            textTransform: (text: string, datas: Object) => {
                return {
                    projectId: datas['projectId']
                };
            },
            action: (data: Object) => {
                return ['projects', data['projectId']];
            }
        });

        notificationsService.registerNotification({
            name: 'Home',
            type: 'DASHBOARD',
            icon: 'dashboard',
            text: 'Truc à faire sur le dashboard',
            action: 'dashboard'
        });

        notificationsService.registerNotification({
            name: 'Corpus',
            type: 'FILEMANAGER_NEW_FILES',
            icon: 'folder_shared',
            text: 'Nouveaux fichiers disponibles',
            action: 'file-manager'
        });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: NotificationModule,
            providers: [
                NotificationsService,
                {provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl}
            ]
        };
    }

    private postLogout(): void {
        NotificationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'notifications');
    }

    private postAuthentication(): void {
        if (!NotificationModule.isMenuSet &&
            this.authService.hasLevel(['trainer', 'manager', 'administrator']) &&
            this.notificationsService.settings && this.notificationsService.settings.show) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id'   : 'notifications',
                'title': 'Notifications',
                'translate': 'navigation.notifications',
                'type' : 'item',
                'icon' : 'notifications',
                'url'  : '/notifications',
            });

            NotificationModule.isMenuSet = true;
        }
    }
}
