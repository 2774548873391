import {Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {GroupsListingService} from '../groups-listing.service';
import {FuseGroupsFormDialogComponent} from '../groups-form/groups-form.component';
import {MatAutocompleteSelectedEvent, MatDialog, MatDialogRef} from '@angular/material';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {DataSource} from '@angular/cdk/collections';
import {fuseAnimations} from 'fuse-core/animations';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import {Subject} from 'rxjs/index';
import {JoinGroupComponent} from '@modules/groups-management/core/join-group/join-group.component';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Group, Workgroup} from '@modules/groups-management/core/definitions';
import {map, startWith} from 'rxjs/operators';
import {GroupsCustomActionComponent} from '../groups-custom-action/groups-custom-action.component';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {UsersImportComponent} from '@modules/groups-management/core/users-import/users-import.component';
import {ngxCsv} from 'ngx-csv/ngx-csv';
import {GroupService} from '@modules/groups-management/core/group/group.service';

@Component({
    selector: 'fuse-groups-list',
    templateUrl: './groups-list.component.html',
    styleUrls: ['./groups-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseGroupsListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent') dialogContent: TemplateRef<any>;
    @ViewChild('groupsChipInput') groupsChipInput: ElementRef;
    @ViewChild('workgroupsChipInput') workgroupsChipInput: ElementRef;

    @Input('displayedColumns') displayedColumns: string[];
    @Input('dataSource') dataSource: DataSource<any>;
    @Input('editEntity') editEntitySettings: { data: any, callback: (any) => {} };
    @Input('archiveEntity') archiveEntitySettings: { data: any, callback: (any) => {}, archiveList: (any) => {} };
    @Input('deArchiveEntity') dearchiveEntitySettings: { data: any, callback: (any) => {}, dearchiveList: (any) => {} };
    @Input('activateMetacognitionEntity') activateMetacognitionEntitySettings: { data: any, callback: (any) => {}, activateMetacognitionList: (any) => {} };
    @Input('deactivateMetacognitionEntity') deactivateMetacognitionEntitySettings: { data: any, callback: (any) => {}, deactivateMetacognitionList: (any) => {} };
    @Input('deleteEntity') deleteEntitySettings: { data: any, callback: (any) => {}, deleteList: (any) => {} };
    @Input('customActions') customActions: { data: object };

    entities: any;
    user: any;
    checkboxes: {};

    public defaultArchive = false;
    public displayFilters = true;

    dialogRef: any;

    Object = Object;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    joinGroupDialogRef: MatDialogRef<JoinGroupComponent>;

    selectAll: any;

    private unsubscribeInTakeUntil = new Subject();

    type = '';

    archived: boolean;
    metacognitionActive: boolean;

    groupsChips: string[] = [];
    groupsCtrl = new FormControl();
    groupsAddOnBlur = false;
    groupsSeparatorKeysCodes = [ENTER, COMMA];
    groupsSelectable = true;
    groupsRemovable = true;
    groupsFilteredChips: Observable<any[]>;
    groupsAllChips = [];

    workgroupsChips = []; // array of workgroup Chips selected
    workgroupsCtrl = new FormControl();
    workgroupsAddOnBlur = false;
    workgroupsSeparatorKeysCodes = [ENTER, COMMA];
    workgroupsSelectable = true;
    workgroupsRemovable = true;
    workgroupsFilteredChips: Observable<any[]>;

    workgroupsAllChips = [];

    learnersList = [];

    renderedData = {};

    deleteLearner: boolean = false;
    deleteLearners: boolean = false;

    constructor(
        private groupsService: GroupsListingService,
        private groupsManagementService: GroupsManagementService,
        public authService: AuthenticationService,
        public dialog: MatDialog,
        private translate: TranslateService,
        public communicationCenter: CommunicationCenterService,
        private groupService: GroupService
    ) {
        if (this.groupsManagementService.settings.canBeDelete.learner) {
            // delete learner one by one is allowed
            this.deleteLearner = true;
        }

        if (this.groupsManagementService.settings.canBeDelete.learners) {
            // delete more than one learners by select is allowed
            this.deleteLearners = true;
        }
    }

    ngOnInit(): void {

        this.displayFilters = this.groupsManagementService.settings.displayFilters;

        if (this.dataSource['groupsService']) {
            this.type = 'class';
            this.defaultArchive = this.dataSource['groupsService'].archiveMode ? this.dataSource['groupsService'].archiveMode : false;
            this.dataSource['groupsService'].onGroupsChanged
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                });
        }
        if (this.dataSource['workGroupService']) {
            this.type = 'group';
            this.defaultArchive = this.dataSource['workGroupService'].archiveMode ? this.dataSource['workGroupService'].archiveMode : false;
            this.dataSource['workGroupService'].onWorkgroupsChanged
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                });
        }

        if (this.dataSource['learnerService']) {
            this.type = 'learner';
            this.defaultArchive = this.dataSource['learnerService'].archiveMode ? this.dataSource['learnerService'].archiveMode : false;
            this.dataSource['learnerService'].onLearnersChanged
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);

                    this.groupsAllChips = this.groups;
                    this.workgroupsAllChips = this.workgroups;
                    this.learnersList = this.learners;

                    this.groupsFilteredChips = this.groupsCtrl.valueChanges.pipe(
                        startWith(null),
                        map((chip: string | null) => chip ? this.filter(chip, 'groups') : this.groupsAllChips.slice()));

                    this.workgroupsFilteredChips = this.workgroupsCtrl.valueChanges.pipe(
                        startWith(null),
                        map((chip: string | null) => chip ? this.filter(chip, 'workgroups') : this.workgroupsAllChips.slice()));

                });
        }

        if (this.dataSource['trainerService']) {
            this.type = 'trainer';
            this.defaultArchive = this.dataSource['trainerService'].archiveMode ? this.dataSource['trainerService'].archiveMode : false;
            this.dataSource['trainerService'].onTrainersChanged
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                });
        }

        if (this.dataSource['institutionGroupService']) {
            this.type = 'institution';
            this.defaultArchive = this.dataSource['institutionGroupService'].archiveMode ? this.dataSource['institutionGroupService'].archiveMode : false;

            this.dataSource['institutionGroupService'].getUserInstitutions();
            this.dataSource['institutionGroupService']
                .onInstitutionGroupsChanged
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((entities: DataEntity[]) => {
                    this.reset(entities);
                });
        }

        if (!((this.deleteEntitySettings && this.deleteLearners) || this.dearchiveEntitySettings)) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('checkbox'), 1);
        }
    }

    ngOnDestroy(): any {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    reset(entities): void {
        this.entities = entities;
        this.checkboxes = {};
        if (entities) {
            for (const entity of entities) {
                this.checkboxes[entity.id] = false;
            }
            this.selectAll = false;
        }
    }

    public isSelectionActionIsEnabled(): boolean {
        return Object.keys(this.checkboxes).some(k => this.checkboxes[k] === true);
    }

    public getCustomActionsKeyToShow(entity) {
        const keys = [];
        for (const key in this.customActions) {
            const rules = this.customActions[key].data.rules || {};
            if (!rules['needSso'] || rules['needSso'] === entity.sso) {
                keys.push(key);
            }
        }
        return keys;
    }

    updateCheck(event): void {
        this.selectAll = event.checked;
        for (const entity of this.entities) {
            this.checkboxes[entity.id] = event.checked;
        }
    }

    applyFilters(event): void {
        this.archived = event.value;
        this.metacognitionActive = event.value;
        this.dataSource['groupService'].filterArchived(event.value);
    }

    filterSelectedEntities(): string[] | number[] {
        const selectedEntities = [];
        for (const id in this.checkboxes) {
            if (this.checkboxes[id]) {
                selectedEntities.push(...this.entities.filter((entity) => entity.id.toString() === id));
                this.checkboxes[id] = false;
            }
        }

        return selectedEntities;
    }

    archiveListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();
        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.archive',
                bodyDialog: 'groups-management.sure_archive_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_archive_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.archiveEntitySettings.archiveList(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });

        }
    }

    dearchiveListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();

        if (selectedEntities.length) {

            const data = {
                titleDialog: 'generic.unarchive',
                bodyDialog: 'groups-management.sure_dearchive_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_dearchive_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.dearchiveEntitySettings.dearchiveList(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    activateMetacognitionListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();
        if (selectedEntities.length) {
            const data = {
                titleDialog: 'generic.metacognition',
                bodyDialog: 'groups-management.sure_activate_metacognition_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_activate_metacognition_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.activateMetacognitionEntitySettings.activateMetacognitionList(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });

        }
    }

    deactivateMetacognitionListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();

        if (selectedEntities.length) {

            const data = {
                titleDialog: 'generic.metacognition',
                bodyDialog: 'groups-management.sure_deactivate_metacognition_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_deactivate_metacognition_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.deactivateMetacognitionEntitySettings.deactivateMetacognitionList(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    deleteListEntity(): void {
        const selectedEntities = this.filterSelectedEntities();

        if (selectedEntities.length) {

            const data = {
                titleDialog: 'generic.delete',
                bodyDialog: 'groups-management.sure_remove_' + [this.type] + '_list',
                labelTrueDialog: 'generic.yes',
                labelFalseDialog: 'generic.no',
            };

            if (selectedEntities.length === 1) {
                data.bodyDialog = 'groups-management.sure_remove_' + [this.type];
            }

            for (const field in data) {
                this.translate
                    .get(data[field], {length: selectedEntities.length.toString()})
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.launchFilter();

                    this.deleteEntitySettings.deleteList(selectedEntities);
                }
                this.selectAll = false;
                this.confirmDialogRef = null;
            });
        }
    }

    editEntity(entity): void {
        if (this.authService.hasLevel(['trainer', 'manager', 'administrator'])) {
            this.editEntitySettings.data.item = entity;
            this.dialogRef = this.dialog.open(FuseGroupsFormDialogComponent, {
                panelClass: 'entity-form-dialog',
                data: this.editEntitySettings
            });

            this.dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.launchFilter();

                    this.editEntitySettings.callback(result);
                }
            });
        }

    }

    archiveEntity(entity): void {

        for (const field in this.archiveEntitySettings.data) {
            this.translate
                .get(this.archiveEntitySettings.data[field])
                .subscribe((translation: string) => this.archiveEntitySettings.data[field] = translation);
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.archiveEntitySettings.data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.archiveEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    deArchiveEntity(entity): void {

        for (const field in this.dearchiveEntitySettings.data) {
            this.translate
                .get(this.dearchiveEntitySettings.data[field])
                .subscribe((translation: string) => this.dearchiveEntitySettings.data[field] = translation);
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.dearchiveEntitySettings.data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dearchiveEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    activateMetacognitionEntity(entity): void {

        for (const field in this.activateMetacognitionEntitySettings.data) {
            this.translate
                .get(this.activateMetacognitionEntitySettings.data[field])
                .subscribe((translation: string) => this.activateMetacognitionEntitySettings.data[field] = translation);
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.activateMetacognitionEntitySettings.data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.activateMetacognitionEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    deactivateMetacognitionEntity(entity): void {

        for (const field in this.deactivateMetacognitionEntitySettings.data) {
            this.translate
                .get(this.deactivateMetacognitionEntitySettings.data[field])
                .subscribe((translation: string) => this.deactivateMetacognitionEntitySettings.data[field] = translation);
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.deactivateMetacognitionEntitySettings.data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deactivateMetacognitionEntitySettings.callback(entity);
            }
            this.confirmDialogRef = null;
        });
    }

    execCustomActions(action, entity): void {
        action.data.item = entity;
        this.dialogRef = this.dialog.open(GroupsCustomActionComponent, {
            panelClass: action.data.styleClasses,
            data: action.data
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.launchFilter();

                action.data.callback(result);
            }
        });
    }

    deleteEntity(entity): void {
        if (!entity.locked || (entity.locked && this.groupsManagementService.settings.canBeDelete.institution)) {
        for (const field in this.deleteEntitySettings.data) {
            this.translate
                .get(this.deleteEntitySettings.data[field])
                .subscribe((translation: string) => this.deleteEntitySettings.data[field] = translation);
        }

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: this.deleteEntitySettings.data
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.launchFilter();
                this.deleteEntitySettings.callback(entity);
                // Force group listing reload
                this.groupService.loadGroups();
            }
            this.confirmDialogRef = null;
        });

        } else {
            const data = {
                titleDialog: 'groups-management.title_remove',
                bodyDialog: 'groups-management.locked',
                labelTrueDialog: 'OK'
            };
            for (const field in data) {
                this.translate
                    .get(data[field])
                    .subscribe((translation: string) => data[field] = translation);
            }

            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                data: data
            });
        }
    }

    joinGroup(): void {
        this.joinGroupDialogRef = this.dialog.open(JoinGroupComponent, {
            data: {
                loadClass: (event) => this.dataSource['groupsService'].getClass(event),
                classExist: (event) => this.dataSource['groupsService'].classExist(event),
                joinClass: (event) => this.dataSource['groupsService'].joinGroup(event),
                removeClassFromList: (event = null) => this.dataSource['groupsService'].removeClassFromList(event),
            }
        });

        this.joinGroupDialogRef.afterClosed().subscribe(result => {
            this.dataSource['groupsService'].removeClassFromList(result);
            this.joinGroupDialogRef = null;
        });
    }

    exportList(): void {
        const list = [];
        const headers = ['groups-management.csv.login', 'groups-management.csv.groups', 'groups-management.csv.workgroups'];
        this.dataSource['learnerService'].onLearnersChanged.value.forEach(function (v) {
            list.push({username: v.username, groups: v.groups.join(', '), workgroups: v.workgroups.join(', ')});
        });

        for (const field in headers) {
            this.translate
                .get(headers[field])
                .subscribe((translation: string) => headers[field] = translation);
        }

        this.translate
            .get('groups-management.csv.filename')
            .subscribe((translation: string) => {
                const csv = new ngxCsv(list, translation, {showLabels: true, headers: headers, fieldSeparator: ';'});
            });

    }

    importLearners(): void {
        const importDialogRef = this.dialog.open(UsersImportComponent);

        importDialogRef.beforeClose().subscribe(() => {
            this.dataSource['learnerService'].loadLearners();
        });
    }

    getTranslate(val): string {
        if (val && val !== '') {
            if (val === 'trainer') {
                return 'groups-management.trainer';
            }

            if (val === 'mentor') {
                return 'groups-management.mentor';
            }
        }

        return 'generic.to-complete';
    }

    public get canBeJoined(): boolean {
        return !!this.groupsManagementService.settings.canBeJoined.group;
    }

    public get canActiveMetacognition(): boolean {
        if (this.groupsManagementService.settings.canActiveMetacognition !== undefined){
            return !!this.groupsManagementService.settings.canActiveMetacognition.group;
        } else{
            return false;
        }
    }

    launchFilter(): void {
        if (this.dataSource['learnerService']) {
            if (this.groupsCtrl.value && this.groupsCtrl.value !== '') {
                this.filteredLearner(this.groupsCtrl.value, 'groups');
            }
            if (this.workgroupsCtrl.value && this.workgroupsCtrl.value !== '') {
                this.filteredLearner(this.groupsCtrl.value, 'workgroups');
            }
        }
    }

    filteredLearner(value, type): void {

        this.learnersList = [];
        if (this.groupsCtrl.value && type === 'workgroups') {
            this.learnersList.push(...this.learners.filter((learner) => learner['groups'].indexOf(this.groupsCtrl.value) !== -1));
        }

        this.learnersList.push(...this.learners.filter((learner) => learner[type].indexOf(value) !== -1));

        this.dataSource['learnerService'].onLearnersChanged.next(this.learnersList);
    }


    alreadyExisting(name: string, type: string): boolean {
        return this[type].indexOf(name) !== -1;
    }

    filter(name: string, type: string): Array<string> {
        const allChips = type + 'AllChips';
        const chipName = type === 'groups' ? 'groupname' : 'workgroupname';

        return this[allChips].filter(chip =>
            chip[chipName].toLowerCase().indexOf(name.toLowerCase()) !== -1);
    }

    removeChip(chip: any, type: string): void {
        // remove chip from array of chips (group or workgroup)
        const chips = type + 'Chips';
        const index = this[chips].indexOf(chip);

        if (!chip) {
            this[chips] = [];
        } else {
            if (index >= 0) {
                this[chips].splice(index, 1);
            }

            if (type === 'workgroups') {
                if (this.groupsChips && this.groupsChips.length) {
                    this.filteredLearner(this.groupsChips[0], 'groups');
                }
            } else {
                if (this.workgroupsChips && this.workgroupsChips.length) {
                    this.filteredLearner(this.workgroupsChips[0], 'workgroups');
                }
            }

            if (this.groupsChips && !this.groupsChips.length && this.workgroupsChips && !this.workgroupsChips.length) {
                this.resetLearnerList();
            }
        }
        this.blurAllChipsList();
    }


    resetLearnerList(): void {
        this.learnersList = [];
        this.learnersList.push(...this.learners);
        this.dataSource['learnerService'].onLearnersChanged.next(this.learnersList);

    }

    blurAllChipsList(): void {
        this.groupsChipInput.nativeElement.blur();
        this.workgroupsChipInput.nativeElement.blur();
    }

    chipSelected(event: MatAutocompleteSelectedEvent, type: string): void {
        const chips = type + 'Chips';
        const input = type + 'ChipInput';
        const chipsCtrl = type + 'Ctrl';
        this[chipsCtrl].setValue(null);
        if (!this.alreadyExisting(event.option.viewValue, chips)) {

            this.removeChip(null, type);
            this.filteredLearner(event.option.value, type);

            this[chips].push(event.option.viewValue);
            this[input].nativeElement.value = '';
        }
        this.blurAllChipsList();
    }

    get groups(): Array<Group> {
        if (this.dataSource['learnerService'] && this.dataSource['learnerService'].groupsList) {
            return this.dataSource['learnerService'].groupsList;
        }

        return [];
    }

    get workgroups(): Array<Workgroup> {
        if (this.dataSource['learnerService'] && this.dataSource['learnerService'].workgroupsList) {
            return this.dataSource['learnerService'].workgroupsList;
        }

        return [];
    }

    get learners(): Array<Workgroup> {
        if (this.dataSource['learnerService'] && this.dataSource['learnerService'].learnersList) {
            return this.dataSource['learnerService'].learnersList;
        }

        return [];
    }

    get empty(): boolean {
        if (this.type === 'learner') {
            return !this.dataSource['learnerService'].onLearnersChanged.value.length;
        }
        if (this.type === 'trainer') {
            return !this.dataSource['trainerService'].onTrainersChanged.value.length;
        }
        return this.dataSource && this.dataSource['data'] && !this.dataSource['data'].value.length;
    }

    get emptyTitle(): string {
        if (this.type === 'class') {
            if (this.authService.isAtLeastTrainer()) {
                return 'dashboard.group_empty_granule_trainer';
            }
            return 'dashboard.group_empty_granule_learner';
        }

        return 'generic.no-data';

    }

    public get align(): string {
        if (!this.empty) {
            return 'start';
        }
        return 'center';
    }

}
