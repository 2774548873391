import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-messaging-confirm',
  templateUrl: './messaging-confirm.component.html',
  styleUrls: ['./messaging-confirm.component.scss']
})
export class MessagingConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MessagingConfirmComponent>
  ) { }

  ngOnInit(): void {
  }
  /**
   * 
   */
  yes(): void {
    this.dialogRef.close(true);
  }
  /**
   * 
   */
  no(): void {
    this.dialogRef.close(false);
  }

}
