import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import {MatButtonModule, MatCardModule, MatIconModule, MatMenuModule, MatTooltipModule, MatChipsModule, MatSlideToggleModule} from '@angular/material';
import {SharedTranslateModule} from '../../../app/shared/shared-translate.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatChipsModule,
    SharedTranslateModule,
    MatSlideToggleModule
  ],
  declarations: [CardComponent],
  exports: [
      CardComponent
  ],
})
export class CardModule { }
