import {Component, OnInit, Inject, Input, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ActivitiesService} from '@modules/activities/core/activities.service';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})

export class DialogComponent implements OnInit, OnDestroy {
    @Input() dialogItemid: Object;
    public checkAnswer: boolean;
    public isLatexKeyboardDisplayed = false;
    public dialogItemTitle: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private activityService: ActivitiesService) {
        this.dialogItemid = data;
        this.dialogItemTitle = data.title;
    }

    ngOnInit(): void {
        this.activityService.onLatexKeyboardDisplayChange.subscribe((isDisplayed) => {
           this.isLatexKeyboardDisplayed = isDisplayed;
        });
    }

    ngOnDestroy(): void {
        this.activityService.clearLessonState(true);
    }
}
