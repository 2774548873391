import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit
} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import {FillInBlanksChildComponent} from './fill-in-blanks-child/fill-in-blanks-child.component';
import {brand} from '../../../../../settings';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {ActivityInterface} from '@modules/activities/core/player-components/activity.interface';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-fill-in-blanks',
    templateUrl: './fill-in-blanks.component.html',
    styleUrls: ['./fill-in-blanks.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FillInBlanksComponent implements OnDestroy, OnInit, AfterViewInit, ActivityInterface {
    @ViewChild(FillInBlanksChildComponent) private child: FillInBlanksChildComponent;

    @Input('activityId') public activityId: any;
    @Input('contextId') private contextId: string;
    @Input('questionTypeName') questionTypeName: string;

    @Output() answerClick: EventEmitter<String> = new EventEmitter<String>();

    public activityPercentil = 0;
    public answerFeedback: string;
    public answersEntered = [];
    public answerStatus = 2; // 1 => correct, 2 => missing, 3 => wrong;
    public answerValidated = false;
    public apiAnswer = [];
    public brand = brand;
    public childReadiness: Subject<FillInBlanksChildComponent> = new Subject<FillInBlanksChildComponent>();
    public disableOnChange = new ReplaySubject<boolean>(1);
    public displayFeedbackMissing = false;
    public displayFeedbackWrong = false;
    public fillInBlanksWording: any;
    public instruction: any;
    public isCaseSensitive = true;
    public isFormula = false;
    public isTwoColumns = true;
    public items = [];
    public options: any;
    public questionObject: any;
    public rightAnswer: any;
    public showSolution = false;
    public title: string;
    public userAnswer: any;
    public wording: any;

    private activityStepIndex: number;
    private childIsReady: FillInBlanksChildComponent;
    private isSaving = false;
    private showCorrection = false;
    private unsubscribeInTakeUntil = new Subject();
    private userAccessCorrection = false;
    private userSave: DataEntity;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private elementRef: ElementRef,
        private lessonsService: LessonsService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });

        // Une subtilité du cycle angular fait qu'à un moment donné, deux FillInBlanksChildComponent existent
        //  Mais le ViewChild derrière "this.child" possède le dernier et qu'il nous faut le différencier du nouveau
        this.childReadiness.subscribe((child: FillInBlanksChildComponent) => {
            this.childIsReady = child;
            if (child && this.userAnswer) {
                this.checkAnswer(false);
            }
        }, (err) => {
            throw err;
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.initialize();
        });
    }

    initialize(): void {
        this.lessonsService.initDebugGrade();
        this.reset(true);

        this.activityService.launchActivity(this.activityId)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(data => {
                if (data.reference.config) {
                    this.isTwoColumns = data.reference.config.doubleColumn !== 0;
                    this.isCaseSensitive = !!data.reference.config.caseSensitive;
                    this.isFormula = this.activityService.settings.latexKeyboard;

                }

                if (data.reference.feedback) {
                    this.answerFeedback = data.reference.feedback;
                }

                this.questionObject = this.activityService.getPropertyFromNestedObject(data, ['reference']);
                this.instruction = this.questionObject.instruction;
                this.wording = this.questionObject.wording;
                this.fillInBlanksWording = this.questionObject.activity_content;
                this.items[0] = data;

                this.apiAnswer = [];
                for (const obj of this.questionObject.activity_content.answers) {
                    if (!Array.isArray(obj.answer)) {
                        obj.answer = JSON.parse(obj.answer);
                    }
                    this.apiAnswer.push(_.clone(obj));
                }
                this.disableOnChange.next(true);
                this.loadUserSave();
                this.activityStepIndex = this.activityService.presentArrayElementIndex;

                if (this.userAccessCorrection) {
                    this.setActivityWithUserAccess(); // TODO vérifier s'il ne vaut pas mieux exécuter cette fonction une fois la userSave chargée
                }

            });

        this.activityService.userActionWaiting
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((action) => {
                switch (action.actionLabel) {
                    case 'test':
                        this.showCorrection = true;
                    case 'save':
                        this.validateAndSaveAnswer(action.endSubject);
                        break;
                    case 'reset':
                        this.showCorrection = false;
                        this.reset(false, 'reset');
                        action.endSubject.next();
                        break;
                    case 'modify':
                        this.reset(false, 'modify');
                        action.endSubject.next();
                        break;
                    case 'see_solution':
                        this.seeAnswerSolution();
                        action.endSubject.next();
                        break;
                    case 'see_answer':
                        this.reviewAnswer();
                        action.endSubject.next();
                        break;
                }
            });
    }

    private loadUserSave(): void {
        this.activityService.getUserSave(this.activityId.id, this.contextId)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    this.setAnswers();

                    this.activityPercentil = Math.round(this.getOldGrade() * 100);

                } else if (this.lessonsService.isMyAssignment()) {
                    this.saveDefault();
                } else if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.checkAnswer(false);
                }

                this.disableOnChange.next(this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest());
            });
    }

    ngAfterViewInit(): void {
        this.setActivityWithUserAccess(); // TODO vérifier utilité d'exécuter cette fonction ici
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    setActivityWithUserAccess(): void {
        if (this.lessonsService.isTrainerSeeCorrection()) {
            this.userAccessCorrection = true;
            this.showCorrection = true;
        }
    }

    getAnswersFromChild(): any[] {
        let answersEntered = [];
        if (this.child) {

            if (this.isFormula) {
                answersEntered = this.child.latexKeyboard.getAllLatex();
            } else {
                const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
                elcolor.forEach((elem, index) => {
                    answersEntered[index] = elem.innerText.trim();
                });
            }

        }
        return answersEntered;
    }

    setAnswers(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete') {
            const answers = this.userSave.get('userActivity').entitySave.answers;

            this.userAnswer = answers;
            this.answersEntered = answers;

            if (this.answersEntered) {
                this.setUserAnswersToForm();
                this.checkAnswer(false);
                this.activityService.userAnswer.next(answers);
            }
        } else if (this.lessonsService.isTrainerSeeCorrection()) {
            this.checkAnswer(false);
        }
    }

    private setUserAnswersToForm(): void {
        if (this.isFormula) {
            this.child.latexKeyboard.pushAllLatex(this.answersEntered);
        } else {
            const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
            elcolor.forEach((elem, index) => {
                elem.innerText = this.answersEntered[index];
            });
        }
    }

    checkAnswer(isUserValidateAnswer, fromChild = false): void {
        if (fromChild) {
            this.answersEntered = this.getAnswersFromChild();
        }
        const answersEntered = this.answersEntered;
        let answerCorrect = [];
        let rightAnswer;
        let maxCorrect = 0;
        if (answersEntered && answersEntered.length) {
            this.answerStatus = 3;
        }

        if (!answersEntered.length || !answersEntered.some((answer) => answer)) {
            this.answerStatus = 2;
        }

        this.answerValidated = true;
        this.disableOnChange.next(true);
        this.apiAnswer.forEach((item, index) => {
            if (answersEntered.length === item.answer.length) {
                const answerCorrectTemp = [];
                let correctCnt = 0;
                answersEntered.forEach((entered, no) => {
                    let answerIsCorrect: boolean;

                    if (this.isCaseSensitive) {
                        answerIsCorrect = answersEntered[no].replace(/ /g, '') === item.answer[no].replace(/ /g, '');
                    } else {
                        answerIsCorrect = answersEntered[no].replace(/ /g, '').toLowerCase() === item.answer[no].replace(/ /g, '').toLowerCase();
                    }

                    answerCorrectTemp[no] = answerIsCorrect ? 1 : 0;
                    // take how many correct answer
                    if (answerIsCorrect) {
                        correctCnt++;
                    }
                });
                // if maximum answer correct assign right answer
                if (maxCorrect <= correctCnt) {
                    rightAnswer = item;
                    answerCorrect = answerCorrectTemp;
                    maxCorrect = correctCnt;
                }
                // is correct answer
                if (item.answer.length === correctCnt) {
                    this.answerStatus = 1;
                }
            }
        });

        if (isUserValidateAnswer) {
            const grade = (maxCorrect / this.apiAnswer[0].answer.length);
            this.lessonsService.setAssignGrade(grade, this.getOldGrade());

            if (this.userSave) {
                this.userSave.set('grade', Math.round(grade * 100));
            }
        }

        if (answersEntered.length && this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
            answersEntered.forEach((value, index) => {
                if (value.length && answerCorrect[index] === 0) {
                    this.displayFeedbackWrong = true;
                }
                if (!value.length) {
                    this.displayFeedbackMissing = true;
                }
            });
        }

        if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining() || this.userAccessCorrection) {
            this.showCorrection = true;
        }

        this.rightAnswer = rightAnswer;
        if (this.childIsReady) {
            const isAnswersEntered = this.answersEntered.some(answer => answer !== '');
            this.childIsReady.showError(
                answerCorrect,
                answersEntered,
                this.questionObject.activity_content.answers[0],
                !isAnswersEntered,
                this.showCorrection || this.showSolution);
        }

        this.activityService.isUserAnswerStatus
           .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.userAccessCorrection) {
            this.activityService.checkAnswers.next({lessonCorrected: true});
        }

    }

    getOldGrade(): number {
        let answersEntered = [];
        let answerCorrect = [];
        let rightAnswer;
        let maxCorrect = 0;

        if (this.userSave) {
            answersEntered = this.userSave.get('userActivity').entitySave.answers;

            if (!answersEntered) {
                answersEntered = [];
            }
        }

        this.apiAnswer.forEach((item, index) => {
            if (answersEntered.length === item.answer.length) {
                const answerCorrectTemp = [];
                let correctCnt = 0;
                answersEntered.forEach((entered, no) => {

                    answerCorrectTemp[no] = answersEntered[no] === item.answer[no] ? 1 : 0;
                    // take how many correct answer
                    if (answersEntered[no] === item.answer[no]) {
                        correctCnt++;
                    }
                });
                // if maximum answer correct assign right answer
                if (maxCorrect <= correctCnt) {
                    rightAnswer = item;
                    answerCorrect = answerCorrectTemp;
                    maxCorrect = correctCnt;
                }
            }
        });

        return maxCorrect / this.apiAnswer[0].answer.length;
    }

    private validateAndSaveAnswer(endSubject: Subject<any>): void {
        this.checkAnswer(true, true);
        this.saveAnswer()
            .take(1)
            .subscribe((userSave) => {
                if (this.isSaving && userSave) {
                    this.isSaving = false;
                    this.userSave = userSave;
                }
                endSubject.next();
            });
    }

    private saveDefault(): void {
        if (!this.isSaving && !this.userSave && !this.lessonsService.isLessonTest()) {
            this.isSaving = true;
            this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, [], this.answerStatus, 'rb-save')
                .subscribe((userSave: DataEntity) => {
                    this.userSave = userSave;
                    this.isSaving = false;
                });
        }
    }

    seeAnswerSolution(): void {
        this.answersEntered = this.getAnswersFromChild();
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');
        let solutionElements;
        let rightAnswer = this.questionObject.activity_content.answers[0];

        if (this.rightAnswer) {
            rightAnswer = this.rightAnswer;
        }

        if (this.isFormula) {
            solutionElements = elcolor;
            this.child.latexKeyboard.pushAllLatex(rightAnswer.answer);
        } else {
            solutionElements = anstxt;
        }

        solutionElements.forEach((elem, index) => {
            elem.classList.remove('hide');
            const errorElem = msgicon[index];
            errorElem.classList.remove('missingResponse');
            errorElem.classList.remove('wrong');
            errorElem.classList.remove('hide'); // oui mais
            errorElem.classList.add('right');

            if (this.isFormula) {
                wrappers[index].classList.add('correction');
            } else {
                elem.innerText = rightAnswer.answer[index];
                elcolor[index].classList.add('hide');
            }
        });
        this.showSolution = true;
        this.displayFeedbackWrong = false;
        this.displayFeedbackMissing = false;
    }

    reset(resetAllSubscribe = false, type = null): void {
        this.activityService.displayActions.next(true);
        this.userAnswer = null;
        this.answersEntered = [];
        if (!type) {
            //  type is only set when we need usersave for calculating grade.
            this.userSave = type;
        }
        if (resetAllSubscribe) {
            this.childReadiness.next(null);
            this.answerFeedback = null;

            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject();
        }
        this.isSaving = false;
        this.answerStatus = 2;
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');

        elcolor.forEach((elem, index) => {
            const errorElem = msgicon[index];
            if (errorElem) {
                errorElem.classList.remove('right');
                errorElem.classList.remove('wrong');
                errorElem.classList.remove('validated');
                errorElem.classList.remove('missingResponse');
                errorElem.classList.add('hide');
            }


            if (this.isFormula) {
                /* HACK : L'un des passage pour arriver ici c'est ngOnInit -> initialize -> reset, mais this.child n'est pas encore valorisé
                 *  donc on s'attends à ce que ça plante sur cette ligne.
                 *  Mais comme on est en initialisation "elcolor" est lui aussi vide (tableau vide) donc on passe pas dans cette boucle.
                 */
                wrappers[index].classList.remove('correction');
            } else if (anstxt[index]) {
                elem.innerText = '';
                elem.classList.remove('hide');
                anstxt[index].innerText = '';
                anstxt[index].classList.remove('hide');
            }
        });

        if (this.isFormula) {
            this.child.latexKeyboard.pushAllLatex([]);
        }

        this.answerValidated = false;
        this.displayFeedbackWrong = false;
        this.displayFeedbackMissing = false;
        this.disableOnChange.next(false);
    }

    reviewAnswer(): void {
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');
        let solutionElements;

        if (this.isFormula) {
            solutionElements = elcolor;
            this.child.latexKeyboard.pushAllLatex([]);
        } else {
            solutionElements = anstxt;
        }

        solutionElements.forEach((elem, index) => {
            elem.classList.remove('hide');
            const errorElem = msgicon[index];
            errorElem.classList.remove('right');
            errorElem.classList.remove('wrong');
            errorElem.classList.remove('missingResponse');
            errorElem.classList.add('hide');

            if (this.isFormula) {
                wrappers[index].classList.remove('correction');
            } else {
                elem.innerText = '';
                elcolor[index].innerText = '';
                elcolor[index].classList.remove('hide');
            }
        });

        this.setAnswers();

        if (this.answerValidated) {
            this.checkAnswer(false);
        }

        this.showSolution = false;
    }

    public saveAnswer(): Observable<DataEntity> {
        let answersEntered: string[] | string = this.answersEntered;
        if (this.contextId) {
            answersEntered = JSON.stringify(this.answersEntered);
        }
        this.lessonsService.setProgress(this.userSave, this.answerStatus);
        this.isSaving = true;
        return this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, answersEntered, this.answerStatus, 'rb-save', this.userSave, this.activityStepIndex);
    }

    public get showPercentil(): string {
        if (this.isTrainerAndAssessment) {
            return this.activityPercentil ? ' ' + this.activityPercentil + '%' : ' 0%';
        }

        return '';
    }

    public get isTrainerAndAssessment(): boolean {
        return this.lessonsService.isAtLeastTrainer() && this.lessonsService.isLessonEvaluation() || this.userAccessCorrection;
    }
}
