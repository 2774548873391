import {Component, OnInit, Input, ViewEncapsulation, OnDestroy, AfterViewChecked} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {DataEntity} from 'octopus-connect';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import Keyboard from 'latex-keyboard';
import * as _ from 'lodash';

@Component({
    selector: 'app-short-answer',
    templateUrl: './short-answer.component.html',
    styleUrls: ['./short-answer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShortAnswerComponent implements OnInit, AfterViewChecked, OnDestroy {
    public static activityLabel = 'shortAnswer';

    @Input('activityId') public activityId: any;
    @Input('contextId') public contextId: string;
    @Input('disabled') public disabled: boolean;
    @Input('hideCorrection') hideCorrection: boolean;
    @Input('questionTypeName') questionTypeName: string;

    public activityPercentil = 0;
    public answerFeedback: string;
    public answerStatus = 2; // 1 => correct, 2 => missing, 3 => wrong;
    public assignatedCount = 20;
    public disable: boolean;
    public displayFeedback = false;
    public hasAnswer: boolean;
    public instruction: any;
    public isCaseSensitive = true;
    public isFormula = false;
    public isPoll = false;
    public isSaveLoading = false;
    public isSaving = false;
    public isTwoColumns = true;
    public options: any;
    public questionObject: any;
    public reply: any;
    public showFinalAnswers = false;
    public userSave: DataEntity;
    public validated = false;
    public wording: any;

    private activityStepIndex: number;
    private callbackToTriggerOnAfterViewChecked: Function;
    private latexAnswer: string;
    private latexKeyboard: Keyboard;
    private unsubscribeInTakeUntil = new Subject();
    private userAccessCorrection = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        private lessonsService: LessonsService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
    }

    private initialize(): void {
        this.isPoll = false;
        this.lessonsService.initDebugGrade();
        this.reset(true);

        this.activityService.launchActivity(this.activityId)
            .take(1)
            .subscribe(data => {
                if (data.reference.config) {
                    this.isTwoColumns = data.reference.config.doubleColumn !== 0;
                    this.isCaseSensitive = !!data.reference.config.caseSensitive;
                    this.isFormula = this.activityService.settings.latexKeyboard;
                }

                if (data.reference.config.poll) {
                    /**
                     * endpoint "assignation_search" called if user came from assignment list.
                     * endpoint "assignations" called if user came from player (lesson).
                     */
                    if (this.activityService.currentAssignment && this.activityService.currentAssignment.get('assignatedCount')) {
                        this.assignatedCount = this.activityService.currentAssignment.get('assignatedCount');
                    } else {
                        this.assignatedCount = 20;
                    }

                    this.isTwoColumns = false;
                    this.reply = 0;
                    this.isPoll = true;
                }

                if (this.isFormula) {
                    this.latexKeyboard = new Keyboard(null, {
                        inputFields: [
                            {
                                placeholder: '\\blue{[?]}',
                                welcomeText: '',
                                katexOptions: {                           /* See https://katex.org/docs/options.html */
                                    throwOnError: false,
                                    strict: false,
                                    allowAllSymbols: true
                                },
                                renderedLatexDivId: 'latex-rendering'          /* Id of div where rendering occurs         */
                            }
                        ],
                        blacklistTabs: this.activityService.isPrimary(data) ? ['123 secondaire'] : ['123 primaire'],
                        keyboardDivId: 'latex-keyboard',           /* Id of div where keyboard tabs are        */
                        tabDivContainerGridClass: 'keyboard-grid-container',  /* Class name of each div grid tab          */
                        tabDivContainerItemClass: 'keyboard-grid-item',       /* Class name of each div item tab          */
                        tabDivMenuEntryClass: 'keyboard-tab-title',       /* Class name of tab titles (123, abc, ..)  */
                        tabSwitchClassListener: 'toggleTab',                /* Class name where tab switch has to occur */
                        hideKeyboardButtonClass: 'hideKeyboardButton',      /* Class name of close '✖' button containers */
                        moveLeftButtonClass: 'moveLeft',                 /* Class name of Move Left button(s) */
                        moveRightButtonClass: 'moveRight',                /* Class name of Move Right button(s) */
                        backspaceButtonClass: 'backspace'                 /* Class name of Move Backspace button(s) */
                    });

                    this.latexKeyboard.addEventListener('change', (fields: string[]) => {
                        const fieldsData = fields.join('').trim();

                        if (fieldsData === '') {
                            this.activityService.doesUserResponsed.next(false);
                        } else {
                            this.activityService.doesUserResponsed.next(true);
                        }
                    });

                    this.latexKeyboard.addEventListener('display', (isDisplayed: boolean) => {
                        this.activityService.onLatexKeyboardDisplayChange.next(isDisplayed);
                        if (isDisplayed) {
                            this.callbackToTriggerOnAfterViewChecked = () => {
                                const inputId = this.latexKeyboard.getInputIdFocused();
                                document.getElementById(inputId).scrollIntoView();
                            };
                        }
                    });
                }

                this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();

                if (data.reference.feedback) {
                    this.answerFeedback = data.reference.feedback;
                }

                this.questionObject = this.activityService.getPropertyFromNestedObject(data, ['reference']);
                this.instruction = this.questionObject.instruction;
                this.wording = this.questionObject.wording;
                this.options = [];
                for (const obj of this.questionObject.activity_content.answers) {
                    this.options.push(_.clone(obj));
                }
                this.loadUserSave();

                if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.userAccessCorrection = true;
                }

                this.activityStepIndex = this.activityService.presentArrayElementIndex;
            });

        this.activityService.userActionWaiting
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((userAction) => {
                switch (userAction.actionLabel) {
                    case 'test':
                        this.hasAnswer = true;
                    case 'save':
                        this.checkAnswer();
                        this.saveAnswer()
                            .take(1)
                            .subscribe((userSave: DataEntity) => {
                                if (this.isSaving && userSave) {
                                    this.userSave = userSave;
                                    this.isSaving = false;
                                }
                                userAction.endSubject.next();
                            });
                        break;
                    case 'reset':
                        this.reset(false, 'reset');
                        userAction.endSubject.next();
                        break;
                    case 'modify':
                        this.reset(false, 'modify');
                        userAction.endSubject.next();
                        break;
                    case 'see_solution':
                        if (this.isFormula) {
                            this.latexAnswer = this.latexKeyboard.getLatex();
                            this.latexKeyboard.pushLatex(this.options[0].answer);
                        }
                        this.showFinalAnswers = true;
                        this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();
                        userAction.endSubject.next();
                        break;
                    case 'see_answer':
                        if (this.isFormula) {
                            this.latexKeyboard.pushLatex(this.latexAnswer);
                        }
                        this.showFinalAnswers = false;
                        this.displayFeedback = this.answerStatus !== 1 && this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer();
                        this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();
                        userAction.endSubject.next();
                        break;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();

        const haveToSaveOnDestroy = this.activityService.settings['saveOnDestroy'].includes(ShortAnswerComponent.activityLabel+'1');

        let todoBeforeDestroy: Observable<any>;
        if (haveToSaveOnDestroy) {
            this.checkAnswer();
            todoBeforeDestroy = this.saveAnswer();
        } else {
            todoBeforeDestroy = Observable.of([]);
        }

        todoBeforeDestroy
            .take(1)
            .subscribe(() => {
                if (this.latexKeyboard) {
                    this.latexKeyboard.destroy();
                }
            });
    }

    private loadUserSave(): void {
        this.isSaveLoading = true;
        this.activityService.getUserSave(this.activityId.id, this.contextId)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(userSave => {
                if ((userSave && !this.isPoll) || (userSave && this.isPoll && +userSave.get('step') === this.activityService.presentArrayElementIndex)) {
                    this.userSave = userSave;
                    this.setAnswer();
                    this.activityPercentil = Math.round(this.getGrade().oldGrade * 100);
                } else if (this.lessonsService.isMyAssignment()) {
                    this.saveDefault();
                } else if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.checkAnswer();
                }

                this.isSaveLoading = false;
            });
    }

    private setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete' && this.options) {
            let answer;

            if (this.userSave instanceof DataEntity) {
                answer = this.userSave.get('userActivity').entitySave.answers[0];
            } else {
                answer = this.userSave;
            }

            if (answer) {
                this.reply = answer.answer;

                if (this.isFormula) {
                    this.latexKeyboard.pushLatex(this.reply);
                }

                this.checkAnswer();
                this.activityService.doesUserResponsed.next(true);
            }

            this.activityService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
        } else if (this.lessonsService.isTrainerSeeCorrection()) {
            this.checkAnswer();
        }
    }

    getGrade(): any {
        let oldGrade = 0;
        let grade = 0;

        for (const option of this.options) {
            if (this.userSave) {
                const answer = this.userSave.get('userActivity').entitySave.answers[0];
                if (answer && answer.answer === option.answer) {
                    oldGrade += 1;
                }
            }
            if (option.answer === this.reply) {
                grade += 1;
            }
        }

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    private saveDefault(): void {
        if (!this.isSaving && !this.userSave && !this.lessonsService.isLessonTest()) {
            this.isSaving = true;
            this.activityService.saveUserSave(this.activityId.id.toString(), this.contextId, [], this.answerStatus, 'qcm-save')
                .subscribe((userSave: DataEntity) => {
                    this.userSave = userSave;
                    this.isSaving = false;
                });
        }
    }

    public saveAnswer(status?: number): Observable<DataEntity> {
        if (status !== undefined) {
            this.answerStatus = status;
        }

        this.isSaving = true;
        const grade = this.getGrade();
        this.lessonsService.setAssignGrade(grade['newGrade'], grade['oldGrade']);
        this.lessonsService.setProgress(this.userSave, this.answerStatus);

        if (this.userSave) {
            this.userSave.set('grade', +this.calculUserSavePercentil);
        }

        return this.activityService
            .saveAnswer({answer: this.reply}, 'answer')
            .take(1)
            .flatMap((answer: DataEntity) => {
                return this.activityService
                    .saveUserSave(
                        this.activityId.id.toString(),
                        this.contextId,
                        answer ? [answer.id.toString()] : [],
                        this.answerStatus,
                        'qcm-save',
                        this.userSave,
                        this.activityStepIndex
                    );
            });
    }

    reset(resetAllSubscribe: boolean = false, type = null): void {
        if (!type) {
            //  type is only set when we need usersave for calculating grade.
            this.userSave = type;
        }

        if (this.latexKeyboard) {
            this.latexKeyboard.pushLatex('');
        }

        if (resetAllSubscribe) {
            if (this.latexKeyboard) {
                this.latexKeyboard.destroy();
                delete this.latexKeyboard;
            }

            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject();
        }
        this.isSaving = false;
        this.answerStatus = 2;
        this.displayFeedback = false;
        this.answerFeedback = null;
        this.hasAnswer = false;
        this.showFinalAnswers = false;
        this.validated = false;
        this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();
        this.reply = undefined;
        this.activityService.displayActions.next(true);
        this.activityService.doesUserResponsed.next(this.reply);
    }

    public answerState(): string {
        if (this.showFinalAnswers) {
            return 'correctReply';
        } else {
            if (!this.hideCorrection && this.hasAnswer) {
                if (this.isReplyCorrect()) {
                    return 'correctReply';
                } else if (!this.reply) {
                    return 'missingReply';
                } else {
                    return 'wrongReply';
                }
            }

            if (this.validated) {
                return 'validated';
            }
        }

        return '';
    }

    checkAnswer(): any {
        if (this.isFormula) {
            this.reply = this.latexKeyboard.getLatex();
        }

        if (this.reply) {
            if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining()) {
                this.hasAnswer = true;
            } else if (this.lessonsService.isLessonEvaluation()) {
                this.validated = true;
            }
        }
        this.disable = true;
        if (this.userAccessCorrection) {
            this.hasAnswer = true;
            this.validated = false;
        }

        if (this.options) {
            this.answerStatus = 3;

            if (this.reply) {
                if (this.isReplyCorrect()) {
                    this.answerStatus = 1;
                }
            } else {
                this.answerStatus = 2;
            }

            if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
                if (!this.hideCorrection && this.answerStatus === 3) {
                    this.displayFeedback = true;
                }
            }

            this.activityService.isUserAnswerStatus
                .next({status: this.answerStatus, index: this.activityStepIndex});

            if (this.userAccessCorrection) {
                this.activityService.checkAnswers.next({lessonCorrected: true});
            }

        }
    }

    public onNgModelChange(event): void {
        this.activityService.doesUserResponsed.next(event !== '');
    }

    public get showPercentil(): string {
        if (this.isTrainerAndAssessment) {
            return this.activityPercentil ? ' ' + this.activityPercentil + '%' : ' 0%';
        }

        return '';
    }

    public get isTrainerAndAssessment(): boolean {
        return this.lessonsService.isAtLeastTrainer() && this.lessonsService.isLessonEvaluation() || this.userAccessCorrection;
    }

    public ngAfterViewChecked(): void {
        if (this.callbackToTriggerOnAfterViewChecked) {
            this.callbackToTriggerOnAfterViewChecked();
            this.callbackToTriggerOnAfterViewChecked = null;
        }
    }

    private isReplyCorrect(): boolean {

        return this.options.some(option => option.answer.replace(/ /g, '') === this.reply.toString().replace(/ /g, '') ||
            (this.reply && !this.isCaseSensitive && option.answer.replace(/ /g, '').toLowerCase() === this.reply.toString().replace(/ /g, '').toLowerCase()));
    }

    private get calculUserSavePercentil(): number {
        return Math.round(this.getGrade().newGrade * 100);
    }

    /**
     * set the value to save in the field of the short answer
     * @param sliderPosition : value of slider equal nbre of vote
     */
    public onMoveSlider(sliderPosition: number): void {
        this.reply = sliderPosition;
    }

    /**
     * set the value to save in the field of the short answer
     * @param sliderPosition : value of slider equal nbre of vote
     */
    public onClickSlider(sliderPosition: number): void {
        this.reply = sliderPosition;
    }

    public get isLessonWithStep(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.lessonStep;
    }
}
