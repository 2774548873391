export const dashboard = {
    alwaysShowDashboard: true,
    displayHeaderLink: true,
    displayedWidgets: {
        default: ['agenda-weekly', 'assignmentsGroup', 'MessageThreads'],
        learner: ['agenda-weekly', 'MessageThreads', 'assignments'],
        manager: ['analytics'],
        administrator: [
            'group',
            'project',
            'sheetModels',
            'formModels',
            'assignmentsGroup',
            'favorites-lessons',
            'favorites-corpus',
            'consulted-corpus',
            'created-corpus',
            'analytics']
    },
    filtersByRole: {
        default : [],
        trainer : ['group']
    },
    redirectUrlBasicPage: null
};