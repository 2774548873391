import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { IdeasWallsListComponent } from './ideas-walls-list/ideas-walls-list.component';
import {IdeasWallService} from './ideas-wall.service';
import { IdeasViewComponent } from './ideas-view/ideas-view.component';
import { CategoriesViewComponent } from './categories-view/categories-view.component';
import { IdeasWallsRootComponent } from './ideas-walls-root/ideas-walls-root.component';
import { IdeasWallMainViewComponent } from './ideas-wall-main-view/ideas-wall-main-view.component';
import { IdeaItemComponent } from './idea-item/idea-item.component';
import { CategoryItemComponent } from './category-item/category-item.component';
import {IdeasWallDdService} from './ideas-wall-dd.service';
import { ColumnDirective } from './column.directive';
import { IdeaEditionModalComponent } from './idea-edition-modal/idea-edition-modal.component';
import {SharedModule} from '../../../shared/shared.module';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {LayoutModule} from '../../../layout/layout.module';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {RouterModule, Routes} from '@angular/router';
import {IsUserLogged} from '../../../is-user-logged.class';
import { RemoteControllerComponent } from './remote-controller/remote-controller.component';
import { IdeasWallItemComponent } from './ideas-wall-item/ideas-wall-item.component';
import {ClickOutsideModule} from 'ng-click-outside';
import { IdeasWallEditionModalComponent } from './ideas-wall-edition-modal/ideas-wall-edition-modal.component';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';

const routes: Routes = [
    {
        path: 'ideas-wall',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: IdeasWallsListComponent
            },
            {
                path: ':wallid/edit',
                component: IdeasWallMainViewComponent
            },
            {
                path: '**',
                redirectTo: 'list',
                pathMatch: 'full'
            },
        ]
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        LayoutModule,
        FuseSharedModule,
        SharedModule,
        ClickOutsideModule
    ],
    declarations: [
        IdeasWallsListComponent,
        IdeasViewComponent,
        CategoriesViewComponent,
        IdeasWallsRootComponent,
        IdeasWallMainViewComponent,
        IdeaItemComponent,
        CategoryItemComponent,
        ColumnDirective,
        IdeaEditionModalComponent,
        RemoteControllerComponent,
        IdeasWallItemComponent,
        IdeasWallEditionModalComponent
    ],
    entryComponents: [
        IdeaEditionModalComponent,
        RemoteControllerComponent,
        IdeasWallEditionModalComponent
    ]
})
export class IdeasWallModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: IdeasWallModule,
            providers: [
                IdeasWallService,
                IdeasWallDdService
            ]
        };
    }

    private postLogout(): void {
        IdeasWallModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'ideasWall');
    }

    private postAuthentication(): void {
        if (!IdeasWallModule.isMenuSet && this.authService.isAuthenticated) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id'   : 'ideasWall',
                'title': 'Ideas Walls',
                'translate': 'navigation.ideas-wall',
                'type' : 'item',
                'icon' : 'projects',
                'url'  : '/ideas-wall'
            });

            IdeasWallModule.isMenuSet = true;
        }
    }
}
