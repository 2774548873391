import {Component, OnInit, Input} from '@angular/core';
import {LicensingService} from '../licensing.service';
import {localizedDate} from '../../../../shared/utils';

@Component({
    selector: 'licensing-content',
    templateUrl: './licensing-content.component.html',
    styleUrls: ['./licensing-content.component.scss']
})
export class LicensingContentComponent implements OnInit {

    @Input('uid') uid: string;
    public dataSource: any[];
    public displayedColumns: string[] = ['name', 'unlockedDate', 'code'];
    public subscribeGetMethods: any;

    constructor(private licensingService: LicensingService) {}

    ngOnInit(): void {
        this.subscribeGetMethods = this.licensingService.getMethods().subscribe((entities) => {
            let licenses = [];
            for (const entity of entities) {
                if (!this.uid || this.uid === entity.get('uid')) {
                    let access = entity.get('access') || {};
                    licenses.push({
                        id: access.id,
                        name: access.name,
                        unlockedDate: localizedDate(entity.get('date')),
                        code: entity.get('code')
                    });
                }
            }
            this.dataSource = licenses;
        })
    }
    ngOnDestroy() {
        this.subscribeGetMethods.unsubscribe();
    }
}
