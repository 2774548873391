import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {ActivityInterface} from '@modules/activities/core/player-components/activity.interface';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

declare var require: any;
const urlParser = require('js-video-url-parser');

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MediaComponent implements OnInit, OnDestroy, ActivityInterface {
    @Input('activityId') private activityId?: any;

    public media: any;
    public urlMedia: SafeResourceUrl;
    public videoUrl: boolean;

    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private activityService: ActivitiesService,
        public sanitizer: DomSanitizer
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = {};
            }

            if (params) {
                for (let key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.initialize();
        });
    }

    initialize(): void {
        this.reset();
        console.log('this.activityId', this.activityId);
        this.activityService.launchActivity(this.activityId)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe(data => {
                this.media = this.activityService.getPropertyFromNestedObject(data, ['reference']);

                console.log('this.media', this.media);

                if (this.media && this.media.url){
                    console.log(this.media.url);
                    const parsed: Object = urlParser.parse(this.media.url);

                    if (parsed) { // check if video url
                        this.videoUrl = true;
                        this.urlMedia = this.sanitizer.bypassSecurityTrustResourceUrl(
                            this.activityService.getVideoLinkInfo(this.media.url));
                    } else {
                        this.videoUrl = false;
                        this.urlMedia = this.media.url;
                    }

                    console.log(this.videoUrl);

                }

            });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    reset(): void {
        this.urlMedia = null;
        this.activityService.displayActions.next(false);
    }

    public get isImage(): boolean {
        return this.media && this.media.type === 'image';
    }
    
    public get isVideo(): boolean {
        return this.media && this.media.type === 'video';
    }

    public get isAudio(): boolean {
        return this.media && this.media.type === 'audio';
    }

    public get isDocument(): boolean {
        return this.media && this.media.type === 'document';
    }

    public get isUrl(): boolean {
        return !!this.urlMedia;
    }

}
