import {Component, ComponentFactoryResolver, OnInit, Type, ViewChild} from '@angular/core';
import {DynamicComponentDirective} from "fuse-core/directives/dynamic-component/dynamic-component.directive";
import {CommunicationCenterService} from "@modules/communication-center";

@Component({
    selector: 'app-corpus-display-embed',
    templateUrl: './corpus-display-embed.component.html'
})
export class CorpusDisplayEmbedComponent implements OnInit {
    @ViewChild(DynamicComponentDirective) corpusComponent: DynamicComponentDirective;
    private componentReference: Type<any>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('corpus')
            .getSubject('corpusDisplayComponent')
            .subscribe((component: Type<any>) => {
                this.componentReference = component;
            });
    }

    ngOnInit() {
        this.loadComponent();
    }

    private loadComponent(): void {
        if (this.componentReference) {
            let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentReference);

            let viewContainerRef = this.corpusComponent.viewContainerRef;
            viewContainerRef.clear();

            viewContainerRef.createComponent(componentFactory);
        } else {
            console.error('Corpus Component Reference not initialized.');
        }
    }
}
