export const assignation = {
    columns: {
        default: ['assigned_node_title', 'assignatedCount', 'educationalLevel', 'changed']
    },
    filters: {
        default: ['title', 'educationalLevel']
    },
    getStateFromDate: false,
    hasCompletionDate: false,
    hasCompletionTime: false,
    hasType: false,
    stateWithIcon: false,
};