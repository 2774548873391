import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsignesComponent} from '@modules/activities/core/shared-components/consignes/consignes.component';
import {OptionsComponent} from './options/options.component';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatGridListModule,
    MatIconModule, MatInputModule,
    MatMenuModule, MatProgressSpinnerModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {UserActionButtonComponent} from './user-action-button/user-action-button.component';
import {TrainerFeedbackComponent} from './trainer-feedback/trainer-feedback.component';
import { CorpusDisplayEmbedComponent } from './corpus-display-embed/corpus-display-embed.component';
import {SharedModule} from '../../../../shared/shared.module';
import {ErrorReportingModule} from 'fuse-core/components/error-reporting/error-reporting.module';
import {GenericStepperComponent} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';
import { StepperWrapperComponent } from './basic-stepper-container/stepper-wrapper.component';
import {VideoWithMarkersComponent} from '@modules/activities/core/shared-components/video-markers/video-with-markers.component';
import {SummaryComponent} from '@modules/activities/core/shared-components/summary/summary.component';
import {PollReviewComponent} from '@modules/activities/core/player-components/poll-review/poll-review.component';
import {ChartsModule} from 'ng2-charts';
import { CorpusDisplayWrapperComponent } from './corpus-display-wrapper/corpus-display-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatRadioModule,
        MatGridListModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ActivitiesPipesModule,
        ErrorReportingModule,
        ChartsModule
    ],
    declarations: [
        ConsignesComponent,
        OptionsComponent,
        UserActionButtonComponent,
        TrainerFeedbackComponent,
        CorpusDisplayEmbedComponent,
        GenericStepperComponent,
        StepperWrapperComponent,
        VideoWithMarkersComponent,
        SummaryComponent,
        PollReviewComponent,
        CorpusDisplayWrapperComponent
    ],
    exports: [
        ConsignesComponent,
        OptionsComponent,
        UserActionButtonComponent,
        TrainerFeedbackComponent,
        CorpusDisplayEmbedComponent,
        StepperWrapperComponent,
        VideoWithMarkersComponent,
        SummaryComponent,
        PollReviewComponent
    ],
    entryComponents: [VideoWithMarkersComponent, SummaryComponent, PollReviewComponent, CorpusDisplayWrapperComponent]
})
export class CommonSharedModule {
}
