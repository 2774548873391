import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Subscription} from 'rxjs/Subscription';
import {ActivitiesService} from '@modules/activities/core/activities.service';


@Injectable()
export class ResolverForFormPlayer implements Resolve<any> {

    activitiesSubscription: Subscription;
    activities: DataEntity[];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private activitiesService: ActivitiesService,
                private octopusConnect: OctopusConnectService,) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.octopusConnect.loadCollection('granule-form').map(collection => collection.entities).subscribe(resp => {
                this.activitiesService.pushValueIntoSubscriber(resp[0].attributes.reference);
                resolve(resp[0]);
            }, reject);
        });
    }
}
