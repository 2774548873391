import { Component, OnInit } from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {TrainerService} from '@modules/groups-management/core/trainer/trainer.service';
import {FormGroup} from '@angular/forms';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {
  public displayedColumns = ['checkbox', 'avatar', 'trainer-name', 'email', 'type', 'access', 'buttons'];
  public dataSource: TrainerDataSource | null;

  public newTrainer: any = {
    data: {
      action: 'new',
      title: 'add_trainer',
      fields: ['avatar', 'username', 'password', 'type', 'email'],
      selects: {
        groups: () => this.trainerService.getGroups(),
        types: () => this.trainerService.getTypes()
      },
      typeEntity: 'trainer'
    },
    callback: (response) => this.newTrainerCallback(response)
  };
  public editTrainer: any = {
    data: {
      action: 'edit',
      title: 'edit_trainer',
      fields: ['id', 'avatar', 'username', 'password', 'type', 'email'],
      selects: {
        groups: () => this.trainerService.getGroups(),
        types: () => this.trainerService.getTypes()

      },
      typeEntity: 'trainer'
    },
    callback: (response) => this.editTrainerCallback(response)
  };

  public deleteTrainer: any = {
      data: {
          titleDialog: 'groups-management.title_remove',
          bodyDialog: 'groups-management.sure_remove_trainer',
          labelTrueDialog: 'generic.yes',
          labelFalseDialog: 'generic.no',
      },
    callback: (learner) => this.deleteTrainerCallback(learner),
    deleteList: (list) => this.deleteListCallback(list)

  };

    constructor(
        private trainerService: TrainerService,
        private groupsManagementService: GroupsManagementService,
        private authService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.dataSource = new TrainerDataSource(this.trainerService);
        this.groupsManagementService.setHeaderTitle('groups-management.my_trainers');

        this.setTrainerColumnsdByAccessLevel();
        this.setTrainersFieldsByAccessLevel();
    }

    private setTrainersFieldsByAccessLevel(): void {
        const fields = this.groupsManagementService.settings.trainer.fields[this.authService.accessLevel] ?
            this.groupsManagementService.settings.trainer.fields[this.authService.accessLevel] : this.groupsManagementService.settings.trainer.fields['default'];

        this.newTrainer.data.fields = [...fields];
        this.editTrainer.data.fields = [...fields];
        // push id because need for edit mode but field doesn't exist in form
        if (this.editTrainer.data.fields) {
            this.editTrainer.data.fields.push('id');
        }
    }

    private setTrainerColumnsdByAccessLevel(): void {
        this.displayedColumns = this.groupsManagementService.settings.trainer.columns[this.authService.accessLevel] ?
            this.groupsManagementService.settings.trainer.columns[this.authService.accessLevel] : this.groupsManagementService.settings.trainer.columns['default'];
    }

    public newTrainerCallback(response: FormGroup): Observable<DataEntity> {
    if (response) {
      return this.trainerService.addTrainer(response.getRawValue());
    }
  }

  public editTrainerCallback(response: FormGroup): Observable<DataEntity> {
    if (response) {
      return this.trainerService.saveTrainer(response.getRawValue());
    }
  }

  public deleteTrainerCallback(trainer: any): void {
    this.trainerService.deleteTrainer(trainer);
  }

  public deleteListCallback(trainers: any): void {
      for (const trainer of trainers){
          this.trainerService.deleteTrainer(trainer);
      }
  }
}

export class TrainerDataSource extends DataSource<any> {
  constructor(private trainerService: TrainerService) {
    super();
  }

  connect(): Observable<any[]> {
    return this.trainerService.onTrainersChanged;
  }

  disconnect(): void{ }
}
