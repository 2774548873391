import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {navigation} from 'app/navigation/navigation';

import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {DataEntity} from 'octopus-connect';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {brand, modulesSettings, langs, brandLogoSvg, tralalereBar} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {Router} from '@angular/router';

const settingsToolbar: ModelSchema = new ModelSchema({
    translationAccess: Structures.object({show: false, withFlag: true}),
    notificationButtonAccess: Structures.boolean()
});
const settingsNotification: ModelSchema = new ModelSchema({
    show: Structures.boolean(),
});
const settingsLicensing: ModelSchema = new ModelSchema({
    visible: Structures.boolean(false),
});

const settingsTralalereToolbar: ModelSchema = new ModelSchema({
    displayed: Structures.boolean(false),
});

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    userInformation: DataEntity;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public langs: object[] = langs;

    public settings: {[key: string]: any};
    public settingsForNotification: {[key: string]: any};
    public settingsLicensing: {[key: string]: any};
    public showTranslateMenu: boolean;
    public showFlagImg: boolean;
    public showNotification: boolean;
    public settingsTralereToolbar: { [key: string]: any };

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public authenticationService: AuthenticationService,
        public accountService: AccountManagementProviderService,
        private notificationsService: NotificationsService,
        private router: Router
    ) {
        this.settings = settingsToolbar.filterModel(modulesSettings.featureAccess);
        this.settingsForNotification = settingsNotification.filterModel(modulesSettings.notification);
        this.settingsTralereToolbar = settingsTralalereToolbar.filterModel(tralalereBar);

        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = this.langs;

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.showTranslateMenu = this.settings.translationAccess.show;
        this.showFlagImg = this.settings.translationAccess.withFlag;
        this.showNotification = this.settingsForNotification.show;
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

        this.accountService.data.subscribe(userInformation => {
            this.userInformation = this.accountService.loggedUser;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public get showLicensing(): boolean {
        return settingsLicensing.filterModel(modulesSettings.licensing).visible && !this.authenticationService.isManager();
    }

    get notificationsCount(): number {
        return this.notificationsService.unreadNotificationsCount;
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        localStorage.setItem('lang', lang.id);

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logOut() {
        this.authenticationService.logoutFrom('http');
    }

    goToLoginPage() {
        this.router.navigate(['/login']);
    }
}
