import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import {FuseValidateEmailComponent} from './validate-email.component';
import {SharedModule} from "../../../../shared/shared.module";

const routes = [
    {
        path     : 'validate-email',
        component: FuseValidateEmailComponent
    }
];

@NgModule({
    declarations: [
        FuseValidateEmailComponent
    ],
    imports     : [
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes)
    ]
})

export class ValidateEmailModule
{

}
