import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {defaultLoginRoute} from '../../../../../settings/lamorim';
import {AuthenticationService} from '@modules/authentication';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Injectable({
    providedIn: 'root'
})
export class CanShowLessonListGuard implements CanActivate {
    constructor(public lessonsService: LessonsService,
                private authService: AuthenticationService,
                private router: Router,
                private activitiesService: ActivitiesService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

        if (!this.authService.isAtLeastTrainer()) {
            subject.next(false);
            this.router.navigate([defaultLoginRoute]);
        }

        this.activitiesService.licensingMethods.take(1).subscribe((methods) => {
            const noLicensingMethods = this.activitiesService.licensingSettings
                && this.activitiesService.licensingSettings.restrict.includes('lessons-list')
                && methods.length === 0;

            if (this.authService.isTrainer() && noLicensingMethods) {
                subject.next(false);
                this.router.navigate(['/licensing-restricted']);
            } else {
                subject.next(true);
            }
        });

        return subject;
    }
}
