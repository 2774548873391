import {Component, Input, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';

@Component({
  selector: 'info-graph',
  templateUrl: './info-graph.component.html',
  styleUrls: ['./info-graph.component.scss']
})
export class InfoGraphComponent implements OnInit {
  @Input('infoSettings') infoSettings: ReplaySubject<any>;
    customSettingsTooltip: any;

  constructor(private router: Router,
              private communicationCenter: CommunicationCenterService,
              private authService: AuthenticationService) { }

  ngOnInit(): any {
    this.infoSettings.subscribe((data) => {
        this.customTooltip = data;
    });

    this.communicationCenter
        .getRoom('assignment')
        .getSubject('current')
        .subscribe((data) => {
           if (data !== null) {
               this.router.navigate(['followed', 'assignment', 'lessons', this.settingsTooltip.lessonId, 'player']);
           }
        });
  }

  public get isAtLeastTrainer(): boolean {
      return this.authService.isAtLeastTrainer();
  }

    public lauchAssignment(): void {
      this.communicationCenter
          .getRoom('assignment')
          .next('launch', this.settingsTooltip.assignmentId);
    }

    public navigateToAssignmentList(): void {
        this.router.navigate(['followed', 'list'], {queryParams: this.settingsTooltip.queryParams});
    }

    public get settingsTooltip(): any {
        return this.customSettingsTooltip;
    }

    public set customTooltip(data){
        this.customSettingsTooltip = data;
    }

}
