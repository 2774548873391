import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {Observable} from 'rxjs/Observable';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {modulesSettings} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {CommunicationCenterService} from '@modules/communication-center';

const settingsStructure: ModelSchema = new ModelSchema({
    selfSignup: Structures.boolean(true),
    signup: Structures.boolean(true)
});

@Injectable()
export class AccountManagementProviderService {

    message: any;
    currentUserData: DataEntity;
    subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    loggedUser: DataEntity;

    public settings: {[key: string]: any};
    public data: BehaviorSubject<Object> = new BehaviorSubject({});
    userData: DataEntity;

    constructor(
        private router: Router,
        private connector: OctopusConnectService,
        private authenticationService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
      this.communicationCenter
        .getRoom('authentication')
        .getSubject('userData')
        .subscribe((data: DataEntity) => {
            if (data) {
                this.userData = data;
                this.postAuthentication();
                this.connector.createEntity('authenticated', {id: this.userData.id, myType: 'authenticated'}).take(1);

            } else {
                this.postLogout();
            }
        });

        this.communicationCenter
            .getRoom('account-management')
            .getSubject('refreshUser')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.getCurrentUser;
                }
            });
        this.communicationCenter
        .getRoom('authentication').next('userAccessToken', this.userAccessToken);
      this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
    }

    private postLogout(): void{

    }

    private postAuthentication(): void {
        this.getCurrentUser;
    }

    createUser(user, callback, callbackError) {
        this.connector.createEntity('user-registration', user).subscribe((userData: DataEntity) => {
            this.userData = userData;
            let user_email = user['email'];
            if (!user_email){
                user_email = user['label'];
            }
            console.log('user crée', this.userData);
            this.authenticationService.authenticateIn('http', user_email, user['password']).subscribe((userData: DataEntity) => {
                this.loggedUser = userData;
                this.currentUserData = userData;
                callback();
            }, err => {
                console.log(err);
            });
        }, (error: Object) => {
            if (callbackError) {
                callbackError(error);
            } else {
                console.error(error);
            }
        });
    }

  sendMessage(message, callback){
    this.connector.createEntity('contact-message', message).subscribe(() => {
      callback();
    });
  }

    get getCurrentUser(): any {

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.subscribe((userData: DataEntity) => {

            this.loggedUser = userData;
            this.data.next(this.loggedUser);
            this.subject.next(true);

        }, () => {
            this.subject.next(false);
        });

        return this.subject;
    }

    /**
     * Save user data
     * @param user data
     * @param callback with true if success, else false
     */
    public editUser(user, callback): void {
        this.loggedUser.set('label', user.label );
        this.loggedUser.set('email', user.email );
        this.loggedUser.set('institution', user.institution );
        this.loggedUser.set('you_are', user.you_are );
        this.loggedUser.set('find_us', user.find_us );
        this.loggedUser.set('level', user.level );
        this.loggedUser.set('region', user.region );
        this.loggedUser.set('newsletter', user.newsletter );
        this.loggedUser.set('_picture', user.picture );
        // this.loggedUser.set('role', user.role );
        if (user.password){
          this.loggedUser.set('password', user.password );
        }
        this.loggedUser.save().subscribe((userEdited: DataEntity) => {
            this.loggedUser = userEdited;
            this.data.next(this.loggedUser);
            callback(true);
        }, error => callback(false));
    }

    editUserValidateEmail(){
        if (this.loggedUser){
            this.loggedUser.set('email_status', true );
            this.loggedUser.save().subscribe((userEdited: DataEntity) => {
                console.log(userEdited);
            });
        } else{
            this.router.navigate(['/']);
        }
    }

    get userAccessToken(): any {
        const access = JSON.parse(localStorage.getItem('http_accessToken'));

        return access;
    }

    /**
     * Remove current logged user
     */
    public deleteCurrentLoggedUser(): Observable<boolean> {
        return this.loggedUser.remove();
    }
}
