import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {DataCollection, DataEntity, PaginatedCollection, OctopusConnectService} from 'octopus-connect';
import {InstitutionGroup} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {Subscription} from 'rxjs/Subscription';
import {CollectionOptionsInterface} from 'octopus-connect/src/collection-options.interface';


@Injectable()
export class InstitutionGroupService {

    private userData: DataEntity;
    public paginatedCollection: PaginatedCollection;
    private institutionGroupsCollection: { [key: number]: DataEntity } = {};
    private institutionsInUserDataCollection: { [key: number]: DataEntity } = {};
    public institutionGroupsSubscription: Subscription = null;
    public onInstitutionGroupsChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    public institutionInUserSubscription: Subscription = null;

    private institutionField: Array<string> = ['institutiongroupname', 'address', 'city', 'postalCode', 'country'];

    private institutionInUserData: Array<any>;
    private institutionsInUserDataFormated: Array<object> = [];

    private allInstitutions: InstitutionGroup[] = [];
    private allInstitutionsFormated: Array<object>;

    private postalCodes: Array<any>;

    public optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 10
    };

    private refreshListCallback: any;

    constructor(private groupsManagement: GroupsManagementService,
                private octopusConnect: OctopusConnectService,
                private communicationCenter: CommunicationCenterService) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('userProfileEdit')
            .getSubject('userData').subscribe((data) => {
            this.getUserInstitutions();
        });
    }

    private postLogout(): void {
        if (this.institutionGroupsSubscription) {
            this.unsubscribeAll();
        }
    }

    private postAuthentication(): void {

        if (!this.institutionGroupsSubscription) {
            this.institutionGroupsSubscription = this.refreshList(this.optionsInterface);

            // get institutions from Institutions endpoint to retrieve institutions in user 's group
            if (!this.institutionInUserSubscription) {
                this.getUserInstitutions();
            }
            this.getPostalCodeCollection()
                .subscribe((data) => {
                    const postalCodesMap = data.entities.map((entity) => {
                        const postalCodes = entity.get('postalCodes');
                        return postalCodes[0];
                    });
                    this.postalCodes = postalCodesMap[0];
                });
        }

    }

    getAllInstitutionsFormated(): Array<object> {
        return this.allInstitutionsFormated;
    }

    getInstitutionGroup(optionsInterfarce): any {
        this.paginatedCollection = this.octopusConnect.paginatedLoadCollection('groups_search', optionsInterfarce);

        return this.paginatedCollection.collectionObservable;

    }

    getUserInstitutions() {

        this.institutionInUserSubscription = this.getInstitutionGroupInUserFromServer()
            .subscribe((data) => {
                this.institutionInUserData = [];
                this.institutionsInUserDataFormated = [];
                if (data && data.entities) {
                    for (const entity of data.entities) {
                        const institution: InstitutionGroup = {
                            id: parseInt(entity.id.toString()),
                            institutiongroupname: entity.get('label'),
                            address: this.getSubEntity('address', entity.get('address')),
                            city: this.getSubEntity('city', entity.get('address')),
                            postalCode: this.getSubEntity('postalCode', entity.get('address')),
                            country: this.getSubEntity('country', entity.get('address')),
                            locked:  entity.get('locked')
                        };

                        this.institutionsInUserDataCollection[entity.id] = entity;

                        // used to show all institution entities in User DATA.
                        this.institutionInUserData.push(institution);

                        // used to show all institution entities in User DATA FORMATED.
                        this.institutionsInUserDataFormated.push(this.getAllFields(institution));
                    }

                    this.communicationCenter
                        .getRoom('groups-management')
                        .next('institutionList', this.institutionInUserData);

                    this.communicationCenter
                        .getRoom('groups-management')
                        .next('institutionListFiltered', this.institutionsInUserDataFormated);

                    this.onInstitutionGroupsChanged.next(this.institutionInUserData);

                }
            });


    }

    getPostalCodeCollection() {
        return this.octopusConnect.loadCollection('variables/postalCodes');

    }

    getInstitutionGroupInUserFromServer(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('institutions');
    }

    getMetadata(): any {

        return {
            postalCodes: this.postalCodes ? this.postalCodes : [],
            country: [],
        };
    }

    launchSearch(callback: any, optionsInterface: CollectionOptionsInterface): void {
        this.refreshListCallback = callback;
        for (const field in optionsInterface) {
            this.optionsInterface[field] = optionsInterface[field];
        }

        if (!this.institutionGroupsSubscription) {
            this.institutionGroupsSubscription = this.refreshList(optionsInterface);
        } else {
            this.institutionGroupsSubscription.unsubscribe();
            this.institutionGroupsSubscription = this.refreshList(optionsInterface);

            // todo fix reload until paginator function work
            /*
            this.paginatedCollection.paginator.page = optionsInterface.page;
			this.paginatedCollection.paginator.filter = optionsInterface.filter;
			*/
        }
    }

    refreshList(optionsInterface): Subscription {

        return this.getInstitutionGroup(optionsInterface)
            .subscribe(data => {

                this.allInstitutions = [];
                this.allInstitutionsFormated = [];
                this.institutionGroupsCollection = {};

                if (!data) return;

                for (const entity of data.entities) {
                    const institution: InstitutionGroup = {
                        id: parseInt(entity.id.toString()),
                        institutiongroupname: entity.get('label'),
                        address: entity.get('address'),
                        city: entity.get('city'),
                        postalCode: entity.get('postalCode'),
                        country: entity.get('country'),
                        locked: entity.get('locked')
                    };

                    this.institutionGroupsCollection[entity.id] = entity;

                    // used to show all institution entities.
                    this.allInstitutions.push(institution);

                    // used to show all institution entities FORMATED.
                    this.allInstitutionsFormated.push(this.getAllFields(institution));
                }

                if (this.refreshListCallback) {
                    this.refreshListCallback();
                }
            });
    }


    addInstitutionGroup(group): Observable<DataEntity> {
        const groups = [];
        let selectedInstitution;

        if (group && group.institution) {
            const id = group.institution.id;
            selectedInstitution = null;
            if (this.userData.get('groups').indexOf(id) === -1) {
                groups.push(id);

            }
        }

        groups.push(...this.userData.get('groups'));
        this.userData.set('groups', groups);

        const obs = this.userData.save();
        obs.subscribe((data) => {
            this.communicationCenter.getRoom('userProfileEdit')
                .getSubject('userData')
                .next(data);
        });

        return obs;
    }


    saveInstitutionGroup(group) {
    }

    deleteInstitutionGroup(group) {

        const groups = [];
        let index = null;

        groups.push(...this.userData.get('groups'));
        index = this.userData.get('groups').indexOf(group.id.toString());

        if (index !== -1) {
            groups.splice(index, 1);

            this.userData.set('groups', groups);
            this.userData.save().subscribe((data) => {
                this.communicationCenter.getRoom('userProfileEdit')
                    .getSubject('userData')
                    .next(data);
            });
        }

    }

    getSubEntity(field, data) {
        return data ? data[field] : '';
    }


    getAllFields(institution: InstitutionGroup) {
        const filteredInstitution = {};
        let stringToFilter: string = '';

        stringToFilter += ' ' + institution['institutiongroupname'];
        stringToFilter += ' (' + institution['address'];
        stringToFilter += ' ' + institution['city'];
        stringToFilter += ' ' + institution['postalCode'];
        stringToFilter += ' ' + institution['country'] + ')';

        filteredInstitution['id'] = institution['id'].toString();
        filteredInstitution['name'] = institution['institutiongroupname'];
        filteredInstitution['field'] = stringToFilter;

        return filteredInstitution;
    }

    public get groups(): InstitutionGroup[] {
        return this.allInstitutions.slice();
    }

    resetOptionInterfaces() {
        this.optionsInterface = {
            filter: {},
            page: 1,
            range: 10
        };

        this.launchSearch(this.unsubscribeAll, this.optionsInterface);
    }

    unsubscribeAll(): void {
        if (this.institutionGroupsSubscription) {
            this.institutionGroupsSubscription.unsubscribe();
            this.institutionGroupsSubscription = null;
        }
        if (this.institutionInUserSubscription) {
            this.institutionInUserSubscription.unsubscribe();
            this.institutionInUserSubscription = null;
        }
    }

}
