import { Injectable } from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';

const settingsStructure: ModelSchema = new ModelSchema({
    alwaysShowDashboard: Structures.boolean(false),
    displayHeaderLink: Structures.boolean(true),
    displayTabsPage: Structures.boolean(false),
    displayedWidgets: Structures.object(),
    filtersByRole: Structures.object({
        default: []
    }),
    redirectUrlBasicPage: Structures.string()
});

@Injectable()
export class DashboardService {

    private components: any[] = [];
    public settings: {[key: string]: any};

    constructor(
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.settings = settingsStructure.filterModel(modulesSettings.dashboard);
    }

    private postLogout(): void{

    }

    private postAuthentication(): void {

    }

    register(component: string, callback: () => any): void {
        this.components = this.components.filter((element) => element.name !== component);
        this.components.push({
            name: component,
            getData: callback
        });
    }

    get widgets(): any[] {
        const data = [];

        this.components.forEach((element: any) => {
            data.push({
                name: element.name,
                data: element.getData()
            });
        });

        return data;
    }

    public get dashboardSettings (): any {
        return this.settings;
    }
}
