export const authentication = {
    enableSSO: true,
    displayLoginLogo: true,
    logoutUrlSSO: {
        fr: 'https://api.digiportail.be/v1/invalidate-browser-session?external_token=',
        nl: 'https://api.knooppunt.net/v1/invalidate-browser-session?external_token=',
        en: 'https://api.knooppunt.net/v1/invalidate-browser-session?external_token='
    },
    urlSSO: {
        fr: 'https://api.digiportail.be/v1/oauth/knp_authorize?client_id=tralalere.averbode.KNP30&response_type=code&scope=full&redirect_uri=',
        nl: 'https://api.knooppunt.net/v1/oauth/knp_authorize?client_id=tralalere.averbode.KNP30&response_type=code&scope=full&redirect_uri=',
        en: 'https://api.knooppunt.net/v1/oauth/knp_authorize?client_id=tralalere.averbode.KNP30&response_type=code&scope=full&redirect_uri='
    },
};