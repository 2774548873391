import { Injectable } from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {Group, Learner, Workgroup} from '@modules/groups-management/core/definitions';
import {Observable} from 'rxjs/Observable';

const settingsStructure: ModelSchema = new ModelSchema({
    groupType: Structures.number(2),
    workgroupType: Structures.number(3),
    institutionGroupType: Structures.number(52),
    primaryColors: Structures.array(['#D4E157', '#FFD740', '#F4511E', '#EC407A', '#4A148C', '#26C6DA']),
    accessGroupsTrainer: Structures.array(),
    accessGroupsManager: Structures.array(),
    canActiveMetacognition: Structures.object(),
    canBeDelete: Structures.object(),
    canBeJoined: Structures.object({}),
    displayedColumns: Structures.array(['checkbox', 'avatar', 'trainer-name', 'email', 'type', 'access', 'buttons']),
    displayFilters: Structures.boolean(true),
    displayHeader: Structures.boolean(true),
    gettingStarted: Structures.object({}),
    group: Structures.object({columns: []}),
    trainer: Structures.object({
        columns: {
            default: ['checkbox', 'avatar', 'trainer-name', 'email', 'type', 'access', 'buttons']
        },
        fields: {
            default: ['avatar', 'username', 'password', 'email']
        }
    }),
    graph: Structures.object()
});

const settingsAccessFeature: ModelSchema = new ModelSchema({
    institutionsGroupAccess: Structures.boolean(),
});

@Injectable()
export class GroupsManagementService {
    public settings: { [key: string]: any };
    public settingsAccess: { [key: string]: any };
    private alias: string;
    public learnerList: Learner[];
    public groupList: Group[];
    headerTitle = 'navigation.groups-management';
    public workgroupList: Workgroup[];
    public activitiesTypes: any;
    public activities: any[] = ['individuel', 'binôme', 'collectif', 'collaboratif'];

    constructor(
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.settings = settingsStructure.filterModel(modulesSettings.groupsManagement);
        this.settingsAccess = settingsAccessFeature.filterModel(modulesSettings.featureAccess);
    }

    private postLogout(): void{

    }

    private postAuthentication(): void {
        this.communicationCenter
            .getRoom('activities')
            .getSubject('activitiesType')
            .subscribe((data) => {
                this.activitiesTypes = data;
            });
        const learners = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList');
        const groups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');
        const workgroups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');

        learners.combineLatest(groups, workgroups).subscribe((data: [any, any, any]) => {
            this.learnerList = data[0];
            this.groupList = data[1].filter((group) => !group.archived);
            this.workgroupList = data[2].filter((group) => !group.archived);
        });
    }

    loadActivityTypes(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('variables/instance').take(1);
    }

    public loadPaginatedGraphDatas(type, filterOptions = {}): Observable<Object[]> {
        const assignmentGroupListPaginated = this.octopusConnect.paginatedLoadCollection(type, filterOptions);
        const assignmentsGroupListPaginatedObs = assignmentGroupListPaginated.collectionObservable.map(collection => collection.entities);

        return assignmentsGroupListPaginatedObs;
    }

    public setHeaderTitle(title): void {
        this.headerTitle = title;
    }

    public getHeaderTitle(): string {
        return this.headerTitle;
    }

    public set gettingStarted(alias: string) {
        this.alias = alias;
    }

    public get gettingStarted(): string {
        return this.alias;
    }
}
