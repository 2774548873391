import {DataEntity} from 'octopus-connect/src/data-structures/data-entity.class';
import {Observable} from 'rxjs/Observable';
import {CorpusService} from '@modules/corpus';
import {CorpusRessource} from "@modules/corpus/core/corpus-ressource.class";

export class Corpus {
  public id: number;
  public name: string;

  constructor(
    private corpusService: CorpusService,
    private corpusEntity: DataEntity
  ) {
    this.id = +corpusEntity.id;
    this.name = corpusEntity.get('name');
  }

  public getCorpusResources(): Observable<CorpusRessource[]> {
    return this.corpusService.loadCorpusRessources(this.id.toString());
  }

  remove() {
    this.corpusEntity.remove();
  }
}
