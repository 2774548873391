import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Draft} from '@modules/research-sheet/core/section-draft-display/draft.model';
import {ResearchSectionTemplate, ResearchTemplate} from '@modules/research-sheet/core/definitions';
import {SectionDraftCreationModalComponent} from '@modules/research-sheet/core/section-draft-display/section-draft-creation-modal/section-draft-creation-modal.component';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange} from '@angular/material';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import {Router} from '@angular/router';
import {DataEntity} from 'octopus-connect';

@Component({
    selector: 'app-rename-template-modal',
    templateUrl: './rename-template-modal.component.html',
    styleUrls: ['./rename-template-modal.component.scss']
})
export class RenameTemplateModalComponent implements OnInit {

    projectForm: FormGroup;
    name: string;

    constructor(
        public dialogRef: MatDialogRef<RenameTemplateModalComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public template: DataEntity,
        private sheetService: ResearchSheetService,
        private router: Router
    ) { }

    ngOnInit() {
        this.name = this.template.get('name');
        this.projectForm = this.formBuilder.group({
            name: [this.name]
        });
    }

    save() {
        this.template.set('name', this.name);
        this.template.save();
        this.dialogRef.close();
    }
}
