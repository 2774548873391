import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { GroupsListingService } from './groups-listing.service';
import { fuseAnimations } from 'fuse-core/animations';
import { FormControl } from '@angular/forms';
import { FuseGroupsFormDialogComponent } from './groups-form/groups-form.component';
import { MatDialog } from '@angular/material';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { Subscription } from 'rxjs/Subscription';
import {DataSource} from '@angular/cdk/table';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';

@Component({
    selector     : 'module-groups-listing',
    templateUrl  : './groups-listing.component.html',
    styleUrls    : ['./groups-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class GroupsListingComponent implements OnInit, OnDestroy
{
    @Input('displayedColumns') displayedColumns: string[];
    @Input('dataSource') dataSource: DataSource<any>;
    @Input('newEntity') addEntity: {data: any, callback: (any) => {}};
    @Input('editEntity') editEntity: {data: any, callback: (any) => {}};
    @Input('archiveEntity') archiveEntity: {data: any, callback: (any) => {}};
    @Input('deArchiveEntity') deArchiveEntity: {data: any, callback: (any) => {}};
    @Input('activateMetacognitionEntity') activateMetacognitionEntity: {data: any, callback: (any) => {}};
    @Input('deactivateMetacognitionEntity') deactivateMetacognitionEntity: {data: any, callback: (any) => {}};
    @Input('deleteEntity') deleteEntity: {data: any, callback: (any) => {}};
    @Input('customActions') customActions: {data: any, callback: (any) => {}};

    hasSelectedEntity: boolean;
    searchInput: FormControl;
    dialogRef: any;
    onSelectedEntityChangedSubscription: Subscription;

    selectedIcon = 'student_add';

    isStudent: boolean;

    constructor(
        private groupsService: GroupsListingService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private authService: AuthenticationService
    ) {


        this.searchInput = new FormControl('');
    }

    newEntity(): void {
        this.dialogRef = this.dialog.open(FuseGroupsFormDialogComponent, {
            panelClass: 'entity-form-dialog',
            data      : this.addEntity
        });

        this.dialogRef.afterClosed();
    }

    ngOnInit(): any {
        if (this.authService.hasLevel(['learner'])){
            this.isStudent = true;
        }
        this.route.data.subscribe(data => {
            this.selectedIcon = data['icon'];
        });

        this.onSelectedEntityChangedSubscription =
            this.groupsService.onSelectedEntityChanged
                .subscribe(selectedEntity => {
                    this.hasSelectedEntity = selectedEntity.length > 0;
                });

        this.searchInput.valueChanges
            .debounceTime(300)
            .distinctUntilChanged()
            .subscribe(searchText => {
                this.groupsService.onSearchTextChanged.next(searchText);
            });

    }

    ngOnDestroy(): any {
        this.onSelectedEntityChangedSubscription.unsubscribe();
    }
}
