import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs/Observable';
import {AccountManagementProviderService} from '../../../account-management/core/account-management-provider.service';
import {ProfileService} from '../../../account-management/core/profile/profile.service';
import {Subject} from "rxjs";
import {AuthenticationService} from '../authentication.service';

@Component({
    selector: 'fuse-reset-password',
    templateUrl: './reset-password.component.html'
})
export class FuseResetPasswordComponent implements OnInit, OnDestroy {
    token: string;
    private unsubscribeInTakeUntil = new Subject();

    constructor( private connector: OctopusConnectService, private route: ActivatedRoute, public router: Router,
                 private usersProvider: AccountManagementProviderService,
                 private profileProvider: ProfileService,
                 private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(( value: Object ) => {
            this.token = value['token'];
            this.setToken(this.token);

        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    setToken(token): void {
        let myDate = new Date();
        let timestampDate;
        myDate.setHours(myDate.getHours() + 24);
        timestampDate = Math.floor(new Date(myDate).getTime());

        localStorage.setItem('http_accessToken', JSON.stringify(token));
        localStorage.setItem('http_expires_in',  JSON.stringify(timestampDate));
        localStorage.setItem('http_currentUser', JSON.stringify({}));

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((userData: DataEntity) => {
                this.authenticationService.onAuthenticated(userData);
                this.router.navigate(['/profile']);
            }, () => {
                console.log('error');
            });
    }
}
