import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ResearchSheetService} from '@modules/research-sheet/core/research-sheet.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ResearchSection, ResearchSheet} from '@modules/research-sheet/core/definitions';
import {localizedDate} from '../../../../shared/utils';
import {CorpusService, ExternalCorpusDisplayComponent} from '@modules/corpus';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {ReplaySubject} from 'rxjs/ReplaySubject';



@Injectable()
export class SectionDisplayService {

  sectionTextReady:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  sectionCommentReady:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  sectionExistOrNot:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  popUpEditComment:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  sectionMedias:  BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  access:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  sectionLockSave:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  settings: {[key: string]: any};
  sectionDefinition: DataEntity;

  linkedResources: CorpusRessource[] = [];

  corpus: ExternalCorpusDisplayComponent;



  constructor(public researchSheetService: ResearchSheetService, private octopusConnect: OctopusConnectService, public corpusService: CorpusService ) {
    this.settings = this.researchSheetService.settings;
  }

  getDataForm(dataForm: any, section: ResearchSection){
    const commentArray: Array<any> = [];

    for (const name in dataForm) {
      if (dataForm.hasOwnProperty(name)){
        const field = name.toString();
        if (name !== 'comments'){
          section[field] = dataForm[name];
        }
      }
    }

    if (this.sectionExistOrNot.value){
      section.medias = this.sectionMedias.value;

      const comments = section.comments === null ? [] : section.comments;

      commentArray.push(...comments.map(comment => comment.id));
      section.comments = commentArray;

      if (dataForm.comments){
        this.createComment(commentArray, section, dataForm);
      } else{
        this.researchSheetService.saveSection(section);
      }

    } else{
      if (dataForm.comments){
        this.createComment(commentArray, section, dataForm);
      } else{
        this.researchSheetService.addSection(section);
      }
    }

  }

  createComment(commentArray, section, dataForm){

    const date = new Date();
    const timestamp = Math.round(date.getTime() / 1000);
    this.octopusConnect.createEntity('comments', {comment: dataForm.comments, comment_date: timestamp}).subscribe((commentEntity) => {

      this.researchSheetService.sendResearchSheetTeacherCommentNotification(section);

      console.log("ADD COMMENT", section, commentEntity);

      section.comments.push(commentEntity.id);
      if (this.sectionExistOrNot.value){
        this.researchSheetService.saveSection(section);
      } else{
        this.researchSheetService.addSection(section);
      }
    });
  }

  getSectionTextReady(){
    this.sectionTextReady.next(true);
  }

  getSectionCommentReady(){
    this.sectionCommentReady.next(true);
  }

  sectionNotReady(){
    this.sectionTextReady.next(false);
    this.sectionCommentReady.next(false);
  }

  public lockSave(): void {
      this.sectionLockSave.next(true);
  }

  public unlockSave(): void {
      this.sectionLockSave.next(false);
  }

  showComment(condition: boolean){
    this.popUpEditComment.next(condition);
  }


  localeDate(date) {
    return localizedDate(date);
  }

  setMedias(mediaIds){
    this.sectionMedias.next(mediaIds);
  }
}
