import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import {LicensingService} from '../licensing.service';

@Component({
  selector: 'app-licensing-display',
  templateUrl: './licensing-display.component.html',
  styleUrls: ['./licensing-display.component.scss']
})
export class LicensingDisplayComponent implements OnInit {
    public id: string;
    constructor(public authenticateService: AuthenticationService,
                private licensingService: LicensingService) {}

    ngOnInit() {
        this.id = this.authenticateService.userData.id;
    }

    syncSSOLicenses (): void {
        this.licensingService.syncSSOMethod('sso').take(1);
    }

}
