import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DataEntity} from "octopus-connect";
import {LessonsService} from "@modules/activities/core/lessons/lessons.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivitiesService} from '../../activities.service';
import {Subject} from "rxjs";

@Component({
  selector: 'app-short-answer-editor',
  templateUrl: './short-answer-editor.component.html',
  styleUrls: ['./short-answer-editor.component.scss']
})
export class ShortAnswerEditorComponent implements OnInit, OnDestroy {
    private activity: DataEntity;
    private metadatas: DataEntity;
    public entity: any;
    public entityForm: FormGroup;
    public myFocusTriggeringEventEmitter = new EventEmitter<string>();
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private lessonsService: LessonsService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private activitiesService: ActivitiesService,
    ) {
        const granule = this.lessonsService.getCurrentActivity();


        this.entity = {
            wording: '',
            answers: ['']
        };

        if (granule) {
            this.activity = <DataEntity>granule.getEmbed('reference');
            this.metadatas = <DataEntity>granule.getEmbed('metadatas');

            this.entity.wording = this.activity.get('instruction');
            this.activity.get('activity_content')
                .answers.forEach((answer) => {
                this.entity.answers.push(answer.answer);
            });
        }

        this.entityForm = this.createEntityForm();
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.lessonsService.clearCurrentActivity();
    }

    createEntityForm(): FormGroup {
        const config: any = {};

        config.wording = [this.entity.wording];

        this.entity.answers.forEach((answer, index) => {
            config[`answers${index}`] = [answer];
        });

        return this.formBuilder.group(config);
    }

    saveActivity() {
        const answers = [];
        this.entity.answers.forEach((answer, index) => {
            answers.push(this.entityForm.value[`answers${index}`]);
        });

        if (this.activity && this.metadatas) {
            this.lessonsService.saveActivityQcm({
                wording: this.entityForm.value.wording,
                answers: answers,
                removeAnswers: []
            })
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((entities) => {
                    this.exit();
                });
        } else {
            this.lessonsService.createActivityQcm('CRT', {
                wording: this.entityForm.value.wording,
                answers: answers
            })
                .takeUntil(this.unsubscribeInTakeUntil)
                .subscribe((entity: DataEntity) => {
                    this.lessonsService.addActivityToForm(entity);
                    this.exit();
                });
        }
    }

    exit() {
        this.router.navigate(['../..'], {relativeTo: this.route});
    }

}
