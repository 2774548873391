import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/Observable';
import {GroupService} from '@modules/groups-management/core/group/group.service';
import {FormGroup} from '@angular/forms';
import {DataSource} from '@angular/cdk/collections';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {AuthenticationService} from '@modules/authentication';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {DataEntity} from 'octopus-connect';


interface Institution {
    field: string;
    name: string;
    id: string;
}

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})

export class GroupComponent implements OnInit, OnDestroy {
    public displayedColumns = ['color', 'groupname', 'level', 'code'];
    public dataSource: GroupDataSource | null;

    groupColors = this.groupsManagementService.settings.primaryColors;

    private noParentObject: Institution;

    public newGroup: any = {
        data: {
            action: 'new',
            title: 'add_class',
            fields: ['color', 'groupname', 'level'],
            colors: this.getColor(),
            selects: {
                class: () => this.groupService.getGroups()
            }
        },
        callback: (response) => this.newGroupCallback(response)
    };
    public editGroup: any = {
        data: {
            action: 'edit',
            title: 'edit_class',
            fields: ['id', 'color', 'groupname', 'level'],
            colors: this.getColor(),
            selects: {
                class: () => this.groupService.getGroups()
            }
        },
        callback: (response) => this.editGroupCallback(response)
    };
    public archiveGroup: any = {
        data: {
            titleDialog: 'generic.archive',
            bodyDialog: 'groups-management.sure_archive_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.archiveGroupCallback(group),
        archiveList: (list) => this.archiveListCallback(list)
    };

    public deArchiveGroup: any = {
        data: {
            titleDialog: 'generic.archive',
            bodyDialog: 'groups-management.sure_archive_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deArchiveGroupCallback(group),
        dearchiveList: (list) => this.dearchiveListCallback(list)
    };

    public activateMetacognitionGroup: any = {
        data: {
            titleDialog: 'generic.metacognition',
            bodyDialog: 'groups-management.sure_activate_metacognition_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.activateMetacognitionGroupCallback(group),
        activateMetacognitionList: (list) => this.activateMetacognitionListCallback(list)
    };

    public deactivateMetacognitionGroup: any = {
        data: {
            titleDialog: 'generic.metacognition',
            bodyDialog: 'groups-management.sure_deactivate_metacognition_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deactivateMetacognitionGroupCallback(group),
        deactivateMetacognitionList: (list) => this.deactivateMetacognitionListCallback(list)
    };

    public deleteGroup: any = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_class',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (group) => this.deleteGroupCallback(group),
        deleteList: (list) => this.deleteListCallback(list)

    };

    constructor(
        private groupService: GroupService,
        private translate: TranslateService,
        private groupsManagementService: GroupsManagementService,
        private authService: AuthenticationService
    ) {
        this.displayedColumns = this.groupsManagementService.settings.group.columns[this.authService.accessLevel] ?
            this.groupsManagementService.settings.group.columns[this.authService.accessLevel] : this.groupsManagementService.settings.group.columns['default'];
        if (this.groupsManagementService.settingsAccess.institutionsGroupAccess) {
            if (!this.displayedColumns.includes('institutions')){
                this.addInstitutionsColumn();
            }
            this.newGroup.data.fields.push('parent');
            this.editGroup.data.fields.push('parent');

            this.translate.get('groups-management.no_institution').subscribe((res: string) => {
                this.noParentObject = {name: res, field: res, id: null};
            });

            this.newGroup.data.selects.parent = () => {
                return this.getGroupParents();
            };
            this.editGroup.data.selects.parent = () => {
                return this.getGroupParents();
            };
        }
    }

    private addInstitutionsColumn() {
        if (this.displayedColumns.includes('buttons')) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('buttons'), 0, 'institutions');
        } else {
            this.displayedColumns.push('institutions');
        }
    }

    private getGroupParents(): object[] {
        const parents = this.groupService.getParents();
        if (!parents.find(parent => parent['id'] === this.noParentObject.id)) {
            parents.unshift(this.noParentObject);
        }
        return parents;
    }

    ngOnInit(): any {
        this.dataSource = new GroupDataSource(this.groupService);
        this.groupsManagementService.setHeaderTitle('groups-management.classes');
        this.groupsManagementService.gettingStarted = this.groupsManagementService.settings.gettingStarted.groups;
    }

    ngOnDestroy(): void {
        this.groupsManagementService.gettingStarted = '';
    }

    public newGroupCallback(response: FormGroup): Observable<DataEntity> {
        if (response) {
            return this.groupService.addGroup(response.getRawValue());
        }
    }

    public editGroupCallback(response: FormGroup): Observable<DataEntity>{
        if (response) {
            return this.groupService.saveGroup(response.getRawValue());
        }
    }

    public archiveGroupCallback(group: any): void {
        group.archived = true;
        this.groupService.saveGroup(group);
    }

    public deArchiveGroupCallback(group: any): void {
        group.archived = false;
        this.groupService.saveGroup(group);
    }

    public activateMetacognitionGroupCallback(group: any): void {
        group.metacognitionActive = true;
        this.groupService.saveGroup(group);
    }

    public deactivateMetacognitionGroupCallback(group: any): void {
        group.metacognitionActive = false;
        this.groupService.saveGroup(group);
    }

    public deleteGroupCallback(group: any): void {
        this.groupService.deleteGroup(group);
    }

    public archiveListCallback(groups: any): void {
        for (const group of groups) {
            group.archived = true;
            this.groupService.saveGroup(group);
        }
    }

    public dearchiveListCallback(groups: any): void {
        for (const group of groups) {
            group.archived = false;
            this.groupService.saveGroup(group);
        }
    }

    public activateMetacognitionListCallback(groups: any): void {
        for (const group of groups) {
            group.metacognitionActive = true;
            this.groupService.saveGroup(group);
        }
    }

    public deactivateMetacognitionListCallback(groups: any): void {
        for (const group of groups) {
            group.metacognitionActive = false;
            this.groupService.saveGroup(group);
        }
    }

    public deleteListCallback(groups: any): void {
        for (const group of groups) {
            this.groupService.deleteGroup(group);
        }
    }

    getColor(): string[] {
        return this.groupColors;
    }

}

export class GroupDataSource extends DataSource<any> {
    data: BehaviorSubject<any>;

    constructor(public groupsService: GroupService) {
        super();
        this.data = new BehaviorSubject<any>(this.groupsService.groups);

        this.groupsService.onGroupsChanged.subscribe((data) => {
            this.data.next(data);
        });
    }

    get groupService(): any {
        return this.groupsService;
    }

    connect(): Observable<any[]> {
        return this.groupService.onGroupsChanged;
    }

    disconnect(): any {}
}
