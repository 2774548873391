import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subscription} from 'rxjs/Subscription';

@Injectable()
export class ChaptersService {

    private userData: DataEntity;
    public chapters: Array<object> = [];
    public chaptersSubscription: Subscription = null;
    public chaptersChanged: BehaviorSubject<any> = new BehaviorSubject([]);



    constructor(private octopusConnect: OctopusConnectService,
                private communicationCenter: CommunicationCenterService) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                } else {
                    this.postLogout();
                }
            });
    }

    private postLogout(): void {
        if (this.chaptersSubscription) {
            this.unsubscribeAll();
        }
    }

    getMethods() {
        return this.octopusConnect.loadCollection('chapters', {parent: 'null'}).take(1);
    }

    getChapters(methodId) {
        this.octopusConnect.loadCollection('chapters', {parent: methodId}).take(1).subscribe(data => {
            this.chapters = [];
            for (const entity of data.entities) {
                this.chapters.push({
                    id : entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                    parent: entity.get('parent')[0]
                });
            }
            this.chaptersChanged.next(this.chapters);
        });
    }

    unsubscribeAll(): void {
        this.chaptersSubscription.unsubscribe();
    }

}
