import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";

import {AuthenticationService} from './authentication.service';

import {OctopusConnectModule} from 'octopus-connect';
import {FuseSharedModule} from "@fuse/shared.module";

import {FuseForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {FuseResetPasswordComponent} from './reset-password/reset-password.component';
import {FuseLoginComponent} from "./login/login.component";
import {FusePasswordResetEmailComponent} from '@modules/authentication/core/password-reset-email/password-reset-email.component';
import {SharedModule} from "../../../shared/shared.module";
import {IsUserAnonymous} from '../../../is-user-anonymous.class';

const routes = [
  {
    path     : 'login',
    component: FuseLoginComponent,
    canActivate: [IsUserAnonymous]
  },
  {
    path     : 'forgot-password',
    component: FuseForgotPasswordComponent
  },
  {
    path     : 'user/reset/:token',
    component: FuseResetPasswordComponent
  },
  {
        path     : 'password-reset-email',
        component: FusePasswordResetEmailComponent
  }
];

@NgModule({
    imports: [
        FuseSharedModule,
        SharedModule,
        //OctopusConnectModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        FuseLoginComponent,
        FuseForgotPasswordComponent,
        FuseResetPasswordComponent,
        FusePasswordResetEmailComponent
    ],
    providers: [

    ]
})
export class AuthenticationModule {

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: AuthenticationModule,
            providers: [
                AuthenticationService
            ]
        };
    }
}
