export const groupsManagement = {
    accessGroupsManager: ['manager', 'administrator'],
    authorizedRoles: ['manager', 'administrator'],
    canBeDelete: {
        workgroup: false,
        institution: false,
        learner: true,
        learners: true,
    },
    // TODO check access for groups/institutions
    group: {
        columns: {
            trainer: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            administrator: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            manager: ['checkbox', 'color', 'groupname', 'learners', 'level', 'code', 'buttons'],
            default: ['color', 'groupname', 'level', 'code']
        }
    },
    trainer: {
        columns: {
            trainer: ['checkbox', 'avatar', 'trainer-name', 'email', 'access', 'buttons'],
            administrator: ['checkbox', 'avatar', 'trainer-name', 'email', 'access', 'buttons'],
            manager: ['checkbox', 'avatar', 'trainer-name', 'email', 'access', 'buttons'],
            default: ['checkbox', 'avatar', 'trainer-name', 'email', 'type', 'access', 'buttons']
        },
        fields: {
            trainer: ['avatar', 'username', 'password', 'passwordConfirm', 'email'],
            administrator: ['avatar', 'username', 'password', 'passwordConfirm', 'email'],
            manager: ['avatar', 'username', 'password', 'passwordConfirm', 'email'],
            default: ['avatar', 'username', 'password', 'passwordConfirm', 'email']
        }
    }
};
