import {Component, OnInit, ElementRef, ViewChild, EventEmitter} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {Group} from '@modules/groups-management/core/definitions';
import {AgendaService} from '../agenda.service';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Input} from '@angular/core';
import {Output} from '@angular/core';

@Component({
  selector: 'app-members-selection',
  templateUrl: './members-selection.component.html',
  styleUrls: ['./members-selection.component.scss']
})
export class MembersSelectionComponent implements OnInit {

  public dialogTitle: string;
  public entityForm: FormGroup;
  public entity: any;
  public allTypes: any[] = [];

  private filterGroupActive = '';
  private filterWorkgroupActive = '';

  groupsChips: string[] = [];
  groupsCtrl = new FormControl();
  groupsAddOnBlur = false;
  groupsSeparatorKeysCodes = [ENTER, COMMA];
  groupsSelectable = true;
  groupsRemovable = true;
  groupsFilteredChips: Observable<any[]>;
  groupsAllChips = [];

  workgroupsChips = []; // array of workgroup Chips selected
  workgroupsCtrl = new FormControl();
  workgroupsAddOnBlur = false;
  workgroupsSeparatorKeysCodes = [ENTER, COMMA];
  workgroupsSelectable = true;
  workgroupsRemovable = true;
  workgroupsFilteredChips: Observable<any[]>;

  workgroupsAllChips = [];

  learnersChips = []; // array of workgroup Chips selected
  learnersCtrl = new FormControl();
  learnersAddOnBlur = false;
  learnersSeparatorKeysCodes = [ENTER, COMMA];
  learnersSelectable = true;
  learnersRemovable = true;
  learnersFilteredChips: Observable<any[]>;
  learnersAllChips = [];

  learnersList = [];

  translatedTermAll: string;

  initialDate: any;

  @ViewChild('groupsChipInput') groupsChipInput: ElementRef;
  @ViewChild('workgroupsChipInput') workgroupsChipInput: ElementRef;
  @ViewChild('learnersChipInput') learnersChipInput: ElementRef;

  @Input()
  displayAsFilter: boolean = false;

  @Input()
  set selectedLearners(value: Array<number | string>) {
    if (value) {
      const lArray = [];
      value.forEach(id => {
        const foundLearner = this.agendaService.learnersList.find(l => l.id.toString() === id.toString());
        if (foundLearner) {
          lArray.push(foundLearner);
        }
      });
      const currentL = (this.entityForm.get('learners').value as Array<any> || []).map(l => l.id);
      const newL = lArray.map(l => l.id);
      const difference = currentL.filter(x => !newL.includes(x))
        .concat(newL.filter(x => !currentL.includes(x)));
      if (difference.length > 0) {
        lArray.forEach(l => {
          this.chipSelected({option: {value: l.username, viewValue: l.username}} as any, 'learner');
        });

      }
    }
  }
  @Output()
  public selectedLearnersChange = new EventEmitter<Array<number>>();

  @Input()
  readonly = false;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private agendaService: AgendaService
  ) {

    this.entity = {};

    const formFields = ['learners'];
    this.entityForm = this.createEntityForm(formFields);

    this.entityForm.valueChanges.subscribe(() => {
    });
  }

  private createEntityForm(fields: string[]): FormGroup {
    const config = {};
    fields.forEach((field: string) => {
      config[field] = [this.entity[field]];
    });

    return this.formBuilder.group(config);
  }

  ngOnInit(): void {
    this.translate.get('generic.all').subscribe((translation: string) => this.translatedTermAll = translation);
    this.translate.onLangChange.subscribe((data) => {
      this.translatedTermAll = data.translations.generic.all;
    });

    this.agendaService.groupsReady.subscribe(groupsReady => {
      this.learnersAllChips = this.learners;
      this.groupsAllChips = this.groups;
      this.workgroupsAllChips = this.worksgroups;

      this.learnersFilteredChips = this.learnersCtrl.valueChanges.pipe(
        startWith(null),
        map((chip: string | null) => chip ? this.filter(chip, 'learner') : this.learnersAllChips.slice()));

      this.groupsFilteredChips = this.groupsCtrl.valueChanges.pipe(
        startWith(null),
        map((chip: string | null) => chip ? this.filter(chip, 'group') : this.groupsAllChips.slice()));


      this.workgroupsFilteredChips = this.workgroupsCtrl.valueChanges.pipe(
        startWith(null),
        map((chip: string | null) => chip ? this.filter(chip, 'workgroup') : this.workgroupsAllChips.slice()));
    });
  }

  /***********  chips *************/

  get learners(): Array<Group> {
    if (this.agendaService.learnersList) {
      return this.agendaService.learnersList;
    }

    return [];
  }

  get groups(): Array<Group> {
    if (this.agendaService.groupsList) {
      return this.agendaService.groupsList;
    }

    return [];
  }

  get worksgroups(): Array<Group> {
    if (this.agendaService.workgroupsList) {
      return this.agendaService.workgroupsList;
    }

    return [];
  }

  filter(name: string, type: string): Array<string> {
    const allChips = type + 'sAllChips';
    let chipName = type + 'name';
    if (type === 'learner') {
      chipName = 'username';
    }
    return this[allChips].filter(chip =>
      chip[chipName].toLowerCase().indexOf(name.toLowerCase()) !== -1);
  }

  removeChip(chip: any, type: string): void {
    // remove chip from array of chips (group or workgroup)
    const chips = type + 'sChips';
    const index = this[chips].indexOf(chip);

    if (!chip) {
      this[chips] = [];
    } else {
      if (index >= 0) {
        this[chips].splice(index, 1);
      }
      if (type !== 'learner') {
        if (type === 'workgroup') {
          if (this.groupsChips && this.groupsChips.length) {
            this.filteredLearner(this.groupsChips[0], 'groups');
          }
        } else {
          if (this.workgroupsChips && this.workgroupsChips.length) {
            this.filteredLearner(this.workgroupsChips[0], 'workgroups');
          }
        }

        if (this.groupsChips && !this.groupsChips.length && this.workgroupsChips && !this.workgroupsChips.length) {
          this.resetLearnerList();
        }
      }

      if ((chip === 'All' || chip === this.translatedTermAll) || !this.learnersChips.length) {
        this.learnersList = [];
      }

      this.getFilterFromChip();
    }
    this.emitLearners();
    this.blurAllChipsList();
  }

  blurAllChipsList(): void {
    this.groupsChipInput.nativeElement.blur();
    this.workgroupsChipInput.nativeElement.blur();
    this.learnersChipInput.nativeElement.blur();
  }

  chipSelected(event: MatAutocompleteSelectedEvent, type: string): void {
    const chips = type + 'sChips';
    const input = type + 'sChipInput';
    const chipsCtrl = type + 'sCtrl';
    this[chipsCtrl].setValue(null);
    if (!this.alreadyExisting(event.option.viewValue, chips)) {
      if (type === 'learner') {
        if (this[chips].indexOf('All') !== -1 || this[chips].indexOf(this.translatedTermAll) !== -1) {
          this.removeChip(this.translatedTermAll, 'learner');
        }

        if (this[chips].length && event.option.value === 'all') {
          this.removeChip(null, type);
        }

        if (this.groupsChips && !this.groupsChips.length && this.workgroupsChips && !this.workgroupsChips.length) {
          this.resetLearnerList();
        }

      } else {
        this.removeChip(null, type);
        type = type === 'group' ? 'groups' : 'workgroups';
        this.filteredLearner(event.option.value, type);
      }
      this[chips].push(event.option.viewValue);
      this[input].nativeElement.value = '';
      this.getFilterFromChip();
    }
    this.emitLearners();
    this.blurAllChipsList();
  }

  emitLearners(): void {
    if (this.entityForm.get('learners').value) {
      this.selectedLearnersChange.emit((this.entityForm.get('learners').value || []).map(l => l.id));
    } else {
      this.selectedLearnersChange.emit(undefined);
    }
  }

  filteredLearner(value, type): void {

    this.learnersChips = [this.translatedTermAll];
    this.learnersList = [];
    if (this.groupsCtrl.value && type === 'workgroups') {
      this.learnersList.push(...this.learners.filter((learner) => learner['groups'].indexOf(this.groupsCtrl.value) !== -1));
    }
    this.learnersList.push(...this.learners.filter((learner) => learner[type].indexOf(value) !== -1));

    this.learnersFilteredChips = this.learnersCtrl.valueChanges.pipe(
      startWith(null),
      map((chip: string | null) => chip ? this.filter(chip, 'learner') : this.learnersList.slice()));

  }

  getFilterFromChip(): any {

    let learners = [];

    if (this.learnersChips.length &&
      (this.learnersChips.indexOf('All') === -1 &&
        this.learnersChips.indexOf(this.translatedTermAll) === -1)) {

      for (const learner of this.learnersChips) {
        learners.push(...this.learners
          .filter((item) => item['username'] === learner));

      }
    } else {
      learners = this.learnersList;
    }

    const controlLearners = this.entityForm.get('learners');
    if (learners.length) {
      controlLearners.reset(null);
      controlLearners.setValue(learners);
    } else {
      controlLearners.setValue(null);
      controlLearners.setErrors({learnersArrayEmpty: true});
    }
  }

  alreadyExisting(name: string, type: string): boolean {
    return this[type].indexOf(name) !== -1;
  }

  resetLearnerList(): void {
    this.learnersList = [];
    this.learnersList.push(...this.learners);

    this.learnersFilteredChips = this.learnersCtrl.valueChanges.pipe(
      startWith(null),
      map((chip: string | null) => chip ? this.filter(chip, 'learner') : this.learnersAllChips.slice()));
  }

  public get typeList(): any[] {
    return this.allTypes;
  }

  public localizedType(type: string): string {
    return `assignment.type.${type}`;
  }

  applyFilters(event, type): void {
    switch (type) {
      case 'group':
        this.filterGroupActive = event.value;
        break;
      case 'workgroup':
        this.filterWorkgroupActive = event.value;
        break;
    }

    this.learnersList = this.agendaService.learnersList;

    if (this.filterGroupActive !== '') {
      this.learnersList = this.learnersList.filter((learner) => learner.groups.indexOf(this.filterGroupActive) !== -1);
    }
    if (this.filterWorkgroupActive !== '') {
      this.learnersList = this.learnersList.filter((learner) => learner.workgroups.indexOf(this.filterWorkgroupActive) !== -1);
    }
  }

}
