import { NgModule } from '@angular/core';
import {MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatToolbarModule} from '@angular/material';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {CommonModule} from '@angular/common';
import {ErrorReportingModule} from 'fuse-core/components/error-reporting/error-reporting.module';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        FuseConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        ErrorReportingModule,
        FormsModule,
        MatInputModule
    ],
    entryComponents: [
        FuseConfirmDialogComponent
    ],
})
export class FuseConfirmDialogModule
{
}
