import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataEntity} from 'octopus-connect';

@Component({
    selector     : 'show-profile',
    templateUrl  : './show-profile.component.html',
    styleUrls    : ['./show-profile.component.scss'],
    animations   : fuseAnimations
})
export class ShowProfileComponent implements OnInit
{

    userInformation: DataEntity;

    constructor(private profileProvider: ProfileService)
    {

    }

    ngOnInit()
    {
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            this.userInformation = userInformation;
        });

    }

    getMode(){
        return this.profileProvider.selectedMode;
    }
}
