import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Group, Learner, Workgroup} from '@modules/groups-management/core/definitions';
import {CommunicationCenterService} from '@modules/communication-center';
import {Subscription} from 'rxjs/Subscription';
import {currentTimestamp} from '../../../../shared/utils';

@Injectable()
export class LearnerService {
  private userData: DataEntity;
  private workgroupsList: Workgroup[] = [];
  private groupsList: Group[] = [];
  private learnersCollection: {[key: number]: DataEntity} = {};
  private learnersList: Learner[] = [];
  private learnerSubscription: Subscription = null;
  public onLearnersChanged: BehaviorSubject<any> = new BehaviorSubject([]);
  public settingsLicensing: {[key: string]: any};

  constructor(
    private octopusConnect: OctopusConnectService,
    private communicationCenter: CommunicationCenterService,
  ) {
    this.communicationCenter
      .getRoom('authentication')
      .getSubject('userData')
      .subscribe((data: DataEntity) => {
          if (data) {
              this.userData = data;
              this.postAuthentication();
          } else {
              this.postLogout();
          }
      });

      this.communicationCenter
          .getRoom('groups-management')
          .getSubject('learnersChanged')
          .subscribe((data: any[]) => {
              // data = array of ids learners, workgroupEntity, callback

              for (const learnerId of data[0]) {
                const learnerEntity = this.learnersCollection[learnerId];
                const learnerGroups = [];
                learnerGroups.push(...learnerEntity.get('groups'));
                learnerGroups.splice(learnerGroups.indexOf(data[1].id.toString()), 1);

                learnerEntity.set('groups', learnerGroups);
                learnerEntity.save();
            }

            data[2](data[1]);
          });

    // Send first value empty array to learnerList subject to allow for subscribe to trigger in workgroup.service
    this.communicationCenter
      .getRoom('groups-management')
      .next('learnerList', this.learnersList);
  }

    private postLogout(): void {
      if (this.learnerSubscription) {
          this.learnerSubscription.unsubscribe();
          this.learnerSubscription = null;
      }
    }

    private postAuthentication(): void {
    if (!this.learnerSubscription) {
      const subjectGroupsList = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('groupsList');
      const subjectWorkgroupsList = this.communicationCenter
        .getRoom('groups-management')
        .getSubject('workgroupsList');


        this.communicationCenter.getRoom('licenses')
            .getSubject('settings')
            .take(1)
            .subscribe((data) => {
                this.settingsLicensing = data;
            });

      this.learnerSubscription = this.loadLearners()
        .combineLatest(subjectGroupsList, subjectWorkgroupsList)
        .subscribe((data: [DataCollection, Group[], Workgroup[]]) => {
          this.groupsList = data[1];
          this.workgroupsList = data[2];
          this.learnersList = [];

          for (const entity of data[0].entities) {
            // TODO - Refactor to another function and document it
            // TODO - Limit (work)group name duplicate creation (front + webservice)
            const groupsId = entity.get('groups') || [];
            const groups: string[] = this.groupsList
              .filter((group: Group) => groupsId.indexOf(group.id.toString()) > -1)
              .map((group: Group) => group.groupname);

            const workgroups: string[] = this.workgroupsList
              .filter((workgroup: Workgroup) => groupsId.indexOf(workgroup.id.toString()) > -1)
              .map((workgroup: Workgroup) => workgroup.workgroupname);

            this.learnersList.push({
              id: (entity.id ? parseInt(entity.id.toString()) : -1),
              avatar: entity.get('picture'),
              username: entity.get('label'),
              password: '',
              groups: groups,
              workgroups: workgroups,
              sso: entity.get('sso')
            });

            this.learnersCollection[entity.id] = entity;
          }

          this.communicationCenter
            .getRoom('groups-management')
            .next('learnerList', this.learnersList);

          this.onLearnersChanged.next(this.learnersList);
        });
    }
  }

  getGroups(): string[] {
    return this.groupsList.filter((grp) => !grp.archived).map((group: Group) => group.groupname);
  }

  getWorkgroups(): string[] {
    return this.workgroupsList.filter((grp) => !grp.archived).map((workgroup: Workgroup) => workgroup.workgroupname);
  }

  get userEmail(): string {
    return this.userData.get('contact_email') || this.userData.get('email');
  }

  /**
   *
   * @param learner
   * @returns {number[]}
   */
  groupsListToId(learner): number[] {
    const groups: any[] = [];
    if (learner.groups) {
      groups.push(...this.groupsList
        .filter((group: Group) => learner.groups.indexOf(group.groupname) > -1)
        .map((group: Group) => group.id.toString()));
    }
    if (learner.workgroups) {
      groups.push(...this.workgroupsList
        .filter((workgroup: Workgroup) => learner.workgroups.indexOf(workgroup.workgroupname) > -1)
        .map((workgroup: Workgroup) => workgroup.id.toString()));
    }

    return groups;
  }

  loadLearners(): Observable<DataCollection> {
    return this.octopusConnect.loadCollection('learners');
  }

  addLearner(learner): Observable<DataEntity> {
    return this.octopusConnect
      .createEntity('learners', {
        label: learner.username,
        password: learner.password,
        picture: learner.avatar,
        newsletter: false,
        you_are: 'teen',
        find_us: 'other',
        groups: this.groupsListToId(learner),
        role: 6, // TODO - webservice should set it itself
        contact_email: this.userEmail
      });
  }

  saveLearner(learner): Observable<DataEntity> {
    const learnerEntity = this.learnersCollection[learner.id];

    if (learnerEntity) {
      if (learner.password) {

        // HACK - TODO fix octopus connect in cases where we set a field not present in the dataEntity
        // TODO - Check if hack can be removed
        if (learnerEntity.get('password') === undefined) {
          learnerEntity.set('password', '');
          learnerEntity.save();
        }

        learnerEntity.set('password', learner.password);
        // if we have policy of change password active user will have to change password next time he will be logged
        learnerEntity.set('expirePassword', currentTimestamp());
      }

      const addedTo: string[] = [];
      const removedFrom: string[] = [];

      const newGroupsList: any[] = this.groupsListToId(learner);
      const oldGroupsList: any[] = learnerEntity.get('groups');

      newGroupsList.forEach(group => {
        if (oldGroupsList.indexOf(group) === -1) {
          addedTo.push(group);
        }
      });

      oldGroupsList.forEach(group => {
        if (newGroupsList.indexOf(group) === -1) {
          removedFrom.push(group);
        }
      });

      learnerEntity.set('label', learner.username);
        learnerEntity.set('groups', this.groupsListToId(learner));


      addedTo.forEach(id => {

        const wgroup: Workgroup = this.workgroupsList.find(elem => +elem.id === +id);

        if (wgroup) {

            this.communicationCenter
                .getRoom('notifications')
                .next('sendNotification', {
                    recipient : +learnerEntity.id,
                    type: 'ADDED_IN_GROUP',
                    content: {
                        author: (this.userData ? this.userData.get('label') : ''),
                        group: id,
                        groupName: wgroup.workgroupname
                    }
                });

            // les autres élèves du groupe, sauf l'envoyeur
            const sids: number[] = wgroup.learnersIds.filter(sid => +sid !== +learnerEntity.id);

            this.communicationCenter
                .getRoom('notifications')
                .next('sendNotification', {
                    recipient : sids,
                    type: 'ADDED_IN_YOUR_GROUP',
                    content: {
                        author: (this.userData ? this.userData.get('label') : ''),
                        studentName: learnerEntity.get('label'),
                        groupName: wgroup.workgroupname
                    }
                });
        }
      });


      removedFrom.forEach(id => {

        const wgroup: Workgroup = this.workgroupsList.find(elem => +elem.id === +id);

        if (wgroup) {

            this.communicationCenter
                .getRoom('notifications')
                .next('sendNotification', {
                    recipient : +learnerEntity.id,
                    type: 'REMOVED_FROM_GROUP',
                    content: {
                        author: (this.userData ? this.userData.get('label') : ''),
                        group: id,
                        groupName: wgroup.workgroupname
                    }
                });

        }
      });

      // TODO set other fields
      return learnerEntity.save();
    }
  }

  deleteLearner(learner): void {
    const learnerEntity = this.learnersCollection[learner.id];

    if (learnerEntity) {
      learnerEntity.remove();
    }
  }
}
