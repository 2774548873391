import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appMoveElement]'
})
/**
 * directive for moving position of html élèment in regard of settings
 * take an array of fields, the current fields and a boolean to apply or not moving html élèment.
 * the position of the fields will be the same position as in the array
 * /!\Important: Need to use flex to work beacause use the order flex style to move html élèment./!\
 */
export class MoveElementDirective implements OnInit {

    @Input() fields: string[]; // list of fields ordered
    @Input() field: string; // current field to move at the good place in regard of fields
    @Input() isActive: boolean = false; // active or not the directive

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        if (this.isActive) {
            this.el.nativeElement.setAttribute('style', 'order: ' + this.fields.indexOf(this.field) + 1);
        }
    }
}
