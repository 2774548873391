import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LessonsService} from '../../lessons.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivitiesService} from '../../../activities.service';
import {DragulaService} from 'ng2-dragula';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../../../shared-components/dialog/dialog.component';
import {ISingleActivityNavParams} from '@modules/activities/core/models';
import {MatDialog} from '@angular/material';
import {Subject} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/Subscription';
import {modulesSettings} from 'app/settings';

@Component({
    selector: 'app-lesson-activities',
    templateUrl: './lesson-activities.component.html',
    styleUrls: ['../../../shared-components/activities-shared-styles.scss', './lesson-activities.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        DragulaService
    ]
})
export class LessonActivitiesComponent implements OnInit {
    public selectedLessonContent: DataEntity[] = [];
    public orderedContent: DataEntity[] = [];
    public btnLeave = true;
    public selectedLesson: DataEntity;

    private unsubscribeInTakeUntil = new Subject();
    private dragulaItems = 'itemsBAG';
    private dragulaSubs = new Subscription();
    private corpusService: any;
    private openDividerCreationModal: (DataEntity) => {};

    constructor(
        private octopusConnect: OctopusConnectService,
        public lessonsService: LessonsService,
        public activitiesService: ActivitiesService,
        private dragulaService: DragulaService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
        public translate: TranslateService,
    ) {
        this.dragulaService.createGroup(this.dragulaItems, {});
        this.dragulaSubs.add(dragulaService.out(this.dragulaItems)
            .subscribe(({ el, container }) => {
                this.lessonsService.btnSave = true;
            })
        );

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('corpusService')
            .subscribe((corpusService) => this.corpusService = corpusService);

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('openDividerCreationModal')
            .subscribe((openDividerCreationModal) => this.openDividerCreationModal = openDividerCreationModal);
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.dragulaSubs.unsubscribe();
    }

    ngOnInit(): void {
        if (this.corpusService && !this.corpusService.formats) { // get granule formats
            this.corpusService.loadFormats();
        }

        this.lessonsService.getLessonObs(this.lessonsService.selectedLessonId)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((lesson: DataEntity) => this.updateCurrentLesson(lesson));

        this.lessonsService.onLessonUpdate
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((lesson: DataEntity) => this.updateCurrentLesson(lesson));
    }

    private updateCurrentLesson(entity: DataEntity): void {
        this.selectedLesson = entity;
        if (this.selectedLesson['LocaleDateCreated'] === undefined) { // add localeDate if not exist
            this.selectedLesson['LocaleDateCreated'] = this.lessonsService.localeDate(this.selectedLesson.attributes.metadatas.created);
            this.selectedLesson['LocaleDateChanged'] = this.lessonsService.localeDate(this.selectedLesson.attributes.metadatas.changed);
        }
        const lessonContentArray = entity.get('reference');
        this.activitiesService
            .setActivitiesAfterLoaded(lessonContentArray)
            .take(1)
            .subscribe((entities) => {
                entities.forEach((val, index) => {
                    this.selectedLessonContent[index] = val;
                    this.orderedContent = this.selectedLessonContent.slice();
                });
            });
    }

    public createActivity(type: string): void {
        this.router.navigate(['..', 'activity', type], {relativeTo: this.route});
    }

    public openMediaCreationModal(item?: DataEntity): void {
        if (this.openDividerCreationModal) {
            this.openDividerCreationModal(item);
        }
    }

    public chooseActivity(): void {
        this.activitiesService.setSelectionMode(this.router.routerState.snapshot.url);
        this.router.navigate(['activities'], {relativeTo: this.route});
    }

    public chooseResource(): void {
        this.communicationCenter.getRoom('corpus').next('selectionMode', this.router.routerState.snapshot.url);
        this.router.navigate(['resources'], {relativeTo: this.route});
    }

    public editActivity(item: any): void {
        if (item) {
            this.lessonsService.setCurrentActivity(item);
            this.router.navigate(['..', 'activity', item.get('metadatas').typology.label.toLowerCase()], {relativeTo: this.route});
        }
    }

    public deleteActivity(item: any): void {
        let activityIndex = this.selectedLessonContent.findIndex((element) => +element.id === +item.id);
        if (activityIndex > -1) {
            this.selectedLessonContent.splice(activityIndex, 1);
        }

        activityIndex = this.orderedContent.findIndex((element) => +element.id === +item.id);
        if (activityIndex > -1) {
            this.orderedContent.splice(activityIndex, 1);
        }

        this.lessonsService.removeActivityFromLesson(item);
    }

    public navigateToMainActivity(row: DataEntity): void {
        this.activitiesService.loadActivitiesFromId(row.id.toString())
            .take(1)
            .subscribe((entity: DataEntity) => {
                let tempLabel: any;
                if (row.get('format').label === 'activity') {
                    tempLabel = this.activitiesService.getPropertyFromNestedObject(row.attributes, ['metadatas', 'typology']);
                } else {
                    tempLabel = row.get('format');
                }
                /* Here Data is of Type : ISingleActivityNavParams */
                const data: ISingleActivityNavParams = {
                    id: row.id.toString(),
                    template: tempLabel.label,
                    isLoadBeforeLaunch: true
                };
                this.dialog.open(DialogComponent, {
                    panelClass: 'activities-list-dialog',
                    width: '90%',
                    height: '100%',
                    data: data
                });
            });
    }

    public saveLesson(): void {

        this.btnLeave = false;

        this.selectedLessonContent = this.orderedContent; // update selectedLessonContent

        const lessonContent = this.orderedContent.map((activity) => {
            if (activity) {
                let activityData = activity.attributes;
                activityData.id = activity.id.toString();
                return activityData;
            }
        });

        this.selectedLesson.set('reference', lessonContent);
        this.lessonsService
            .saveLessonActivities(+this.selectedLesson.id)
            .takeUntil(this.unsubscribeInTakeUntil)
            .subscribe((entity) => {
                this.btnLeave = true;
                this.lessonsService.btnSave = false;
                this.lessonsService.saveMetadatas(this.selectedLesson.id, [] , 'lesson')
                    .subscribe(() => {
                        // this.exit();
                    });
            });
    }

    public exit(): void {
        this.lessonsService.clearTempActivities();
        if (modulesSettings.mainMenu['level0'].indexOf('lessonsDeployable') ||
            modulesSettings.mainMenu['level0'].indexOf('lessonsDeployableWithoutCommunity')) {
            this.router.navigate(['lessons', 'list', 'lessons']);
        } else {
            this.router.navigate(['lessons', 'list']);
        }
    }

    public get btnSave(): boolean{
        return this.lessonsService.btnSave;
    }

    public getContentTypeIcon(data): object {
        return this.activitiesService.getContentTypeIcon(data);
    }

    /**
     * Create a child multimedia activity for current lesson & redirect to the editor of it.
     */
    public addMultimediaActivity(): void {
        this.lessonsService.addChildMultimediaActivity(this.selectedLesson)
            .subscribe((multimediaActivity: DataEntity) => {
                this.router.navigate(['multi', multimediaActivity.id], {relativeTo: this.route});
            });
    }

    public displayAddButton(buttonLabel: string): boolean {
        return this.lessonsService.getAvailableAddButtons().includes(buttonLabel);
    }

    public editSubLesson(subLessonGranule: DataEntity): void {
        this.router.navigate(['multi', subLessonGranule.id], {relativeTo: this.route});
    }
}

