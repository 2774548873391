import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {fuseAnimations} from 'fuse-core/animations';

@Component({
    templateUrl: 'only-model-lessons-list.component.html',
    selector: 'app-only-model-lessons-list',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OnlyModelLessonsListComponent implements OnInit {
    public creatorsRolesFilter: number[] = this.lessonService.getAllowedRoleIdsForModelsCreation();

    constructor(public lessonService: LessonsService) {
    }

    ngOnInit(): any {
        this.lessonService.isShareableCommunity = false;
    }
}

