import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {GraphAssignationComponent} from './graph-assignation.component';
import {CommunicationCenterService} from '../../communication-center/index';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '../../authentication/index';
import {DataEntity} from 'octopus-connect';
import {IsUserLogged} from '../../../is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {GraphAssignationService} from './graph-assignation.service';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import { GraphAssignationExportDirective } from './graph-assignation-export.directive';

const routes: Routes = [
    {
        path: 'result',
        canActivate: [IsUserLogged],
        component: GraphAssignationComponent
    }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    SharedModule,
    CommonSharedModule,
  ],
  declarations: [
      GraphAssignationComponent,
      GraphAssignationExportDirective
  ],
  exports: [GraphAssignationComponent]
})
export class GraphAssignationModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }


    static forRoot(): ModuleWithProviders {

        return {
            ngModule: GraphAssignationModule,
            providers: [
              GraphAssignationService
            ]
        };
    }

    private postLogout(): void{
        GraphAssignationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'graph-followed');
    }

    private postAuthentication(): void {
        if (!GraphAssignationModule.isMenuSet && this.authService.hasLevel(['trainer', 'learner'])) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'graph-followed',
                'title': 'Résultats',
                'translate': 'graph-assignation.title',
                'type': 'item',
                'icon': 'followed',
                'url': '/result',
            });

            GraphAssignationModule.isMenuSet = true;
        }
    }
}

