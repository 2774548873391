import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {cloneObject} from '../../../../app/shared/utils';
import {ReplaySubject} from 'rxjs/index';

@Component({
  selector: 'app-graph-mixed',
  templateUrl: './graph-mixed.component.html',
  styleUrls: ['./graph-mixed.component.scss']
})
export class GraphMixedComponent implements OnInit {

    @Input('config') config: any;
    @ViewChild('chart') chart: ElementRef;

    public chartType = 'bar';
    public customSettingsWrapper = {};
    public infoSettings: ReplaySubject<any> = new ReplaySubject<any>();
    public showTooltip: boolean;
    public modalContent: any[];
    private selectedPoint: object;

    chartOptions = {
        onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        responsive: true,
        event: ['click'],
        title: {
            display: false,
            text: ''
        },
        elements: {
            line: {
                tension: 0 // disables bezier curves
            }
        },
        tooltips: {
            enabled: false,
            // Tooltip Element
            custom: (tooltipModel) => {
                // Tooltip Element
                const position = this.chart.nativeElement.getBoundingClientRect();

                const clonedModel = cloneObject(tooltipModel);

                this.customWrapper = {
                    wrapperY: position.y,
                    wrapperX: position.x,
                    width: position.width,
                    height: position.height,
                    tooltipY: clonedModel.caretY + 33,
                    tooltipX: clonedModel.caretX - 140,
                };
            },
            callbacks: {
                title: (tooltipItem, data) => {
                    return {
                       // title: this.titles[tooltipItem[0]['index']],
                    };
                }
            }
        },
        layout: {
            padding: {
                left: 50,
                right: 50,
                top: 25,
                bottom: 50
            }
        },
    };

    chartData = [];
    chartLabels = [];
    chartColors = [];
    legend = true;
    graphGenerated: boolean;
    titles = [];
    types = [];

    constructor(public router: Router) { }

    ngOnInit(): any {

        this.config.subscribe((data) => {
            this.showTooltip = false;

            const dataLength = data['chartData'].length;
            const chartDataLength = this.chartData.length;
            const lengthToSplice = chartDataLength - dataLength;

            if (dataLength) {
                this.chartOptions = {...this.chartOptions, ...data['chartConfig']};
                this.modalContent = data['modalContent'];
                for (let index = 0; index < dataLength; index += 1) {
                    this.chartData[index] = data['chartData'][index];
                    this.chartColors[index] = data['chartColors'][index];
                }

                if (lengthToSplice > 0) {
                    this.chartData.splice(dataLength, lengthToSplice);
                    this.chartColors.splice(dataLength, lengthToSplice);
                }

                this.chartLabels = data['chartLabels'];
                this.titles = data['titles'];
                this.graphGenerated = true;
                this.types = data['types'];
            } else {
                this.graphGenerated = false;
            }
        });

    }

    onChartClick(e): void {
        if (e.active.length > 0) {
            const chart = e.active[0]._chart;
            const activePoints = chart.getElementAtEvent(e.event);
            if ( activePoints.length > 0) {
                // get the internal index of slice in pie chart
                const clickedElementIndex = activePoints[0]._index;
                const clickedElementDataIndex = activePoints[0]._datasetIndex;

                if (this.selectedPoint && this.selectedPoint['index'] === clickedElementIndex && this.selectedPoint['dataIndex'] === clickedElementDataIndex) {
                    this.selectedPoint = null;
                    this.showTooltip = false;
                    return;
                } else {
                    this.selectedPoint = {
                        index: clickedElementIndex,
                        dataIndex: clickedElementDataIndex,
                    };

                    this.customWrapper = {
                        modalContent: this.modalContent[clickedElementDataIndex][clickedElementIndex],
                    };
                    this.infoSettings.next(this.settingsWrapper);
                    this.showTooltip = true;
                }
            }
        }
    }

    public set customWrapper(data){
        this.customSettingsWrapper = {...cloneObject(this.customSettingsWrapper), ...cloneObject(data)};
    }

    public get settingsWrapper(): any {
        return cloneObject(this.customSettingsWrapper);
    }
}

