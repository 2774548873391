import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {FormGroup} from '@angular/forms';
import {DataSource} from '@angular/cdk/collections';
import {WorkgroupService} from '@modules/groups-management/core/workgroup/workgroup.service';
import {AuthenticationService} from '@modules/authentication';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Workgroup} from '@modules/groups-management/core/definitions';
import {GroupsManagementService} from '@modules/groups-management/core/groups-management.service';
import {DataEntity} from 'octopus-connect';

@Component({
  selector: 'app-workgroup',
  templateUrl: './workgroup.component.html',
  styleUrls: ['./workgroup.component.scss']
})
export class WorkgroupComponent implements OnInit, OnDestroy {
  public displayedColumns = ['workgroupname'];
  public dataSource: WorkgroupDataSource | null;
  public newWorkgroup: any = {
    data: {
      action: 'new',
      title: 'add_workgroup',
      fields: ['workgroupname'],
      selects : {
          workgroups : () => this.workgroupService.getWorkgroupService()
      }
    },
    callback: (response) => { return this.newWorkgroupCallback(response); }
  };
  public editWorkgroup: any = {
    data: {
      action: 'edit',
      title: 'edit_workgroup',
      fields: ['id', 'workgroupname'],
      selects : {
          workgroups : () => this.workgroupService.getWorkgroupService()
      }
    },
    callback: (response) => this.editWorkgroupCallback(response)
  };

  public archiveWorkgroup: any = {
      data: {
          titleDialog: 'generic.archive',
          bodyDialog: 'groups-management.sure_archive_group',
          labelTrueDialog: 'generic.yes',
          labelFalseDialog: 'generic.no',
      },
    callback: (workgroup) => this.archiveWorkgroupCallback(workgroup),
    archiveList: (list) => this.archiveListCallback(list)

  };

  public deArchiveWorkgroup: any = {
      data: {
          titleDialog: 'generic.archive',
          bodyDialog: 'groups-management.sure_dearchive_group',
          labelTrueDialog: 'generic.yes',
          labelFalseDialog: 'generic.no',
      },
    callback: (workgroup) => this.deArchiveWorkgroupCallback(workgroup),
    dearchiveList: (list) => this.dearchiveListCallback(list)

  };

  public deleteWorkgroup: any = this.groupsManagementService.settings.canBeDelete ? {
      data: {
          titleDialog: 'groups-management.title_remove',
          bodyDialog: 'groups-management.sure_remove_group',
          labelTrueDialog: 'generic.yes',
          labelFalseDialog: 'generic.no',
      },
    callback: (workgroup) => this.deleteWorkgroupCallback(workgroup),
    deleteList: (list) => this.deleteListCallback(list)

  } : null;

  constructor(
    private workgroupService: WorkgroupService,
    private authService: AuthenticationService,
    private groupsManagementService: GroupsManagementService,
  ) {
      if (this.authService.hasLevel(['trainer', 'manager', 'administrator'])){
          this.displayedColumns = [];
          this.displayedColumns.push(...['checkbox', 'workgroupname', 'learnerslist', 'buttons']);
      }
  }

  ngOnInit() {
    this.dataSource = new WorkgroupDataSource(this.workgroupService);
    this.groupsManagementService.setHeaderTitle('groups-management.workgroups');
    this.groupsManagementService.gettingStarted = this.groupsManagementService.settings.gettingStarted.workgroups;
  }

  ngOnDestroy(): void {
    this.groupsManagementService.gettingStarted = '';
  }

  public newWorkgroupCallback(response: FormGroup): Observable<DataEntity> {
    if (response) {
      return this.workgroupService.addWorkgroup(response.getRawValue());
    }
  }

  public archiveWorkgroupCallback(workgroup: any) {
    workgroup.archived = true;
    this.workgroupService.saveWorkgroup(workgroup);
  }

  public deArchiveWorkgroupCallback(workgroup: any) {
    workgroup.archived = false;
    this.workgroupService.saveWorkgroup(workgroup);
  }


  public editWorkgroupCallback(response: FormGroup): Observable<DataEntity> {
    if (response) {
       return this.workgroupService.saveWorkgroup(response.getRawValue());
    }
  }

  public deleteWorkgroupCallback(workgroup: any) {
    this.workgroupService.deleteWorkgroup(workgroup);
  }


    public archiveListCallback(workgroups: any): void {
        for (const workgroup of workgroups){
            workgroup.archived = true;
            this.workgroupService.saveWorkgroup(workgroup);
        }
    }

    public dearchiveListCallback(workgroups: any): void {
        for (const workgroup of workgroups){
            workgroup.archived = false;
            this.workgroupService.saveWorkgroup(workgroup);
        }
    }

    public deleteListCallback(workgroups: any): void {
        for (const workgroup of workgroups){
            this.workgroupService.deleteWorkgroup(workgroup);
        }
    }
}

export class WorkgroupDataSource extends DataSource<any> {
  data: BehaviorSubject<Workgroup[]>;

  constructor(public workGroupService: WorkgroupService) {
    super();
      this.data = new BehaviorSubject<Workgroup[]>(this.workGroupService.groups);

      this.workGroupService.onWorkgroupsChanged.subscribe((data: Workgroup[]) => {
          this.data.next(data);
      });
  }

    get groupService(): WorkgroupService {
        return this.workGroupService;
    }

    connect(): Observable<any[]> {
    return this.groupService.onWorkgroupsChanged;
  }

  disconnect() { }
}
