import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MessagingService} from '../../messaging.service';
import {MatDialog} from '@angular/material';
import {MessagingAddEditComponent} from '../messaging-add-edit/messaging-add-edit.component';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {
  DOMAIN_MESSAGING,
  EVENT_MESSAGE_LIST,
  EVENT_MESSAGE_NEW,
  EVENT_THREAD_ACTIVATED,
  EVENT_THREAD_COUNT,
  EVENT_THREAD_DELETE,
  EVENT_THREAD_EDIT,
  EVENT_THREAD_EDIT_LAST_VIEWED,
  EVENT_THREAD_EDIT_OPEN_MODAL,
  EVENT_THREAD_LIST,
  EVENT_THREAD_LIST_NEXT,
  EVENT_THREAD_LIST_NEXT_HREF,
  EVENT_THREAD_NEW,
  EVENT_THREAD_UPDATE
} from '../../messaging.events';
import {isArray} from 'util';

@Component({
  selector: 'app-messaging-list',
  templateUrl: './messaging-list.component.html',
  styleUrls: ['./messaging-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessagingListComponent implements OnInit {

    nextPage: string;
    threads: any[];
    activThread: any;
    dialogRef: any;

    constructor(
        public messagingService: MessagingService,
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
    ){}

  ngOnInit(): void {
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_LIST, ({data}) => this.threads = data);
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_LIST_NEXT, ({data}) => this.threads = this.threads.concat(data));
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_LIST_NEXT_HREF, (data) => this.nextPage = data);
    // remove old subject to avoid modal opening if communication center have data for prévious push
    this.messagingService.removeSubject(DOMAIN_MESSAGING, EVENT_THREAD_EDIT_OPEN_MODAL);
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_EDIT_OPEN_MODAL, (data) => this.editThread(undefined, data));

    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_ACTIVATED, (thread) => this.activThread = thread);
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_UPDATE, (data) => {
        this.threads[this.threads.findIndex(thread => String(thread.id) === data.id)] = data;
    });
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_DELETE, (data) => {
        this.threads = this.threads.filter(thread => String(thread.id) !== data);
    });
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_NEW, (data) => {
        this.messagingService.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_COUNT, this.threads.unshift(data));
        this.activThread = data;
        this.messagingService.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_ACTIVATED, data);
        this.messagingService.updateLastViewedMessageByUser(String(this.activThread.id), String(this.activThread.lastMessage));
    });
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_EDIT, (data) => {
        this.threads[this.threads.findIndex(item => item.id === data.id)] = data;
    });
    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_THREAD_EDIT_LAST_VIEWED, (data) => {
        const toUpdateThread = this.threads.find(th => String(th.id) === String(data.id));
        toUpdateThread.lastViewed = String(data.lastViewed);

    });


    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_NEW, (message) => {
        if (String(message.thread) === String(this.activThread.id)) {
            this.activThread.lastMessage = String(message.created);
        }
    });

    this.messagingService.consumeEvent(DOMAIN_MESSAGING, EVENT_MESSAGE_LIST, (messages) => {
        if (isArray(messages) && messages.length > 0 && String(messages[0].thread) === String(this.activThread.id)) {
            const lastMessageTimestamp = messages.map(msg => parseInt(msg.created, 10)).sort((a, b) => b - a)[0];
            this.activThread.lastMessage = String(lastMessageTimestamp);
        }
    });
  }
  /**
   *
   * @param epochMillis
   */
  parseDate(epochSecond: number): Date {
    return new Date(epochSecond * 1000);
  }
  /**
   *
   * @param item
   */
  itemClicked(thread: any): void {
    this.activThread = thread;
    this.messagingService.activeThread = thread;
    this.messagingService.publishEvent(DOMAIN_MESSAGING, EVENT_THREAD_ACTIVATED, thread);
  }
  /**
   *
   * @param item
   */
  isActivThread(item: any): boolean {
    return item && this.activThread && item.id === this.activThread.id;
  }
  /**
   *
   */
  addThread(): void {
    this.dialogRef = this.dialog.open(MessagingAddEditComponent, {
        width: '600px',
        data: {
            action_type: 'add'
        }
    });
    this.dialogRef.afterClosed().subscribe(result => {});
  }
  /**
   *
   */
  editThread($event: any, threadId: number): void {
    if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
    }
    this.dialog.closeAll();
    this.dialogRef = this.dialog.open(MessagingAddEditComponent, {
        width: '600px',
        data: {
            action_type: 'edit',
            thread: this.threads.find(thread => String(thread.id) === String(threadId))
        }
    });
    this.dialogRef.afterClosed().subscribe(result => this.dialogRef = undefined);
  }
  /**
   *
   */
  loadMoreThreads(): void {
      this.messagingService.loadThreads(undefined, this.nextPage).subscribe((data) => {});
  }
  /**
   *
   */
  nextPageExists(): boolean {
    return typeof this.nextPage === 'string' && this.nextPage.length > 0;
  }
  /**
   *
   * @param thread
   * @param field
   */
  archivedStyle(thread: any): SafeStyle {
    if (thread.archived) {
        return this.sanitizer.bypassSecurityTrustStyle('gray');
    } else {
        return undefined;
    }
  }
  /**
   *
   * @param thread
   */
  canShowUnviewedChip(thread: any): boolean {
    return !this.messagingService.isArchived(thread) && !this.messagingService.isLastMessageHasBeenViewedByUser(thread);
  }
}
