import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource} from '@angular/material';
import {fuseAnimations} from 'fuse-core/animations';
import {Router} from '@angular/router';
import 'rxjs/add/operator/map';
import {FormGroup} from '@angular/forms';
import {ChaptersService} from './chapters.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ISubscription} from 'rxjs-compat/Subscription';

@Component({
    selector: 'fuse-app-chapters-list',
    templateUrl: './chapters-selection.component.html',
    styleUrls: ['./chapters-selection.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ChaptersSelectionComponent implements OnInit, OnDestroy {
    public displayedColumns = ['label'];
    public dataSource = new MatTableDataSource([]);
    public methods: any;
    public entity: any = {};
    public chaptersSelected: object[] = [];
    public selectedMethod: string = null;
    entityForm: FormGroup;
    loadChapters: any;
    chaptersChanged:  BehaviorSubject<any>;
    public selectAll = false;

    public chapters: any[] = [];
    public isLoading: boolean = false;
    private subscription: ISubscription;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        public dialogRef: MatDialogRef<ChaptersSelectionComponent>,
        private chaptersService: ChaptersService,
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
        if (data.data && data.data.length > 0){
            this.selectedMethod = data.data[0].parent;
            this.chaptersSelected = data.data;
            this.chapters = data.data;
        }
        this.chaptersChanged = data.chaptersChanged;
        this.methods = data.methods;
        this.loadChapters = data.loadChapters;
        this.setChaptersUpdateSubscription();
        this.selectMethod({value : this.selectedMethod});
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /***
     *
     * @param event : choose element in comboBox
     * @param userAction : is launch by user in html or in constructor
     */
    public selectMethod(event, userAction = false): void {
        if (event.value && event.value !== 'all') {
            this.isLoading = true;
            this.loadChapters(event.value);
        } else {
            this.isLoading = false;
            this.dataSource.data = [];
            this.resetChapters();
            this.selectAll = false;
        }
    }

    private setCheckBoxStatus(userAction): void {
        if (userAction) {
            this.updateCheck({checked: true});
            this.selectAll = true;
        } else {
            this.selectAll = this.checkAllSelected();
        }
    }

    /***
     * upate data each time after choosing a new item in combobox
     * icone stop loading and validate button become enable
     */
    private setChaptersUpdateSubscription(): void {
        this.subscription = this.chaptersChanged.subscribe((data) => {
            data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            this.dataSource.data = data;
            this.chapters = data;
            this.setCheckBoxStatus(this.isLoading);
            this.isLoading = false;
        });
    }

    resetChapters(userAction = false): void {
        if (userAction) {
            this.selectAll = false;
        }
        this.chaptersSelected = [];
    }

    selectedChapter(event: object): void{
        this.selectAll = false;

        const position = this.checkSelected(event);
        const parent = this.chapters.find((chapter) => +chapter.id === +event['parent']);
        if (position > -1) {
            this.chaptersSelected.splice(position, 1);
            this.selectChaptersChildren(event, false);
            if (parent && this.checkSelected(parent) > -1) {
                this.chaptersSelected.splice(this.checkSelected(parent), 1);
            }
        } else {
            this.chaptersSelected.push(event);
            if (parent) {
                const childrens = this.chapters.filter((children) => +children.parent === +parent.id);
                const childrenSelected = this.chaptersSelected.filter((children) => +children['parent'] === +parent.id && this.checkSelected(children) > -1);
                if (parent && this.checkSelected(parent) === -1 && childrens.length === childrenSelected.length) {
                    this.chaptersSelected.push(parent);
                }
            }
            this.selectAll = this.checkAllSelected();
            this.selectChaptersChildren(event, true);
        }
    }

    selectChaptersChildren(parent, type): void {
        if (type) {
            const childrens = this.chapters.filter((children) => +children.parent === +parent.id && this.checkSelected(children) === -1);
            this.chaptersSelected.push(...childrens);
        } else {
            const childrens = this.chapters.filter((children) => +children.parent === +parent.id);
            for (const children of childrens) {
                const position = this.checkSelected(children);
                this.chaptersSelected.splice(position, 1);
            }
        }
    }
    checkSelected(entity): number{
        return this.chaptersSelected.findIndex(obj => entity['id'] === obj['id']);
    }

    checkAllSelected(): boolean{
        if (this.dataSource.data && this.dataSource.data.length > 0 && this.dataSource.data.length === this.chaptersSelected.length) {
            return !this.dataSource.data
                .some((entity) => !this.chaptersSelected.find((chapter) => +chapter['id'] === +entity['id']));
        }

        return false;
    }

    updateCheck(check): void {
        this.resetChapters();

        if (check && check.checked) {
            this.chaptersSelected.push(...this.dataSource.data);
        }
    }

    isChildren(chapter): boolean {
        if (chapter && this.chapters && this.chapters.length) {
            return +chapter.parent !== +this.selectedMethod;
        }

        return false;
    }

}

