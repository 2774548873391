import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-ideas-wall-edition-modal',
    templateUrl: './ideas-wall-edition-modal.component.html',
    styleUrls: ['./ideas-wall-edition-modal.component.scss']
})
export class IdeasWallEditionModalComponent implements OnInit {

    ideaForm: FormGroup;
    name: string;
    text: string;

    phase: string;

    constructor(
        public dialogRef: MatDialogRef<IdeasWallEditionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.text = this.data['text'] || '';
        this.name = this.data['name'] || '';
        this.phase = this.data['phase'];

        this.ideaForm = this.formBuilder.group({
            text: [this.text],
            name: [this.name]
        });
    }

    validate(): void {
        this.dialogRef.close({
            name: this.name,
            text: this.text
        });
    }
}
